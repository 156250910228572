// @ts-nocheck
import Loading from '../Loading';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import Button from '@mui/material/Button';
import VerificationFileUploadComponent
	from "../Components/VerificationFileUploadComponent/VerificationFileUploadComponent";
import LoadingList from "../LoadingList";
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";
import '../Assets/css/settings.css';
import '../../index.css';
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from 'react-bootstrap';
import GuestSelect from '../Components/GuestSelect/GuestSelect';
import { Alert, Snackbar } from '@mui/material';

export default class BatchSenderData {

	static get key() {
		return "batch-sender-data";
	}
	static get name() {
		return "BatchSenderData";
	}
	static get code() {
		return BatchSenderData.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = BatchSenderData.key;
		this.name = BatchSenderData.name;
		this.code = BatchSenderData.code;

		this.formData = {
			param_info: "param-info"
		};

		this.objMain.setPageObject(this);
	}

	formSubmitValidation = (ev, PDFFiles) => {
		ev.preventDefault();

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState, PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	ValidateTotalDataStatus = (ev) => {
		const {csvVerificationList} = this.objMain.state;

		return csvVerificationList?.filter(item => item.row_data_status === 0)?.length > 0 || csvVerificationList?.length === 0 ? 0 : 1;
	}

	formSubmitCallback = (ev) => {
		if (this.ValidateTotalDataStatus() === 0) {
			return;
		}
		console.log(ev);
		console.log(this.constructor);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	ShowErrorMessage = (errReponse, fnCallback) => {
		console.log(errReponse);
		let { t } = this.props;
		let { modal } = this.objMain.state;

		let errMessage = errReponse.data.message;
		if (errReponse.data.hasOwnProperty("error")) {
			errMessage = errReponse.data.error;
		}

		modal.body = errMessage;
		modal.action = [(
			<Button key="ok" variant="primary" onClick={(ev) => {
				modal.body = Loading;
				modal.action = [];
				this.objMain.updateState({ modal }, fnCallback);
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		)];
		this.objMain.updateState({ modal });
	}

	ValidateRequiredField = (row) => {
		const { PDFFiles } =  this.objMain.state
		const fields = ['document_type', 'doc_title', 'pdf_name'];
		const checkGuest = row.guests?.length === 0 || row.validate_guests?.every(v => v === 1);
		const checkFile = Object.values(PDFFiles).some(
			(v) => 
				v?.originalName === row.pdf_name 
				|| v.originalName?.substring(0, v.originalName?.lastIndexOf(".")) === row.pdf_name
			);

		return !fields.some(field => !row[field]) && checkGuest && checkFile;
	}
	ValidateRequiredRow = (row) => {
		const fields = ['document_type', 'doc_title', 'pdf_name'];

		return !fields.some(field => !row[field]);
	}

	ListCsvTable = () => {
		let { t } = this.props;

		let { csvVerificationList, formData } = this.objMain.state;

		console.log("csvVerificationList", csvVerificationList)

		let datalistLoading = false;

		if (datalistLoading || csvVerificationList === null) {
			return <LoadingList />;
		}

		let rows = [];

		let rowStatus = (status) => {
			switch (status) {
				case 0:
					return (
						<ErrorIcon style={{
							color: "#e1b03e"
						}} />
					);
				case 1:
					return (
						<CheckCircleIcon color={"success"}/>
					);
				default:
					return (
						<></>
					);
			}
		}

		const removeRow = (rowIndex) => {
			csvVerificationList.splice(rowIndex, 1);
			this.objMain.updateState({
				csvVerificationList: csvVerificationList,
			})
		}

		for (let i = 0; i < csvVerificationList?.length; i++) {
			let data = csvVerificationList[i];

			rows.push(
				<tr
					key={i}
					className={`document-row-list clickable ${this.ValidateRequiredRow(data) ? "" : "verification-row-error"}`}
					onClick={(ev) => {

					}}
				>
					<td className="creator-info">
						<div className="inner-container">
							{rowStatus(data?.row_data_status)}
						</div>
					</td>
					<td className="creator-info">
						<div className={data?. document_type_validate ? 'inner-container' : 'inner-container text-red'}>
							{data?.document_type_text}
						</div>
					</td>
					{/* <td className="creator-info">
						<div className="inner-container">
							{data?.preservation_require_text}
						</div>
					</td> */}
					<td className="creator-info">
						<div className="inner-container doc_title">
							{data?.doc_title}
						</div>
					</td>
					{/* <td className="creator-info">
						<div className="inner-container">
							{data?.received_date}
						</div>
					</td> */}
					{/* <td>
						<div className="text-ellipsis">
							{data?.counter_party_name}
						</div>
					</td> */}
					<td>
						<div className="text-ellipsis">
							{data?.amount}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.currency}
						</div>
					</td>
					{/* <td>
						<div className="text-ellipsis">
							{data?.invoice_number}
						</div>
					</td> */}
					<td>
						<div className="text-ellipsis">
							{data?.product}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.reference}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.shared_folders_path}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.doc_attributes_text}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.guests_text.replace(/;/g, ',').split(',')?.map((email, index) => {
								return <span key={index} className={data?.validate_guests[index] === 1 ? '': 'text-red'} onClick={() => {
									formData.guests = {};
									this.objMain.setState({
										formData
									})
									if(data?.validate_guests[index] === 0){
										this.objMain.setState({
											showSelectGuestModal: true,
											currentIndex: i,
											currentEmail: index
										})
									}
								}}>
									{email}
								</span>
							})}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.pdf_name}
						</div>
					</td>
					<td className="verification-trash-icon">
						<div className="inner-container">
							<DeleteIcon onClick={() => {
								removeRow(i)
							}}/>
						</div>
					</td>
				</tr>
			)

		}

		if (rows.length === 0) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.doocument.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		return (
			<table className={"verification-table"}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th className='verification-doc-type'>
									<div>{rowStatus(this.ValidateTotalDataStatus())}</div>
								</th>
								<th className='verification-doc-type'>
									<div>{t('common:document.verification.doc-type')}</div>
								</th>
								{/* <th className='verification-preservation-require'>
									<div>{t('common:document.verification.preservation-require')}</div>
								</th> */}
								<th className='verification-doc-title'>
									<div>{t('common:document.verification.doc-title')}</div>
								</th>
								{/* <th className='verification-received-date'>
									<div>{t('common:document.verification.received-date')}</div>
								</th> */}
								{/* <th className='verification-counterparty'>
									<div>{t('common:document.verification.counter-party-name')}</div>
								</th> */}
								<th className='verification-amount'>
									<div>{t('common:document.verification.amount')}</div>
								</th>
								<th className='verification-currency'>
									<div>{t('common:document.verification.currency')}</div>
								</th>
								{/* <th className='verification-invoice'>
									<div>{t('common:document.verification.invoice-number')}</div>
								</th> */}
								<th className='verification-product'>
									<div>{t('common:document.verification.product')}</div>
								</th>
								<th className='verification-reference'>
									<div>{t('common:document.verification.reference')}</div>
								</th>
								<th className='verification-shared-folders-path'>
									<div>{t('common:document.verification.shared-folders-path')}</div>
								</th>
								<th className='verification-doc-attributes'>
									<div>{t('common:document.verification.doc-attributes')}</div>
								</th>
								<th className='verification-guests'>
									<div>{t('common:document.verification.guest')}</div>
								</th>
								<th className='verification-pdf-name'>
									<div>{t('common:document.verification.pdf-name')}</div>
								</th>
								<th className='verification-trash'>
									{/*<div>{t('common:document.trash')}</div>*/}
								</th>
							</tr>
						)}
					</StateFragment>
				</thead>
				<tbody>
				{rows}
				</tbody>
			</table>
		)
	}

	handleProcessFile = (files) => {
		this.objMain.setState({files})
		const {csvVerificationList, PDFFiles} = this.objMain.state;
		Object.keys(files).forEach((key) => {
			let pdfObject = files[key];
			let csvIndex = csvVerificationList.findIndex((item) => {
				const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
				if(isAnyExtFile){
					return item.pdf_name === pdfObject.originalName
				}else{
					const fileNameWithoutExt =  pdfObject.originalName ? pdfObject.originalName?.substring(0, pdfObject.originalName.lastIndexOf(".")) :  pdfObject.pdf_name?.substring(0, pdfObject.pdf_name.lastIndexOf("."));
					return item.pdf_name === fileNameWithoutExt
				}
				
			})
			if (csvIndex >= 0) {
				PDFFiles[key] = {
					...files[key],
					formData: {
						...csvVerificationList[csvIndex],
					},
					validity: true,
					initialDone: true,
				}
			}
			if(csvIndex >= 0 && this.ValidateRequiredField(csvVerificationList[csvIndex])){
				csvVerificationList[csvIndex] = {
					...csvVerificationList[csvIndex],
					row_data_status: 1
				}
			}
		})

		this.objMain.updateState({
			files: files,
			csvVerificationList,
			PDFFiles,
			PDFFile: PDFFiles[Object.keys(PDFFiles)[0]],
			formData: PDFFiles[Object.keys(PDFFiles)[0]].formData,
			PDFLoadSuccess: true,
			selectedFile: Object.keys(PDFFiles)[0],
			filesSort: [],
			isFileLoading: false,
		})
	}

	handleRemoveFile = (pdfObject) => {
		const {PDFFiles, csvVerificationList} = this.objMain.state;

		let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
		if (csvIndex >= 0) {
			csvVerificationList[csvIndex] = {
				...csvVerificationList[csvIndex],
				row_data_status: 0
			}
			delete(PDFFiles[pdfObject.hash])
		}

		this.objMain.updateState({
			PDFFiles,
			csvVerificationList
		})
	}

	// IsFileNameExistsWithExt = (files, fileName) => {
    //     let check = false
    //     Object.keys(files).forEach((key) => {
    //         let file = files[key];
    //         if (file.name === fileName) {
    //             check = true;
    //         }
    //     })
    //     return check;
    // }

    IsExistExtension = (fileName) => {
        let check = false;
        this.objMain.state.csvVerificationList?.map((item) => {
            const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
            if(!isAnyExtFile){
                const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf("."));
                if (item.pdf_name === fileNameWithoutExt) check = true
            }
        })
        return check
    }


	handleReplaceFile = () => {
		const { files, csvVerificationList, PDFFiles, selectedFile } = this.objMain.state;
		const keys = Object.keys(files || {});
		if (keys.length === 0) return;
		const fileKey = keys[keys.length - 1];
		const fileName = files[fileKey].name.split('.').slice(0, -1).join('.');
		Object.keys(files).forEach((key) => {
			const pdfObject = files[key];
			const fileNameWithoutExt = pdfObject.name.split('.').slice(0, -1).join('.');
			if(this.IsExistExtension(files[fileKey].name)){
				if(key !== fileKey && fileNameWithoutExt === fileName ){
					console.log(pdfObject);
					let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
					if (csvIndex >= 0) {
						csvVerificationList[csvIndex] = {
							...csvVerificationList[csvIndex],
							row_data_status: 0
						}
					}
					delete PDFFiles[pdfObject.hash]
					delete files[key]
				}
			}else{
				if(key !== fileKey && pdfObject.name === files[fileKey].name ){
					console.log(pdfObject);
					let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
					if (csvIndex >= 0) {
						csvVerificationList[csvIndex] = {
							...csvVerificationList[csvIndex],
							row_data_status: 0
						}
					}
					delete PDFFiles[pdfObject.hash]
					delete files[key]
				}
			}
			
		})
		this.objMain.setState({
			files,
			showAlertDuplicateUpload: false,
			PDFFiles,
			csvVerificationList
		})
	}

	handleCancelReplaceFile = () => {
		const { files } = this.objMain.state;
		const keys = Object.keys(files || {});
		if (keys.length === 0) return;
		const fileKey = keys[keys.length - 1];
		this.handleRemoveFile(files[fileKey])
		delete files[fileKey]
		this.objMain.setState({
			files,
			showAlertDuplicateUpload: false
		})
	}

	handleCancelSelectGuest = () => {
		const { unAssignUser } = this.objMain;
		const { guests } = this.objMain.state.formData;
		if(guests && Object.values(guests)[0]){
			unAssignUser(null, Object.values(guests)[0], 'guest');
		}
		this.objMain.setState({
			showSelectGuestModal: false
		})
	}

	handleConfirmSelectGuest = () => {
		const { csvVerificationList, currentIndex, currentEmail, formData, PDFFiles } = this.objMain.state;
		const { unAssignUser } = this.objMain;
		const { guests } = this.objMain.state.formData;
		console.log('test@example.com', guests);
		const listGuestEmail = csvVerificationList[currentIndex].guests.replace(/;/g, ',').split(',');
		if(Object.keys(guests || {})?.length){
			const firstKey = Object.keys(guests)[0];
			if(!listGuestEmail.includes(guests[firstKey].email)){
				listGuestEmail[currentEmail] = guests[firstKey].email;
				csvVerificationList[currentIndex].validate_guests[currentEmail] = 1;
				csvVerificationList[currentIndex].guests = listGuestEmail.join(',');
				if(this.ValidateRequiredField(csvVerificationList[currentIndex])){
					csvVerificationList[currentIndex] = {
						...csvVerificationList[currentIndex],
						row_data_status: 1
					}
				}
				unAssignUser(null, Object.values(formData.guests)[0], 'guest');
				formData.guests = {};
				Object.keys(PDFFiles).forEach((key) => {
					let pdfObject = PDFFiles[key];
					let csvIndex = csvVerificationList.findIndex((item) => {
						const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
						if(isAnyExtFile){
							return item.pdf_name === pdfObject.originalName
						}else{
							const fileNameWithoutExt = pdfObject.originalName.substring(0, pdfObject.originalName.lastIndexOf("."));
							return item.pdf_name === fileNameWithoutExt
						}
						
					})
					if (csvIndex >= 0) {
						PDFFiles[key].formData.guests = listGuestEmail.join(',');
					}
				})
				this.objMain.setState({
					showSelectGuestModal: false,
					csvVerificationList,
					formData,
					PDFFiles
				})
			}else{
				this.objMain.setState({showAlertExsist: true})
			}
		}
	}


	render() {
		let { t } = this.props;
		let { ListCsvTable } = this;

		let viewerProps = {
			"files": this.objMain.state.files || {},
			"file": {},
			"upload": true,
			"showAll": true,
			"fit": true,
			"controller": true,
			"multiple": true,
			"full": false,
			"list": true,
			"info": false,
			"objMain": this.objMain,
			"isFileLoading": false,
			"fileChangeCB": (files) => this.handleProcessFile(files),
			"fileRemoveCB": (file) => this.handleRemoveFile(file),
			"submitCB": () => {},
			"filesSort": [],
			"csvVerificationListComponent": this.objMain.state.csvVerificationList,
			"creationMode": this.objMain.state.creationMode,
			"creationType": this.objMain.state.creationType
		}

		return (
			<>
				<Snackbar
					anchorOrigin={{
					vertical: "top",
					horizontal: "right",
					}}
					open={this.objMain.state.showAlertExsist}
					autoHideDuration={3500}
					onClose={() => this.objMain.setState({showAlertExsist: false})}
				>
					<Alert severity={'warning'}>{t('common:alert.sender.batch.exsist-guest-email')}</Alert>
				</Snackbar>
				<div className={"verification-data-container form-wrapper confirmation-info"}>
					<div className={'verification-data full-form'}>
						<div className={'verification-data-table'}>
							<div className={'verification-table-outer'}>
								<ListCsvTable/>
							</div>
							<div className={'verification-upload-outer'}>
								<VerificationFileUploadComponent
									key={this.objMain.state.keyFileUpload}
									{...viewerProps}
								/>
							</div>

							<div className="form-foot verification-data-foot">
								<div className="page-action-bottom-relative">
									<div className="wrapper-button-cancel">
										<Button
											variant="contained"
											color="info"
											size='large'
											startIcon={<ChevronLeftIcon/>}
											onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}
										>
											<span className="btn-label">{t('common:documents.general.back')}</span>
										</Button>
									</div>
									<div className="wrapper-button-submit">
										<Button
											variant="contained"
											disabled={this.ValidateTotalDataStatus()===0}
											onClick={(ev) => this.formSubmitCallback(ev)}
											size='large'
											endIcon={<ChevronRightIcon/>}
										>
											<span className="btn-label">{t('common:documents.general.next')}</span>
										</Button>
									</div>
								</div>
							</div>

						</div>
					</div>

					<Modal
						show={this.objMain.state.showAlertDuplicateUpload}
						centered
					>
						<Modal.Body>
							<p>{t("common:sender.batch.duplicate-pdf-name-replace")}</p>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="outlined" type="submit" onClick={this.handleCancelReplaceFile}>
							{t("common:general.cancel")}
							</Button>
							<Button variant="contained" type="submit" onClick={this.handleReplaceFile}>
							{t("common:general.ok")}
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal
						show={this.objMain.state.showSelectGuestModal}
						centered
						dialogClassName="modal-select-guest"
						onHide={this.handleCancelSelectGuest}
					>
						<Modal.Header closeButton={true}>
							<Modal.Title>{t('common:sender.batch.select-guest-title')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<GuestSelect objMain={this.objMain} {...this.props} state={this.objMain.state}></GuestSelect>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="outlined" type="submit" onClick={this.handleCancelSelectGuest}>
							{t("common:general.cancel")}
							</Button>
							<Button variant="contained" type="submit" onClick={this.handleConfirmSelectGuest}>
							{t("common:general.ok")}
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</>
		);

	}

}
