import Main from "./Main";
import { withTranslation } from "react-i18next";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useRef, useState } from "react";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {documentMainList} from "../../Layout";

class Signer extends Main {
	constructor(props) {
		super(props);
		this.state.page = "Signer";
		this.state.code = "signer";
		this.state.function_type = 1;
		this.state.searchOption.function_type = 1;
	}

	AdditionalButtons = () => {
		let { t } = this.props;
		let { code,loadingDownload } = this.state;
		let ButtonList = [];
		const [open, setOpen] = useState(false);
		const anchorRef = useRef(null);
		const [selectedIndex, setSelectedIndex] = useState(0);
		const handleClose = (event) => {
			if (anchorRef.current && anchorRef.current.contains(event.target)) {
			  return;
			}

			setOpen(false);
		  };
		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
		};

		ButtonList.push(
			<div key={'document-create'}>
				<ButtonGroup variant="contained" ref={anchorRef}  className="btn-custom-group btn-custom-mui">
					<Link
						key="0"
						className="btn btn-custom btn-primary"
						to={`/${code}/documents/create`}
						// style={{borderRadius: '4px 0px 0px 4px'}}
					><AddIcon/>
						{t("common:main.general.create")}
					</Link>
					<Button
						size="small"
						id="document-create-button-spliter"
						className="btn btn-custom btn-primary btn-doropdown-arrow"
						aria-controls={open ? "split-button-menu" : undefined}
						aria-expanded={open ? "true" : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
						// style={{borderRadius: '0px 4px 4px 0px'}}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					sx={{
						zIndex: 1,
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
							}}
							>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									<MenuItem key="1">
									<Link
										key="1"
										to={`/${code}/documents/create/by-template`}
									>
										{t("common:main.general.create-from-word")}
									</Link>
									</MenuItem>
								</MenuList>
								</ClickAwayListener>
							</Paper>
							</Grow>
						)}
				</Popper>
			</div>
		);

		ButtonList.push(
			<Tooltip key={0} title={t("common:tooltip.common.download-csv")} arrow>
				<Button
					sx={{ mr: 2, ml: 2 }}
					key="list-download"
					variant="outlined"
					size="small"
					startIcon={loadingDownload ? <CircularProgress size={16}/> : <DownloadIcon />}

					onClick={loadingDownload ? undefined : this.onListDownloadRequestHandler}
				>
					{/* {"CSVダウンロード"} */}
					{t("common:main.general.list-download")}
				</Button>
			</Tooltip>
		);

		return ButtonList;
	};

	OperationButtons = () => {
		let { t } = this.props;
		let { searchOption, isSelectedAll, selected_documents } = this.state;
		let ButtonList = [];

		const {documents} = documentMainList.get({
			noproxy: true
		});

		ButtonList.push(
			<IconButton
				key={'btn-select-all'}
				className="btn-custom"
				color="primary"
				onClick={() => {
					this.handleSelectAll(!isSelectedAll)
				}}
			>
				{isSelectedAll ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
			</IconButton>
		);

		if (selected_documents.length === 0) {
			ButtonList.push(
				<IconButton
					key="list-reload"
					color="primary"
					disabled={!documents}
					onClick={(ev) => {
						this.GetDocumentListData({ ...searchOption, function_type: 1 }, true);
					}}
				>
					{documents ? <RefreshIcon /> : <CircularProgress size="1em" />}
				</IconButton>
			);
		} else {
			ButtonList.push(
				<Button
					key="btn-bulk-verify"
					//startIcon={<AddIcon />}
					variant="outlined"
					size="small"
					startIcon={<AccessTimeIcon />}
					onClick={this.BulkSignDocumentConfirmation} >
					{t('common:document.batch.signatures')}
				</Button>
			)
		}

		return ButtonList;
	};
}

export default withTranslation()(Signer);
