// @ts-nocheck
import axios from "axios";
import AuthService from '../../Service/Auth';
import OtpDialog from '../Auth/OtpDialog';

import {
	faAngleDoubleLeft,
	faAngleLeft,
	faCheckCircle,
	faExclamationCircle,
	faInfoCircle,
	faMinusCircle,
	faQuestionCircle,
	faStopCircle,
	faTimes,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Form, InputGroup, ListGroup, Tab, Tabs, } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import API from "../../Config/API";
import ClipIcon from '../Assets/img/icons/clip_icon_primary.svg';

import FullPreview from "../Fragments/PDFViewer/FullPreview";

import { hookstate, none, useHookstate } from "@hookstate/core";
import { Print } from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HistoryIcon from '@mui/icons-material/History';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleIcon from '@mui/icons-material/People';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ReplyIcon from '@mui/icons-material/Reply';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, Drawer, FormGroup, ListItemIcon, ListItemText, Menu, Snackbar } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import Moment from 'moment';
import { AttachFileOutlined } from "@mui/icons-material";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { pdfjs } from 'react-pdf';
import { Virtuoso } from 'react-virtuoso';
import { Android12Switch } from '../../Components/switch-customize/switch-customize';
import { getSignGuestDocumentStatus } from '../../data-access/documents/document.api';
import { breabCrumbState, folderDetailSearch, selectedRowsTreeSearch, treeSearchData, userInfoState } from '../../drive-feature/data-access/state';
import { throttle } from '../../drive-feature/utils/hooks';
import Layout from '../../Layout';
import ProfileAvatar from '../../Layout/ProfileAvatar';
import Service from '../../Service';
import formatDateIntlApi from '../../Utils/format_date_intl';
import PlaceIcon from '../Assets/img/icons/place.svg';
import PlaceTickIcon from '../Assets/img/icons/place_tick.svg';
import GetStatusInterval from "../Components/GetStatusInterval/GetStatusInterval";
import { SignatureInfoItem } from "../Documents/SignatureInfoItem";
import Error from '../Error';
import PDFViewer from '../Fragments/PDFViewer';
import Loading from '../Loading';
import FormDetail from '../Settings/AppCert/FormDetail';
import { defaultPageSize } from "./index";

import TreeSearchAutocomplete from '../../drive-feature/components/tree-search-autocomplete/tree-search-autocomplete';
import { generatePresignedUrlsForPreview, getFoldersTree } from '../../drive-feature/data-access/api';
import mappedDataToTreeView from '../../drive-feature/utils/mappedDataToTreeView';
import downloadFileUrl from '../../Utils/download-file';
import DrivePreviewDialog from "../../drive-feature/components/drive-dialogs/drive-preview-dialog";
import { getCompanyFolders } from "../../drive-feature/data-access/company-folders/company-folders.api";
import SendIcon from '@mui/icons-material/Send';
import {
	documentProcessState,
	DocumentProcessStatus
} from "../../Components/document-process-state/document-process-state";

export const forwardUserSearchState = hookstate({
	data: {},
	search: '',
	page_number: 1,
	isHasMore: true,
	page_size: defaultPageSize,
})

const loadMoreLoadingComp = (props)=> {
	return <div className='loading_more_comp'>
		{props.context.loadMoreLoading ? <CircularProgress size={20}/>:null}
	</div>
}
const Scroller = React.forwardRef(({ style, ...props }, ref) => {
	return <ListGroup {...props} ref={ref} as="ul" className="user-selection-box" ></ListGroup>
})
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const showPreviewAttachment = hookstate({
	open: false,
	files: [],
	fileData: null,
	data: {},
	fileType: "",
	fileUrl: "",
	fileName: "",
	loading: false,
	row: {},
})

const generalUserData = hookstate({
	host: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	guest: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	viewer: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"sign-permission": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"stamps": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"group-signer": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"group-viewer": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
});

function PrintIcon(props) {
	return null;
}

PrintIcon.propTypes = {fontSize: PropTypes.string};

class DocumentDetail extends Layout {

	constructor(props) {
		super(props);

		let SelectedFile = null;
		let PDFFiles = null;
		this.newProps = props?.newProps;
		if (props.docInfo) {
			PDFFiles = props.docInfo.files;

			if (Object.keys(PDFFiles).length > 0) {
				SelectedFile = Object.keys(PDFFiles)[0];
			}
		}

		this.documentProcessId = window.crypto.randomUUID();
		this.state.openOtpDialog = false;
		// this.state.isVerifiedGuest = true;
		this.state.isVerifySoftware = false;
		this.state.isVerifyEmail = false;
		this.state.isVerified = true;
		this.state.signingTypeFunc = 'SignConfirmHandler';
		this.state.msgValidate = {};
		this.state.loading = true;
		this.state.token = props.token || null;
		this.state.docInfo = props.docInfo;
		this.state.docInfo.amount = parseFloat(this.state.docInfo.amount).toString();
		this.state.PDFFiles = PDFFiles;
		this.state.SelectedFile = SelectedFile;
		this.state.AttachmentFiles = props.docInfo.attachments;
		this.state.view_status = props.docInfo.view_status ?? {};
		this.state.action = props.action || null;
		this.state.resendingEmail = {};
		this.state.appCert = null;
		this.state.appCertLoading = true;
		this.state.appCertApplied = false;
		this.state.usersGroup = ["hosts", "guests", "groups"];
		this.state.users = props.users || null;
		this.state.certApply = false;
		this.state.certUpload = {
			file: null,
			password: null,
		};
		this.state.isInvalid = {};
		this.state.operationLogFlg = false;
		this.state.docViewers = {};
		this.state.viewerIndex = [];
		// console.log(props.token);
		this.state.previewPdf = null;
		this.state.PageData = null;
		this.state.isGuest = !!(props.token || null);

		this.state.formData = {};
		this.state.fields = {};
		this.state.keyFields = {
			document_id: "document_id",
			document_type_id: "document_type",
			preservation_require: "preservation_require_label",
			active_flg: "active_flg",
			title: "title",
			amount: "amount_text",
			currency: "currency",
			product_name: "product_name",
			reference: "reference",
			filebox_path: "filebox_path",
			shared_folders_path: 'shared_folders_path',

			counter_party_name: "counter_party_name",
			contract_date: "contract_date",
			expiry_date: "expiry_date",
			received_date: "received_date",
			effective_date: "effective_date",

			sign_finish_date: "sign_finish_date",
			content: "content",

			attributes: "attributes",

			email: "email",
			family_name: "family_name",
			first_name: "first_name",
			company_name: "company_name",
			mobile: "mobile",

			dateofnoti: "dateofnoti",

			select_datenoti: "select_datenoti",
			files_sort: "files_sort",
			invoice_number: "invoice_number"
		};

		this.state.anchorElDownloadPDF = null;
		this.state.anchorElDownloadPDFMobile = null;
		this.state.downloadLoading = false;
		this.state.printAlertVisibility = false;
		this.state.printAlertResult = false;
		this.state.isDraggingViewStatus = false;
		this.pdfViewerRef = React.createRef();
		// console.log("PageData", this.state.PageData);
	}

	/** please use function componentDidMountExtends intread */
	// componentDidMount() {
	// 	let { users, token, usersGroup } = this.state;
	// 	// console.log("async componentDidMount");
	// 	this.GetLayoutResources();
	// }

	processInitDataResponse = (user_type = 'viewer', response) => {
		generalUserData[user_type].data.merge(response.data);
		generalUserData[user_type].total.set(response.total);
		generalUserData[user_type].loading.set(false);
		if (Object.keys(response.data).length === generalUserData[user_type].page_size.get({noproxy: true})) {
			generalUserData[user_type].hasMore.set(true);
		} else {
			generalUserData[user_type].hasMore.set(false);
		}
	}

	componentDidMountExtends = async () => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		let { users, token, usersGroup, docInfo, PageData, isGuest, UserInfo, modal } = this.state;

		if (!users) {
			users = {};
			for (let group of usersGroup) {
				users[group] = {};
			}
		}
		this.initComponentNew(users);

		Service.GetHostGuestViewerInitData(docInfo.function_code, 1, defaultPageSize, "").then(resp => {
			if (resp) {
				let hostResult = resp.payload.host
					, guestResult = resp.payload.guest
					, viewerResult = resp.payload.viewer
					, hostGroupResult = resp.payload["group-signer"]
					, viewerGroupResult = resp.payload["group-viewer"];

				this.processInitDataResponse('host', hostResult);

				this.processInitDataResponse('guest', guestResult);

				this.processInitDataResponse('viewer', viewerResult);

				this.processInitDataResponse('group-signer', hostGroupResult);

				this.processInitDataResponse('group-viewer', viewerGroupResult);
			}

		}).catch(err => {
			//
		});
	}

	initComponentNew(users){

		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		let { token, usersGroup, docInfo, PageData, isGuest, UserInfo, modal } = this.state;
		// if (users) {
		// 	users["sign-permission"] = {}
		// 	if (users["hosts"]) {
		// 		console.log("hosts", users["hosts"])
		// 		for (let i in users["hosts"]) {
		// 			if (users["hosts"][i]["permission"].signer && users["hosts"][i]["permission"].certificate) {
		// 				users["sign-permission"][i] = users["hosts"][i];
		// 			}
		// 		}
		// 	}
		//
		// 	users["stamps"] = {}
		// 	if (users["groups"]) {
		// 		console.log("groups", users["groups"])
		// 		for (let i in users["groups"]) {
		// 			if (users["groups"][i]["available_certs"].includes(2)) {
		// 				users["stamps"][i] = users["groups"][i];
		// 			}
		// 		}
		// 	}
		// }

		//console.log(PageData);
		if (docInfo && !PageData && !isGuest) {
			Service.GetPageData(docInfo.function_code, docInfo.function_type_id).then(resp => {
				PageData = resp;

				PageData.doc_types_index = {};
				for (let item of PageData.doc_types) {
					PageData.doc_types_index[item.id] = item;
				}

				PageData.currencies_index = {};
				for (let item of PageData.currencies) {
					PageData.currencies_index[item.code] = item;
				}

				PageData.preservation_require_index = {};
				for (let item of PageData.preservation_require) {
					PageData.preservation_require_index[item.id] = item;
				}
				this.setState({
					PageData
				});
			});
		}

		if (docInfo && !docInfo.amount_text) {
			docInfo.amount_text = this.numFormat(docInfo.amount);
		}

		for (let key in keyFields) {
			fields[key] = {
				name: key,
				value: formData[key] ? formData[key] : "",
				label: t(`common:settings.guest.${keyFields[key]}`),
			};
		}

		// if (isGuest) {

		// 	console.log(UserInfo);

		// 	// modal.props.show = true;
		// 	// modal.props.size = "xl";
		// 	// modal.props.className = "layout modal-responsive";
		// 	// modal.title = "common:documents.viewer-setting.header";
		// 	// modal.body = Loading;
		// 	// modal.action = [];

		// }

		// if (docInfo['is_applying_guest_mfa']){
		// 	this.setState({isVerifiedGuest: false});
		// 	this.getCurrentUserAttributesGuest();
		// }

		if (docInfo && !this.state.appCert) {
			this.handleGetAppCertRecord();
		}

		// console.log('userssssss', users);
		this.setState({
			users, docInfo, fields, UserInfo, modal,
		}, (ev, fnCB) => {
			this.resetDocViewerList(ev, fnCB);
			this.resetDocForwardUserList(ev, fnCB);
		});
	}

	handleGetDocumentStatus = async() => {
		let {docInfo} = this.state;

		if (
			(this.state.token === "" || this.state.token === null || this.state.token === undefined)
			&& docInfo.document_id
			&& docInfo['processing_flg'] === 1
		) { // While document in processing step
			await Service.getDocumentStatus({
				document_id: docInfo.document_id
			}).then((resp) => {
				if (resp.ok) {
					this.setState((prevState) => {
						let signers = this.ProcessGuestCollaborationForward(resp.payload.signers);
						return ({
							docInfo: {
								...prevState.docInfo,
								doc_status: resp.payload.doc_status,
								processing_flg: resp.payload.processing_flg,
								signers: signers,
								viewers: resp.payload.viewers,
							}
						});
					});
				}
			}).catch(() => {
				//
			})
		}

		if (this.state.token && docInfo['processing_flg'] === 1) {
			await getSignGuestDocumentStatus(this.state.token).then((resp) => {
				if (resp.ok) {
					this.setState((prevState) => {
						const signers = this.ProcessGuestCollaborationForward(resp.payload.signers);
						return ({
							docInfo: {
								...prevState.docInfo,
								doc_status: resp.payload.doc_status,
								signers: signers,
								viewers: resp.payload.viewers,
								processing_flg: resp.payload.processing_flg,
							}
						});
					});
				}
			}).catch(() => {
				//
			})
		}
	}

	handleGetAppCertRecord() {
		let {docInfo, token} = this.state;
		if ([1, 6, 7, 9, 11, 14].includes(docInfo.doc_status)) {
			if (docInfo.sign_permission === 1) {
				if (docInfo.signer_info && docInfo.signer_info.signing_status !== 'WAIT' && docInfo.signer_info.signed === 0) {
					if (docInfo.function_code === "verification") {

					} else if (docInfo.signer_info.cert_exists) {

					} else {
						if (token) {
							this.getCertificateApplicationInfo();
						}
					}
				}
			}
		}
	}

	numFormat(value) {
		if (isNaN(parseFloat(value))) {
			return value;
		} else {
			value = parseFloat(value);
			value = value.toString().split(".");
			value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return value.join(".");
		}
	}

	resetDocForwardUserList = (ev, fnCB) => {
		let { docInfo, users } = this.state;
		// let { docInfo, users, user_quick_filter, forwardUser } = this.state;
		let searchAvailableForwardUsers = [];
		let searchAvailableForwardUsersText = '';
		let availableForwardUsers = [];
		// if (!users) return;
		// console.log('userssssss', users);
		availableForwardUsers = Object.values(users["sign-permission"] || {});
		if (parseInt(docInfo.certificate_type) === 2) {
			availableForwardUsers = Object.values(users["stamps"] || {});
		}
		searchAvailableForwardUsers = [...availableForwardUsers];
		this.setState({
			searchAvailableForwardUsers, availableForwardUsers, searchAvailableForwardUsersText
		}, fnCB);
	}

	resetDocViewerList = (ev, fnCB) => {
		let { docInfo, users } = this.state;

		let prevDocViewers = this.state.docViewers;
		if (prevDocViewers) {
			for (let id in prevDocViewers) {
				let prevData =  prevDocViewers[id];
				if (prevData.user_type === 2) {
					generalUserData["group-viewer"].data.merge({[id]: prevData});
				} else {
					if (prevData?.groups && prevData?.groups.length > 0) {
						prevData.groups.forEach((item) => {
							if (generalUserData['viewer'].detail?.[item.id]?.data) {
								generalUserData['viewer'].detail[item.id].data.merge({[id]: prevData});
							}
						})
					} else {
						generalUserData["group-viewer"].data.merge({[id]: prevData});
					}
				}
			}
		}

		let docViewers = {};
		let viewerIndex = [];
		// if (!users) return;
		for (let user of docInfo.viewers) {
			if (user.user_type === 0) {
				docViewers[user.user_id] = {...user};
				viewerIndex.push(user.user_id);
			} else if (user.user_type === 2) {
				docViewers[user.user_id] = {...user};
				viewerIndex.push(user.user_id);
			}
		}

		let availableViewers = {};
		let searchAvailableViewers = {};
		let searchAvailableViewersText = '';
		let availableViewersGroup = {};
		let searchAvailableViewersGroup = {};
		let searchAvailableViewersGroupText = '';
		for (let user of Object.values(users?.hosts || {})) {
			if (!viewerIndex.includes(user.user_id)) {
				availableViewers[user.user_id] = users?.hosts[user.user_id];
			}
		}
		for (let user of Object.values(users?.groups || {})) {
			if (!viewerIndex.includes(user.user_id)) {
				availableViewersGroup[user.user_id] = users?.groups[user.user_id];
			}
		}

		searchAvailableViewers = { ...availableViewers };
		searchAvailableViewersGroup = { ...availableViewersGroup };
		this.setState({
			docViewers, viewerIndex, availableViewers, availableViewersGroup,
			searchAvailableViewers, searchAvailableViewersText, searchAvailableViewersGroup, searchAvailableViewersGroupText
		}, fnCB);
	}

	viewerUpdateSubmit = (ev) => {
		let { t } = this.props;
		let { docInfo, viewerIndex, modal, docViewers } = this.state;
		let document_id = docInfo.document_id;
		let viewers = viewerIndex;

		modal.body = Loading;
		modal.action = [];
		delete modal.props.size;
		delete modal.props.className;

		this.setState({ modal }, () => {
			Service.UpdateDocumentViewers({ document_id, viewers }).then(resp => {
				let { modal } = this.state;
				modal.props.show = false;
				docInfo.viewers = docViewers;
				// docInfo.viewers = resp.viewers;
				this.setState({ docInfo, modal });
			}).catch(err => {
				console.log(err.response);
				this.ShowErrorMessage(err.response, [(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={(ev) => {
							let { modal } = this.state;
							modal.props.show = false;
							this.setState({ modal });
						}}>
						<span>{t("common:general.ok")} </span>
					</Button>
				)]);
			});
		});
	}

	// componentDidUpdate() {	}
	SignerResendEmail = (ev, userId, resendProcessId) => {
		let { docInfo, resendingEmail,modal } = this.state;
		const {t} = this.props;
		let user_id = userId || parseInt(ev.target.closest(".action-resend-email").dataset.user_id);
		resendingEmail[user_id] = null;
		modal.body = Loading;
		modal.action = [];
		this.setState({ resendingEmail,modal }, () => {
			Service.resendEmail(docInfo.id, user_id).then(resp => {
				let { resendingEmail } = this.state;
				// console.log(resp);
				delete resendingEmail[user_id];
				modal.action = [];
				modal.body = () => (<div>
					{t('common:documents.dialog.resendmail.sended')}
				</div>);
				this.setState({ modal });
				this.setState({ resendingEmail }, () => {
					this.UpdateResendStatus(user_id);
				});

				documentProcessState?.[resendProcessId]?.set(prevState => ({
					...prevState,
					status: DocumentProcessStatus.SUCCESS,
					result: resp,
					successTitle: t('common:documents.dialog.resendmail.sended')
				}));
			}).catch(err => {
				let errMessage = err?.response?.data?.message;
				if (err?.response?.data?.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}
				documentProcessState?.[this.documentProcessId].set(prevState => ({
					...prevState,
					status: DocumentProcessStatus.ERROR,
					result: err,
					isSign: true,
					errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
				}));
				//console.log(err.response);
			})
		});
		// resendEmail
	}

	OpenResendMailDialog = (user) =>{

		let { modal, modalClose, docInfo } = this.state;

		const {t} = this.props;
		modal.props.show = true;
		modal.props.close = false;
		modalClose = false;
		modal.props.size = "md";
		modal.props.centered = true;
		console.log(this);
		// modal.props.className = "layout modal-responsive";
		modal.title = t("common:documents.dialog.resendmail.header");
		modal.body = () => (<div>
			{ t('common:documents.dialog.resendmail.body')}<br/>
			{user.company_name}<br/>
			{user.full_name}
		</div>);
		modal.action = [
			<Button key="submit"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={()=>{
					// modal.props.show =false;
					// this.goBackAfterSign(docInfo);

					modal.props.show = false;
					this.setState({ modal, modalClose });
					let resendProcessId = `${this.documentProcessId}${user?.user_id || user.id}`;
					console.log('docInfo1111', docInfo);
					this.retryDocumentSigning(docInfo, () => this.SignerResendEmail(undefined, user?.user_id || user.id, resendProcessId),
					t('common:documents.dialog.resendmail.processing', {
						user_id: user?.user_id || user.id
					}),
					resendProcessId);
				}}
			>
				<span>{t("common:general.send")}</span>
			</Button>
		];

		this.setState({ modal, modalClose });
	}

	SignerOperation = (props) => {
		// let { t } = this.props;
		let { user, status, isReceiver } = props;
		let { docInfo, resendingEmail } = this.state;
		let SendEmailIcon = MailOutlineIcon;
		// <FontAwesomeIcon icon={faCaretDown} className="action-icon" />

		if (docInfo.master_doc_info) {
			return null;
		}

		if (docInfo.user_type === 0 && ![0, 5].includes(docInfo.doc_status)) {
			// console.log(resendingEmail);
			// console.log(status);
			// console.log(docInfo.function_code);
			// console.log(docInfo.doc_status);
			if (!status && docInfo.function_code === "sender" && ![2, 8].includes(docInfo.doc_status) && isReceiver) {
				return null;
			}

			// console.log(user.signing_status);
			if (user.signing_status === 'WAIT') {
				return null;
			}

			// console.log(user.signing_status);
			// console.log(user)
			let sendClickHandler = ()=>this.OpenResendMailDialog(user);
      let { t } = this.props;
			if (Object.keys(resendingEmail).includes(user.id.toString())) {
				SendEmailIcon = CircularProgress;
				sendClickHandler = null;
			}
			return (
				<div className={"sign-status"} >
					<Tooltip title={t("common:tooltip.document.resend-email")}  arrow >
						<div className="icon-container clickable" >
							<SendEmailIcon data-user_id={user.id} className="action-resend-email" onClick={sendClickHandler} />
						</div>
					</Tooltip>
				</div>
			);
		} else {
			return null;
		}

	}

	UpdateResendStatus = (user_id) => {
		let { docInfo } = this.state;

		let allResent = true;
		let targetUser = null;
		for (let i in docInfo.signers) {
			let user = docInfo.signers[i];

			if (user.id === user_id) {
				user.token_expired = 0;
				targetUser = user;
			}

			if (allResent && user.token_expired === 1) {
				allResent = false;
			}

			if (!allResent && targetUser) {
				break;
			}
		}

		if (allResent) {
			if (docInfo.function_code === "sender") {
				docInfo.doc_status = 7;
			} else if (docInfo.function_code === "circle") {
				docInfo.doc_status = 11;
			} else if (docInfo.function_code === "verification") {
				docInfo.doc_status = 9;
			} else {
				docInfo.doc_status = 1;
			}
		}

		this.setState({ docInfo });
	}

	SignStatus = (props) => {
		let { t } = this.props;
		let { docInfo } = this.state;
		let { user, showStatus } = props;

		if (!showStatus || docInfo.doc_status === 0) {
			return null;
		}

		if (user.signing_status === 'WAIT') {
			return null;
		}

		let status = <CircleIcon fontSize="small" color='disabled' />;//"wait-signed";
		if (user.signing_status === 'FORWARD') {
			status = <ReplyIcon fontSize="small" color='primary' style={{ transform: "rotateZ(225deg) rotateX(180deg)" }} /> // "forward";
		} else if (user.signed === 1) {
			status = <CheckCircleIcon fontSize="small" color='success' />  //"signed";
		}// else if (user.user_type === 1 && user.token_expired === 1) {
		//	status = <CancelIcon fontSize="small" color='warning' />  //"token-expired";
		//}
		if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4 && Object.keys(docInfo.operation_log).length > 0) {
			docInfo.status_code = 'signed';
			status = <CheckCircleIcon fontSize="small" color='success' />  //"signed";
		}
		return (
			<div className={`sign-status ${docInfo.status_code}`} /*title={t(`common:document.${docInfo.status_code}`)}*/ >
				<div className="icon-container">
					{status}
				</div>
			</div>
		);
	}

	SignerList = (props) => {
		let { t } = this.props;
		let { users, label, showStatus, filter, isReceiver } = props;
		let ObjElements = [];
		let { SignerOperation, SignStatus } = this;
		// console.log(users, isReceiver)
		if (!users) {
			return null;
		}

		if (users instanceof Array && users.length === 0) {
			return null
		}

		if (users && typeof users === 'object' && Object.keys(users).length === 0) {
			return null
		}


		if (!(users instanceof Array)) {
			users = Object.values(users);
		}

		ObjElements.push(
			<Form.Group key="group-label" xl={12} md={12} as={Col} className="info-label">
				<span className="document-info-key">{t(`common:${label}`)}</span>
			</Form.Group>
		);

		let inCaseUsers = [];
		for (let user of users) {
			if (filter) {
				let goNext = false;
				for (let i in filter) {
					let filterValue = filter[i];
					if (filterValue instanceof Array) {
						if (!filterValue.includes(user[i])) {
							goNext = true;
							break;
						}
					} else if (user[i] !== filter[i]) {
						goNext = true;
						break;
					}
				}
				if (goNext) {
					continue;
				}
			}

			inCaseUsers.push(user);
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = `${user.name} ${user.company_name || ""}`;
			let bottomInfo = `${user.email || ""}`;
			if (user.user_type === 2 && user.signedBy_name) {
				bottomInfo = `${t("common:general.total-members")} ${user.members_count}`;
			} else if (user.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user.members_count}`;
			}
			// console.log('hello', isReceiver)
			ObjElements.push(
				<Form.Group key={user.id} xs={12} md={12} as={Col}>
					<div className={`user-info  user${isReceiver ?1: user.user_type}`}>
						<div className="display-avatar">
							<ProfileAvatar {...iconProps} />
						</div>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{topInfo} </span>
							</div>
							<div className="bottom-info">
								<span>{bottomInfo} </span>
							</div>
						</div>
						<SignerOperation user={user} showStatus={showStatus} isReceiver={isReceiver} />
						<SignStatus user={user} showStatus={showStatus} />
					</div>
				</Form.Group >
			);
		}

		if (inCaseUsers.length === 0) {
			ObjElements.push(
				<Form.Group key="empty-signer-list" xs={12} md={12} as={Col}>
					<div className="user-info empty"></div>
				</Form.Group >
			);
		}

		return ObjElements;

	}

	ViewerList = (props) => {
		let { t } = this.props;
		let { token } = this.state;
		let { users, label } = props;
		let ObjElements = [];

		if (!users) {
			users = [];
		}

		if (token) {
			return null;
		}

		//if (users instanceof Array && users.length === 0) {
		//	return null;
		//}

		if (!(users instanceof Array)) {
			users = Object.values(users);
		}

		let BTNEditViewer = () => {
			return (
				<IconButton
					sx={{ mr: 1, display: { xs: 'none', sm: 'inline-flex' } }}
					color="primary"
					className="btn-action"
					// title={t("common:document.edit-viewer")}
					onClick={(ev) => {
						let { modal } = this.state;

						modal.props.show = true;
						modal.props.size = "xl";
						modal.props.className = "layout modal-responsive";
						modal.title = "common:documents.viewer-setting.header";
						modal.body = this.ViewerFormBody;
						modal.action = [
							<Button
								key="reset"
								sx={{ ml: 1 }}
								variant="outlined"
								onClick={this.resetDocViewerList}
							>
								<span>{t("common:general.reset")}</span>
							</Button>,
							<Button key="submit"
								sx={{ ml: 1 }}
								variant="contained"
								onClick={this.viewerUpdateSubmit}
							>
								<span>{t("common:general.submit")}</span>
							</Button>
						];

						this.setState({ modal });
					}}>
					<VisibilityIcon fontSize="small" />
				</IconButton>
			);
		};


		ObjElements.push(
			<Form.Group key="group-label" xl={12} md={12} as={Col} className="info-label">
				<BTNEditViewer />
				<span className="document-info-key">{t(`common:${label}`)}</span>
			</Form.Group>
		);

		let ViewersList = [];
		for (let user of users) {
			let viewerGroup = "";// user.user_type === 2 ? " group" : "";
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			}
			ViewersList.push(
				<div key={user.user_id} className={`viewer-info${viewerGroup}`}>
					<div className="viewer-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<span>{user.full_name}</span>
				</div>
			);
		}

		ObjElements.push(
			<Form.Group key="viewers-list" xs={12} md={12} as={Col}>
				{ViewersList}
			</Form.Group>
		);

		return ObjElements;

	}

	SignatureInfo = (props) => {
		let { t } = this.props;
		let { label } = props;
		let { docInfo } = this.state;
		// console.log('props',props.loadingSignatureInfo)
		if(props?.loadingSignatureInfo) return <div className='signature-container'><Loading /></div>;

		const {signaturesInfo, timestampsInfo} = docInfo;

		let ObjElements = [];

		if (!signaturesInfo?.length && !timestampsInfo?.length) {
			return (
				<Form.Group key="signature-verification-list" xs={12} md={12} as={Col}>
					<div className="signature-info-item empty"></div>
				</Form.Group >
			)
		}

		ObjElements.push(
			<Form.Group key="group-label" xl={12} md={12} as={Col} className="info-label">
				<div className="signature-info-header">
					<span className="signature-info-title">{t(`common:${label}`)}</span>
				</div>

			</Form.Group>
		);

		let SignaturesList = [];
		for (let i = 0; i < signaturesInfo.length; i++) {
			const signature = signaturesInfo[i];
			SignaturesList?.push(<SignatureInfoItem key={i} signature={signature} translator={t}/>);
		}

		let TimestampsList = [];
		for (let i = 0; i < timestampsInfo.length; i++) {
			const timestamp = timestampsInfo[i];
			TimestampsList?.push(
				<div key={i} className="signature-item-container">
					<div className="signature-icon">
						<AccessTimeIcon sx={{ color: '#b5d3f2' }} fontSize={"large"} />
					</div>
					<div className="timestamp-info-item">
						<div className="signature-info-left">
							<span>{timestamp.certificateName}</span>
						</div>
						<div className="signature-info-right">
							<span>{timestamp.DateTime}</span>
						</div>
					</div>
				</div>
			);
		}

		ObjElements.push(
			<div key={1421}>
				{SignaturesList}
			</div>
		);

		ObjElements.push(
			<div key={213}>
				{TimestampsList}
			</div>
		);


		return (
			<div className='signature-container'>
				{ObjElements}
			</div>
		);
	}

	ViewerFormBody = () => {
		let { t } = this.props;
		let { SelectedUsers, AvailableUser, SearchViewerGroupsInputChange } = this;

		let {docInfo} = this.state;

		let handleGetViewerSearch = useCallback((searchInput)=> {
			generalUserData['viewer'].loading.set(true);

			Service.GetViewerList(
				docInfo.function_code,
				1,
				defaultPageSize,
				searchInput,
			).then((data) => {
				if (data.ok) {
					if (Object.keys(data.payload).length === defaultPageSize || defaultPageSize <= data.total) {
						generalUserData['viewer'].hasMore.set(true);
					} else {
						generalUserData['viewer'].hasMore.set(false);
					}
					generalUserData['viewer'].data.set(data.payload);
					generalUserData['viewer'].detail.set({});
					generalUserData['viewer'].page_number.set(1);
					generalUserData['viewer'].loading.set(false);
					generalUserData['viewer'].search.set(searchInput);

					const ids = Object.keys(data.payload);
					for (let i in data.payload) {
						const user = data.payload[i];
						if (user?.groups?.length > 0) {
							let removeFlag = false;
							for (let group of user.groups) {
								if (ids.includes(group.id)) {
									removeFlag = true;
									if (!generalUserData['viewer'].detail.get({noproxy: true})[group.id]) {
										generalUserData['viewer'].detail.merge({[group.id]: {
												data: {user},
												page_number: 1,
												page_size: defaultPageSize,
												show: true,
												loading: false,
												hasMore: false,
											}});
									} else {
										generalUserData['viewer'].detail[group.id].data.merge({[user.id]: user});
									}
								}
							}

							if (removeFlag) {
								generalUserData['viewer'].data[user.id].set(none);
							}
						}
					}
				}
			}).catch((e) => {});
		}, []);

		const searchViewer = (val) => {
			handleGetViewerSearch(val);
		}
		const handleInputViewer = useCallback(
			throttle(searchViewer, 1000, { leading: false }),
			[]
		);

		let handleGetGroupSearch = useCallback((searchInput)=>{
			generalUserData['group-viewer'].loading.set(true);

			Service.GetViewerGroup(
				docInfo.function_code,
				1,
				defaultPageSize,
				searchInput,
			).then((data) => {
				if (data.ok) {
					if (Object.keys(data.payload).length === defaultPageSize || defaultPageSize <= data.total) {
						generalUserData['group-viewer'].hasMore.set(true);
					} else {
						generalUserData['group-viewer'].hasMore.set(false);
					}
					generalUserData['group-viewer'].data.set(data.payload);
					generalUserData['group-viewer'].loading.set(false);
					generalUserData['group-viewer'].page_number.set(1);
					generalUserData['group-viewer'].search.set(searchInput);
				}
			}).catch((e) => {});
		}, []);

		const searchViewerGroup = (val) => {
			handleGetGroupSearch(val);
		}
		const handleInputViewerGroup = useCallback(
			throttle(searchViewerGroup, 1000, { leading: false }),
			[]
		);
		let AvailableGroupProps = {
			users: generalUserData["group-viewer"].data.get({noproxy: true}),
			user_type: "group-viewer",
			group_id: "group",
			by_group: false,
		};

		let AvailableUserProps = {
			users: generalUserData["viewer"].data.get({noproxy: true}),
			user_type: "viewer",
		};

		return (
			<div className="form-body flex-column full-height-modal-body">

				<Form.Row className="flex-full">

					<Form.Group xs={6} md={6} as={Col} controlId="user_list" className="signer-list-wrapper" style={{ height: "100%" }}>
						<Form.Label>{t("common:documents.viewer-setting.viewers-list")}</Form.Label>
						<div className="user-info-container" style={{ width: "100%", overflow: "auto" }}>
							<SelectedUsers />
						</div>
					</Form.Group>

					<Form.Group xs={6} md={6} as={Col} controlId="all_user_list" className="user-selection-container" style={{ height: "100%" }}>
						<Form.Label>{t("common:documents.host-setting.available-viewers-list")}</Form.Label>


						<Tabs defaultActiveKey="veiwer-by-user" transition={false} id="viewers-selection">

							<Tab eventKey="veiwer-by-user" title={t("common:documents.host-setting.viewer-by-user")}>
								<InputGroup className="form-control tab-input-group">
									<div className="search-input-container">
										<TextField
											className="search-input-field"
											placeholder={t('common:documents.general.text-input-placeholder')}
											onChange={(e) => {
												generalUserData['viewer'].search.set(e.target.value);
												this.SearchInputChange(AvailableUserProps, e);
											}}
											defaultValue={this.state.searchAvailableViewersText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
									</div>
									<AvailableUser {...AvailableUserProps}/>
								</InputGroup >
							</Tab>

							<Tab eventKey="viewer-by-group" title={t("common:documents.host-setting.viewer-by-group")}>
										<InputGroup className="form-control">
											<div className="search-input-container">
												<TextField
													className="search-input-field"
													placeholder={t('common:documents.general.text-input-placeholder')}
													onChange={(e) => SearchViewerGroupsInputChange(AvailableGroupProps, e)}
													onKeyDown={(e) => {
														if (e.key === 'Enter'){
															e.preventDefault();
															e.stopPropagation();
														}
													}}
													defaultValue={''}
													sx={{
														"& fieldset": { border: 'none' },
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment>
																<IconButton>
																	<SearchIcon />
																</IconButton>
															</InputAdornment>
														),
														style: {
															height: 40,
															paddingLeft: 5,
															fontSize: 15,
														},
													}}
												/>
											</div>
											<AvailableUser {...AvailableGroupProps} />
										</InputGroup >
								</Tab>
						</Tabs>
					</Form.Group>

				</Form.Row>

			</div>
		);
	}


	objMapping = (type) => {
		let objMap = {
			"host": {
				"form": "signers",
				"available": "availableSigners",
				"searchAvailable": "searchAvailableSigners",
				"searchText": "searchSignerText",
			},
			"guest": {
				"form": "guests",
				"available": "availableGuests",
				"searchAvailable": "searchAvailableGuests",
				"searchText": "searchGuestsText",
			},
			"viewer": {
				"form": "viewers",
				"available": "availableViewerUsers",
				"searchAvailable": "searchAvailableViewerUsers",
				"searchText": "searchViewerText",
			},
			"group-viewer": {
				"form": "viewers",
				"available": "availableViewerGroups",
				"searchAvailable": "searchAvailableViewerGroups",
				"searchText": "searchViewerGroupsText",
			},
			"group-signer": {
				"form": "signers",
				"available": "availableHostGroups",
				"searchAvailable": "searchAvailableHostGroups",
			},
		};

		return objMap[type];
	}

	/** */
	assignUser = (ev, user_id, user_type = 'viewer', by_group = false, group_id = null, isGroupDetail = false) => {
		let { docViewers, docInfo, viewerIndex } = this.state;
		// console.log(formData);
		// console.log(user_id, user_type, by_group)
		// let availableUsers = null;

		let keyMap = this.objMapping(user_type);

		// let availableUsers = this.state[keyMap.available];
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];

		let availableUsers = generalUserData[user_type].get({noproxy:true})?.data ?? {};

		if (isGroupDetail) {
			availableUsers = generalUserData[user_type].detail[group_id].get({noproxy:true}).data ?? {};
		}
		// console.log('assiged', generalUserData[user_type].detail[group_id].get({noproxy:true}), by_group);

		// console.log(availableUsers[user_id]);
		availableUsers?.[user_id]?.groups?.forEach(v=>{
			if(v.user_type !== 2) generalUserData[user_type].data?.[v.id]?.group_members_count?.set(v=>  v - 1 < 0 ? 0: v-1);

			// if(!generalUserData[user_type].detail[v.id]?.data?.get({stealth: true, noproxy: true})){
			// 	generalUserData[user_type].detail.merge({[v.id]: {
			// 		data: {
			// 			[user_id]: availableUsers[user_id]
			// 		},
			// 		page_number: 1,
			// 		page_size: defaultPageSize,
			// 		loading: true,
			// 		hasMore: true,
			// 	}});
			// } else {
			// 	generalUserData[user_type].detail[v.id].data?.merge(availableUsers[user_id]);
			// }
			// generalUserData[user_type].detail[v.id].data?.[user_id].set(none);
		})
		const processAddGroup = (groupMembers, total) => {
			// console.log('processAdd', groupMembers)
			for (let i in groupMembers) {
				let member = groupMembers[i];
				docViewers[member.id] = member;
				viewerIndex.push(member.id);
				docViewers[member.id].groupDetail = {
					isGroupDetail: true,
					group_id,
				};
				// console.log('member', member)
				if(member?.groups?.length > 0){
					member.groups.forEach((g)=>{
						if(g.user_type !== 2) generalUserData[user_type].data?.[g.id]?.group_members_count?.set(v=>  v - 1 < 0 ? 0: v-1);
					})
				}
				generalUserData[user_type].detail[group_id].data[member.id].set(none);
			}

			this.setState({ docViewers, viewerIndex });
		}
		if (by_group) {
			// localStorage.setItem(`defaultUserGroup${this?.state?.activePage?.name}`, user_id);
			// console.log( availableUsers[i], availableUsers[i].groups)

			const groupMemberCount = availableUsers[group_id].group_members_count;
			const totalMemberCount = generalUserData[user_type].detail[group_id]?.total?.get();
			if (!generalUserData[user_type].detail.get({noproxy:true})?.[group_id]?.data
				|| groupMemberCount !== Object.keys(generalUserData[user_type].detail.get({noproxy:true})?.[group_id]?.data ?? {}).length) {
				const handleGetUserResponse = async (resp) => {
					if (resp.ok) {
						generalUserData[user_type]['detail'][group_id].loading.set(() => false);
						await processAddGroup(resp.payload, resp.total);
					}
				}
				const handleGetUserError = (e) => {

				}
				generalUserData[user_type]['detail']?.[group_id]?.loading.set(true);
				// generalUserData[user_type]['detail'].merge({[group_id]: {
				// 		data: {},
				// 		page_number: 1,
				// 		page_size: defaultPageSize,
				// 		show: false,
				// 		loading: true,
				// 		hasMore: false,
				// }});

				switch (user_type) {
					case "viewer":
						Service.GetViewerGroupDetail(
							docInfo.function_code,
							group_id,
							1,
							// totalMemberCount,
							groupMemberCount,
							"",
						).then(handleGetUserResponse).catch(handleGetUserError)
						break;
				}
			} else {
				processAddGroup(generalUserData[user_type].detail[group_id].get({noproxy:true}).data ?? {});
			}


		} else {
			viewerIndex.push(user_id);
			// docViewers[user_id] = JSON.parse(JSON.stringify(availableUsers[user_id]));
			docViewers[user_id] = availableUsers[user_id];
			docViewers[user_id].groupDetail = {
				isGroupDetail,
				group_id,
			};
			// delete availableUsers[user_id];
			// delete searchAvailableUsers[user_id];
			generalUserData[user_type].data[user_id].set(none);
			if (isGroupDetail) {
				generalUserData[user_type].detail[group_id].data[user_id].set(none);
			}
		}

		// this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers });
		this.setState({ docViewers, viewerIndex });
		// console.log(formData)
	}

	unAssignUser = (ev, user, user_type) => {
		// console.log(user_type, user)AssignU
		let { docViewers } = this.state;


		if (user?.user_type === 2) {
			if (user_type === "host") {
				user_type = "group-signer";
			} else if (user_type === "viewer") {
				user_type = "group-viewer";
			}
			//  else {
			// 	user_type = "group";
			// }
		} else {
			if(user?.['members_count'] > 0 && user_type === 'viewer'){
				user_type = "group-viewer";
			}
		}

		// let keyMap = this.objMapping(user_type);
		// let availableUsers = generalUserData[user_type].data.get({noproxy:true});
		// let availableUsers = this.state[keyMap.available];
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];
		// if()
		// availableUsers[user.id] = JSON.parse(JSON.stringify(docViewers[user.id]));
		// console.log(docViewers[user.id]?.groups)
		// availableUsers[user.id] = docViewers[user.id];
		if (docViewers[user.id]?.groups && docViewers[user.id]?.groups.length > 0) {
			docViewers[user.id].groups.forEach((item) => {
				// console.log(generalUserData[user_type]?.detail?.[item.id]?.get({noproxy: true}).total, item.id, generalUserData[user_type].data?.[item.id]?.group_members_count.get(), generalUserData[user_type].data?.[item.id].get({noproxy:true}))
				if (generalUserData[user_type].detail?.[item.id]?.data) {
					generalUserData[user_type].detail[item.id].data.merge({[user.id]: docViewers[user.id]});
				}
				if(item.user_type !== 2){
					generalUserData[user_type]?.detail?.[item?.id].group_members_count?.set(v=> {
						if(v < generalUserData[user_type]?.detail?.[item.id]?.total?.get()) {
							return v + 1;
						}
						return v;
					});
					generalUserData[user_type].data?.[item.id]?.group_members_count?.set(v=> {
						// console.log(v, generalUserData[user_type]?.detail?.[item.id]?.get({noproxy: true}))
						if(v < generalUserData[user_type]?.detail?.[item.id]?.total?.get()){
							return v + 1;
						}
						return v;
					});
				}
				// console.log(generalUserData[user_type].detail[item.id].get({noproxy: true}))
			})

		} else {
			generalUserData[user_type].data.merge({[user.id]: docViewers[user.id]});
		}
		// searchAvailableUsers[user.id] = docViewers[user.id];
		// if(formData.certificate_type == '2') availableHostGroups[user.id] = docViewers[user.id];
		delete docViewers[user.id];


		const newViewerIndex = docViewers ? Object.keys(docViewers) : [];

		// console.log(availableUsers, searchAvailableUsers);
		// console.log('availableHostGroups', availableHostGroups)
		// this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers,  });
		this.setState({ docViewers, viewerIndex: newViewerIndex });

	}

	UserSelectionBox = (props) => {
		let { user, user_type = 'viewer', info, children, by_group, isGroupDetail, hidden } = props;
		let dataset = {};
		let { t } = this.props;
		let BtnIcon = faAngleLeft;
		// let by_group = true;
		if (["group-signer", "group-viewer"].includes(user_type)) {
			by_group = false;
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		} else if (by_group !== undefined) {
			// by_group = by_group;
			// do nothing
		} else {
			by_group = true;
		}

		if (by_group) {
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		}

		let OptionalAction = (props) => { return null; };
		if (props.hasOwnProperty("optional") && props.optional) {
			OptionalAction = props.optional;
		}
		// console.log(props.optional);
		// console.log();

		const signerAvailable = useHookstate(generalUserData);

		// console.log("user", user_type, user.id, generalUserData[user_type].detail.get({noproxy: true});
		// console.log("loading asdf", generalUserData[user_type].detail.get({noproxy: true}))

		// faExclamationTriangle
		// console.log('dataset', hidden, dataset)
		return (
			<ListGroup.Item as="li" className={`user-info ${hidden ? 'd-none':''}`} {...dataset} >
				<div className="action">
					<button
						type="button"
						className="btn-add-user"
						onClick={ev => {
							this.assignUser(ev, user.id, user_type, by_group, props.group_id, isGroupDetail)
						}}
					>
						{(by_group && signerAvailable[user_type].detail.get({noproxy: true})?.[props.group_id]?.loading)
							? <CircularProgress size={12}/>
							: <FontAwesomeIcon icon={BtnIcon}/>}
					</button>
				</div>
				<div className="info">
					<div
						className={info.class}
						onClick={info.click}
					>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{info.top}</span>
							</div>
							<div className="bottom-info">
								<span>{info.bottom}</span>
							</div>
						</div>
						<div className="user-info-optional">
							<OptionalAction user={user}/>
						</div>
					</div>
					{children}
				</div>
			</ListGroup.Item>
		);
	}

	MultipleUserSelectionBox = (props) => {
		let { t } = this.props;
		let { users, user_type = 'viewer', group_id, by_group, optional, isGroupDetail } = props;
		let { UserSelectionBox } = this;
		let UsersBoxElements = [];
		let {docInfo} = this.state;

		const groupSearch = generalUserData[user_type]['detail']?.[group_id]?.search?.get({noproxy:true}) || '';

		const signerAvailable = useHookstate(generalUserData);
		// console.log('Multibox', group_id, users)
		const handleGetUserResponse = (resp) => {
			if (resp.ok) {
				signerAvailable[user_type]['detail'][group_id].data.merge(resp.payload);
				signerAvailable[user_type]['detail'][group_id].loading.set(() => false);
				signerAvailable[user_type]['detail'][group_id].hasMore.set(Object.keys(resp.payload).length === defaultPageSize);
			}
		}
		const handleGetUserError = (e) => {

		}
		const handleGetMoreUser = () => {

			if (!signerAvailable[user_type]['detail'][group_id].hasMore.get() || signerAvailable[user_type]['detail'][group_id].loading.get()) {
				return;
			}

			signerAvailable[user_type]['detail'][group_id].loading.set(true);

			signerAvailable[user_type]['detail'][group_id].page_number.set(prevState => prevState + 1);

			switch (user_type) {
				case "host":
					Service.GetHostGroupDetail(
						docInfo.function_code,
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
				case "guest":
					Service.GetGuestGroupDetail(
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
				case "viewer":
					Service.GetViewerGroupDetail(
						docInfo.function_code,
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
			}
		}

		for (let i in users) {
			let user = users[i];
			let info = {
				class: "info-container",
				top: `${user?.name} ${user?.company_name || ""}`,
				bottom: user?.email,
			}

			if (["group-signer", "group-viewer"].includes(user_type)) {
				info.top = `${user.id ?? " "}: ${user?.name ?? " "}`;
				info.bottom = t("common:general.total-members") + " " + user?.members_count;
			}
			// if (group_id !== undefined) {
			// 	info = {
			// 		class: "info-container group",
			// 		top: user.name,
			// 		bottom: t("common:general.total-members") + " " + user.members_count,
			// 	};
			// }

			let userProps = {
				user: user,
				user_type: user_type,
				group_id: group_id,
				optional: optional,
				by_group: by_group,
				info: info,
				isGroupDetail: isGroupDetail,
			}
			UsersBoxElements.push(<UserSelectionBox key={group_id + "-" + i}  {...userProps} />);
		}

		if(signerAvailable.get({noproxy: true})?.[user_type]?.['detail']?.[group_id]?.hasMore){
			UsersBoxElements.push(
				<ListGroup.Item as="li" className="user-info">
					{/* <div class="action">HEhlehe</div> */}
					<div className="info">
						<div className="info-container">
							<div
								className="user-info-detail"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: "white",
									cursor: "pointer",
								}}
							>
								<div
									className="top-info"
									style={{
										height: 'auto',
									}}
									onClick={() => {
										handleGetMoreUser();
									}}
								>
									{signerAvailable.get({noproxy: true})?.[user_type]?.['detail']?.[group_id]?.loading
										? <CircularProgress size={20}/>
										: <span>{t("common.general.show_more")}</span>
									}
								</div>
								{/* <div class="bottom-info">
								<span>321s</span>
							</div> */}
							</div>
							{/* <div class="user-info-optional"></div> */}
						</div>
					</div>
				</ListGroup.Item>
			   );
		}
		return UsersBoxElements;
	}

	UserSelectionGroup = (props) => {
		let { group_id, user_type, by_group, optional, rendeUsers, show,isGroupDetail, hasMore } = props;

		let { MultipleUserSelectionBox,UserSelectionBox } = this;

		const {t} = this.props;
		let userRemain = Object.keys(rendeUsers).length;
		let collapseClass = ["custom-collapse"];
		if (show === true) {
			collapseClass.push("show");
		}

		let userProps = {
			users: rendeUsers,
			user_type: user_type,
			group_id: group_id,
			optional: optional,
			by_group: by_group,
			isGroupDetail: isGroupDetail,
		}
		// console.log('rendeUsers', rendeUsers)

		// console.log(group_id, userRemain)
		return (
			<ListGroup as="ul" className={collapseClass} style={{ height: ((userRemain + (hasMore ? 1 : 0)) * (54 )) + "px" }}>
				<MultipleUserSelectionBox {...userProps}  />
			</ListGroup>
		);
	}

	setUserGroupCollapse = (ev, user_type) => {
		let {docInfo} = this.state;
		let parent = ev.target.closest(".user-info");
		const groupDetail = generalUserData[user_type]['detail'].get({noproxy:true});
		// console.log('hehe', generalUserData[user_type]['detail']?.[parent.dataset.group].get({noproxy: true}))
		const groupSearch = generalUserData[user_type]['detail']?.[parent.dataset.group]?.search?.get({noproxy:true}) || '';
		if (generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]?.data && generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]?.show) {
			generalUserData[user_type]['detail'][parent.dataset.group].show.set(false);
			return;
		}

		if (generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]?.data && !generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]?.show && Object.keys(generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]?.data).length > 0) {
			generalUserData[user_type]['detail'][parent.dataset.group].show.set(true);
			return;
		}

		console.log(parent.dataset.group, groupSearch)
		if (!generalUserData[user_type]['detail'].get({noproxy:true})[parent.dataset.group]) {
			generalUserData[user_type]['detail'].merge({[parent.dataset.group]: {
					data: {},
					page_number: 1,
					page_size: defaultPageSize,
					show: false,
					loading: true,
					hasMore: false,
					search: groupSearch,
					total: 0,
			}});
		} else {
			generalUserData[user_type]['detail']?.[parent.dataset.group]?.search?.set(groupSearch);
		}

		// console.log("generalUserData[user_type]['detail']", generalUserData[user_type]['detail'].get({noproxy:true}))

		const handleGroupDetailResponse = (resp) => {
			if (resp.ok) {

				// console.log('user',resp.payload )
				let newDataTotal = {};
				// for (let key in resp.payload) {
				// 	let user2 = resp.payload[key];
				// 	if(user2?.groups?.length > 0){
				// 		user2.groups.forEach(b=>{
				// 			if(!newDataTotal[b.id]){
				// 				newDataTotal[b.id] = {...resp.payload[b.id]}.group_members_count;
				// 			}
				// 			// console.log('helehlelh', generalUserData[user_type].data?.[b.id]?.get({noproxy: true}))
				// 			if(resp.payload[b.id]){
				// 				resp.payload[b.id].group_members_count = resp.payload[b.id].group_members_count - 1;
				// 			}
				// 			if (resp.payload[b.id].group_members_count < 0) resp.payload[b.id].group_members_count = 0;
				// 			// data.payload[b.id]?.group_members_count?.set(v =>  v - 1 < 0 ? 0: v - 1);
				// 			// generalUserData[user_type].data?
				// 		});
				// 	}
				// }
				// console.log('resp.total', parent.dataset.group, resp.total)
				generalUserData[user_type]['detail'].merge({
					[parent.dataset.group]: {
						data: resp.payload,
						page_number: 1,
						page_size: defaultPageSize,
						show: true,
						hasMore: Object.keys(resp.payload).length === defaultPageSize,
						loading: false,
						search: groupSearch,
						total: resp.total
					}}
				);
			}
		}

		const handleGroupDetailError = (e) => {

		}

		generalUserData[user_type]['detail']?.[parent.dataset.group]?.loading.set(true);
		switch (user_type) {
			case "host":
				Service.GetHostGroupDetail(
					docInfo.function_code,
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
			case "guest":
				Service.GetGuestGroupDetail(
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
			case "viewer":
				Service.GetViewerGroupDetail(
					docInfo.function_code,
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
		}


	}
	// a = 1;
	AvailableUser = (props) => {
		let { t } = this.props;
		let { users, user_type, optional } = props;
		// user_type = 'viewer';
		let keyMap = user_type;
		let keyMap2 = this.objMapping(user_type);
		const groupState = useHookstate(generalUserData);
		// const groupedSave = useHookstate(groupedState)
		const hostAvailable = useHookstate(generalUserData);

		const loadingMore = useHookstate(false);

		let { userGroupCollapse } = this.state;
		let isGroupCheck = user_type !== 'group-signer' || user_type !== 'group-viewer' || user_type !== 'group-guest';
		let { UserSelectionGroup, UserSelectionBox } = this;
		// console.log("keyMap", keyMap);
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];
		let { docViewers, PageProps, docInfo } = this.state;
		// console.log('searchAvailableUsers', searchAvailableUsers);
		// if(formData.certificate_type == '2') searchAvailableUsers = this.state.availableHostGroups;
		// if (!searchAvailableUsers) {
		// 	return null;
		// }
		useEffect(()=>{
			// console.log('ok boy')
			if(props.user_type){
				generalUserData[user_type].loading.set(true);
				// hostAvailable[keyMap].loading.set(true);
				this.fetchDataUserList(user_type, '');
			}
		}, [props.user_type]);
		// if (parseInt(formData.sign_level) === 2 || formData.use_digital_certificate) {
		// 	strongSign = true;
		// }

		// let defaultUserGroupOpen = localStorage.getItem(`defaultUserGroup${this.state?.activePage?.name}`);

		const processGroupAndUser = (newData) => {
			let data = {...newData};
			let { docViewers } = this.state;
			let assignedData = docViewers;

			let isGroup = [];
			let noGroup2 = [];

			for (let i in data) {
				if (assignedData[i]) {
					continue;
				}
				if (
					user_type !== 'group-signer'
					&& user_type !== 'group-viewer'
					&& data[i].user_type === 2
					&& (data[i]?.group_members_count === 0 && isGroupCheck)
				) {
					continue;
				}

				if (data[i].user_type === 2) {
					// let groupUsers = groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id];
					// console.log('data[i].user_id',groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id]?.filter(a=> assignedData[a]), data[i].user_id,groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id])
					// if(groupUsers && groupUsers?.length === groupUsers?.filter(a=> assignedData[a])?.length) continue;
					// console.log('groupUsers?.filter(a=> assignedData[a])', data[i].group_members_count, groupUsers, groupUsers?.filter(a=> assignedData[a]))
					let groupProps = {
						user: {...data[i]},
						user_type: user_type,
						group_id: data[i].user_id,
						optional: optional,
						by_group: true,
						info: {
							class: "info-container group",
							click: (ev) => this.setUserGroupCollapse(ev, user_type),
							top: `${data[i].user_id ?? " "}: ${data[i].name ?? " "}`,
							bottom: t("common:general.member-remaining") + " " + `${((user_type === 'group-signer' || user_type === 'group-viewer') ? data[i].members_count : data[i].group_members_count) ?? 0}`,
						}
					}

					let userProps = {
						users: {},
						user_type: user_type,
						group_id: data[i].user_id,
						optional: optional,
						by_group: false,
						show: false,
					}

					// isGroup.push(searchAvailableUsers[i])

					if (groupProps?.by_group
						&& user_type !== 'group-signer'
						&& user_type !== 'group-viewer'
						&& !hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.loading
						&& (hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.group_members_count === 0 && isGroupCheck
						)
					) {
						continue;
					}

					isGroup.push({
						groupProps,
						userProps,
					});
				} else {
					let userProps = {
						user: {...data[i]},
						user_type: user_type,
						group_id: "no-group",
						optional: optional,
						by_group: false,
						info: {
							top: `${data[i].name} ${(data[i].company_name) ?? "" }`,
							bottom: data[i].email,
							class: "info-container",
						}
					}

					noGroup2.push({
						groupProps: null,
						userProps
					})
				}
			}

			return [...isGroup, ...noGroup2];
		}

		const loadMore = useCallback(async () => {
			if (!generalUserData[keyMap].hasMore.get()) {
				return;
			}

			loadingMore.set(true);

			hostAvailable[keyMap].page_number.set((prevState) => prevState + 1);

			const handleGetListResponse = (data) => {
				if (data.ok) {
					loadingMore.set(false);
					if (Object.keys(data.payload).length === defaultPageSize) {
						generalUserData[keyMap].hasMore.set(true);
					} else {
						generalUserData[keyMap].hasMore.set(false);
					}
					hostAvailable[keyMap].data.set((prevState) => {
						return {
							...prevState,
							...data.payload
						}
					});
				}
			}

			const handleGetListError = (e) => {}

			switch (keyMap) {
				case 'host':
					await Service.GetHostList(
						docInfo.function_code,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case 'guest':
					await Service.GetGuestList(
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case 'viewer':
					await Service.GetViewerList(
						docInfo.function_code,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case "group-signer":
					Service.GetHostGroup(
						docInfo.function_code,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError)
					break;
				case "group-viewer":
					Service.GetViewerGroup(
						docInfo.function_code,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError)
					break;
				default:
					break;
			}

		}, [])

		// console.log('runRender')
		if(hostAvailable[keyMap].loading.get({noproxy: true})) return <Loading />;
		if (!Object.keys(hostAvailable[keyMap].data.get({noproxy: true}))?.length) {
			return (
				<div></div>
			);
		}
		let renderData = hostAvailable[keyMap].data.get({noproxy: true});
		//End test
		return hostAvailable[keyMap].loading.get({noproxy: true}) ? (
			<Loading />
		) : (
			<Virtuoso
				components={{
					Scroller,
					Footer: loadMoreLoadingComp
				}}
				context={{
					loadMoreLoading: loadingMore.get({noproxy:true})
				}}
				style={{ height: 'calc(100% - 55px) !important' }}
				data={processGroupAndUser(renderData)}
				endReached={loadMore}
				overscan={100}
				itemContent={(i, group) => {
					// if (group_id !== undefined) {
					// 	info = {
					// 		class: "info-container group",
					// 		top: user.name,
					// 		bottom: t("common:general.total-members") + " " + user.members_count,
					// 	};
					// }
					// let group = groupInfo[i];
					if(group?.groupProps){
						let {docViewers}= this.state;
						let groupProps = group.groupProps;
						let userProps = group.userProps;
						let {user_type, group_id} = userProps;
						let users = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.data ?? {};
						let assignedData = docViewers;
						let rendeUsers = Object.keys(users).reduce((arr, a)=> {
							// console.log()
							if(!assignedData[a]){
								arr[a] = users[a];
							}
							return arr;
						}, {});
						let isGroupDetail = !!groupState[user_type]['detail'].get({noproxy: true})[group_id];
						let show = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.show ?? false;
						let hasMore = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.hasMore;
						// console.log('hello', groupedSave.get({stealth: true, noproxy: true}), rendeUsers)
						// if(!Object.keys(hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[group?.groupProps.group_id]?.data || {})?.length && show) return null;
						return (
							<UserSelectionBox key={`group_${group.id}`} {...groupProps}>
								<UserSelectionGroup {...userProps} isGroupDetail={isGroupDetail} hasMore={hasMore} show={show} rendeUsers={rendeUsers}  />
							</UserSelectionBox>
						);
					}
					return <UserSelectionBox key={`user_${group.userProps.user.id}`}  {...group.userProps} />
				}}
				// initialItemCount={hostAvailable[keyMap].total.get({noproxy: true})}
		  	/>
			// <ListGroup as="ul" className="user-selection-box">
			// 	{UserGroupElements}
			// </ListGroup>
		);
	}

	SearchViewerGroupsInputChange = throttle(async (props, e) => {
		let { user_type } = props;

		const {docInfo} = this.state;
		generalUserData[user_type].loading.set(true);

		const search = e.target.value;

		await Service.GetViewerGroup(
			docInfo.function_code,
			1,
			defaultPageSize,
			search
		).then((data) => {
			if (data.ok) {
				if (Object.keys(data.payload).length === defaultPageSize) {
					generalUserData[user_type].hasMore.set(true);
				} else {
					generalUserData[user_type].hasMore.set(false);
				}
				generalUserData[user_type].detail.set({});
				generalUserData[user_type].page_number.set(1);
				generalUserData[user_type].loading.set(false);
				generalUserData[user_type].total.set(data.total);
				generalUserData[user_type].data.set(data.payload);
			}
		}).catch((e) => {});

		// let availableGroups = this.state[keyMap.available];
		// if (e.target.value === '') {
		// 	this.setState({ [keyMap.searchAvailable]: { ...availableGroups }, [keyMap.searchText]: e.target.value });
		// }
		// else {
		// 	let searchAvailableGroups = {};
		// 	for (const key in availableGroups) {
		// 		if (availableGroups[key].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableGroups[key] = availableGroups[key];
		// 		}
		// 	}
		// 	this.setState({ [keyMap.searchAvailable]: { ...searchAvailableGroups }, [keyMap.searchText]: e.target.value });
		// }
	}, 700, {leading: false})

	async fetchDataUserList(user_type, search){
		const {docInfo, docViewers} = this.state;
		let keyMap2 = this.objMapping(user_type);
		const handleGetListResponse = (data) => {
			if (data.ok) {
				if (Object.keys(data.payload).length === defaultPageSize) {
					generalUserData[user_type].hasMore.set(true);
				} else {
					generalUserData[user_type].hasMore.set(false);
				}
				let assignedData = docViewers;
				// console.log('assignedData', Object.keys(assignedData || {})?.length)
				// console.log(assignedData);
				// generalUserData[user_type]['detail']?.[parent.dataset.group];
				// const ids = Object.keys(data.payload);
				// console.log('assignedData', assignedData, generalUserData[user_type].detail.get())
				// generalUserData[user_type]['detail']?.[parent.dataset.group]?.get()
				let newDataTotal = {};
				if(Object.keys(assignedData || {})?.length > 0){
					for (let key in assignedData) {
						let a = assignedData[key];
						if(a?.groups?.length > 0){
							a.groups.forEach(b=>{
								if(!newDataTotal[b.id]){
									newDataTotal[b.id] = {...data.payload[b.id]}.group_members_count;
								}
								// console.log('helehlelh', generalUserData[user_type].data?.[b.id]?.get({noproxy: true}))
								if(data.payload[b.id]){
									data.payload[b.id].group_members_count = data.payload[b.id].group_members_count - 1;
								}
								if (data.payload[b.id].group_members_count < 0) data.payload[b.id].group_members_count = 0;
								// data.payload[b.id]?.group_members_count?.set(v =>  v - 1 < 0 ? 0: v - 1);
								// generalUserData[user_type].data?
							});
							// console.log('newDataTotal', newDataTotal)
						}
					}
				}
				// console.log(newData)
				for (let i in data.payload) {
					const user = data.payload[i];
					// console.log('search', search, user_type);
					generalUserData[user_type].detail.merge({
						[user.id]: {
							data: {},
							search: search,
							total: newDataTotal[user.id] || user.group_members_count,
							group_members_count: user.group_members_count,
							hasMore: false,
							page_number: 1,
							page_size: defaultPageSize
						}
					});
				// 	// if (user?.groups?.length > 0) {
				// 		// let removeFlag = false;
				// 		// for (let group of user.groups) {
				// 		// 	if(!groupedState[group.id]){
				// 		// 		groupedState.merge({[group.id]: [user.user_id] })
				// 		// 	} else {
				// 		// 		groupedState[group.id].merge([user.user_id]);
				// 		// 	}
				// 		// 	if (ids.includes(group.id)) {
				// 		// 		removeFlag = true;
				// 		// 		if (!generalUserData[user_type].detail.get({noproxy: true})[group.id]) {
				// 		// 			generalUserData[user_type].detail.merge({
				// 		// 				[group.id]: {
				// 		// 					data: {[user.id]: user},
				// 		// 					page_number: 1,
				// 		// 					page_size: defaultPageSize,
				// 		// 					show: true,
				// 		// 					loading: false,
				// 		// 					hasMore: false,
				// 		// 				}
				// 		// 			});
				// 		// 		} else {
				// 		// 			generalUserData[user_type].detail[group.id].data.merge({[user.id]: user});
				// 		// 		}
				// 		// 	}
				// 		// }

				// 		// if (removeFlag) {
				// 		// 	generalUserData[user_type].data[user.id].set(none);
				// 		// }
				// 	// }
				}

				generalUserData[user_type].page_number.set(1);
				setTimeout(()=>{
					generalUserData[user_type].loading.set(false);
				}, 100);
				// generalUserData[user_type].loading.set(false);
				generalUserData[user_type].total.set(data.total);
				generalUserData[user_type].data.set(data.payload);
			}
		}

		const handleGetListError = (e) => {
			generalUserData[user_type].loading.set(false);
		}
		generalUserData[user_type].loading.set(true);
		// console.log(user_type)
		switch (user_type) {
			case 'host':
				await Service.GetHostList(
					docInfo.function_code,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case 'guest':
				await Service.GetGuestList(
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case 'viewer':
				await Service.GetViewerList(
					docInfo.function_code,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case "group-signer":
				Service.GetHostGroup(
					docInfo.function_code,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError)
				break;
			default:
				generalUserData[user_type].loading.set(false);
				break;
		}
	}

	SearchInputChange = throttle(async (props, e) => {
		let {user_type} = props;
		const search = e.target.value;
		console.log('search', search, user_type)
		this.fetchDataUserList(user_type, search);
		// let keyMap = this.objMapping(user_type);
		// let availableUsers = this.state[keyMap.available];
		// console.log(keyMap.available);
		// if (e.target.value === '') {
		// 	this.setState({ [keyMap.searchAvailable]: { ...availableUsers }, [keyMap.searchText]: e.target.value });
		// }
		// else {
		// 	let searchAvailableUsers = {};
		// 	for (const key in availableUsers) {
		// 		if (keyMap.available === 'availableGuests' && availableUsers[key].company_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableUsers[key] = availableUsers[key];
		// 			continue;
		// 		}
		// 		if (availableUsers[key]?.email?.indexOf(e.target.value) > -1 || availableUsers[key]?.name?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableUsers[key] = availableUsers[key];
		// 		} else {
		// 			if (availableUsers[key].groups.length > 0) {
		// 				for (let i = 0; i < availableUsers[key].groups.length; i++) {
		// 					if (availableUsers[key].groups[i].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 						if (searchAvailableUsers.hasOwnProperty(key)) {
		// 							searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
		// 						} else {
		// 							searchAvailableUsers[key] = { ...availableUsers[key] };
		// 							searchAvailableUsers[key].groups = [];
		// 							searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// 	this.setState({ [keyMap.searchAvailable]: { ...searchAvailableUsers }, [keyMap.searchText]: e.target.value });
		// }
	}, 1000, { leading: false });

	SelectedUsers = (props) => {
		let { t } = this.props;
		// let { formData, fields } = this.state;
		let { users, user_type } = props;
		let objElements = [];
		// let { ProfileAvatar } = this;
		let sort_users = Object.values(users);

		sort_users.sort(function (a, b) {
			return a.sign_seq - b.sign_seq;
		});
		// console.log(users, user_type);
		for (let user of sort_users) {
			let iconProps = {};
			if (user?.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user?.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = `${user?.name} ${user_type === "guest" ? (user?.company_name || "") : ""}`;
			let bottomInfo = `${user?.email || ""}`;
			if (user?.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user?.members_count}`;
			}

			let OptionalAction = () => { return null; };
			if (props.hasOwnProperty("optional") && props.optional) {
				OptionalAction = props.optional;
			}

			objElements.push(
				<div key={user?.id} className={`user-info user${user?.user_type}`}>
					<div className="display-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div
						className="user-info-detail"
						// style={{
						// 	width: "50%"
						// }}
					>
						<div className="top-info">
							<span>{topInfo} </span>
						</div>
						<div className="bottom-info">
							<span>{bottomInfo} </span>
						</div>
					</div>
					{user?.user_type === 1 && user?.status === 3 && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								flex: 1,
							}}
						>
							<p
								className={"approval-pending-label"}
								style={{
									fontSize: 12,
								}}
							>
								{t("common:settings.guest.status-approval-pending")}
							</p>
						</div>
					)}
					<div className="user-info-optional">
						<OptionalAction user={user} />
					</div>
					<div className="remove-user">
						<button type="button" className="btn-icon" onClick={(ev) => { this.unAssignUser(ev, user, user_type) }}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</div>
			);
		}

		return objElements;

	}

	// AvailableUser = (props) => {
	// 	// let { searchAvailableViewers } = this.state;
	// 	let { t } = this.props;

	// 	let { users, groupCollapsed, docInfo } = this.state;
	// 	let { UserSelectionBox } = this;

	// 	const generalUserList = useHookstate(generalUserData);

	// 	const loadingMore = useHookstate(false);

	// 	const loadMore = useCallback(async () => {
	// 		if (!generalUserData['viewer'].hasMore.get({noproxy: true})
	// 			|| generalUserData['viewer'].loading.get({noproxy: true})
	// 		) {
	// 			return;
	// 		}

	// 		loadingMore.set(true);

	// 		Service.GetViewerList(
	// 			docInfo.function_code,
	// 			generalUserList['viewer'].page_number.get({noproxy: true, stealth: true}) + 1,
	// 			generalUserList['viewer'].page_size.get({noproxy: true, stealth: true}),
	// 			generalUserList['viewer'].search.get({noproxy: true, stealth: true}),
	// 		).then((data) => {
	// 			if (data.ok) {
	// 				loadingMore.set(false);
	// 				generalUserList['viewer'].page_number.set((prevState) => prevState + 1);
	// 				if (Object.keys(data.payload).length === defaultPageSize) {
	// 					generalUserList['viewer'].hasMore.set(true);
	// 				} else {
	// 					generalUserList['viewer'].hasMore.set(false);
	// 				}
	// 				generalUserList['viewer'].data.set((prevState) => {
	// 					return {
	// 						...prevState,
	// 						...data.payload
	// 					}
	// 				});
	// 			}
	// 		}).catch((e) => {});


	// 	}, [docInfo.function_code, generalUserList]);


	// 	if (!Object.keys(generalUserList['viewer'].data.get({noproxy: true})).length) {
	// 		return <div></div>;
	// 	}

	// 	if (generalUserList['viewer'].loading.get({noproxy: true})) {
	// 		return <Loading />;
	// 	}

	// 	const handleGroupDetail = (group) => {
	// 		if (!generalUserList['viewer']['detail'].get({noproxy:true})[group.user_id]) {
	// 			generalUserList['viewer']['detail'].merge({
	// 				[group.user_id]: {
	// 					data: {},
	// 					page_number: 1,
	// 					page_size: defaultPageSize,
	// 					show: false,
	// 					loading: true,
	// 					hasMore: true,
	// 				}});
	// 		}
	// 		return new Promise(resolve=>{
	// 			Service.GetViewerGroupDetail(
	// 				docInfo.function_code,
	// 				group.user_id,
	// 				1,
	// 				 defaultPageSize,
	// 				"",
	// 			).then((resp) => {
	// 				if (resp.ok) {
	// 					generalUserList['viewer']['detail'][group.user_id].data.merge(resp.payload);
	// 					generalUserList['viewer']['detail'][group.user_id].show.set(()=>true);
	// 					generalUserList['viewer']['detail'][group.user_id].loading.set(() => false);
	// 					generalUserList['viewer']['detail'][group.user_id].hasMore.set(Object.keys(resp.payload).length === defaultPageSize);
	// 					// console.log(`generalUserList['viewer']['detail'][group.user_id]`, generalUserList['viewer']['detail'][group.user_id].get({stealth: true}))
	// 					this.setState({ groupCollapsed: group.user_id });
	// 					resolve(group.user_id);
	// 				}
	// 			}).catch(() => {

	// 			})
	// 		})
	// 		// group?.group_members_count ?? defaultPageSize,

	// 	}
	// 	const handleGetMoreGroupDetail = (group) => {
	// 		if (!generalUserList['viewer']['detail'][group.user_id].hasMore.get({noproxy: true})
	// 			|| generalUserList['viewer']['detail'][group.user_id].loading.get({noproxy: true})
	// 		) {
	// 			return;
	// 		}

	// 		generalUserList['viewer'].detail[group.user_id].loading.set(true);

	// 		Service.GetViewerGroupDetail(
	// 			docInfo.function_code,
	// 			group.user_id,
	// 			generalUserList['viewer'].detail.get({noproxy: true})?.[group.user_id]?.page_number + 1,
	// 			defaultPageSize,
	// 			"",
	// 		).then((resp) => {
	// 			if (resp.ok) {
	// 				generalUserList['viewer'].detail[group.user_id].data.merge(resp.payload);
	// 				generalUserList['viewer'].detail[group.user_id].loading.set(false);
	// 				generalUserList['viewer'].detail[group.user_id].page_number.set((prevState) => prevState + 1);
	// 				generalUserList['viewer'].detail[group.user_id].hasMore.set(Object.keys(resp.payload).length === defaultPageSize);
	// 			}
	// 		}).catch(() => {

	// 		})
	// 	}

	// 	const processGroupAndUser = (newData) => {
	// 		let groupBox = [];
	// 		let noGroup = [];

	// 		for (let id in newData) {
	// 			let group = newData[id];
	// 			if (group?.user_type === 2) {
	// 				if(group.group_members_count === 0) {
	// 					continue;
	// 				}
	// 				if (!generalUserList['viewer'].detail.get({noproxy: true})?.[id]?.loading
	// 					&& (
	// 						generalUserList['viewer'].detail.get({noproxy: true})?.[id]?.data
	// 						&& Object.keys(generalUserList['viewer'].detail.get({noproxy: true})?.[id]?.data).length === 0
	// 					)
	// 				) {
	// 					continue;
	// 				}
	// 				let memberBox = [];
	// 				const groupDetail = generalUserList['viewer'].get({noproxy: true})?.detail?.[id]?.data ?? {};

	// 				if (Object.keys(groupDetail).length > 0) {
	// 					for (let user_id in groupDetail) {
	// 						memberBox.push(
	// 							<UserSelectionBox
	// 								key={`${id} -${groupDetail[user_id].user_id}`}
	// 								info={{
	// 									icon: faAngleLeft,
	// 									class: "info-container",
	// 									top: groupDetail[user_id].full_name || groupDetail[user_id].name,
	// 									bottom: groupDetail[user_id].email,
	// 									user: groupDetail[user_id],
	// 									groupDetail: newData[id],
	// 								}}
	// 							/>
	// 						);
	// 					}

	// 					if (generalUserList['viewer'].detail.get({noproxy: true})?.[id]?.hasMore) {
	// 						memberBox.push(
	// 							<ListGroup.Item as="li" className="user-info">
	// 								<div className="info">
	// 									<div className="info-container">
	// 										<div
	// 											className="user-info-detail"
	// 											style={{
	// 												display: 'flex',
	// 												justifyContent: 'center',
	// 												alignItems: 'center',
	// 												backgroundColor: "white",
	// 												cursor: "pointer",
	// 											}}
	// 										>
	// 											<div
	// 												className="top-info"
	// 												style={{
	// 													height: 'auto',
	// 												}}
	// 												onClick={() => {
	// 													handleGetMoreGroupDetail(group);
	// 												}}
	// 											>
	// 												{generalUserList.get({noproxy: true})?.['viewer']?.['detail']?.[id]?.loading
	// 													? <CircularProgress size={20}/>
	// 													: <span>{t("common.general.show_more")}</span>
	// 												}
	// 											</div>
	// 										</div>
	// 									</div>
	// 								</div>
	// 							</ListGroup.Item>
	// 						);
	// 					}
	// 				}

	// 				let groupBoxClass = `custom-collapse${groupCollapsed === id  ? " show" : ""}`;
	// 				let groupBoxStyle = {
	// 					height: (((groupDetail ? Object.keys(groupDetail).length : 0) + (generalUserList['viewer'].detail.get({noproxy: true})?.[id]?.hasMore ? 1 : 0)) * 54) + "px"
	// 				};
	// 				groupBox.push(
	// 					<UserSelectionBox
	// 						key={id}
	// 						info={{
	// 							icon: faAngleDoubleLeft,
	// 							class: "info-container group",
	// 							top: group.full_name || group.name,
	// 							bottom: `${t("common:general.member-remaining")} ${group.group_members_count}`,
	// 							dataset: {"data-group": id},
	// 							user: group,
	// 							click: (ev) => {
	// 								if (this.state.groupCollapsed === id) {
	// 									this.setState({groupCollapsed: null});
	// 								} else {
	// 									if (generalUserList['viewer'].detail.get({noproxy: true})?.[id]) {
	// 										this.setState({groupCollapsed: id});
	// 									} else {
	// 										handleGroupDetail(group).then(()=>{
	// 											this.setState({groupCollapsed: id});
	// 										});
	// 									}
	// 								}
	// 							},
	// 						}}
	// 					>
	// 						<ListGroup as="ul" className={groupBoxClass} style={groupBoxStyle}>
	// 							{memberBox}
	// 						</ListGroup>
	// 					</UserSelectionBox>
	// 				);
	// 			} else {
	// 				noGroup.push(id);
	// 			}

	// 		}

	// 		noGroup.forEach((id) => {
	// 			let user = newData[id];
	// 			groupBox.push(
	// 				<UserSelectionBox
	// 					key={id}
	// 					info={{
	// 						icon: faAngleLeft,
	// 						class: "info-container",
	// 						top: user.full_name || user.name,
	// 						bottom: user.email,
	// 						user: user,
	// 					}}
	// 				/>
	// 			);
	// 		});

	// 		return groupBox;
	// 	}

	// 	return (
	// 		<Virtuoso
	// 			components={{
	// 				Scroller,
	// 				Footer: loadMoreLoadingComp
	// 			}}
	// 			context={{
	// 				loadMoreLoading: loadingMore.get({noproxy:true})
	// 			}}
	// 			style={{ height: 'calc(100% - 55px) !important' }}
	// 			data={processGroupAndUser(generalUserList['viewer'].data.get({noproxy: true}))}
	// 			endReached={loadMore}
	// 			overscan={200}
	// 			itemContent={(i, group) => {
	// 				return group;
	// 			}}
	// 		/>
	// 	)
	// }

	AvailableGroup = (props) => {
		let { docInfo } = this.state;

		const generalGroupList = useHookstate(generalUserData);

		const loadingMore = useHookstate(false);

		const loadMore = useCallback(async () => {
			if (!generalGroupList['group-viewer'].hasMore.get({noproxy: true, stealth: true})
				|| generalGroupList['group-viewer'].loading.get({noproxy: true, stealth: true})
			) {
				return;
			}

			loadingMore.set(true);

			generalGroupList['group-viewer'].page_number.set((prevState) => prevState + 1);

			Service.GetViewerGroup(
				docInfo.function_code,
				generalUserData['group-viewer'].page_number.get({noproxy: true}),
				generalUserData['group-viewer'].page_size.get({noproxy: true}),
				generalUserData['group-viewer'].search.get({noproxy: true}),
			).then((data) => {
				if (data.ok) {
					loadingMore.set(false);
					if (Object.keys(data.payload).length === defaultPageSize) {
						generalUserData['group-viewer'].hasMore.set(true);
					} else {
						generalUserData['group-viewer'].hasMore.set(false);
					}
					generalUserData['group-viewer'].data.set((prevState) => {
						return {
							...prevState,
							...data.payload
						}
					});
				}
			}).catch((e) => {});
		}, []);

		if (!Object.keys(generalGroupList['group-viewer'].data.get({noproxy: true})).length) {
			return (
				<div></div>
			);
		}

		if (generalGroupList['group-viewer'].loading.get({noproxy: true})) {
			return <Loading />;
		}

		let { t } = this.props;
		let { UserSelectionBox } = this;

		let groupBox = [];

		for (let id in generalGroupList['group-viewer'].data.get({noproxy: true})) {
			let user = generalGroupList['group-viewer'].data.get({noproxy: true})[id];
			// if(user.group_members_count === 0) {
			// 	continue;
			// }
			groupBox.push(
				<UserSelectionBox key={user.user_id} info={{
					icon: faAngleDoubleLeft,
					class: "info-container group",
					top: user.full_name,
					bottom: `${t("common:general.member-remaining")} ${user.members_count}`,
					user: user,
					type: "group-viewer",
				}} />
			);
		}


		return (
			<Virtuoso
				components={{
					Scroller,
					Footer: loadMoreLoadingComp
				}}
				context={{
					loadMoreLoading: loadingMore.get({noproxy:true})
				}}
				style={{ height: 'calc(100% - 55px) !important' }}
				data={groupBox}
				endReached={loadMore}
				overscan={100}
				itemContent={(i, group) => {
					return group;
				}}
			/>
		)
	}

	// UserSelectionBox = (props) => {
	// 	let { children, info } = props;
	// 	// con
	// 	// console.log('info', info)
	// 	const generalUserList = useHookstate(generalUserData);

	// 	return (
	// 		<ListGroup.Item as="li" className={`user-info`} {...info.dataset} >
	// 			<div className="action">
	// 				<button
	// 					type="button"
	// 					className="btn-add-user"
	// 					onClick={ev => {
	// 						let {
	// 							docViewers, viewerIndex, availableViewers, availableViewersGroup,
	// 							searchAvailableViewers, searchAvailableViewersGroup, docInfo
	// 						} = this.state;

	// 						if (info.user.user_type === 2) {
	// 							if (info.type !== "group-viewer") {
	// 								// Viewer User Group
	// 								const groupDetail = generalUserList["viewer"].detail.get({noproxy: true})?.[info.user.user_id]?.data ?? {};
	// 								if (groupDetail && Object.keys(groupDetail).length > 0) {
	// 									for (let member_id in groupDetail) {
	// 										docViewers[member_id] = groupDetail[member_id];
	// 										viewerIndex.push(member_id);
	// 										generalUserList['viewer'].detail[info.user.user_id].data[member_id].set(none);
	// 									}
	// 								} else {
	// 									generalUserList['viewer']['detail'].merge({
	// 										[info.user.user_id]: {
	// 											data: {},
	// 											page_number: 1,
	// 											page_size: defaultPageSize,
	// 											show: false,
	// 											loading: true,
	// 											hasMore: true,
	// 										}});

	// 									Service.GetViewerGroupDetail(
	// 										docInfo.function_code,
	// 										info.user.user_id,
	// 										1,
	// 										info.user?.group_members_count ?? defaultPageSize,
	// 										"",
	// 									).then((resp) => {
	// 										if (resp.ok) {
	// 											let {docViewers, viewerIndex} = this.state;
	// 											for (let member_id in resp.payload) {
	// 												docViewers[member_id] = {
	// 													...resp.payload[member_id],
	// 													groupDetail: info.user,
	// 												};
	// 												viewerIndex.push(member_id);
	// 											}

	// 											viewerIndex = [...new Set(viewerIndex)];

	// 											this.setState({
	// 												docViewers, viewerIndex
	// 											})
	// 											generalUserList['viewer']['detail'][info.user.user_id].loading.set(() => false);
	// 											generalUserList['viewer']['detail'][info.user.user_id].hasMore.set(Object.keys(resp.payload).length === defaultPageSize);
	// 										}
	// 									}).catch(() => {

	// 									})
	// 								}
	// 							} else {
	// 								docViewers[info.user.user_id] = info.user;
	// 								viewerIndex.push(info.user.user_id);
	// 								generalUserList['group-viewer'].data[info.user.user_id].set(none);
	// 							}
	// 						} else {
	// 							docViewers[info.user.user_id] = info.user;
	// 							viewerIndex.push(info.user.user_id);
	// 							if (info?.groupDetail) {
	// 								docViewers[info.user.user_id] = {
	// 									...info.user,
	// 									groupDetail: info.groupDetail,
	// 								};
	// 								generalUserList['viewer'].detail[info.groupDetail.user_id].data[info.user.user_id].set(none);
	// 							} else {
	// 								generalUserList['viewer'].data[info.user.user_id].set(none);
	// 							}
	// 						}
	// 						// console.log(docViewers)

	// 						viewerIndex = [...new Set(viewerIndex)];

	// 						this.setState({
	// 							docViewers, viewerIndex, availableViewers, availableViewersGroup,
	// 							searchAvailableViewers, searchAvailableViewersGroup
	// 						}, () => {
	// 							let viewerBoxSelected = document.querySelector(".layout .signer-list-wrapper .user-info-container");
	// 							viewerBoxSelected.scrollTop = 10000;
	// 						});
	// 					}}
	// 				>
	// 					{(info.user.user_type === 2 && generalUserList["viewer"].detail.get({noproxy: true})?.[info.user.user_id]?.loading)
	// 						? <CircularProgress size={12}/>
	// 						: <FontAwesomeIcon icon={info.icon}/>
	// 					}
	// 				</button>
	// 			</div>
	// 			<div className="info">
	// 				<div className={info.class} onClick={info.click}>
	// 					<div className="user-info-detail">
	// 						<div className="top-info">
	// 							<span>{`${info.user.user_id ?? info.user.id ?? " "} - ${info.top ?? " "}`}</span>
	// 						</div>
	// 						<div className="bottom-info">
	// 							<span>{info.bottom}</span>
	// 						</div>
	// 					</div>
	// 					<div className="user-info-optional">
	// 						{/* <OptionalAction user={user} /> */}
	// 					</div>
	// 				</div>
	// 				{children}
	// 			</div>
	// 		</ListGroup.Item>
	// 	);
	// }

	SelectedUsers = (props) => {
		let { t } = this.props;
		let { docViewers, viewerIndex } = this.state;
		// let {user_type} = props;
		let objElements = [];

		const generalUserList = useHookstate(generalUserData);
		console.log(docViewers)
		for (let userID in docViewers) {
			let user = docViewers[userID];
			// console.log(user);
			let iconProps = {};
			if (user?.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user?.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = user.user_id + "-" + user.full_name;
			let bottomInfo = user.email;
			if (user.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user.members_count || user.group_members_count}`;
			}

			let OptionalAction = () => { return null; };
			if (props.hasOwnProperty("optional") && props.optional) {
				OptionalAction = () => {
					return (
						<div className="user-info-optional">
							{props.optional}
						</div>
					);
				};

			}

			objElements.push(
				<div key={userID} className="user-info">
					<div className="display-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div className="user-info-detail">
						<div className="top-info">
							<span>{topInfo} </span>
						</div>
						<div className="bottom-info">
							<span>{bottomInfo} </span>
						</div>
					</div>
					<OptionalAction user={user} />
					<div className="remove-user">
					<button	button type="button" className="btn-icon" onClick={(ev) => { this.unAssignUser(ev, user, 'viewer') }}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</div>
			);
		}

		return objElements;
	}

	ObjectAttributes = (props) => {
		let { t } = this.props;
		let { docInfo, UpdateInfo, formData } = this.state;

		let ObjAttr = [];
		if (UpdateInfo) {
			if (formData.attributes.length === 0) {
				formData.attributes.push({
					label: "",
					value: "",
				});
			}
			for (let attr of formData.attributes) {
				let key_index = formData.attributes.indexOf(attr);
				// RowItems.push(<InfoValue key={`${key} -${value} `} key_value={value} key_label={key} key_editable={true} />);
				ObjAttr.push(
					<Form.Row key={key_index} data-key={key_index} style={{ position: "relative" }}>
						<AddCircleIcon className="btn-attribute add-attribute" color="primary" onClick={(ev) => {
							console.log(key_index);
							formData.attributes.splice(key_index + 1, 0, {
								label: "",
								value: "",
							});
							this.setState({ formData });
						}} />
						<RemoveCircleIcon className="btn-attribute remove-attribute" color="error" onClick={(ev) => {
							console.log(key_index);
							formData.attributes.splice(key_index, 1);
							this.setState({ formData });
						}} />
						<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
							<Form.Control
								name={`label[${key_index}`}
								value={attr.label || ""}
								placeholder={t("common:documents.document-info.key")}
								onChange={(ev) => {
									formData.attributes[key_index].label = ev.target.value || "";
									this.setState({ formData });
								}}
							// required
							/>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group xl={8} md={12} xs={9} as={Col} controlId={attr.value} className={`custom-form-outline{ validated }`}>
							<Form.Control
								name={`value[${key_index}`}
								value={attr.value || ""}
								placeholder={t("common:documents.document-info.value")}
								onChange={(ev) => {
									formData.attributes[key_index].value = ev.target.value || "";
									this.setState({ formData });
								}}
							// required
							/>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
				);
			}
		} else {
			for (let key in docInfo.attributes) {
				ObjAttr.push(
					<Form.Row key={key}>
						<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
							<span className="document-info-key">{key}</span>
						</Form.Group>
						<Form.Group xl={8} md={12} xs={9} as={Col}>
							<span className="form-control document-info-value">{docInfo.attributes[key]}</span>
						</Form.Group>
					</Form.Row >
				);
			}
		}

		return ObjAttr;
	}

	handleChangeFileOrder(filesSortArray, parent) {
		const {formData} = parent.state;

		formData.files_sort = [...filesSortArray];

		this.setState({formData});
	}

	DocumentInfoSave = (ev) => {
		let { t } = this.props;
		let { docInfo, formData, modal, PageData } = this.state;

		let data = JSON.parse(JSON.stringify(formData));
		data.attributes = {};
		for (let attr of formData.attributes) {
			if (attr.label) {
				data.attributes[attr.label] = attr.value;
			}
		}
		if (data.amount && data.amount !== "") {
			data.amount = data.amount.toString().replaceAll(",", "");
			// if (data.amount.endsWith(".")) {
			// 	data.amount = data.amount.substring(0, data.amount.length - 1);
			// }
		}

		// console.log(docInfo.amount_text,  data.amount, data.amount && data.amount !== "");
		modal.close = false;
		modal.body = Loading;
		modal.action = [];
		let amount = data.amount;
		// console.log('data', data);
		this.setState({ modal }, () => {
			Service.DocumentInfoSave(data).then(resp => {
				// console.log(resp);
				docInfo = {...docInfo, ...data};
				modal.close = true;
				modal.body = `common:${resp.message}`;
				modal.action = [
					<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						modal.props.show = false;
						this.setState({ modal });
					}}>
						<span>{t("common:general.ok")}</span>
					</Button>
				];
				docInfo.amount_text = this.numFormat(amount);
				docInfo.amount = amount;
				docInfo.document_type = PageData.doc_types_index[docInfo.document_type_id].label;
				if (docInfo.preservation_require) {
					docInfo.preservation_require_label = PageData.preservation_require_index[docInfo.preservation_require].label;
				}
				docInfo.operation_log = resp.payload.operation_log;
				if([1, 6, 7, 9, 11, 14].includes(docInfo.doc_status) &&
					(
						(docInfo.sign_permission === 1 &&
							docInfo.signer_info && docInfo.signer_info.signing_status !== 'WAIT' &&
							docInfo.signer_info.signed === 0)
						|| (docInfo.sign_permission === 1 &&
								[14].includes(docInfo.doc_status)
								&& docInfo.function_type_id === 4
								&& docInfo.signer_info.signed === 0)
					)
				){
						const userInfoGet = userInfoState.get({noproxy: true});
						switch (docInfo.function_type_id) {
							case 1:
								if (userInfoGet?.signed_document_signer > 0) {
									userInfoState.set(v=>{
										if (!v) return v;
										let counted =  v.signed_document_signer - 1;
										if(data.active_flg == 1){
											counted = v.signed_document_signer + 1;
										}
										return {...v,
											signed_document_signer: counted
										}
									});
									localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
								}
								break;
							case 2:
								if (userInfoGet.signed_document_sender > 0) {

									let counted =  userInfoGet.signed_document_sender - 1;
									if(data.active_flg == 1){
										counted = userInfoGet.signed_document_sender + 1;
									}
									userInfoState.set(v=>({
										...v,
										signed_document_sender: counted
									}));
									localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
								}
								break;
							case 3:
								if (userInfoGet.signed_document_circle > 0) {

									let counted =  userInfoGet.signed_document_circle - 1;
									if(data.active_flg == 1){
										counted = userInfoGet.signed_document_circle + 1;
									}
									userInfoState.set(v=>({
										...v,
										signed_document_circle: counted
									}));
									localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
								}
								break;
							case 4:
								if (userInfoGet.signed_document_verification > 0) {
									let counted =  userInfoGet.signed_document_verification - 1;
									if(data.active_flg == 1){
										counted = userInfoGet.signed_document_verification + 1;
									}
									userInfoState.set(v=>({
										...v,
										signed_document_verification: counted
									}));
									localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
								}
								break;
							default:
								break;
					}
				}
				this.setState({ docInfo, modal, UpdateInfo: false });
			}).catch(err => {
				console.log(err.response);

				let errMessage = err?.response?.data?.message;
				if (err?.response?.data?.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}
				let { t } = this.props;
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}
				modal.close = true;
				modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
				modal.action = [(
					<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.DocumentInfoSave}>
						<span>{t("common:general.try-again")}</span>
					</Button>
				)];

				this.setState({ modal });
			});
		});
	}

	ActionBar = (props) => {
		let { t } = this.props;
		let { docInfo, operationLogFlg, isGuest, UserInfo, AttachmentFiles } = this.state;

		if (!docInfo || docInfo === false) {
			return null;
		}

		let ActionList = [];

		let permission = docInfo.permission;

		if (!isGuest) {
			if ([0, 5].includes(docInfo.doc_status) && permission.update &&
				(
					parseInt(docInfo.creator_flg) === 0 ||
					// allow administrators to continue editing drafts
					(UserInfo && [1, "1"].includes(UserInfo.administrator_role))
				)
			) {
				ActionList.push(
					<div key="edit">
						<Tooltip title={t("common:tooltip.document.edit-draft")} arrow>
							<span>
								<Button
									sx={{ mr: 2 }}
									title={t("common:document.edit")}
									variant="contained"
									startIcon={<EditIcon />}
									className="as-link"
									component={Link}
									to={`/${docInfo.function_code}/documents/edit/${docInfo.id}`}
								>
									{t("common:document.edit")}
								</Button>
							</span>
						</Tooltip>
					</div>
				);
			} else if (![0, 5].includes(docInfo.doc_status) && (permission.update || (UserInfo && [1, "1"].includes(UserInfo.administrator_role)))) {
				const { UpdateInfo } = this.state;

				if (UpdateInfo) {
					ActionList.push(
						<div key="info-save">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.save")}
								variant="contained"
								startIcon={<SaveIcon />}
								className="as-link"
								onClick={(ev) => {
									let btnSubmit = document.getElementById("form_detail_submit");
									btnSubmit.click();
								}}
							>
								{t("common:document.save")}
							</Button>
						</div>
					);
					ActionList.push(
						<div key="info-cancel">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.cancel")}
								variant="outlined"
								startIcon={<CancelIcon />}
								className="as-link"
								onClick={(ev) => {
									console.log(ev.target);
									this.setState({ UpdateInfo: false });
								}}
							>
								{t("common:document.cancel")}
							</Button>
						</div>
					);
				} else {
					ActionList.push(
						<div key="info-edit">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.edit")}
								variant="contained"
								startIcon={<EditIcon />}
								className="as-link"
								onClick={(ev) => {
									let { docInfo, formData, keyFields } = this.state;
									if (docInfo) {
										for (let key in keyFields) {
											formData[key] = [null, undefined].includes(docInfo[key]) ? "" : docInfo[key];
										}

										formData.attributes = [];
										for (let key in docInfo.attributes) {
											formData.attributes.push({
												label: key,
												value: docInfo.attributes[key],
											});
										}
									}
									this.setState({ UpdateInfo: true, formValidate: false, formData });
								}}
							>
								{t("common:document.edit")}
							</Button>
						</div>
					);
				}
			}
		}

		// if (permission.download && docInfo.doc_status === 2) {
		const open = Boolean(this.state.anchorElDownloadPDF);

		const handleOpenDownloadOption = (event) => {
			this.setState({
				anchorElDownloadPDF: event.currentTarget,
			});
		};

		const handleCloseMenu = () => {
			this.setState({
				anchorElDownloadPDF: null,
			});
		}

		const handleDownload = () => {
			this.onDownloadRequestHandler();
			handleCloseMenu();
		};

		const handleDownloadAttachments = () => {
			this.onDownloadAttachmentsRequestHandler();
			handleCloseMenu();
		}

		const handlePrint = () => {
			this.onPrintRequestHandler();
			handleCloseMenu();
		};

		const hasAttachmentFiles = AttachmentFiles && Object.keys(AttachmentFiles).length > 0;
		ActionList.push(
			<Box key="download" sx={{ mr: 2 }}>
				<Button
					variant="outlined"
					className="btn-action"
					startIcon={
						 this.state.downloadLoading
							 ? <CircularProgress size={20} style={{color: "#1976d2"}} />
							 : <CloudDownloadOutlinedIcon />
					}
					title={t("common:document.download-pdf")}
					onClick={handleOpenDownloadOption}>
					{t("common:document.download-pdf")}
				</Button>

				{}<Menu
					id="lock-menu"
					anchorEl={this.state.anchorElDownloadPDF}
					open={open}
					onClose={handleCloseMenu}
					MenuListProps={{
						'aria-labelledby': 'lock-button',
						role: 'listbox',
					}}
        >
          {[
            {
              onClick: handleDownload,
              icon: <DownloadIcon fontSize="medium" style={{ color: "#1976d2" }} />,
              label: t("common:document.conventional-download"),
            },
            hasAttachmentFiles ? {
              onClick: handleDownloadAttachments,
              icon: <AttachFileOutlined fontSize="medium" style={{ color: "#1976d2" }} />,
              label: t("common:document.attachments-download"),
            } : null,
            {
              onClick: handlePrint,
              icon: <Print fontSize="medium" style={{ color: "#1976d2" }} />,
              label: t("common:document.print"),
            }
          ].reduce((arr, item, index) => {
			if(item){
				let MenuItemRender = (<MenuItem onClick={item.onClick} key={index}>
										<ListItemIcon>
											{item.icon}
										</ListItemIcon>
										<ListItemText style={{ color: "#1976d2" }}>
											{item.label}
										</ListItemText>
									</MenuItem>);
				arr.push(MenuItemRender)
			}
			// if(item){
				return arr;
			// }
		  },[])}
        </Menu>

			</Box>
		);
		// }

		if (!isGuest) {
			ActionList.push(
				<div key="history">
					<Tooltip title={t("common:document.history")} arrow>
						<span>
							<IconButton
								sx={{ mr: 1 }}
								color="primary"
								className="btn-action"
								onClick={(e) => {
									if (operationLogFlg) {
										this.setState({ operationLogFlg: false });
									} else {
										this.setState({ operationLogFlg: true });
									}
								}}>
								<HistoryIcon fontSize="inherit" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			);
			// ActionList.push(
			// 	<div key="access-logs">
			// 		<Tooltip title={t("common:document.history")} arrow>
			// 			<span>
			// 				<IconButton
			// 					sx={{ mr: 1 }}
			// 					color="primary"
			// 					className="btn-action"
			// 					onClick={(e) => {
			// 						if (accessLogFlg) {
			// 							this.setState({ accessLogFlg: false });
			// 						} else {
			// 							this.setState({ accessLogFlg: true });
			// 						}
			// 					}}>
			// 					<AccessTimeIcon fontSize="inherit" />
			// 				</IconButton>
			// 			</span>
			// 		</Tooltip>
			// 	</div>
			// );
		} else {
			ActionList.push(
				<Box key="FAQ" sx={{ mr: 2 }}>
					<Tooltip title={t("common:tooltip.common.go-to-faq")} arrow>
						<IconButton
							aria-label="FAQ"
							href={window.location.pathname.includes('guest/document/')? "https://support.paperlogic.jp/manual/manual-24301/":"https://support.paperlogic.jp/"}
							target="_blank"
						>
							<HelpOutlineOutlinedIcon />
						</IconButton>
					</Tooltip>
				</Box>
			);
		}

		return ActionList;
	}

	UpdateModalDialog = (options) => {
		// let { t } = this.props;
		// console.log(options);
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		if (options.title) {
			modalTitle = options.title;
		}
		if (options.body) {
			ModalBody = options.body;
		}
		if (options.action) {
			ModalAction = options.action;
		}
		if (options.props) {
			modalProps = options.props;
		}
		// console.log(modalProps)
		this.setState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	UpdateDocInfo = (resp) => {
		let { docInfo } = this.state;
		docInfo.signers = this.ProcessGuestCollaborationForward(docInfo.signers);
		docInfo.signed = 1;
		docInfo.signer_info.signed = 1;
		let allSigned = [];
		for (let i in docInfo.signers) {
			let user = docInfo.signers[i];
			if (user.id === docInfo.permission.user_id) {
				user.signed = 1;
			}
			else if (user.id === docInfo.signer_info.user_id) {
				user.signed = 1;
				user.signing_status = "SIGN";
			}
			if (user.signed === 1) {
				allSigned.push(true);
			} else {
				allSigned.push(false);
			}
		}

		if (!allSigned.includes(false)) {
			if (docInfo.function_code === "sender") {
				docInfo.doc_status = 8;
			} else if (docInfo.function_code === "circle") {
				docInfo.doc_status = 12;
			} else if (docInfo.function_code === "verification") {
				if (docInfo.doc_status !== 14) {
					docInfo.doc_status = 10;
				}
			} else {
				docInfo.doc_status = 2;
			}
		}

		if(resp?.message === 'message.documents.verify.success-protected'){
			// let statusInfo = docInfo.status_list[docInfo.doc_status];
			if (docInfo.function_type_id === 4) {
					// statusInfo.label = "document.status.cannot-timestamp";
					docInfo.status_list[docInfo.doc_status].label = "document.status.protected-PDF-supported";
					docInfo.status_list[docInfo.doc_status].code = "confirmed";
			}
		}

		if (Object.keys(resp?.result?.data || {}).some(a=> resp?.result?.data[a]?.doc_status == 14) && [1, 2, 3].includes(docInfo.function_type_id)) {
			docInfo.status_list[docInfo.doc_status].code = "error";
			docInfo.status_list[docInfo.doc_status].label = "document.status.file-error";
		}
		this.setState({ docInfo });
	}

	GuestSignConfirmHandler = (ev) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.signing.title",
			body: Loading,
			action: [],
			props: {
				"show": false,
				"centered": true,
			},
		});

		let { token } = this.state;

		let { docInfo } = this.state;
		this.setState((prevState) => {
			return ({
				docInfo: {
					...prevState.docInfo,
					processing_flg: 1,
				}
			});
		});

		documentProcessState?.set(prevState => ({
			...prevState,
			[this.documentProcessId]:{
				status: DocumentProcessStatus.PROCESSING,
				data: {
					...this.state,
					formData: {
						...this.state.formData,
						doc_title: `${docInfo.document_id}: ${docInfo.title}`
					}
				},
			}
		}));

		// console.log("docInfo", docInfo);
		Service.signGuestDocument(token).then(resp => {
			console.log(resp);
			this.setState((prevState) => {
				return ({
					docInfo: {
						...prevState.docInfo,
						processing_flg: 0,
					}
				});
			});
			// this.UpdateModalDialog({
			// 	body: () => {
			// 		return (
			// 			<div>{t("common:documents.signing.confirmed")}</div>
			// 		);
			// 	},
			// 	action: [],
			// });
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
				isSign: true,
				successTitle: t("common:documents.signing.confirmed"),
			}));

			this.UpdateDocInfo(resp);
		}).catch(err => {
			//console.log(err.response);
			this.setState((prevState) => {
				return ({
					docInfo: {
						...prevState.docInfo,
						processing_flg: 0,
					}
				});
			});

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			let { t } = this.props;
			let onRetry = () => {};

			if(['documents.sign.md5-error','documents.sign.imprint-error','documents.sign.pdf-cannot-sign'].includes(errMessage)){
				this.UpdateModalDialog({
					body: () => {
						return (
							<div>
								<h4>{t(`common:${errMessage}`)}</h4>
								{
									!!err.response.data?.data && typeof err.response.data?.data === 'object'
									? Object.keys(err.response.data?.data).map((file, i) => {
										const fileErr = err.response.data?.data[file]
										return <div key={i}>{fileErr?.pdf_name}: {fileErr?.message}</div>
									})
									: <></>
								}
							</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
				onRetry = () => this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')
			}else{
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}
				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.GuestSignConfirmHandler}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
				onRetry = () => this.GuestSignConfirmHandler();
			}
			documentProcessState?.[this.documentProcessId].set(prevState => ({
					...prevState,
					status: DocumentProcessStatus.ERROR,
					result: err,
					isSign: true,
					errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`),
					onRetry: onRetry
				}));
		})
	}

	goBackAfterSign = (docInfo) =>{
		const pageType = docInfo.function_code;
		if (this.newProps && this.newProps?.history) {
			const { history } = this.newProps;
			if(!history?.location?.state?.from){
				switch (docInfo?.function_type_id) {
				  case 1:
					history.push(`/signer`);
					return;
				  case 2:
					history.push(`/sender`);
					return;
				  case 3:
					history.push(`/circle`);
					return;
				  case 4:
					history.push(`/verification`);
					return;
				  default:
					break;
				}
			}
			if (history && history?.location?.state?.from) {
					history.push(`/${history.location.state.from.toLowerCase()}`);
			} else {
					history.push(`/${pageType.charAt(0).toUpperCase() + pageType.slice(1)}`);
			}
		}
	}
	retryDocumentSigning(docInfo, cb, processingTitle, processId){
		documentProcessState?.set(prevState => ({
			...prevState,
			[processId ? processId :this.documentProcessId]:{
				status: DocumentProcessStatus.PROCESSING,
				processingTitle,
				data: {
					...this.state,
					formData: {
						...this.state.formData,
						doc_title: `${docInfo.document_id}: ${docInfo.title}`
					}
				},
				onRetry: () => this.retryDocumentSigning(docInfo, cb, processingTitle, processId),
			}
		}));
		if(cb) cb();
	}

	SignConfirmHandler = (ev) => {
		let { t } = this.props;
		// console.log(';runnnn')
		this.UpdateModalDialog({
			title: "common:documents.signing.title",
			body: Loading,
			action: [],
			props: {
				"show": false,
				"centered": true,
			},
		});

		let { docInfo } = this.state;
		this.setState((prevState) => {
			return ({
				docInfo: {
					...prevState.docInfo,
					processing_flg: 1,
				}
			});
		});
		// console.log("docInfo", docInfo);
		Service.signDocument(docInfo.id).then(resp => {
			// console.log(resp);
			this.setState((prevState) => {
				return ({
					docInfo: {
						...prevState.docInfo,
						processing_flg: 0,
					}
				});
			});
			// this.UpdateModalDialog({
			// 	body: () => {
			// 		return (
			// 			<div>{t("common:documents.signing.received")}</div>
			// 		);
			// 	},
			// 	action: [],
			// });
			this.UpdateDocInfo(resp);
			resp.message = t("common:documents.signing.received");
			const userInfoGet = userInfoState.get({noproxy: true});
			switch (docInfo.function_type_id) {
				case 1:
					if (userInfoGet?.signed_document_signer > 0) {
						userInfoState.set(v=>{
							if (!v) return v;
							return {...v,
								signed_document_signer: v.signed_document_signer - 1
							}
						});
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 2:
					if (userInfoGet.signed_document_sender > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_sender: userInfoGet.signed_document_sender - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 3:
					if (userInfoGet.signed_document_circle > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_circle: userInfoGet.signed_document_circle - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 4:
					if (userInfoGet.signed_document_verification > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_verification: userInfoGet.signed_document_verification - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				default:
					break;
			}
			resp.data = docInfo;
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
				isSign: true,
				successTitle: resp.message
			}));
		}).catch(err => {
			//console.log(err.response);
			this.setState((prevState) => {
				return ({
					docInfo: {
						...prevState.docInfo,
						processing_flg: 0,
					}
				});
			});
			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			let { t } = this.props;
			if(['documents.sign.md5-error','documents.sign.imprint-error','documents.sign.pdf-cannot-sign'].includes(errMessage)){
				this.UpdateModalDialog({
					body: () => {
						return (
							<div>
								<h4>{t(`common:${errMessage}`)}</h4>
								{
									!!err.response.data?.data && typeof err.response.data?.data === 'object'
									? Object.keys(err.response.data?.data).map((file, i) => {
										const fileErr = err.response.data?.data[file]
										return <div key={i}>{fileErr?.pdf_name}: {fileErr?.message}</div>
									})
									: <></>
								}
							</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
			}else{
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}

				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
			}

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
				isSign: true,
				errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
			}))
		});

	}
	onSignHandlers = (ev) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.signing.titleconfirm",
			body: Loading,
			action: [],
			props: {
				"show": true,
				"centered": true,
			},
		});

		let { docInfo } = this.state;
		// console.log("docInfo", docInfo);
		Service.saveDocumentlog(docInfo.id).then(resp => {
			// console.log(resp);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:documents.signing.confirmed")}</div>
					);
				},
				action: [],
			});
			this.UpdateDocInfo(resp);

			const userInfoGet = userInfoState.get({noproxy: true});
			switch (docInfo.function_type_id) {
				case 1:
					if (userInfoGet.signed_document_signer > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_signer: userInfoGet.signed_document_signer - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 2:
					if (userInfoGet.signed_document_sender > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_sender: userInfoGet.signed_document_sender - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 3:
					if (userInfoGet.signed_document_circle > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_circle: userInfoGet.signed_document_circle - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 4:
					if (userInfoGet.signed_document_verification > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_verification: userInfoGet.signed_document_verification - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				default:
					break;
			}
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			let { t } = this.props;
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}

			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.onSignHandlers}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
	}
	GuestAgreeButton = () => {
		let { t } = this.props;
		let { guestAgreement } = this.state;
		let btnConfirmProps = {
			disabled: true,
		}
		if (guestAgreement) {
			btnConfirmProps.disabled = false;
			btnConfirmProps.onClick = this.GuestSignConfirmHandler;
		}
		// console.log(guestAgreement);
		return (
			<Button sx={{ ml: 1 }} variant="contained" {...btnConfirmProps}  >
				<span>{t("common:general.confirm")}</span>
			</Button>
		);
	}

	onSignHandler = (ev) => {
		let { t } = this.props;
		let { token, docInfo } = this.state;
		let { GuestAgreeButton } = this;
		// console.log(do);
		let guestFilter = docInfo?.signers?.filter(a=>a.user_type === 1);
		let checkGuest = guestFilter?.length > 1 && docInfo.function_code === "signer";
		if (token) {
			let findGuest = guestFilter?.findIndex(a=> a.user_id === docInfo?.signer_info?.user_id);

			this.UpdateModalDialog({
				title: "common:documents.sign.guest-confirm",
				body: () => {

					return (
						<Container fluid="md" className={"container-fluid"} >
							<Form.Row>
								<Form.Group xl={12} md={12} as={Col} >
									
									<div className="agreement_confirm">
										{docInfo?.signature_confirm_text || t("common:documents.sign.guest-agreement-info")}
									</div>
									<br/>
									<div>{checkGuest && findGuest + 1 < guestFilter.length ? t("common:documents.sign.signers.guest-agreement-info", {
										full_name: guestFilter[findGuest + 1].full_name,
										// family_name:  docInfo?.signer_info?.family_name,
										// first_name:  docInfo?.signer_info?.first_name,
									}): null}</div>
								</Form.Group>
							</Form.Row >
							<Form.Row>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="switch" label={t("common:documents.sign.yes-agree")} onChange={(ev) => {
										// console.log(ev.target.checked);
										this.setState({ guestAgreement: ev.target.checked });
										this.UpdateModalDialog({
											action: [
												<GuestAgreeButton key="guest-ok" />,
											],
										});
									}} />
								</Form.Group>
							</Form.Row >
						</Container>
					);
				},
				action: [
					<GuestAgreeButton key="guest-ok" />,
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		} else {
			this.UpdateModalDialog({
				title: "common:documents.signing.confirm",
				body: () => {
					return (
						<div>{t("common:documents.signing.confirm-question")}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
						<span>{t("common:general.confirm")}</span>
					</Button>),
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		}
	}

	VerifyConfirmProcess = () => {
		let { t } = this.props;
		let { docInfo, modal } = this.state;
		this.setState((prevState) => ({
			docInfo: {
				...prevState.docInfo,
				processing_flg: 1,
			}
		}));


		// console.log("docInfo", docInfo);

		Service.verifyDocument(docInfo.id).then(resp => {
			// console.log(resp);
			modal.body = t("common:" + resp.message);
			modal.action = [];
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
			}))
			this.setState({ modal }, ()=> this.UpdateDocInfo(resp));
			const userInfoGet = userInfoState.get({noproxy: true});
			switch (docInfo.function_type_id) {
				case 1:
					if (userInfoGet.signed_document_signer > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_signer: userInfoGet.signed_document_signer - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 2:
					if (userInfoGet.signed_document_sender > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_sender: userInfoGet.signed_document_sender - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 3:
					if (userInfoGet.signed_document_circle > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_circle: userInfoGet.signed_document_circle - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				case 4:
					if (userInfoGet.signed_document_verification > 0) {
						userInfoState.set(v=>({
							...v,
							signed_document_verification: userInfoGet.signed_document_verification - 1
						}));
						localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				default:
					break;
			}
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			let { t } = this.props;
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}

			modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.VerifyConfirmHandler}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
				isSign: true,
				errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
			}))
		}).finally(() => {
			this.setState((prevState) => ({
				docInfo: {
					...prevState.docInfo,
					processing_flg: 0,
				}
			}))
		});

	}

	VerifyConfirmHandler = () => {
		let { modal, docInfo } = this.state;
		modal.title = "common:documents.verify.title";
		modal.body = Loading;
		modal.action = [];

		modal.props = {
			show: false,
		}
		// this.goBackAfterSign(docInfo);
		this.retryDocumentSigning(docInfo, ()=> this.VerifyConfirmProcess());
		this.setState({ modal }, this.VerifyConfirmProcess);
	}

	onVerifyHandler = (ev) => {
		let { t } = this.props;
		let { modal } = this.state;
		modal.title = "common:documents.verify.confirm";
		modal.body = t("common:message.documents.verify.confirm-question");
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.VerifyConfirmHandler}>
				<span>{t("common:general.confirm")}</span>
			</Button>
		)];
		modal.props = {
			show: true,
			centered: true,
		}

		this.setState({ modal });
	}

	UploadCertificateError = (err, data) => {
		let { t } = this.props;
		let { ModalAction, ModalBody } = this.state;

		let errMessage = err?.response?.data?.message;
		if (err?.response?.data?.hasOwnProperty("error")) {
			errMessage = err.response.data.error;
		}
		// let { t } = this.props;
		if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
			errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
			// return;
		}

		ModalBody = () => {
			return (
				<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
			);
		};

		/* */
		ModalAction = [];
		ModalAction.push(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				this.setState({ ModalBody: Loading, ModalAction: [] }, () => {
					// this.CertificateUploadSubmit(data);
					this.onCertificateUploadHandler();
				});
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		);

		/* */
		this.setState({ ModalAction, ModalBody });
	}

	CertificateUploadSubmit = (data) => {
		let { token } = this.state;
		if (token) {
			Service.guestUpdateCertificate(token, data).then(resp => {
				window.location.reload();
			}).catch(err => {
				this.UploadCertificateError(err, data);
			});
		} else {
			Service.updateCertificate(data).then(resp => {
				window.location.reload();
			}).catch(err => {
				this.UploadCertificateError(err, data);
			});
		}
	}

	PrepareCertificateUploadParams = (ev) => {

		let { docInfo, certUpload } = this.state;
		// console.log(certUpload);

		/* File required */
		if (certUpload.file == null) {
			return false;
		}

		let reader = new FileReader();
		reader.readAsDataURL(certUpload.file);
		reader.onload = (ev) => {
			// console.log(ev);
			// console.log(reader);
			let data = {
				certificate_type: docInfo.signer_info.certificate_type_id,
				certificate_file: ev.target.result.replace(/^data:.+;base64,/, ''),
				certificate_extension: certUpload.file.name.split('.').pop().toLowerCase(),
				certificate_password: certUpload.password,
			};
			// console.log(data);// /* */
			this.setState({ ModalBody: Loading, ModalAction: [] }, () => {
				this.CertificateUploadSubmit(data);
			});
		};
		reader.onerror = (error) => {
			//console.log(error);
		};

	}

	getCertificateApplicationInfo = () => {
		let { docInfo, token } = this.state;

		Service.getGuestAppCertDetail(token, {
			cert_type: docInfo.signer_info.certificate_type_id
		}).then(resp => {
			let { appCert } = this.state;
			appCert = resp;
			// console.log(resp)
			if (appCert?.records.length > 0) {
				this.setState({appCertApplied: true})
			}
			this.setState({ appCert }, this.setStateFields);
		}).catch(err => {
			//console.log(err.response);
		}).finally(() => {
			this.setState({appCertLoading: false});
		});

	}

	onCertificateApplyHandler = (ev) => {
		let { appCert } = this.state;
		this.setState({
			certApply: true,
			appCert: {
				...appCert,
				records: []
			}},
			this.setStateFields
		);
	}

	onCertificateUploadHandler = (ev) => {
		let { t } = this.props;
		// let {token} = this.state;
		// console.log(token);
		this.setState({
			certUpload: {
				file: null,
				password: null,
			}
		}, () => {
			this.UpdateModalDialog({
				title: "common:settings.profile.certificate.upload-title",
				body: () => {
					let { certUpload } = this.state;
					let fileNameLabel = t("common:settings.profile.certificate.file-select");
					if (certUpload && certUpload.file && certUpload.file.name) {
						fileNameLabel = certUpload.file.name;
					}

					return (
						<Container fluid="md" className={"container-fluid"} >
							<Form id="form_certificate_upload" className="full-form" >
								<Form.Row>
									<Form.Group as={Col} md={12} controlId="cert_file_input" >
										<Form.Label>{t("common:settings.profile.certificate.file-select")}</Form.Label>
										<Form.File
											id="cert_file_input"
											name="cert_file_input"
											label={fileNameLabel}
											custom
											onChange={(ev) => {
												// console.log(ev);
												certUpload.file = ev.target.files[0];
												this.setState({ certUpload });
											}}
											accept=".pfx,.p12"
										/>
										{/* <Form.Control type="file" name="cert_file_input" /> */}
									</Form.Group>
									<Form.Group as={Col} md={12} controlId="cert_password" >
										<Form.Label>{t("common:settings.profile.certificate.password")}</Form.Label>
										<Form.Control
											type="password"
											name="password"
											placeholder={t("common:settings.profile.certificate.password")}
											value={this.state.certUpload.password || ""}
											onChange={(ev) => {
												// console.log(ev);
												// let { certUpload } = this.state;
												certUpload.password = ev.target.value;
												this.setState({ certUpload });
											}}
										/>
									</Form.Group>
								</Form.Row >
							</Form>
						</Container>
					);
				},
				action: [
					(
						<Button key="ok" size="large" variant="contained" onClick={this.PrepareCertificateUploadParams}>
							<span>{t("common:general.confirm")}</span>
						</Button>
					),
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		});
	}

	onDownloadRequestHandler = () => {
		let { docInfo, token } = this.state;
		this.setState({
			downloadLoading: true,
		});
		if (token) {
			Service.guestDownloadAllAsZip(token).then(resp => {
				downloadFileUrl(resp.payload.url, 'zip').finally(()=>{
					this.setState({
						downloadLoading: false,
					});
				});
				// let filename = new Date();


			}).catch(err => {
				//console.log(err.response);
				this.setState({
					downloadLoading: false,
				});
			});
		} else {
			Service.downloadAllAsZip(docInfo.id).then(resp => {

				// console.log(resp);
				// let filename = new Date();
				if (resp.ok) {
					downloadFileUrl(resp.payload.url).finally(()=>{
						this.setState({
							downloadLoading: false,
						});
					});
				}
			}).catch(err => {
				//console.log(err.response);
				this.setState({
					downloadLoading: false,
				});
			});
		}
	}

	onDownloadAttachmentsRequestHandler = () => {
		let { docInfo, token } = this.state;
		this.setState({
			downloadLoading: true,
		});
		if (token) {
			Service.guestDownloadAllAsZip(token, true).then(resp => {
				downloadFileUrl(resp.payload.url, 'zip').finally(()=>{
					this.setState({
						downloadLoading: false,
					});
				});
				// let filename = new Date();


			}).catch(err => {
				//console.log(err.response);
				this.setState({
					downloadLoading: false,
				});
			});
		} else {
			Service.downloadAllAsZip(docInfo.id, true).then(resp => {

				// console.log(resp);
				// let filename = new Date();
				if (resp.ok) {
					downloadFileUrl(resp.payload.url).finally(()=>{
						this.setState({
							downloadLoading: false,
						});
					});
				}
			}).catch(err => {
				//console.log(err.response);
				this.setState({
					downloadLoading: false,
				});
			});
		}
	}

	onPrintRequestHandler = () => {
		let { docInfo, token } = this.state;
		this.setState({
			downloadLoading: true,
		});
		if (token){
			Service.downloadForPrintingGuest(token)
			.then((response) => {
				const {ok} = response;
				if (ok) {
					this.setState({
						printAlertResult: true,
					});
					let aLink = document.createElement("a");
					aLink.href = `/guest/documents/preview/${token}`;
					aLink.target = "_blank";
					aLink.click();
				} else {
					this.setState({
						printAlertResult: false,
					});
				}
			})
			.catch(() => {
				this.setState({
					printAlertResult: false,
				});
			}).finally(() => {
				this.setState({
					downloadLoading: false,
					printAlertVisibility: true,
				})
			}) ;
		} else {
			Service.downloadForPrinting(docInfo.id)
			.then((response) => {
				const {ok} = response;
				if (ok) {
					this.setState({
						printAlertResult: true,
					});
					let aLink = document.createElement("a");
					aLink.href = `/documents/preview/${docInfo.id}`;
					aLink.target = "_blank";
					aLink.click();
				} else {
					this.setState({
						printAlertResult: false,
					});
				}
			})
			.catch(() => {
				this.setState({
					printAlertResult: false,
				});
			}).finally(() => {
				this.setState({
					downloadLoading: false,
					printAlertVisibility: true,
				})
			}) ;
		}

	}

	onPrintRequestHandlerMobile = () => {
		console.log('mobile');
		let { docInfo, token, modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		let { t } = this.props;
		modalTitle = "common:documents.confirm.open.print-title";
		ModalBody = () => <>{t("common:documents.confirm.open.print-content")}</>;
		ModalAction = []
		this.setState({
			downloadLoading: true,
		});
		if (token){
			Service.downloadForPrintingGuest(token)
			.then((response) => {
				const {ok} = response;
				if (ok) {
					ModalAction.push(
						<Button key="ok" sx={{ ml: 1 }} variant="contained">
							<a style={{color: 'white'}} href={`/guest/documents/preview/${token}`} target="_blank" rel="noreferrer">
								{t("common:general.ok")}
							</a>
						</Button>
					);
					modalProps.show = true;
					this.setState({ modalTitle, ModalBody, ModalAction, modalProps, printAlertResult: true})
				} else {
					this.setState({
						printAlertResult: false,
					});
				}
			})
			.catch(() => {
				this.setState({
					printAlertResult: false,
				});
			}).finally(() => {
				this.setState({
					downloadLoading: false,
					printAlertVisibility: true,
				})
			}) ;
		} else {
			Service.downloadForPrinting(docInfo.id)
			.then((response) => {
				const {ok} = response;
				if (ok) {
					this.setState({
						printAlertResult: true,
					});
					ModalAction.push(
						<Button key="ok" sx={{ ml: 1 }} variant="contained">
							<a style={{color: 'white'}} href={`/documents/preview/${docInfo.id}`} target="_blank" rel="noreferrer">
								{t("common:general.ok")}
							</a>
						</Button>
					);
					modalProps.show = true;
					this.updateState({ modalTitle, ModalBody, ModalAction, modalProps})
				} else {
					this.setState({
						printAlertResult: false,
					});
				}
			})
			.catch(() => {
				this.setState({
					printAlertResult: false,
				});
			}).finally(() => {
				this.setState({
					downloadLoading: false,
					printAlertVisibility: true,
				})
			}) ;
		}
	}

	onPreviewPdfHandler = async (pdfHash) => {
		this.setState({ previewPdf: pdfHash });
	}

	onCertificateApplyFormClose = (cb) => {
		// modal.onClose
		this.setState({ certApply: false }, cb);
		this.getCertificateApplicationInfo();
		// console.log(this.setState.certApply);
	}

	ForwardUserList = (props) => {
		let { t } = this.props;
		let {forwardUserSearch, loading, loadMore, loadMoreLoading} = props;
		let { docInfo, user_quick_filter, forwardUser, searchAvailableForwardUsers } = this.state;
		if (loading) {
			return <Loading />;
		}
		// console.log('forwardUserSearch', forwardUserSearch)
		let isCompanySeal = false;
		let AvailableUserList = [];
		if (parseInt(docInfo.certificate_type) === 2) {
			isCompanySeal = true;
		}

		for (let uid in forwardUserSearch) {
			let user = forwardUserSearch[uid];
			if ([1, 3].includes(parseInt(docInfo.signer_info.certificate_type_id))) {
				if (!docInfo.signer_info) {
					continue;
				}

				if (!user?.available_certs?.includes(parseInt(docInfo.signer_info.certificate_type_id))) {
					console.log(user.available_certs);
					continue;
				}
			}

			if (user_quick_filter) {
				let regEscape = user_quick_filter.replace(/[-[\]{ }()*+?.,\\^$|]/g, "\\$&");
				let regex = new RegExp(regEscape, "i");
				let searchCondition = [];
				searchCondition.push(regex.test(user.user_id));
				searchCondition.push(regex.test(user.full_name));
				if (!isCompanySeal) {
					searchCondition.push(regex.test(user.email));
					searchCondition.push(regex.test(user.mobile_number));
				}

				if (!searchCondition.includes(true)) {
					continue;
				}
			}

			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let selected = "";
			if (forwardUser && forwardUser.user_id === user.user_id) {
				selected = " selected";
			}

			AvailableUserList.push(
				<ListGroup.Item key={user.user_id} as="li" className={`user-info user-${user.user_type}${selected}`} onClick={ev => {
					console.log(ev.target);
					this.setState({ forwardUser: user }, this.checkAvailableForwardUser);
				}}>
					<div className="user-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div className="user-info-detail" style={{
						cursor: "pointer",
						padding: "0 4px",
					}}>
						<div className="top-info">
							<span>{`${user.user_id}-${user.full_name}`}</span>
						</div>
						<div className="bottom-info">
							<span>{isCompanySeal ? `${t("common:general.total-members")} ${user.members_count}` : user.email || ""}</span>
						</div>
					</div>
				</ListGroup.Item>
			);
		}
		return (
			<Virtuoso
				components={{
					Scroller,
					Footer: loadMoreLoadingComp
				}}
				context={{
					loadMoreLoading
				}}
				style={{ height: 'calc(100% - 55px) !important' }}
				data={AvailableUserList}
				endReached={loadMore}
				overscan={100}
				itemContent={(i, group) => {
					return group;
				}}
			/>
			// <ListGroup as="ul" className="user-selection-box">
			// 	{AvailableUserList}
			// </ListGroup>
		);
	}

	ForwardUserSelected = () => {
		let { t } = this.props;
		let { docInfo, forwardUser } = this.state;
		let LineInfo = [];
		let iconProps = {};

		if (!forwardUser) {
			return null;
		}

		if (forwardUser.user_type === 2) {
			iconProps.icon = PeopleIcon;
		} else if (forwardUser.profile_image) {
			iconProps.avatar = forwardUser.profile_image;
		}

		LineInfo.push(
			<div className='forwardUser-profile'>
				<div className='user-avatar'>
					<ProfileAvatar {...iconProps} />
				</div>
				<div className='user-profile'>
					<div className='top-info'>
						<span>{forwardUser.user_id}</span>-<span>{forwardUser.full_name}</span>
					</div>
					<div className='bottom-info'>
						<span>{forwardUser.email}</span>
					</div>
				</div>
			</div>
		);



		// let loopKeys = {
		// 	"id": forwardUser.user_id,
		// 	"name": forwardUser.full_name,
		// 	"email": forwardUser.email,
		// 	"mobile": forwardUser.mobile_number,
		// };
		// for (let i in loopKeys) {
		// 	LineInfo.push(
		// 		<div key={i} className="forwardUser-profile">
		// 			<span>{loopKeys[i]}</span>
		// 		</div>
		// 	);
		// }

		if (parseInt(docInfo.certificate_type) === 2) {
			LineInfo.push(
				<Form.Row key="group-member-count">
					<Form.Group xs={12} md={4} as={Col} style={{ height: "100%" }}>
						<span>{t('common:general.total-members')}</span>
					</Form.Group>
					<Form.Group xs={12} md={8} as={Col} style={{ height: "100%" }}>
						<span>{forwardUser.members_count}</span>
					</Form.Group>
				</Form.Row>
			);
		}

		return (
			<div className="forward-user-card" >
				{LineInfo}
			</div>
		);
	}

	ForwardFormFilter = () => {
		let { t } = this.props;
		let { showUserFilter } = this.state;

		return (
			<div className="body-action-container">
				<div className="body-action-bar" style={showUserFilter ? { height: "60px" } : null}>
					<Form.Control
						name="user_quick_filter"
						placeholder={t("common:documents.user-quick-filter")}
						value={this.state.user_quick_filter || ""}
						onChange={(ev) => {
							console.log(ev.target.value);
							this.setState({ user_quick_filter: ev.target.value });
						}}
					/>
				</div>
			</div>
		);
	}

	ForwardFormBody = () => {
		let { t } = this.props;
		let { isGuest, forwardUser, userSearching, formData, docInfo } = this.state;
		let { FieldControl, ForwardUserList, ForwardUserSelected, ForwardFormFilter } = this;
		let forwardUsers = useHookstate(forwardUserSearchState);
		let loading = useHookstate(false);
		let loadMoreLoading = useHookstate(false);
		const searchInputVal = useHookstate('');
		let loadMore =  useCallback(()=>{
			let forward = forwardUsers.get({stealth:true});
			// console.log('loadmore',forward)
			if(loadMoreLoading.get({stealth:true})){
				return;
			}
			if(searchInputVal.get({stealth:true})){
				forward.search = searchInputVal.get({stealth:true});
			}
			callForwardUser(forward.page_number + 1, forward.page_size, forward.search, forward.isHasMore);
		}, []);
		const searchUser = (val)=>{
			// console.log('throttling')
			callForwardUser(1, forwardUsers.page_size.get({stealth:true}), val, true, true);
		}
		const handleInput = useCallback(
			throttle(searchUser, 1000, { leading: false }),
			[]
		);
		let callForwardUser = useCallback((page_number, page_size, search, isHasMore, isSearch)=>{
			if(docInfo && docInfo.function_code){
				// console.log(forwardUser	s.isHasMore.value)
				if(isSearch){
					loading.set(true);
					forwardUsers.data.set({});
					forwardUsers.search.set(search);
				}
				if(!isHasMore){
					loading.set(false);
					loadMoreLoading.set(false);
					forwardUsers.isHasMore.set(false);
					return;
				}
				loadMoreLoading.set(true);
				// console.log('callapi')

				const handleListResponse = (r) => {
					// console.log(r);
					forwardUsers.page_number.set(page_number)
					forwardUsers.page_size.set(page_size)
					forwardUsers.search.set(search)
					forwardUsers.isHasMore.set(isHasMore);
					if(isSearch){
						forwardUsers.data.set(r.payload);
					} else {
						forwardUsers.data.merge(r.payload);
					}
					if(Object.keys(r.payload).length < page_size){
						forwardUsers.isHasMore.set(false);
					}
					// console.log(forwardUsers.data.get({stealth:true}))
				}

				const handleListFinally = () => {
					loading.set(false);
					loadMoreLoading.set(false);
				}

				if (parseInt(docInfo.certificate_type) === 2) {
					Service.GetHostGroup(
						docInfo.function_code,
						page_number,
						page_size,
						search
					).then(handleListResponse).finally(handleListFinally)
				} else {
					Service.GetHostUser(docInfo.function_code,
						page_number,
						page_size,
						search
					).then(handleListResponse).finally(handleListFinally)
				}
			}
		}, [docInfo]);
		let isCompanySeal = false;
		let AvailableUserList = [];
		if (parseInt(docInfo.certificate_type) === 2) {
			isCompanySeal = true;
		}
		useEffect(()=>{
			if(docInfo.function_code && !isGuest && !docInfo.master_doc_info){
				loading.set(true);
				callForwardUser(1, forwardUsers.page_size.get({stealth:true}), '', true);
			}
		}, [docInfo, isGuest]);

		if (isGuest || docInfo.master_doc_info) {
			let UserInfoBlock = [];

			if (forwardUser) {
				UserInfoBlock.push(<div>
					<Form.Label style={{marginTop: '-15px' }}>{t("common:documents.forward.comment")}</Form.Label>
					<Form.Control
						className="mb-2"
						rows={4}
                        name={'comment'}
                        placeholder={''}
                        value={formData.comment}
						as="textarea"
						onInput={(ev) => {
							formData.comment = ev.target.value;
							this.setState({ formData });
						}}
                    />
				</div>);
				UserInfoBlock.push(<hr key="seperator" style={{ margin: 0 }} />);
				if (forwardUser.hasOwnProperty("user_id")) {
					UserInfoBlock.push(
						<Form.Row key="user-info-card">

							<Form.Group md={12} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
								<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.user-info")}</Form.Label>
								<div className="user-info-container forward-user-info">
									<ForwardUserSelected />
								</div>
							</Form.Group>

						</Form.Row>
					);
				} else {
					UserInfoBlock.push(
						<Form.Row key="user-info-card">

							<Form.Group as={Col} controlId="all_user_list" className="forward-user-wrapper forward-user-list" style={{
								height: "100%",
								position: "relative",
							}}>
								<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.guest-info")}</Form.Label>
								<div className="user-info-container forward-user-info">

									<Form.Row key="user-info-row-1">
										<FieldControl name="family_name" xs={12} md={6} required validate="true" />
										<FieldControl name="first_name" xs={12} md={6} required validate="true" />
									</Form.Row>

									<Form.Row key="user-info-row-2">
										<FieldControl name="company_name" xs={12} md={12} />
										<FieldControl name="mobile" xs={12} md={6} />
									</Form.Row>

								</div>

							</Form.Group>

						</Form.Row>
					);
				}
			} else if (userSearching) {
				UserInfoBlock.push(<hr key="seperator" style={{ margin: 0 }} />);
				UserInfoBlock.push(
					<Form.Row key="user-searching" style={{ height: "300px" }}>
						<Loading />
					</Form.Row>
				);
			}

			return (
				<div className="form-body flex-column">
					<Form.Control type="hidden" name="action" defaultValue={formData.action} />

					<Form.Row style={{ height: "85px" }}>

						<FieldControl name="email" type="email" required validate="true" change={(ev) => {
							let { forwardUser, formData } = this.state;
							if (forwardUser && forwardUser.email !== formData.email) {
								forwardUser = null;
							}
							this.setState({ forwardUser }, this.checkAvailableForwardUser);
						}} />

						<Form.Group md={2} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
							<Form.Label>&nbsp;</Form.Label>
							<Button
								size="large"
								variant="contained"
								type="submit"
								name="search"
								onClick={this.onButtonSubmit}
								style={{
									marginTop: "6px",
									height: "46px",
								}}
							>
								<span className="btn-label">{t('common:general.search')}</span>
							</Button>
						</Form.Group>

					</Form.Row>

					{UserInfoBlock}

				</div >
			);
		} else {
			return (
				<div className="form-body flex-column full-height-modal-body">
					<Form.Control type="hidden" name="action" defaultValue={formData.action} />

					<Form.Row className="flex-full">

						<Form.Group xs={6} md={6} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
							<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.user-info")}</Form.Label>
							<div className="user-info-container forward-user-info">
								<ForwardUserSelected />
							</div>
						</Form.Group>

						<Form.Group xs={6} md={6} as={Col} controlId="all_user_list" className="user-selection-container forward-user-list" style={{
							height: "100%",
							position: "relative",
						}}>
							<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.host-setting.available-user-list")}</Form.Label>

							<ForwardFormFilter />

							<InputGroup className="form-control">
								<div className="search-input-container">
									<TextField
										className="search-input-field"
										key="searchAvailableForwardUsers"
										placeholder={t('common:documents.general.text-input-placeholder')}
										onChange={(e) => {
											searchInputVal.set(e.target.value);
											return handleInput(e.target.value);
										}}
										defaultValue={this.state.searchAvailableForwardUsersText}
										sx={{
											"& fieldset": { border: 'none' },
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment>
													<IconButton>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											),
											style: {
												height: 40,
												paddingLeft: 5,
												fontSize: 15,
											},
										}}
									/>
								</div>
								<ForwardUserList loadMoreLoading={loadMoreLoading.get()} loadMore={loadMore} loading={loading.get()} forwardUserSearch={forwardUsers.data.get({noproxy:true})}/>
							</InputGroup >
						</Form.Group>

					</Form.Row>

				</div>
			);
		}
	}

	getUserInfoByEmail = (ev, fnCallback) => {
		console.log('getUserInfoByEmail');
		let { token, formData, forwardUser } = this.state;

		// formData.email = null;
		formData.family_name = null;
		formData.first_name = null;
		formData.company_name = null;
		formData.mobile = null;
		this.setState({ userSearching: true, forwardUser: null, formData }, async () => {
			let { docInfo } = this.state;
			if (docInfo.collab_user == 1) {
				await Service.GetForwardCollabUserInfo({
					"email": formData.email,
					"tenant_id": docInfo.tenant_id,
				}).then(resp => {
					console.log(resp);
					forwardUser = resp.payload;
					forwardUser.email = formData.email;
				}).catch(err => {
					console.log(err);
					forwardUser = { email: formData.email };
				});
			} else if (docInfo.master_doc_info) {
				await Service.GetUserGuestInfo({
					"email": formData.email,
					"tenant_id": docInfo.master_doc_info.tenant_id,
				}).then(resp => {
					console.log(resp);
					forwardUser = resp;
					forwardUser.email = formData.email;
				}).catch(err => {
					console.log(err);
					forwardUser = { email: formData.email };
				});
			}
			else {
				await Service.GetGuestUserInfo(token, { email: formData.email }).then(resp => {
					console.log(resp);
					forwardUser = resp;
					forwardUser.email = formData.email;
				}).catch(err => {
					console.log(err);
					forwardUser = { email: formData.email };
				});
			}

			this.setState({ forwardUser, userSearching: false }, fnCallback);
		});
	}

	checkAvailableForwardUser = (ev) => {
		console.log('checkAvailableForwardUser');
		let { t } = this.props;
		let { modal, forwardUser } = this.state;

		modal.action = [];
		if (forwardUser) {
			modal.action = [(
				<Button key="btn-submit"
					sx={{ ml: 1 }}
					variant="contained"
					type="submit"
					name="submit"
					onClick={this.onButtonSubmit}
				>
					<span>{t("common:general.submit")}</span>
				</Button>
			)];
		}

		this.setState({ modal });
	}

	onButtonSubmit = (ev) => {
		document.getElementById("forward_user_form").submited = ev.target.closest("[type=submit]");
	}

	forwardSignSubmit = (ev) => {
		console.log(ev.target.submited.name);
		if (ev.target.submited.name === "search") {
			this.getUserInfoByEmail(ev, this.checkAvailableForwardUser);
		} else {
			let { t } = this.props;
			let { docInfo, modal, forwardUser, isGuest, formData, token } = this.state;

			if (!forwardUser) {
				return null;
			}

			modal.props.size = "sm";
			modal.props.centered = true;
			modal.body = "common:message.documents.forward.confirm";
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					modal.close = false;
					modal.body = Loading;
					modal.action = [];
					modal.props = {
						show: false
					}
					this.setState({ modal }, () => {
						forwardUser.comment = formData.comment;
						if (isGuest) {
							if (!forwardUser.hasOwnProperty("user_id")) {
								forwardUser.email = formData.email;
								forwardUser.family_name = formData.family_name;
								forwardUser.first_name = formData.first_name;
								forwardUser.company_name = formData.company_name;
								forwardUser.mobile = formData.mobile;
							}
							this.retryDocumentSigning(docInfo, () => {
								Service.GuestForwardSign(token, forwardUser).then(resp => {
									this.ForwardSignSuccess(resp);
								}).catch(err => {
									this.ForwardSignFailure(err);
								})
							});
						}
						else if (docInfo.collab_user == 1) {
							if (!forwardUser.hasOwnProperty("user_id")) {
								forwardUser.email = formData.email;
								forwardUser.family_name = formData.family_name;
								forwardUser.first_name = formData.first_name;
								forwardUser.company_name = formData.company_name;
								forwardUser.mobile = formData.mobile;
								forwardUser.user_type = 1;
							}
							// this.goBackAfterSign(docInfo);
							this.retryDocumentSigning(docInfo, ()=> {
								Service.CollabForwardSign({
									"document_id": docInfo.document_id,
									"user_info": forwardUser,
								}).then(resp => {
									this.ForwardSignSuccess(resp);
								}).catch(err => {
									this.ForwardSignFailure(err);
								});
							});

						}
						else {
							// this.goBackAfterSign(docInfo);
							this.retryDocumentSigning(docInfo, ()=> {
								Service.DocumentForwardSign({
									"document_id": docInfo.document_id,
									"user_info": forwardUser,
								}).then(resp => {
									this.ForwardSignSuccess(resp);
								}).catch(err => {
									this.ForwardSignFailure(err);
								});
							});
						}
					});
				}}>
					<span>{t("common:general.confirm")}</span>
				</Button>
			)];

			this.setState({ modal });

		}
	}

	ForwardSignSuccess = (resp) => {
		let { t } = this.props;
		let { docInfo, modal, UserInfo } = this.state;
		console.log(resp);
		modal.close = true;
		modal.props.size = "lg";
		modal.props.show = false;
		modal.props.centered = false;
		modal.body = "common:message.documents.forward.success";
		modal.action = [
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				modal.props.show = false;
				this.setState({ modal });
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		];

		docInfo.operation_log = resp.logs;
		docInfo.signers = this.ProcessGuestCollaborationForward(resp.signers);
		docInfo.signer_info.signed = 1;
		docInfo.signer_info.signing_status = "FORWARD";

		if (UserInfo && parseInt(docInfo.certificate_type) === 2) {
			for (let signer of docInfo.signers) {
				if (signer.new_signer_flag && signer.cs_signning === UserInfo.user_id && signer.signed === 0) {
					docInfo.signer_info = signer;
					break;
				}
			}
		}
		if (resp && resp?.data && resp?.data?.document_id && docInfo && docInfo?.document_id) {
			resp.data.document_id = docInfo.document_id;
		}
		// console.log(docInfo);
		documentProcessState?.[this.documentProcessId]?.set(prevState => ({
			...prevState,
			status: DocumentProcessStatus.SUCCESS,
			result: {
				data: {
					document_id: docInfo.document_id,
				}
			},
			isSign: true,
			successTitle: t("common:message.documents.forward.success")
		}));
		this.setState({ docInfo, modal, forwardUser: null });
	}

	ForwardSignFailure = (err) => {
		let { t } = this.props;
		let { modal } = this.state;
		console.log(err.response);
		let errMessage = err?.response?.data?.message;
		if (err?.response?.data?.hasOwnProperty("error")) {
			errMessage = err.response.data.error;
		}
		// let { t } = this.props;
		if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
			errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
			// return;
		}

		modal.close = true;
		modal.props.size = "lg";
		modal.props.centered = false;
		modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
		modal.action = [
			<Button key="try-again"
				sx={{ ml: 1 }}
				variant="contained"
				type="submit"
				onClick={this.onButtonSubmit}
			>
				<span>{t("common:general.try-again")}</span>
			</Button>
		];

		documentProcessState?.[this.documentProcessId]?.set(prevState => ({
			...prevState,
			status: DocumentProcessStatus.ERROR,
			result: err,
			isSign: true,
			errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
		}))
		this.setState({ modal });
	}

	ButtonForwardHandler = (ev) => {
		let { t } = this.props;
		let { modal, isGuest, forwardUser } = this.state;

		modal.props.show = true;
		modal.props.size = isGuest ? "lg" : "xl";
		modal.props.className = "layout modal-responsive";
		modal.title = "common:documents.sign-forward.header";
		modal.body = this.ForwardFormBody;
		// modal.bodyaction = this.ForwardFormFilter;
		modal.form = {
			id: "forward_user_form",
			noValidate: true,
			onSubmit: (ev) => {
				// console.log(ev.target);

				this.formSubmitHandler(ev, null, this.forwardSignSubmit);
			},
		};

		if (forwardUser) {
			modal.action = [(
				<Button key="btn-submit"
					sx={{ ml: 1 }}
					variant="contained"
					type="submit"
					name="submit"
					onClick={this.onButtonSubmit}
				>
					<span>{t("common:general.submit")}</span>
				</Button>
			)];
		}
		this.setState({ modal });
	}

	ButtonReturnRevisionHandler = () => {
		let { t } = this.props;
		let { modal, isGuest, formData, docInfo, token } = this.state;
		let { FieldControl } = this;

		const returnRevisionClick = (ev) => {
			document.getElementById("return_for_revision_form").submited = ev.target.closest("[type=submit]");
		}
		const returnRevisionSubmit = () => {
			modal.body = () => {
				return <Loading />
			}
			modal.action = []
			this.setState({ modal });
			//cal api
			console.log(formData.return_comment);
			if(isGuest){
				Service.GuestRevisionReturnDocument(token, {
					'document_id': docInfo.document_id,
					'comment': formData.return_comment
				}).then((res) => {
					console.log(res);
					modal.body = () => {
						return <p>{t('common:documents.revision-return.success')}</p>
					};
					docInfo.doc_status = 17;
					this.setState({ modal, docInfo });
				}).catch((err) => {
					console.log(err);
					modal.body = () => {
						return <p>{t('common:documents.revision-return.error')}</p>
					};
					this.setState({ modal });
				})
			}else{
				Service.HostRevisionReturnDocument({
					'document_id': docInfo.document_id,
					'comment': formData.return_comment
				}).then((res) => {
					console.log(res);
					modal.body = () => {
						return <p>{t('common:documents.revision-return.success')}</p>
					};
					docInfo.doc_status = 17;
					this.setState({ modal, docInfo });
				}).catch((err) => {
					console.log(err);
					modal.body = () => {
						return <p>{t('common:documents.revision-return.error')}</p>
					};
					this.setState({ modal });
				})
			}


		}

		modal.props.show = true;
		modal.props.centered = true;
		modal.props.size = isGuest ? "lg" : "xl";
		modal.props.className = "layout modal-responsive";
		modal.title = "common:documents.return-revision.header";
		modal.body = () => {
			return <>
				<div className="mb-2">{t('common:documents.general.reason-return')}</div>
				<InputGroup className="mb-2" hasValidation >
					<Form.Control
						as="textarea"
						className="comment-input"
						row={5}
						value={formData.return_comment}
						name='return_comment'
						required
						isInvalid={this.state.isDirty && !formData.return_comment}
						onChange={(ev) => {
							console.log(ev.target.value)
							formData.return_comment = ev.target.value
							this.setState({formData, isDirty: true})
						}}
					/>
					<Form.Control.Feedback type="invalid">
						{t('common:validation.reason-return.required')}
					</Form.Control.Feedback>
				</InputGroup>
			</>
		}
		modal.form = {
			id: "return_for_revision_form",
			noValidate: true,
			onSubmit: (ev) => {
				this.setState({isDirty: true})
				this.formSubmitHandler(ev, null, returnRevisionSubmit);
			},
		};
		modal.action = [(
			<Button key="btn-submit"
				sx={{ ml: 1 }}
				variant="contained"
				type="submit"
				name="submit"
				onClick={returnRevisionClick}
			>
				<span>{t("common:documents.general.send")}</span>
			</Button>
		)];
		this.setState({ modal });
	}

	ButtonCancelDocumentHandler = () => {
		console.log('ButtonCancelDocumentHandler');
		let { t } = this.props;
		let { modal, formData, docInfo } = this.state;

		const cancelHandle = () => {
			modal.body = () => {
				return <Loading />
			}
			modal.action = []
			this.setState({ modal });
			Service.RevisionCancelDocument({
				'document_id': docInfo.document_id,
				'function_type': docInfo.function_type_id
			}).then((res) => {
				console.log(res);
				modal.body = () => {
					return <p>{t('common:documents.revision-cancel.success')}</p>
				};
				docInfo.doc_status = 18;
				docInfo.active_flg = 0;
				this.setState({ modal, docInfo });
			}).catch((err) => {
				console.log(err);
				modal.body = () => {
					return <p>{t('common:documents.revision-cancel.error')}</p>
				};
				this.setState({ modal });
			})
		}
		modal.props.show = true;
		modal.props.centered = true;
		modal.props.className = "layout modal-responsive";
		modal.title = "common:documents.cancel.header";
		modal.body = () => <div className="mb-2">{t('common:documents.cancel.content')}</div>
		modal.action = [(
			<Button key="btn-submit"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={cancelHandle}
			>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];
		this.setState({ modal });
	}

	ButtonResendDocumentHandler = () => {
		console.log('ButtonResendDocumentHandler');
		let { t } = this.props;
		let { modal, formData, docInfo } = this.state;

		const resendHandle = () => {
			modal.body = () => {
				return <Loading />
			}
			modal.action = []
			this.setState({ modal });
			Service.resendEmail(docInfo.document_id, null, true).then((res) => {
				console.log(res);
				docInfo.doc_status = 1;
				modal.body = () => {
					return <p>{t('common:documents.revision-resend.success')}</p>
				};
				this.setState({ modal, docInfo });
			}).catch((err) => {
				console.log(err);
				modal.body = () => {
					return <p>{t('common:documents.revision-resend.error')}</p>
				};
				this.setState({ modal });
			})
		}
		modal.props.show = true;
		modal.props.centered = true;
		modal.props.className = "layout modal-responsive";
		modal.title = "common:documents.resend.header";
		modal.body = () => <div className="mb-2">{t('common:documents.resend.content')}</div>
		modal.action = [(
			<Button key="btn-submit"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={resendHandle}
			>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];
		this.setState({ modal });
	}

	SignButton = (props) => {
		let { t } = this.props;
		let { docInfo, token, isGuest, view_status } = this.state;
		if (token && docInfo.linkage_doc_id) {
			return null;
		}

		const document_view_required = !!docInfo?.document_view_required

		const files = docInfo?.files || {};
		const filesCount = Object.keys(files).length;

		const viewedCount = Object.values(view_status).filter(value => value?.status == 1).length;

		// create a variable to compare viewedCount and total filesCount
		const isAllFilesViewed = viewedCount === filesCount;

		const documentProcessStatusData = useHookstate(documentProcessState);
		const isDocumentProcessing = documentProcessStatusData?.[this.documentProcessId]?.get({ noproxy: true })?.status === DocumentProcessStatus.PROCESSING;

		if (isDocumentProcessing) {
			return null;
		}

		// console.log('docInfo', docInfo)
		if ([1, 6, 7, 9, 11, 14].includes(docInfo.doc_status)) {
			if (docInfo.sign_permission === 1 && docInfo.active_flg == 1) {
				let Buttons = [];
				if (docInfo.signer_info && docInfo.signer_info.signing_status !== 'WAIT' && docInfo.signer_info.signed === 0 ) {
					if (docInfo.function_code === "verification") {
						Buttons.push(
							<Button key="btn-verify" variant="contained" size="large" onClick={this.onVerifyHandler} {...props}>
								<span className="btn-label">{t('common:documents.general.verify')}</span>
							</Button>
						);
					} else if (docInfo.signer_info.cert_exists) {
						if (document_view_required && !isAllFilesViewed && docInfo?.signer_info?.user_type !== 0 && docInfo?.signer_info?.user_type !== 2) {
							Buttons.push(
								<Button key="btn-sign" variant="contained" size="large" disabled sx={{
									"&.Mui-disabled": {
										color: "#ffffff",
										background: "rgba(25, 118, 210, 0.5)"
									}}} {...props}
								>
									<span className="btn-label">{t('common:documents.general.sign')}</span>
								</Button>
							);
						} else {
							Buttons.push(
								<Button key="btn-sign" variant="contained" size="large" onClick={this.onSignHandler} {...props}>
									<span className="btn-label">{t('common:documents.general.sign')}</span>
								</Button>
							);
						}
					} else {
						// Buttons.push(
						// 	<Button key="btn-upload-cert" variant="outlined" size="large" onClick={this.onCertificateUploadHandler} {...props}>
						// 		<span className="btn-label">{t('common:documents.general.certificate-upload')}</span>
						// 	</Button>
						// );

						if (token) {
							Buttons.push(
								<Button
									key="btn-request-cert"
									variant="outlined"
									size="large"
									onClick={!this.state.appCertLoading && this.onCertificateApplyHandler}
									{...props}
									style={{
										borderColor: this.state.appCert?.records.length > 0 ? "#959595" : undefined,
										minWidth: 150,
									}}
								>
									{this.state.appCertLoading ? (
											<CircularProgress size={18} />
										)
										: (
												<span
												className="btn-label"
												style={this.state.appCertApplied ? {
													color: "#959595"
												} : undefined}
											>
												{this.state.appCertApplied ? t('common:documents.general.certificate-applied') : t('common:documents.general.certificate-apply')}
											</span>
										)
									}
								</Button>
							);
						}
					}

					if ([1, 3].includes(docInfo.function_type_id) || ([2, 4].includes(docInfo.function_type_id) && !isGuest)) {
						// console.log(111);
						// console.log('isGuest', isGuest);
						// console.log('docInfo', docInfo);
						if (window.innerWidth > 760) {
							let checkReturn = !isGuest ? docInfo?.return_for_hosts : docInfo?.return_for_guests;
							if(checkReturn){
								if(isGuest || docInfo.create_user !== userInfoState.get({ noproxy: true })?.user_id){
									Buttons.push(
										<Button key="btn-sign-forward" color="info" variant="contained" size="large" onClick={this.ButtonReturnRevisionHandler} {...props}>
											<span className="btn-label">{t('common:documents.general.return_revision')}</span>
										</Button>
									);
								}
							}

							let checkGuest = !isGuest ? docInfo?.enable_host_transfer : docInfo?.enable_guest_transfer;
							// console.log(222, 'checkGuest', checkGuest);
							if(checkGuest){
								// console.log(333);
								Buttons.push(
									<Button key="btn-sign-forward" color="info" variant="contained" size="large" onClick={this.ButtonForwardHandler} {...props}>
										<span className="btn-label">{t('common:documents.general.forward')}</span>
									</Button>
								);
							}
							// Buttons.push(
							// 	<Button key="btn-sign-forward" color="info" variant="contained" size="large" onClick={this.ButtonForwardHandler} {...props}>
							// 		<span className="btn-label">{t('common:documents.general.forward')}</span>
							// 	</Button>
							// );
						}
					}
					if ([14].includes(docInfo.doc_status)) {
						Buttons = [];
					}
				}

				if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4 && !isGuest && docInfo.signer_info.signed === 0) {
					Buttons.push(
						<Button key="btn-save-error-log" variant="contained" size="large" onClick={this.onSignHandlers} {...props}>
							<span className="btn-label">{t('common:documents.general.signprotectpdf')}</span>
						</Button>
					);
				}
				return Buttons;
			}

		}
		if(docInfo.doc_status == 17){
			if (docInfo.active_flg == 1){
				// Options Available to the Document Creator
				if(!token && docInfo.create_user == userInfoState.get({ noproxy: true })?.user_id){
					let Buttons = [];
					Buttons.push(
						<Button key="btn-sign" variant="outlined" size="large" onClick={this.ButtonCancelDocumentHandler} {...props}>
							<span className="btn-label">{t('common:documents.general.cancel')}</span>
						</Button>,
						<Button key="btn-sign" variant="contained" size="large" color="info" startIcon={<SendIcon/>}
							onClick={this.ButtonResendDocumentHandler} {...props}
						>
							<span className="btn-label">{t('common:documents.general.return')}</span>
						</Button>,
						<Button
							sx={{ mr: 2 }}
							title={t('common:documents.general.recreate')}
							variant="contained"
							startIcon={<EditIcon />}
							className="as-link"
							component={Link}
							to={`/${docInfo.function_code}/documents/edit/${docInfo.id}`}
						>
							{t('common:documents.general.recreate')}
						</Button>
					)

					return Buttons;
				}
				else{
					// disabled all buttons
					let Buttons = [];
					if (docInfo.signer_info && docInfo.signer_info.signing_status !== 'WAIT' && docInfo.signer_info.signed === 0 ) {
						if (docInfo.function_code === "verification") {
							Buttons.push(
								<Button key="btn-verify" variant="contained" size="large" disabled {...props}>
									<span className="btn-label">{t('common:documents.general.verify')}</span>
								</Button>
							);
						} else if (docInfo.signer_info.cert_exists) {
							if (document_view_required && !isAllFilesViewed && docInfo?.signer_info?.user_type !== 0 && docInfo?.signer_info?.user_type !== 2) {
								Buttons.push(
									<Button key="btn-sign" variant="contained" size="large" disabled sx={{
										"&.Mui-disabled": {
											color: "#ffffff",
											background: "rgba(25, 118, 210, 0.5)"
										}}} {...props}
									>
										<span className="btn-label">{t('common:documents.general.sign')}</span>
									</Button>
								);
							} else {
								Buttons.push(
									<Button key="btn-sign" variant="contained" size="large" disabled {...props}>
										<span className="btn-label">{t('common:documents.general.sign')}</span>
									</Button>
								);
							}
						} else {
							if (token) {
								Buttons.push(
									<Button key="btn-request-cert" variant="outlined" size="large" disabled {...props}
										style={{ borderColor: this.state.appCert?.records.length > 0 ? "#959595" : undefined, minWidth: 150,}}
									>
										{this.state.appCertLoading ? ( <CircularProgress size={18} /> )
											: (
												<span className="btn-label" style={this.state.appCertApplied ? { color: "#959595" } : undefined}>
													{this.state.appCertApplied ? t('common:documents.general.certificate-applied') : t('common:documents.general.certificate-apply')}
												</span>
											)
										}
									</Button>
								);
							}
						}

						if ([1, 3].includes(docInfo.function_type_id) || ([2, 4].includes(docInfo.function_type_id) && !isGuest)) {
							if (window.innerWidth > 760) {
								let checkGuest = !isGuest ? docInfo?.enable_host_transfer : docInfo?.enable_guest_transfer;
								if(checkGuest){
									Buttons.push(
										<Button key="btn-sign-forward" color="info" variant="contained" size="large" disabled {...props}>
											<span className="btn-label">{t('common:documents.general.forward')}</span>
										</Button>
									);
								}
							}
						}

					}
					return Buttons
				}
			}
		}

		return null;
	}

	SignerFieldInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;
		let expiry_required = false;
		if (formData.dateofnoti || formData.dateofnoti === 0) {
			expiry_required = true;
		}
		if (parseInt(formData.dateofnoti) === 1) {
			expiry_required = false;
		}
		if (docInfo.function_code !== "signer") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="counter_party_name" key_label="contract-counter-party-name" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);
		RowItems.push(<InfoValue key="2" key_value="contract_date" key_label="contract-date" attributes={{ type: "date" }} />);
		RowItems.push(<InfoValue key="3" key_value="expiry_date" key_label="expiry-date" attributes={{ type: "date" }} required={expiry_required} />);
		RowItems.push(<InfoValue key="4" key_value="received_date" key_label="sign-finish-date" attributes={{ type: "date" }} />);
		RowItems.push(<InfoValue key="5" key_value="effective_date" key_label="effective-date" attributes={{ type: "date" }} />);

		return RowItems;
	}

	SenderFieldInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "sender") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="counter_party_name" key_label="supplier-name" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);
		RowItems.push(<InfoValue key="2" key_value="received_date" key_label="transaction-date" attributes={{ type: "date" }} />);
		// RowItems.push(<InfoValue key="contract_date" key_value="contract_date" key_label="contract-date" attributes={{ type: "date" }} />);
		// RowItems.push(<InfoValue key="expiry_date" key_value="expiry_date" key_label="expiry-date" attributes={{ type: "date" }} />);

		return RowItems;
	}

	CircleFieldInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "circle") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="received_date" key_label="document-create-date" attributes={{ type: "date" }} required  />);
		RowItems.push(<InfoValue key="2" key_value="sign_finish_date" key_label="circle-finish-date" attributes={{ type: "date" }}  />);
		RowItems.push(<InfoValue key="1" key_value="counter_party_name" key_label="supplier-name" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);
		RowItems.push(<InfoValue key="3" key_value="content" key_label="content" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }}  />);

		return RowItems;
	}

	VerificationInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "verification") {
			return null;
		}

		let required = true;
		if ([1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="received_date" key_label="deal-date" attributes={{ type: "date" }} required={required}  />);
		RowItems.push(<InfoValue key="2" key_value="counter_party_name" key_label="counter-party-name" required={required}  />);

		return RowItems;
	}

	SharedFolderInfo = () => {
		const controller = new AbortController();
		let { t } = this.props
		let { InfoValue } = this;
		let { docInfo, formData } = this.state
		const loading = useHookstate(false);
		const inputValue = useHookstate(docInfo.shared_folders_path || '');
		const selectedFolder =useHookstate(selectedRowsTreeSearch);
		const folderSearch = useHookstate([]);
		const handleInput = useCallback(throttle(searchFolder, 1000, { leading: false }), []);
		const getPathFolderById = (val, path) => {
			console.log('getPathFolderById',val[0], path);
			if(val[0]){
				let currentPath = '/' + val[0]?.name + path
				if(val[0].parent_id === 'drive'){
					console.log('currentPath', currentPath);
					inputValue.set(currentPath)
					formData.shared_folders_path = currentPath.slice(1);
					this.updateState({ formData });
				}
				else{
					const parent = folderSearch.get({noproxy: true}).filter((v) => v.drive_folder_id === val[0].parent_id)
					if(!!parent) getPathFolderById(parent, currentPath)
				}
			}
		}
		const handleSelect = (val) => {
			formData.drive_folder_id = val.get({ noproxy: true })[0]?.drive_folder_id
			getPathFolderById(val.get({ noproxy: true }), '');
			this.updateState({ formData });
		}
		async function searchFolder(e) {
			loading.set(true);
			const getFoldersTreeData = await getFoldersTree(
				{
					search: e,
					page_number: 1,
					page_size: 50,
					nameSearch: 1,
					parent_id: "null",
					current_folder_id_list: ""
				},
				controller.signal
			)
			breabCrumbState.set([getFoldersTreeData.data.payload.find(a=> a.type === 6)].map(mappedDataToTreeView));
			const defaultId = breabCrumbState
				.get({ stealth: true })
				.find((a) => a.type === 6)?.id;

			return getCompanyFolders({
				page_number: 1,
				page_size: 100,
				drive_folder_id: defaultId,
				search: e,
				offset_folder_number: 0,
				order_by: "name asc, updated_date desc, size asc, memo asc",
			  }).then((r) => {
				treeSearchData.set([...r.data.payload.map(mappedDataToTreeView)]);
				if (!r?.data?.payload || r?.data?.payload?.length === 0) {
					folderSearch.set([]);
					return;
				}
				folderSearch.set(
					[...r.data.payload.map(mappedDataToTreeView)].reduce((arr, item) => {
					if (
						item.type === 6 &&
						userInfoState.get({ noproxy: true })?.tenant_name
					) {
						item.name = userInfoState.get({ noproxy: true }).tenant_name;
					}
					if (!r.data.payload.find((a) => a.id === item.parent_id)) {
						item.parent_id = "drive";
						item.parent = "drive";
					}
					arr.push(item);

					return arr.sort((a, b) => {
						if (a.type === 7) {
						return -1;
						} else {
						return 0;
						}
					});
					}, [])
				);

			}).finally(() => loading.set(false));
		}
		return <InfoValue key="1" key_value="shared_folders_path" key_label="filebox-path" attributes={{ type: "text" }} required={false}
		children={
			<FormGroup className='custom-form-outline form-group col form-folder' onFocus={() => {
				searchFolder('');
				inputValue.set('');
				docInfo.drive_folder_id ? selectedFolder.set([{
					drive_folder_id: formData.drive_folder_id
				}]) : selectedFolder.set([])
				}}>
				<TreeSearchAutocomplete
					treeData={folderSearch}
					loading={loading}
					defaultTree={folderSearch.get({ noproxy: true })}
					searchInputCb={handleInput}
					selectedCb={handleSelect}
					inputValueProp={inputValue}
				/>
			</FormGroup>
		} />;
	}

	AccesesLogInfo = () => {
		const { AccessLogTree } = this
		return (
			<Form.Row className="d-sm-block">
				<div>
					<AccessLogTree />
				</div>
			</Form.Row >
		)
	}

	CommonItemInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue, InfoValueField } = this;

		let required = true;
		if (docInfo.function_code === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		let RowItems = [];
		let CurrencyItems = { field: "currencies", value: "code", label: "label" };
		RowItems.push(
			<InfoValue key="1" key_value="amount" key_label="amount" key_join=" " alignment=" align-right">
				<InfoValueField key_value="amount" key_label="amount" xl={6} md={8} xs={6} attributes={{ type: "number" }} required={required} />
				<InfoValueField key_value="currency" key_label="currency" xl={2} md={4} xs={3} attributes={{ as: "select" }} items={CurrencyItems} required={required} />
			</InfoValue>
		);
		RowItems.push(<InfoValue key="3" key_value="product_name" key_label="product" />);
		RowItems.push(<InfoValue key="4" key_value="reference" key_label="reference" />);
		if (docInfo.function_code === "verification") {
			RowItems.push(<InfoValue key="5" key_value="invoice_number" key_label="invoice-number" />);
		}


		return RowItems;
	}

	DocumentStatusInfo = () => {
		let { t } = this.props;
		let { docInfo } = this.state;

		let StatusIcon = faQuestionCircle;
		let statusInfo = docInfo.status_list[docInfo.doc_status];
		if (docInfo.doc_status === 0) {
			StatusIcon = faExclamationCircle;
		} else if ([1, 7, 9, 11, 17, 18].includes(docInfo.doc_status)) {
			StatusIcon = faInfoCircle;
		} else if ([2, 8, 10, 12].includes(docInfo.doc_status)) {
			StatusIcon = faCheckCircle;
		} else if (docInfo.doc_status === 3) {
			StatusIcon = faStopCircle;
		} else if (docInfo.doc_status === 4) {
			StatusIcon = faMinusCircle;
		} else if (docInfo.doc_status === 5) {
			StatusIcon = faTimesCircle;
		}
		if (docInfo.doc_status === 14 && docInfo.function_type_id === 4) {
			// statusInfo.label = "document.status.cannot-timestamp";
			statusInfo.label = "document.status.protected-PDF-supported";
			statusInfo.code = "confirmed";
		}

		if (docInfo.remarks === "protected" && [1, 2, 3].includes(docInfo.function_type_id)) {
			statusInfo.code = "error";
		}
		return (
			<Form.Row>
				<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
					<span className="document-info-key">{t("common:documents.document-info.document-status")}</span>
				</Form.Group>
				<Form.Group xl={8} md={12} xs={9} as={Col} className="document-status-label">
					<div className={"form-control document-info-value " + statusInfo.code}>
						{docInfo['processing_flg'] === 1 ? (
							<>
								<CircularProgress color={"primary"} size={22}/>
								<span className="label">{t(`common:document.status.processing`)}</span>
							</>
						) : (
							<>
								<FontAwesomeIcon icon={StatusIcon} className="icon"/>
								<span className="label">{t(`common:${statusInfo.label}`)}</span>
							</>
						)}
					</div>
				</Form.Group>
			</Form.Row>
		)
	}

	SignLevelInfo = () => {
		let { t } = this.props;
		let { docInfo, UpdateInfo } = this.state;

		if (["signer", "circle"].includes(docInfo.function_code)) {
			let style = null;
			if (UpdateInfo) {
				style = { backgroundColor: "#cfd4da" };
			}
			// console.log('d321321312mm321m23123123', docInfo.sign_level)
			return (
				<Form.Row>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.sign-level")}</span>
					</Form.Group>
					<Form.Group xl={8} md={12} xs={9} as={Col}>
						<span className="form-control document-info-value" style={style}>{t(`common:documents.document-info.sign-level-${docInfo.sign_level}`)}</span>
					</Form.Group>
				</Form.Row >
			);
		} else {
			return null;
		}
	}

	DocumentTypeInfo = () => {
		let { InfoValue } = this;
		let { docInfo } = this.state

		let items = { field: "doc_types", value: "id", label: "label" };
		return (
			<InfoValue key_value="document_type_id" key_label="document-type" attributes={{ as: "select" }} items={items} required  />
		)
	}

	DocdateOfNotice = () => {
		let { t, name } = this.props;
		let { InfoValue, InfoValueField } = this;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData, docInfo, UpdateInfo, msgValidate, isInvalid } = this.state;
		let Notival = docInfo.dateofnoti;
		let readOnly = true;
		if (docInfo.function_type_id !== 1) {
			return null;
		}
		if (UpdateInfo) {
			readOnly = false;
		}
		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i === 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i === 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			ItemElements.push(<option key={i} value={i}>{t(label)}</option>);
		}

		if ((parseInt(formData.dateofnoti) !== parseInt(docInfo.dateofnoti)) && formData.dateofnoti !== undefined) {
			Notival = formData.dateofnoti;
		}

		return (
			<InfoValue key_value="dateofnoti" key_label="select_datenoti" >
				<InfoValueField
					key_value="dateofnoti"
					key_label="select_datenoti"
					attributes={{ as: "select", disabled: readOnly }}
					disabled={readOnly}
					value={Notival}

				>
					{ItemElements}
				</InfoValueField>
			</InfoValue>
		);
	}



	DocumentDateofNoti = () => {
		let { t } = this.props;
		let { InfoValue, InfoValueField } = this;
		let { formData, docInfo } = this.state;
		let required = true;

		if (docInfo.function_type_id !== 1) {
			return null;
		}

		if (!formData.expiry_date) {
			required = false;
		}
		let items = ['', 60, 90, 120];
		return (
			<InfoValue key_value="date-noti-" key_label="date-noti-" >
				<InfoValueField key_value="dateofnoti" key_label="date-noti-" attributes={{ as: "select" }} required={required} >
					{items.map(item => <option key={`key-${item}`} value={item}>{t(`common:documents.document-info.date-noti-${item}`)}</option>)}
				</InfoValueField>
			</InfoValue>


		)
	}

	DocumentSelectDatenoti = () => {
		// let { t } = this.props;
		let { InfoValue, InfoValueField, DateNotiRequired } = this;
		let { formData, docInfo, UpdateInfo } = this.state;
		// let lbname = "select_datenoti";
		if (docInfo.function_type_id !== 1) {
			return null;
		}

		if (docInfo.dateofnoti !== 0 && formData.dateofnoti !== 0) {
			return null;
		}

		if (UpdateInfo && formData.dateofnoti !== 0) {
			return null;
		}

		// if (UpdateInfo) {
		// 	lbname = "select_datenoti1";
		// }
		let RowItems = [];
		RowItems.push(
			<InfoValue key_value="select_datenoti" key_label="select_datenoti1">
				<InfoValueField key_value="select_datenoti" key_label="select_datenoti1" attributes={{ type: "date" }} required={DateNotiRequired()}  />
			</InfoValue>
		);

		return RowItems;

	}

	ChkDateNoti = () => {
		let { formData, UpdateInfo, docInfo } = this.state;
		if (UpdateInfo) {
			let repExpiry_date = formData.expiry_date.replaceAll("-", "/");
			let currentDate = new Date();
			let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
			let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');

			if (docInfo.dateofnoti !== formData.dateofnoti) {
				if (calculateDateChk < formData.dateofnoti) {
					// formData.expiry_date = "";

					return true;
					// this.setFormData(formData);
				}
			}
		}

		return false;
	}

	// ChksSelectDateNoti = () => {
	// 	let { formData, UpdateInfo, docInfo } = this.state;
	// 	if (UpdateInfo) {

	// 		let repExpiry_date = formData.select_datenoti.replaceAll("-", "/");
	// 		let currentDate = new Date();
	// 		let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
	// 		let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');

	// 		if (calculateDateChk < 0) {
	// 			if (formData.dateofnoti !== docInfo.dateofnoti) {
	// 				formData.select_datenoti = "";
	// 				return true;
	// 			}
	// 			formData.select_datenoti = docInfo.select_datenoti;

	// 			return true;

	// 		}

	// 	}

	// 	return false;
	// }

	DocumentTitleInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;

		let required = true;
		if (docInfo.function_code === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		return (
			<InfoValue key_value="title" key_label="doc-title" required={required} />
		);
	}

	PreservationRequireInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "verification") {
			return null;
		}

		let items = { field: "preservation_require", value: "id", label: "label" };
		return (
			<InfoValue key_value="preservation_require" key_label="preservation-require" attributes={{ as: "select" }} items={items} required />
		);
	}

	PageDataItems = (props) => {
		let { t } = this.props;
		let { field, value, label } = props;
		let { PageData } = this.state;

		if (!PageData) {
			return null;
		}

		let resultElements = null;
		if (PageData[field] instanceof Array) {
			resultElements = PageData[field].map(item => <option key={item[value]} value={item[value]}>{t(`common:${item[label]}`)}</option>);
		} else {
			resultElements = Object.values(PageData[field]).map(item => <option key={item[value]} value={item[value]}>{t(`common:${item[label]}`)}</option>);
		}
		return resultElements;
	}

	localeStringCustom = (val) => {
		let isNegative = false
		if (val.startsWith('-')) {
			isNegative = true;
			val = val.substring(1);
		}
		let str = val.split('.');
		if (str.length > 0) {
			if (str[0] !== '0') {
				str[0] = str[0].replace(/^0+/, '').split(/(?=(?:...)*$)/).join(",");
			}
		}
		if (isNegative) {
			return '-' + str.join('.')
		}
		return str.join('.');
	}

	InfoValueField = (props) => {
		let { t } = this.props;
		let { key_value, key_label, xl, md, xs, items, required, attributes, disabled } = props;
		let { formData, formValidate, docInfo, msgValidate } = this.state;
		let { PageDataItems,CounterPartyList } = this;

		let ChildElements = props.children;
		if (!ChildElements && items) {
			ChildElements = <PageDataItems {...items} />;
		}
		let key_valueshow = formData[key_value];
		if (key_label === "date-noti-") {
			key_valueshow = docInfo.dateofnoti;
		}

		if (key_value === "dateofnoti") {
			if(!formData.dateofnoti)
				key_valueshow = docInfo.dateofnoti;
			if ((parseInt(formData.dateofnoti) !== parseInt(docInfo.dateofnoti)) && formData.dateofnoti !== undefined) {
				key_valueshow = formData.dateofnoti;
			}
		}

		let classList = ["custom-form-outline"];
		if (formValidate && required) { classList.push("was-validated") }
		if (formValidate) { classList.push("form-validated") }

		let translateLabel = t(`common:documents.document-info.${key_label}`);
		let newAttributes = { ...attributes };

		function validateExpiryDateFunc(self){
			// console.log('3333333', formData.select_datenoti, ev);
				let { isInvalid } = self.state;
				let repExpiry_date = formData?.expiry_date?.replaceAll("-", "/");
				let notiDate = Moment(formData?.select_datenoti).format("YYYY/MM/DD") ?? '';
				let createDate = Moment(self.state.docInfo.create_datetime?.substring(0,10).replaceAll("-", "/")).format("YYYY/MM/DD");
				let calculateDateChk = Moment(repExpiry_date).diff(createDate, 'days');
				// let checkDateNoti = Moment(repExpiry_date).diff(notiDate, 'days');
				if(parseInt(formData.dateofnoti) !== 1){ //setting noti date
					if(!repExpiry_date?.length){
						msgValidate.expiry_date = t("common:message.input.required-expiry-date");
						isInvalid.expiry_date = true
					} else if(parseInt(formData?.dateofnoti) === 0){ //custom noti date
						// if(checkDateNoti < 0){
						// 	msgValidate.expiry_date = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date: notiDate})
						// 	msgValidate.select_datenoti = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date: notiDate})
						// 	isInvalid.expiry_date = true;
						// 	isInvalid.select_datenoti = true;
						// }else{
						// 	isInvalid.expiry_date = false
						// 	isInvalid.select_datenoti = false
						// }
						validateDateofNoti(self);
					} else { //set noti date after 60, 90, 120
						let notiDateSetting = Moment(createDate).add(formData.dateofnoti, 'days')
						let checkDateNotiByDay = Moment(repExpiry_date).diff(notiDateSetting, 'days');
						if(checkDateNotiByDay < 0){
							msgValidate.expiry_date = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date: repExpiry_date?.substring(0, 10)})
							isInvalid.expiry_date = true
						}else{
							isInvalid.expiry_date = false
						}
					}
				}else{ //no setting noti date
					if(calculateDateChk < 0){
						msgValidate[key_value] = t("common:message.input.expiry_date_less_than_create_date", { date: createDate})
                    	isInvalid.expiry_date = true
					}else{
						isInvalid.expiry_date = false
					}
				}


				self.setState({ isInvalid, msgValidate })
		}
		function validateDateofNoti(self){
			let { isInvalid, formData, msgValidate } = self.state;
			let repSelectDate_date = formData?.select_datenoti?.replaceAll("-", "/");
			let expiryDate = formData?.expiry_date?.substring(0, 10)?.replaceAll("-", "/");
			let currentDate = new Date();
			let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
			let calculateDateExpiry = Moment(formData?.expiry_date).startOf("day").diff(repSelectDate_date, 'days');
			let calculateDateChk = Moment(repSelectDate_date).diff(formatcurrentDate, 'days');
			// let calculateDateExpiry = Moment(repSelectDate_date)
			if(!repSelectDate_date?.length) {
				msgValidate.select_datenoti = t("common:message.input.required-select_datenoti")
				isInvalid.select_datenoti = true
			} else if ((isNaN(calculateDateExpiry) || calculateDateExpiry < 0) && new Date(formData.select_datenoti).getFullYear() > 1000) {
				isInvalid.select_datenoti = true
				isInvalid.expiry_date = true
				msgValidate.select_datenoti = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date: expiryDate});
				msgValidate.expiry_date = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date: expiryDate});
				if(!expiryDate?.length){
					msgValidate.select_datenoti = null;
				}
			} else if ((isNaN(calculateDateChk) || calculateDateChk < 0) && new Date(formData.select_datenoti).getFullYear() > 1000) {
				isInvalid.select_datenoti = true
				msgValidate.select_datenoti  = t("common:message.input.less-than-current-select_datenoti")
			} else{
				isInvalid.select_datenoti = false
				isInvalid.expiry_date = false
			}
			if(!expiryDate?.length){
				msgValidate.expiry_date = t("common:message.input.required-expiry-date");
				isInvalid.expiry_date = true;

			}
			self.setState({ isInvalid, msgValidate })
		}
		// if(key_value === 'select_datenoti' && parseInt(formData.dateofnoti) === 0) {
        //     newAttributes.onBlur = validateDateofNoti;
        // }
		// if(key_value === 'expiry_date'){
		// 	newAttributes.onBlur = validateExpiryDateFunc;
		// }
		if (key_value === "amount") {
			// console.log(formData[key_value])
			// key_valueshow = parseFloat(formData[key_value]).toString();
			let val = formData[key_value];

			if ((typeof val) !== "string") {
				val = this.localeStringCustom(val.toString());
				formData[key_value] = val;
			}
			newAttributes.onInput = (e) => {
                if(typeof attributes.onInput === 'function'){
                    attributes.onInput(e)
                }
                const value = e.target.value;
                const parts = value.split('.');
                if (parts.length > 1 && parts[1].length > 6) {
                  e.target.value = `${parts[0]}.${parts[1].slice(0, 6)}`;
                }
                formData[e.target.name] = e.target.value;
                this.setState({ formData });
            }
			newAttributes.onBlur = (ev) => {
				let { isInvalid } = this.state;
				let val = ev.target.value;

				val = val.normalize('NFKC');
				val = val.replaceAll("ー", "-");
				val = val.replaceAll(",", "");
				val = val.trim();
				if (!val || val === "") {
					let fieldTrans = t(`common:message.req.${key_label}`, key_label);
					msgValidate[key_value] = t("common:message.input.required", { field_name: fieldTrans });
					isInvalid["amount"] = true;
				}
				else if (val !== '-' && (isNaN(val) || val === '00' || val === '-00')) {
					msgValidate[key_value] = t("common:message.input.invalid-amount");
					isInvalid["amount"] = true;
				} else {
					isInvalid["amount"] = false;
				}
				if (!isInvalid["amount"]) {
					val = this.localeStringCustom(val);
				}
				formData[ev.target.name] = val;

				this.setState({ formData, isInvalid, msgValidate });
			}
			newAttributes.onClick = (ev) => {
				if (ev.target.value === "0" || ev.target.value === 0) {
					formData[key_value] = '';
				}
				formData[ev.target.name] = formData[ev.target.name].replaceAll(",", "");
				this.setState({ formData });
			}
			newAttributes.onKeyPress = (ev) => {
				if (ev.charCode === 32) {
					ev.preventDefault();
				}
			};
			newAttributes.style = { textAlign: 'right' };
			newAttributes.maxLength = "19";
			delete newAttributes.type;
		}

		// Add max value to date input
		if (newAttributes.type === "date") {
			newAttributes.max = "9999-12-31";
			key_valueshow = key_valueshow.substring(0, 10);
			// this.setState({ formData });
		}
		// let msgValidate = t('common:message.input.required', { field_name: translateLabel });

		// if (key_label === "expiry-date") {
		// 	msgValidate = t("common:message.input.required-expiry-date");
		// }


		// if (key_label === "select_datenoti1") {
		// 	msgValidate = t("common:message.input.required-select_datenoti1");
		// }
		// if ()
		// console.log('key_valueshow', key_valueshow)

		function checkAndConvertDate(value) {
			const regex = /^\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
			if (regex.test(value)) {
				return value.split('T')[0];
			} else {
				return value;
			}
		}

		return (
			<Form.Group key={key_value} xl={xl} md={md} xs={xs} as={Col} controlId={key_value} className={classList.join(" ")}>
				<Form.Control
					{...newAttributes}
					name={key_value}
					{...(key_value === 'counter_party_name' ? {list:"counter-party",
					 autoComplete:"true"}:{})}
					placeholder={translateLabel}
					disabled={disabled}
					value={[null, undefined].includes(key_valueshow) ? "" : key_valueshow}
					isValid={this.state.isInvalid.hasOwnProperty(key_value) && !this.state.isInvalid[key_value]}
					isInvalid={this.state.isInvalid.hasOwnProperty(key_value) && this.state.isInvalid[key_value]}
					onChange={(ev) => {
						if (key_value === "amount") {
							let val = ev.target.value;
							let { t } = this.props;
							if (val === "") {
								let fieldTrans = t(`common:message.req.${key_label}`, key_label);
								msgValidate[key_value] = t("common:message.input.required", { field_name: fieldTrans });
							}
							formData[key_value] = val;
						} else if(key_value === "dateofnoti"){
							// formData.expiry_date = ""
							let { isInvalid } = this.state;
							formData.dateofnoti = parseInt(ev.target.value);
							if (formData.dateofnoti !== 1) {
								formData.select_datenoti = "";
							} else {
								isInvalid.select_datenoti = false;
								isInvalid.expiry_date = false;
							}

							this.setState({formData, isInvalid, msgValidate}, ()=>{
								validateExpiryDateFunc(this);
								if(parseInt(formData?.dateofnoti) > 1){
									let repExpiry_date = checkAndConvertDate(formData?.expiry_date?.replaceAll("-", "/"));
									let currentDate = new Date();
									let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
									let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');
									if (calculateDateChk < parseInt(formData?.dateofnoti)) {
										isInvalid.dateofnoti = true;
										msgValidate.dateofnoti  = t(`common:documents.document-info.msgerrorselect_noti`);
									} else {
										isInvalid.dateofnoti = false;
									}
								} else {
									isInvalid.dateofnoti = false;
								}
								this.setState({isInvalid, msgValidate});
							});
						} else {
							formData[key_value] = [null, undefined].includes(ev.target.value) ? "" : ev.target.value; // ev.target.value || "";
						}
						this.setState({ formData }, ()=>{
							let {formData, isInvalid} = this.state;
							let checkDateValid = ((formData[key_value]?.length > 0 && formData[key_value]?.match(/^\d{4}-\d{2}-\d{2}$/)) || !formData[key_value]?.length);
							// console.log('formData[key_value]',formData?.[key_value] && formData?.[key_value]?.match(/^\d{4}-\d{2}-\d{2}$/) || !formData[key_value]?.length)
							if(key_value === 'select_datenoti' && parseInt(formData.dateofnoti) === 0 && checkDateValid) {
								validateDateofNoti(this);
								validateExpiryDateFunc(this);
							}
							if(key_value === 'expiry_date' && checkDateValid){
								validateExpiryDateFunc(this);
								if(parseInt(formData.dateofnoti) === 0) {
									validateDateofNoti(this);
								}

								if(parseInt(formData?.dateofnoti) > 1){
									let repExpiry_date2 = checkAndConvertDate(formData?.expiry_date?.replaceAll("-", "/"));
									let currentDate2 = new Date();
									let formatcurrentDate2 = Moment(currentDate2).format("YYYY/MM/DD");
									let calculateDateChk2 = Moment(repExpiry_date2).diff(formatcurrentDate2, 'days');
									if (calculateDateChk2 < parseInt(formData?.dateofnoti)) {
										isInvalid.dateofnoti = true;
										msgValidate.dateofnoti  = t(`common:documents.document-info.msgerrorselect_noti`);
									} else {
										isInvalid.dateofnoti = false;
									}
								} else {
									isInvalid.dateofnoti = false;
								}
							}
						});
					}}
					required={required ?? false}
				>{ChildElements}</Form.Control>
				{key_value === 'counter_party_name' ?
					<CounterPartyList />:null}
				<Form.Control.Feedback type="invalid" style={{height: 'auto'}}>
					{msgValidate[key_value]}
				</Form.Control.Feedback>
			</Form.Group>
		);
	}

	CounterPartyList = () => {
		if(!this.state.PageData) return <></>;
		let { counter_party } = this.state.PageData;

		let row = [];
		for (let i = 0; i < counter_party.length; i++) {
			let counterPartyName = counter_party[i].counter_party_name;

			row.push(
				<option key={`${counterPartyName}-${i}`} value={counterPartyName} />
			);
		}
		// console.log(counter_party)
		return (
			<datalist id="counter-party">
				{row}
			</datalist>
		);
	}

	InfoValue = (props) => {
		let { t } = this.props;
		let { key_value, key_label, alignment, key_join } = props;
		let { docInfo, UpdateInfo, keyFields } = this.state;
		let { InfoValueField, CounterPartyList } = this;
		// console.log(docInfo);
		let key_labelshow = t(`common:documents.document-info.${key_label}`);
		let ChildElements = props.children;
		if (key_value === 'amount'){
			docInfo.amount_text = this.numFormat(docInfo.amount);
		}
		if(key_value === 'select_datenoti' && docInfo?.select_datenoti){
			docInfo.select_datenoti = docInfo.select_datenoti.substring(0, 10)
		}
		// console.log(props.attributes)
		if (UpdateInfo) {
			if (!ChildElements) {
				ChildElements = <InfoValueField {...props} />
			}

			if (key_label === "select_datenoti1") {
				key_labelshow = "";
			}
			return (
				<Form.Row data-key={key_label}>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{key_labelshow}</span>
					</Form.Group>
					{ChildElements}
				</Form.Row>
			);
		} else {
			let field_value = "";

			if (ChildElements) {
				if (!(ChildElements instanceof Array)) {
					ChildElements = [ChildElements];
				}
				field_value = ChildElements.map(item => item.props.key_value);
				field_value = field_value.map(item => docInfo[keyFields[item]] || "").join(key_join || ",");
				if ((["select_datenoti", "active_flg"].includes(key_label))) {//select_datenoti1
					if (!ChildElements) {
						ChildElements = <InfoValueField {...props} />
					}
					return (
						<Form.Row data-key={key_label} className={props.className}>
							<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
								<span className="document-info-key">{key_labelshow}</span>
							</Form.Group>
							{ChildElements}
						</Form.Row>
					);
				}
				if (key_value === 'shared_folders_path'){
					field_value =  docInfo[keyFields[key_value]] || "";
				}

			} else {
				field_value = docInfo[keyFields[key_value]] || "";
			}

			if (key_label === "select_datenoti1") {
				key_labelshow = "";
			}

			if (props.attributes?.type === 'date'){
				field_value = field_value ? field_value.substring(0, 10): '';
			}

			// if (key_value === 'shared_folders_path'){
			// 	field_value =  field_value ? field_value + '/': '';
			// }
			// console.log(field_value, key_value, docInfo[keyFields[key_value]], keyFields)

			return (
				<Form.Row data-key={key_label}>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{key_labelshow}</span>
					</Form.Group>
					<Form.Group xl={8} md={12} xs={9} as={Col}>
						<span className={`form-control document-info-value${alignment || ""}`}>{t(`common:${field_value}`)}</span>
					</Form.Group>
				</Form.Row>
			);
		}
	}

	DateNotiRequired = () => {
		let { formData, UpdateInfo } = this.state;
		if (UpdateInfo) {
			if (formData.dateofnoti) {
				if (parseInt(formData.dateofnoti) === 1) {
					return false;
				}
				return true;
			} else if (parseInt(formData.dateofnoti) === 0) {
				return true;
			}

			return false;

		}
	}

	checkPendingGuestExisting = () => {
		const signers = this.state.docInfo.signers;

		for (let signer of signers) {
			if (signer.user_type === 1 && signer.user_status === 3) {
				return true;
			}
		}
		return false;
	}

	ActiveFlgInfo = () => {
		let { t } = this.props;
		let {  InfoValue } = this;
		let {formData, docInfo, UpdateInfo} = this.state;
		if(!UpdateInfo){
			return  <InfoValue key_value="active_flg" className="document_active_chip" key_label="active_flg">
				{(!UpdateInfo && docInfo.active_flg === 1) || formData.active_flg === 1 ?

							<span className="activeFlag_active">{t(`common:documents.document.active`)}</span>:

								<span className="activeFlag_inactive">{t(`common:documents.document.inactive`)}</span>}


			</InfoValue>
		}
		return  <InfoValue key_value="active_flg" key_label="active_flg">
			<Android12Switch
				color="secondary"
				disabled={!UpdateInfo}
				checked={(!UpdateInfo && docInfo.active_flg === 1) || formData.active_flg === 1}
				onChange={()=> {
					formData.active_flg = formData.active_flg === 1 ? 0 : 1;
					this.setState({ formData });
				}
				}
			/>
			{/* <Form.Check name="active_flg" value={this.state.docInfo.active_flg} type="switch" /> */}
		</InfoValue>
		// return <Form.Row>
		// 		<Form.Group className="mb-3" controlId="formBasicCheckbox">

		// 		</Form.Group>
		// 	</Form.Row >
	}

	HostDocInfo = () => {
		const {
			DocumentStatusInfo,
			SignLevelInfo,
			DocumentTypeInfo,
			DocumentTitleInfo,
			CommonItemInfo,
			SignerFieldInfo,
			SenderFieldInfo,
			CircleFieldInfo,
			VerificationInfo,
			ObjectAttributes,
			// DocumentDateofNoti,
			DocumentSelectDatenoti,
			DocdateOfNotice,
			ActiveFlgInfo,
			AccesesLogInfo,
			SharedFolderInfo,
			PreservationRequireInfo,
		} = this;
		let { formData } = this.state;
		let ChkDateNoti = this.ChkDateNoti();
		// let ChksSelectDateNoti = this.ChksSelectDateNoti();
		let chkDatenotiSelected = "";
		// let requireddate = this.DateNotiRequired();
		let { t } = this.props;

		if (parseInt(formData.dateofnoti) === 0) {
			chkDatenotiSelected = "block"
		} else {
			chkDatenotiSelected = "none"
		}
		return (
			<>
				<ActiveFlgInfo />
				<DocumentStatusInfo />

				<SignLevelInfo />

				<DocumentTypeInfo />

				<PreservationRequireInfo />

				<DocumentTitleInfo />

				<CommonItemInfo />

				<SignerFieldInfo />
				<SenderFieldInfo />
				<CircleFieldInfo />
				<VerificationInfo />
				<SharedFolderInfo />

				{/* <DocumentDateofNoti /> */}

				<DocdateOfNotice validate={{ ChkDateNoti }} />
				<DocumentSelectDatenoti style={{ display: chkDatenotiSelected}} />

				<ObjectAttributes />

				<AccesesLogInfo />

				<button id="form_detail_submit" type="submit" hidden></button>

				{this.checkPendingGuestExisting() && (
					<p
						style={{
							whiteSpace: 'pre-line',
							color: "#f3821d",
							marginTop: 16,
						}}
					>
						{t("common:documents.info.guest-registration-not-complete")}{'\n'}
						{t("common:documents.info.guest-registration-not-complete-2")}
					</p>
				)}

			</>
		);
	}

	formSubmitCallback = (ev) => {
		// console.log(ev);
		let { t } = this.props;
		let { modal } = this.state;
		// console.log("docInfo", docInfo);
		modal.title = "common:documents.info.confirm-update";
		modal.body = "common:message.documents.info.confirm-update";
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.DocumentInfoSave}>
				<span>{t("common:general.confirm")}</span>
			</Button>
		)];

		modal.props.show = true;
		modal.props.centered = true;

		this.setState({ modal });
	}

	GuestDocInfo = () => {
		const {
			DocumentStatusInfo,
			DocumentTitleInfo,
		} = this;

		return (
			<>
				<DocumentStatusInfo />
				<DocumentTitleInfo />
			</>
		);
	}

	async previewAttachment(file) {
		// console.log(file);
		let {AttachmentFiles} = this.state;
		file.file_type = file.type;
		// let url = URL.createObjectURL(file.file);
		if(['application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'docx', 'doc', 'ppt', 'pptx'].includes(file.type)){
			file.fileTypePreview = 'office';
		}

		if(file.type.startsWith('image/') || ['png', 'jpg', 'gif', 'jpeg', 'svg'].includes(file.type)) {
			file.fileTypePreview = 'image';
		}

		if(file.type.startsWith('application/pdf') || file?.file_type === 'pdf') {
			file.fileTypePreview = 'pdf';
		}

		if(file.type.startsWith('video/mp4') || file.type === 'mp4') {
			file.fileTypePreview = 'mp4';
		}
		if( ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv', 'xlsx', 'xls' ].includes(file.type)){
			file.fileTypePreview = 'excel';
		}

		let fileUrlTemp = '';
		let previewFile = {...file};
		showPreviewAttachment.loading.set(true);
        showPreviewAttachment.open.set(true);
		if(file.fileTypePreview === 'office'){

			if(!previewFile.url_preview) {
				axios.get(previewFile.url, {responseType: 'blob'}).then(async r=>{
					showPreviewAttachment.loading.set(true);
					const formDataFile = new FormData();
					formDataFile.append('file', r.data, previewFile.name);
					formDataFile.append('ignore_first_page', 'true');
					fileUrlTemp = (await Service.convertFileToPdf(formDataFile))?.payload?.url;
					previewFile.fileTypePreview = 'pdf';
					previewFile.url_preview = fileUrlTemp;
					showPreviewAttachment.fileType.set(previewFile.fileTypePreview);
					showPreviewAttachment.fileName.set(previewFile.name);
					showPreviewAttachment.fileUrl.set(previewFile.url_preview);
					showPreviewAttachment.loading.set(false);
					AttachmentFiles[file.hash].url_preview = previewFile.url_preview;
					AttachmentFiles[file.hash].fileTypePreview = previewFile.url_preview;
				})
			}
		} else if(file.fileTypePreview === 'mp4' || file.fileTypePreview === 'image') {
			// if(previewFile.url_preview) URL.revokeObjectURL(previewFile.url_preview);
			previewFile.url_preview = previewFile.url;
		} else {
			// let file_name = file.name.split('.');
			// let file_tail = `.${file_name[file_name.length - 1]}`;
			if(!previewFile.url_preview) {
				// fileUrlTemp = (await generatePresignedUrlsForPreview([{is_copy: true, file_key: file.temp, file_tail }], file_tail === '.pdf' ? undefined: file_tail))?.data?.payload?.[0]?.url;
				previewFile.url_preview = previewFile.url;
			}
		}
        showPreviewAttachment.fileType.set(previewFile.fileTypePreview);
        showPreviewAttachment.fileName.set(previewFile.name);
        showPreviewAttachment.fileUrl.set(previewFile.url_preview);
		showPreviewAttachment.loading.set(false);
		AttachmentFiles[file.hash].url_preview = previewFile.url_preview;
		AttachmentFiles[file.hash].fileTypePreview = previewFile.url_preview;
		// console.log(file);

		this.setState({AttachmentFiles});
		// console.log(new Excel())
	}

	RenderDialogPreviewAttachment = () => {
		const previewAttach = useHookstate(showPreviewAttachment);
		return (
			<Drawer
				anchor={"right"}
				className="safari_fix_drawer"
				open={previewAttach.open.get({ noproxy: true })}
				onClose={() => {
					previewAttach.open.set(false);
				}}
			>
				<DrivePreviewDialog
					isPreviewAttachment={true}
					handleClose={() => {
						previewAttach.set({
							open: false,
							files: [],
							data: {},
							fileType: "",
							fileUrl: "",
							fileData: null,
							fileName: "",
							loading: false,
							row: {},
						});
					}}
					fileData={previewAttach.fileData.get({ noproxy: true })}
					open={previewAttach.open.get({ noproxy: true })}
					files={previewAttach.files.get({ noproxy: true })}
					fileUrl={previewAttach.fileUrl.get({ noproxy: true })}
					row={previewAttach.row.get({ noproxy: true })}
					fileName={previewAttach.fileName.get({ noproxy: true })}
					fileType={previewAttach.fileType.get({ noproxy: true })}
					loadingFile={previewAttach.loading.get({noproxy: true})}
				/>
			</Drawer>
			);
	}

	Plsdeletefile = () => {
		let { t } = this.props;
		let { docInfo } = this.state;
		if (docInfo.remarks === "protected" && [1, 2, 3].includes(docInfo.function_type_id)) {
			return (
				<div>
					<br />
					<span>
						<span className="label-error" >{t('common:documents.general.protectpdfalert1')}</span>
						<br />
						<span className="label-error" >{t('common:documents.general.protectpdfalert2')}</span>
					</span>
				</div>
			);
		} else {
			return null;
		}
	}

	DocumentDetailBody = () => {
    let { t } = this.props;
		let { docInfo, PDFFiles, previewPdf, isGuest, UpdateInfo, formData, AttachmentFiles, token, view_status } = this.state;
		let hasAttachments = AttachmentFiles && Object.keys(AttachmentFiles).length > 0;
		let {
			SignerList,
			ViewerList,
			HostDocInfo,
			GuestDocInfo,
			SignButton,
			OperationLog,
			Plsdeletefile,
			SignatureInfo,
		} = this;

		if (!docInfo) {
			return <Loading />;
		}
		if (docInfo === false) {
			return <Error />;
		}

		// let PDFFile = PDFFiles[SelectedFile];

		let PDFProps = {
			showAll: false,
			scale: 0.6,
			fit: true,
			files: PDFFiles,
			objMain: this.objMain,
			docInfo: docInfo,
			updateInfo: UpdateInfo,
			filesSort: UpdateInfo ? formData.files_sort : docInfo.files_sort,
			handleChangeFileOrder: (filesSortArray) => this.handleChangeFileOrder(filesSortArray, this),
			isGuest: isGuest,
			token: token,
			onConfirmView: this.onConfirmView,
			view_status: view_status,
			// file: {
			// 	file: PDFFile.url,
			// 	password: PDFFile.password,
			// }
		};
		// console.log('PDFProps', PDFProps)
		// console.log(docInfo);
		// console.log(PDFFile);
		// console.log(PDFFiles);

		if (["signer", "sender", "circle"].includes(docInfo.function_code)) {
			// PDFProps.files = PDFFiles;
			PDFProps.list = true;
		} else if (docInfo.function_code === "verification") {
			// PDFProps.file = PDFFile;
			PDFProps.info = true;
		}

		// let Amount = (docInfo.amount || "0").toString();
		// Amount = Amount.split(".");
		// Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		// Amount = Amount.join(".") + " " + (docInfo.currency || "JPY");

		// console.log(doc_id);
		let { signers, receivers, viewers } = docInfo;
		// console.log(docInfo);
		// return <Error />;

		const pdfs = docInfo?.files_sort ? docInfo?.files_sort?.map((hash) => ({
			hash: hash,
			name: PDFFiles[hash]?.name,
			url: PDFFiles[hash]?.url,
		})): Object.keys(PDFFiles).map((hash) => ({
			hash: hash,
			name: PDFFiles[hash]?.name,
			url: PDFFiles[hash]?.url,
		}));
		const open = Boolean(this.state.anchorElDownloadPDFMobile);
		const handleOpenDownloadOption = (event) => {
			this.setState({
				anchorElDownloadPDFMobile: event.currentTarget,
			});
		};

		const handleCloseMenu = () => {
			this.setState({
				anchorElDownloadPDFMobile: null,
			});
		}

		const handleDownload = () => {
			this.onDownloadRequestHandler();
			handleCloseMenu();
		};

		const handleDownloadAttachments = () => {
			this.onDownloadAttachmentsRequestHandler();
			handleCloseMenu();
		}

		const handlePrint = () => {
			this.onPrintRequestHandler();
			handleCloseMenu();
		};

		const handlePrintMobile = () => {
			this.onPrintRequestHandlerMobile();
			handleCloseMenu();
		};
		let RenderDialogPreviewAttachment = this.RenderDialogPreviewAttachment;
		return (

			<div className="document-detail-container form-wrapper responsiveCSS">
				<Form
					id={"detail"}
					onSubmit={(ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) }}
					noValidate
					className="document-detail-form"
				>

					<div className="form-body flex-column">

						<Form.Row>
							<Container fluid="md" className={"d-block d-sm-none order-first text-center mb-4"}>
								<Form.Row>
									<Form.Group as={Col} className={'mx-4'}>
										<Box>
											{pdfs.length === 1 && <Button
												variant="outlined"
												className="btn-action btn-block"
												size="large"
												startIcon={<DescriptionIcon />}
												onClick={() => this.onPreviewPdfHandler(pdfs[0].hash)}>
												{"PDFを見る"}
											</Button>}
											{pdfs.length > 1 &&
												<Select
													className="btn-select-action btn-action btn-block"
													value={previewPdf || '-1'}
													onChange={(ev) => this.onPreviewPdfHandler(ev.target.value)}
													// style={{ fontSize: '12px', color: "#007BFF" }}
													variant={"standard"}
												>
                          <MenuItem value="-1" key={''} style={{ fontSize: '12px' }} className="d-none"><span className="btn-select-icon"><DescriptionIcon fontSize="small" /></span>{'PDFを見る'}</MenuItem>
													{pdfs.map((pdf, index) => (pdf?.name ? (
														<MenuItem value={`${pdf.hash}`} key={index} style={{ fontSize: '12px' }}>{pdf.name}</MenuItem>
													):null))}
												</Select>}
										</Box>
									</Form.Group>
								</Form.Row>
							</Container>

							<Container fluid="md" className={"container-fluid doc-info col-11 col-sm-5 order-3 order-sm-2"} >
								{/* <Form.Group xs={5} as={Col} className="signer-list-column"> */}

								<Form.Row className="signer-list-wrapper">

									<SignerList users={signers} filter={{ "user_type": [0, 2] }} showStatus={true} label="documents.host-setting.signers-list" />

									{!["sender", "verification"].includes(docInfo.function_code) && <SignerList users={signers} filter={{ "user_type": 1 }} showStatus={true} label="documents.guest-setting.guests-list" />}

									<SignerList users={receivers} isReceiver={true} label={docInfo.function_code == 'sender' ? 'documents.guest-setting.sender-guests-list': 'documents.guest-setting.guests-list'} />

									{!isGuest ? <ViewerList users={viewers} label="documents.viewer-setting.viewers-list" /> : null}

									<SignatureInfo loadingSignatureInfo={this.props?.loadingSignatureInfo} label="documents.signature-info.header"/>

								</Form.Row>

								<Form.Row className='d-sm-block'>
									<Form.Group xl={12} md={12} as={Col} >
									</Form.Group>
								</Form.Row >
								{/* </Form.Group> */}
							</Container>

							<Container fluid="md" className={UpdateInfo ? "container-fluid doc-info col-11 col-sm-7 order-2 order-sm-3 is-edit" : "container-fluid doc-info col-11 col-sm-7 order-2 order-sm-3 "} >
								{
									!isGuest ?
										<HostDocInfo /> :
										<GuestDocInfo />
								}


								<Form.Row className="d-sm-block">
									<Form.Group xl={12} md={12} as={Col} className="">
										<Plsdeletefile />
										<div className="page-action-bottom-relative">
											<SignButton className="btn-form-control" />
										</div>
									</Form.Group>
								</Form.Row >

							</Container>

							<Container fluid="md" className="d-block d-sm-none order-last text-center">
								<Form.Row>
									<Form.Group xl={12} md={12} as={Col} className="mx-4">
										<div className="page-action-bottom-relative">
											<SignButton className="btn-form-control btn-block" />
										</div>
									</Form.Group>
									<Form.Group xl={12} md={12} as={Col} className="mx-4">
										<Box key="download">
											<Button
												variant="outlined"
												className="btn-action btn-block"
												size="large"
												startIcon={this.state.downloadLoading
													? <CircularProgress size={20} style={{color: "#1976d2"}} />
													: <CloudDownloadOutlinedIcon />}
												title={t("common:document.download-pdf")}
												onClick={(event) => {
													this.setState({
														anchorElDownloadPDFMobile: event.currentTarget,
													});
												}}>
												{t("common:document.download-pdf")}
											</Button>
										</Box>
										<Menu
                      id="lock-menu-mobile"
                      anchorEl={this.state.anchorElDownloadPDFMobile}
                      open={open}
                      onClose={() => {
                        this.setState({
                          anchorElDownloadPDFMobile: null,
                        });
                      }}
                      MenuListProps={{
                        "aria-labelledby": "lock-button",
                        role: "listbox",
                      }}
                    >
                      {[
                        {
                          onClick: handleDownload,
                          icon: (
                            <DownloadIcon
                              fontSize="medium"
                              style={{ color: "#1976d2" }}
                            />
                          ),
                          label: t("common:document.conventional-download"),
                        },
                        hasAttachments
                          ? {
                              onClick: handleDownloadAttachments,
                              icon: (
                                <AttachFileOutlined
                                  fontSize="medium"
                                  style={{ color: "#1976d2" }}
                                />
                              ),
                              label: t("common:document.attachments-download"),
                            }
                          : null,
                        {
                          onClick: handlePrintMobile,
                          icon: (
                            <Print
                              fontSize="medium"
                              style={{ color: "#1976d2" }}
                            />
                          ),
                          label: t("common:document.print"),
                        },
                      ].reduce((arr, item, index) => {
						if (item){
							arr.push(  <MenuItem onClick={item.onClick} key={index}>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText style={{ color: "#1976d2" }}>
								  {item.label}
								</ListItemText>
							  </MenuItem>)
						}
						return arr;
					  }, [])}
                    </Menu>

									</Form.Group>
								</Form.Row >
							</Container>
						</Form.Row >


					</div>

					<div className="form-foot">
					</div>

				</Form>

				<div className="file-panel preview">

					<div className={"file-preview-zone"} style={hasAttachments ? {height: '700px'}: {}}>

						<PDFViewer {...PDFProps} key={this.state.UpdateInfo} ref={this.pdfViewerRef} />

					</div>
					<OperationLog />
					{hasAttachments ? <div className="attachment-wrapper">
						<RenderDialogPreviewAttachment />
						<div  className='attachment-upload-preview'>
							<div className='attachment-upload-box'>
								<img src={ClipIcon} alt="" />
								<span>{t('common:document.upload-attachment')}</span>
							</div>
						</div>
						<div className="file-attachment-list-preview">
						{
							hasAttachments ? Object.keys(AttachmentFiles).map((key, i) => {
								return (
									<div key={i} className='attachment-item-preview' data-file={key}>
										<span
											className="file-name"
											onClick={()=>{
												this.previewAttachment(AttachmentFiles[key]);
											}}
											title={AttachmentFiles[key]?.name}
										>
											{AttachmentFiles[key]?.name}
										</span>
									</div>
								)
							}): <></>
						}
						</div>
					</div> : <></>}

					{/* <div className="file-upload-list">
						<ul>
							<FileList />
						</ul>
					</div> */}

				</div>

			</div>
		);
	}

	OperationLog = () => {
		let { operationLogFlg } = this.state;
		let { DisplayOperationLog } = this;

		let active = "";

		if (operationLogFlg) {
			active = " active";
		} else {
			active = " inactive";
		}

		// console.log("PageRender", Pages);
		return (
			<div className={`accesslog ${active}`} >
				<DisplayOperationLog />
			</div>
		);
	}

	DisplayOperationLog = () => {

		let { t } = this.props;
		let { docInfo } = this.state;
		// console.log("docInfo");
		/* */
		if (!docInfo.creator_email) {
			return (
				t("common:documents.document-info.no-operation-log")
			);
		}

		/* Organization Tree-Structure */
		let logList = docInfo?.operation_log?.sort((a,b)=> (a.create_datetime < b.create_datetime) ? -1 : ((a.create_datetime > b.create_datetime) ? 1 : 0)) || [];
		let list = [];
		/* Convert Array to Elements */
		for (let i = 0; i < logList.length; i++) {

			let logData = logList[i];
			let unique = logData.document_id + "-" + i;

			// console.log('1111', logData.create_datetime);
			if (logData.create_datetime.includes(' ')) {
				logData.create_datetime = logData.create_datetime.replace(' ', 'T') + 'Z'
			}
			// console.log('2222', logData.create_datetime);

			// let update_date_format = "YYYY/MM/DD HH:Mi:SS";
			// Update date
			let date = formatDateIntlApi(new Date(logData.create_datetime), {
				timeZone: "UTC",
				month: "2-digit",
				day: "2-digit",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hourCycle: "h23",
			}) ;
			// update_date_format = update_date_format.replace(/YYYY/, date.getFullYear());
			// update_date_format = update_date_format.replace(/MM/, ("00" + (date.getMonth() + 1)).slice(-2));
			// update_date_format = update_date_format.replace(/DD/, ("00" + date.getDate()).slice(-2));
			// update_date_format = update_date_format.replace(/HH/, ("00" + date.getHours()).slice(-2));
			// update_date_format = update_date_format.replace(/Mi/, ("00" + date.getMinutes()).slice(-2));
			// update_date_format = update_date_format.replace(/SS/, ("00" + date.getSeconds()).slice(-2));

			let update_date_block = (
				<div className="operation-log-update-date">
					<span>{t(`common:documents.document-info.opelog.update-date`, { update_date: `${date.year}/${date.month}/${date.day} ${date.hour}:${date.minute}:${date.second}` })}</span>
				</div>
			);

			// Update user
			let update_user_block = (
				<div className="operation-log-update-user">
					<span>{t(`common:documents.document-info.opelog.update-user`, { update_user: logData.email })}</span>
				</div>
			);

			// Document type
			let document_type_id_block = this.OperationLogRequiredContents(
				logData.before_document_type_name,
				logData.after_document_type_name,
				t(`common:documents.document-info.document-type`),
			);

			// title
			let title_block = this.OperationLogNormalContents(
				logData.before_title,
				logData.after_title,
				t(`common:documents.document-info.doc-title`),
			);

			// amount
			let amount_block = this.OperationLogNormalContents(
				logData.before_amount,
				logData.after_amount,
				t(`common:documents.document-info.amount`),
				'amount',
			);

			// currency
			let currency_block = this.OperationLogRequiredContents(
				logData.before_currency,
				logData.after_currency,
				t(`common:documents.document-info.currency`),
			);

			// contract_date
			let contract_date_block = this.OperationLogNormalContents(
				logData.before_contract_date,
				logData.after_contract_date,
				t(`common:documents.document-info.contract-date`),
				'date',
			);

			// expiry_date
			let expiry_date_block = this.OperationLogNormalContents(
				logData.before_expiry_date,
				logData.after_expiry_date,
				t(`common:documents.document-info.expiry-date`),
				'date',
			);

			// effective_date
			let effective_date_block = this.OperationLogNormalContents(
				logData.before_effective_date,
				logData.after_effective_date,
				t(`common:documents.document-info.effective-date`),
				'date',
			);

			// sign_finish_date
			let sign_finish_date_block = this.OperationLogNormalContents(
				logData.before_sign_finish_date,
				logData.after_sign_finish_date,
				t(`common:documents.document-info.sign-finish-date`),
				'date',
			);

			// document_create_date
			let document_create_date_block = this.OperationLogNormalContents(
				logData.before_document_create_date,
				logData.after_document_create_date,
				t(`common:documents.document-info.document-create-date`),
				'date',
			);

			// content
			let content_block = this.OperationLogRequiredContents(
				logData.before_content,
				logData.after_content,
				t(`common:documents.document-info.content`)
			);

			// invoice_number
			let invoice_number_block = this.OperationLogNormalContents(
				logData.before_invoice_number,
				logData.after_invoice_number,
				t(`common:documents.document-info.invoice-number`)
			);

			// product_name
			let product_name_block = this.OperationLogNormalContents(
				logData.before_product_name,
				logData.after_product_name,
				t(`common:documents.document-info.product`)
			);

			// reference_document
			let reference_document_block = this.OperationLogNormalContents(
				logData.before_reference_document,
				logData.after_reference_document,
				t(`common:documents.document-info.reference`)
			);

			// meta_attributes
			let meta_attributes_block = this.OperationLogMapContents(
				logData.before_meta_attributes,
				logData.after_meta_attributes,
				t(`common:documents.document-info.attributes`)
			);

			// files
			let files_block = this.OperationLogFileContents(
				logData.before_files,
				logData.after_files,
				t(`common:documents.document-info.file-name`)
			);

			// attachments
			let attachments_block = this.OperationLogNormalContents(
				logData.before_attachments,
				logData.after_attachments,
				t(`common:documents.document-info.attachments`)
			);

			// filebox_path
			let filebox_path_block = this.OperationLogNormalContents(
				logData.before_filebox_path,
				logData.after_filebox_path,
				t(`common:documents.document-info.filebox-path-select-button`)
			);

			// shared_folder_path
			let shared_folder_path_block = this.OperationLogNormalContents(
				logData.before_shared_folders_path,
				logData.after_shared_folders_path,
				t(`common:documents.document-info.shared_folders_path-select-button`)
			);

			// status_id
			let status_id_block = this.OperationLogTranslateContents(
				logData.before_status_name,
				logData.after_status_name,
				t(`common:documents.document-info.status`)
			);

			// remarks
			let remarks_block = this.OperationLogNormalContents(
				logData.before_remarks,
				logData.after_remarks,
				t(`common:documents.document-info.remarks`)
			);

			// auto_sign_location
			let auto_sign_location_block = this.OperationLogNormalContents(
				logData.before_auto_sign_location,
				logData.after_auto_sign_location,
				t(`common:documents.document-info.auto-sign-location`)
			);

			// use_digital_cert
			let use_digital_cert_block = this.OperationLogDigitalCertContents(
				logData.before_use_digital_cert,
				logData.after_use_digital_cert,
				t(`common:documents.document-info.digital-certificate`)
			);

			// host_cert_type
			let host_cert_type_block = this.OperationLogNormalContents(
				logData.before_host_cert_name,
				logData.after_host_cert_name,
				t(`common:documents.document-info.certificate-type`)
			);

			// received_date
			let received_date_block = this.OperationLogNormalContents(
				logData.before_received_date,
				logData.after_received_date,
				// t(`common:documents.document-info.received-date`)
				t(`common:documents.document-info.deal-date`),
				'date',
			);

			// counter_party_name
			let counter_party_name_block = this.OperationLogNormalContents(
				logData.before_counter_party_name,
				logData.after_counter_party_name,
				t(`common:documents.document-info.counter-party-name`)
			);

			// receivers
			let receivers_block = this.OperationLogNormalContents(
				logData.before_receivers,
				logData.after_receivers,
				t(`common:documents.document-info.receivers`)
			);

			// sign_level
			let sign_level_block = this.OperationLogTranslateContents(
				logData.before_sign_level,
				logData.after_sign_level,
				t(`common:documents.document-info.sign-level`)
			);

			// Preservation require
			let preservation_require_block = this.OperationLogTranslateContents(
				logData.before_preservation_require,
				logData.after_preservation_require,
				t(`common:documents.document-info.document-type`),
			);

			// signers
			let signers_block = this.OperationLogArrayContents(
				logData.before_signers,
				logData.after_signers,
				t(`common:documents.host-setting.signers`)
			);

			// signer_info
			// let signer_info_block = this.OperationLogNormalContents(
			// 	logData.before_signer_info,
			// 	logData.after_signer_info,
			// 	t(`common:documents.document-info.signer-info`)
			// );

			// viewers
			let viewers_block = this.OperationLogArrayContents(
				logData.before_viewers,
				logData.after_viewers,
				t(`common:documents.viewer-setting.viewers-list`)
			);

			// forward
			/* Just work around, need to translate later */
			let forward_user_block = null;
			if (logData?.before_forward && logData?.before_forward) {
				let forwardFrom = logData?.before_forward?.email || logData?.before_forward?.full_name;
				let forwardTo = logData?.after_forward?.email || logData?.after_forward?.full_name;
				logData.email = forwardFrom;
				forward_user_block = (
					<div className="operation-log-content">
						<span>{`転送: 「${forwardFrom}」が「${forwardTo}」へ転送しました`}</span>
					</div>
				);
			}


			// sign_user
			let operationfirst = 'document.sign';
			if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4) {
				operationfirst = 'document.signprotectpdffirst';
			}
			let sign_user_block = this.OperationLogFreeContents(
				logData.sign_user_email,
				t(`common:${operationfirst}`),
				'documents.document-info.opelog.operation-exec',
				t(`common:${operationfirst}`)
			);

			// timestamp_user
			let timestamp_user_block = this.OperationLogFreeContents(
				logData.timestamp_user_email,
				t(`common:documents.evaluate-timestamp`),
				'documents.document-info.opelog.operation-exec',
				t(`common:documents.evaluate-timestamp`)
			);

			// return_user
			let return_user_block = this.OperationLogFreeContents(
				logData.return_user_email,
				t(`common:document.remand`),
				'documents.document-info.opelog.operation-exec',
				t(`common:document.remand`)
			);

			// pdf_download_user
			let pdf_download_user_block = this.OperationLogFreeContents(
				logData.pdf_download_user_email,
				t(`common:document.download`),
				'documents.document-info.opelog.operation-exec',
				t(`common:document.download`)
			);

			// active_flg
			let active_flg_block = null;
			if([0,1, "0","1"].includes(logData.before_active_flg) || [0,1, "0","1"].includes(logData.after_active_flg)){
			 	active_flg_block = this.OperationLogRequiredContents(
					logData.before_active_flg == 1 ? t(`common:documents.document.active`) : t(`common:documents.document.inactive`),
					logData.after_active_flg == 1 ? t(`common:documents.document.active`) : t(`common:documents.document.inactive`),
					t(`common:documents.document-info.active_flg`),
				);
			}

			let viewing_block = this.OperationLogFreeContents(
				logData.file_viewed,
				t(`common:documents.document-info.opelog.document-view`),
				`documents.document-info.opelog.view-confirmation`,
				logData.email,
			);
			let content = ''
			switch (logData.revision_status) {
				case 1:
					// recreate
					content = t(`common:documents.document-info.opelog.document-revision-recreated`, {content: logData.email});
					break;
				case 2:
					//resent
					content = t(`common:documents.document-info.opelog.document-revision-resent`, {content: logData.email});
					break;
				case 3:
					//canceled
					content = t(`common:documents.document-info.opelog.document-revision-canceled`);
					break;
				case 4:
					//return
					content = t(`common:documents.document-info.opelog.document-revision-return`, {content: logData.email});
					break
			}
			// let revision_block = logData.revision_status ? this.OperationLogNormalContents(
			// 	logData.doc_status,
			// 	t(`common:documents.document-info.opelog.document-revision-${convertRevisionStatus(logData.revision_status)}`),
			// 	t(`common:documents.document-info.opelog.document-revision`)
			// ) : <></>;
			let revision_block = logData.revision_status ?
			<div className="operation-log-content">
					<span>
						{content}
					</span>
			</div> : <></>

			let admin_edit_draft_block = logData.admin_edit_draft ?
				this.OperationLogFreeContents(
					logData.email,
					t(`common:documents.document-info.opelog.admin`),
					'documents.document-info.opelog.admin-edit-draft',
					t(`common:documents.document-info.opelog.admin`)
				)
			: <></>;

			/* */
			list.push(
				<div id={unique} className="operation-log-top" key={unique}>
					<div className="">
						<div className="lz-flex-1">
							{update_date_block}
							{update_user_block}
							{document_type_id_block}
							{title_block}
							{amount_block}
							{currency_block}
							{contract_date_block}
							{expiry_date_block}
							{effective_date_block}
							{sign_finish_date_block}
							{document_create_date_block}
							{content_block}
							{invoice_number_block}
							{product_name_block}
							{reference_document_block}
							{meta_attributes_block}
							{files_block}
							{attachments_block}
							{filebox_path_block}
							{shared_folder_path_block}
							{status_id_block}
							{remarks_block}
							{auto_sign_location_block}
							{use_digital_cert_block}
							{host_cert_type_block}
							{received_date_block}
							{counter_party_name_block}
							{receivers_block}
							{sign_level_block}
							{preservation_require_block}
							{signers_block}
							{/* {signer_info_block} */}
							{viewers_block}
							{forward_user_block}
							{sign_user_block}
							{timestamp_user_block}
							{return_user_block}
							{pdf_download_user_block}
							{active_flg_block}
							{viewing_block}
							{revision_block}
							{admin_edit_draft_block}
						</div>
					</div>
				</div>
			);



		}

		// creator_block
		// console.log('docInfo', docInfo)
		let issuedDate = formatDateIntlApi(docInfo?.create_datetime || new Date().toISOString(), {
			timeZone: "UTC",
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hourCycle: "h23",
		});

		let update_date_block = (
			<div className="operation-log-update-date">
				<span>{t(`common:documents.document-info.opelog.create-date`, { create_datetime: `${issuedDate.year}/${issuedDate.month}/${issuedDate.day} ${issuedDate.hour}:${issuedDate.minute}:${issuedDate.second}` })}</span>
			</div>
		);
		let creator_block = this.OperationLogFreeContents(
			docInfo.creator_email,
			t(`common:document.document-info.opelog.creator`),
			'documents.document-info.opelog.operation-creator-exec',
		);

		/* */
		return (
			<div id="top" className="">
				<div className="access-log-head">
					<div className="lz-flex-1">
						<div className="lz-m-0">
							<span>{t("common:document.history")}</span>
						</div>
					</div>
				</div>
				<div className="">
					<div className="operation-log-top">
						<div className="">
							<div className="lz-flex-1">
								{update_date_block}
								{creator_block}
							</div>
						</div>
					</div>
					{list}
				</div>
			</div>
		);
	}

	OperationLogFreeContents = (logData, contentsName, message, operationName) => {
		let { t } = this.props;

		if (logData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:${message}`, { content: logData, operation: operationName })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogRequiredContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		if (beforeLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content:beforeLogData,
							after_content: afterLogData})}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogNormalContents = (beforeLogData, afterLogData, contentsName, contentsType) => {
		let { t } = this.props;

		let beforeData = beforeLogData;
		let afterData = afterLogData;

		if (contentsType) {
			switch (contentsType) {
				case 'amount':
					beforeData = beforeLogData ? this.numFormat(beforeLogData) : beforeLogData;
					afterData = afterLogData ? this.numFormat(afterLogData) : afterLogData;
					break;
				case 'date':
					beforeData = beforeLogData ? Moment(beforeLogData).format('YYYY-MM-DD') : beforeLogData;
					afterData = afterLogData ? Moment(afterLogData).format('YYYY-MM-DD') : afterLogData;
					break;
			}
		}

		if (beforeData && afterData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content: beforeData, after_content: afterData })}
					</span>
				</div>
			);
		} else if (beforeData && !afterData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before`, { before_content: beforeData })}
					</span>
				</div>
			);
		} else if (!beforeData && afterData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-after`, { after_content: afterData })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogTranslateContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		if (beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content: t(`common:${beforeLogData}`), after_content: t(`common:${afterLogData}`) })}
					</span>
				</div>
			);
		} else if (beforeLogData && !afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before`, { before_content: t(`common:${beforeLogData}`) })}
					</span>
				</div>
			);
		} else if (!beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-after`, { after_content: t(`common:${afterLogData}`) })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogMapContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			let beforeEntries = Object.entries(beforeLogData);
			beforeEntries.forEach(function (v) {
				temp.push(v.join(':'));
			});
			beforeStrData = temp.join(',');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			let afterEntries = Object.entries(afterLogData);
			afterEntries.forEach(function (v) {
				temp.push(v.join(':'));
			});
			afterStrData = temp.join(',');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	OperationLogArrayContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			beforeLogData.forEach(function (v, i) {
				temp.push(v.email);
			});
			beforeStrData = temp.join(', ');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			afterLogData.forEach(function (v, i) {
				temp.push(v.email);
			});
			afterStrData = temp.join(', ');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	OperationLogDigitalCertContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		let use_digital_cert_active = t(`common:documents.document-info.use-digital-cert-active`);
		let use_digital_cert_inactive = t(`common:documents.document-info.use-digital-cert-inactive`);
		let beforeUseDigitalCert = null;
		let afterUseDigitalCert = null;

		if (beforeLogData === 0) {
			beforeUseDigitalCert = use_digital_cert_inactive;
		} else if (beforeLogData === 1) {
			beforeUseDigitalCert = use_digital_cert_active;
		}

		if (afterLogData === 0) {
			afterUseDigitalCert = use_digital_cert_inactive;
		} else if (afterLogData === 1) {
			afterUseDigitalCert = use_digital_cert_active;
		}

		return this.OperationLogNormalContents(beforeUseDigitalCert, afterUseDigitalCert, contentsName);
	}

	OperationLogFileContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			for (let key in beforeLogData) {
				temp.push(beforeLogData[key].name);
			}
			beforeStrData = temp.join(',');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			for (let key in afterLogData) {
				temp.push(afterLogData[key].name);
			}
			afterStrData = temp.join(',');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	/** [Element] */
	AccessLogTree = () => {

		let { t } = this.props;
		let { docInfo, token } = this.state;

		/* */
		if (docInfo.access_log == null) {
			return null;
		}

		if (token) {
			return null;
		}

		/* Organization Tree-Structure */
		let tree = docInfo?.access_log?.sort((a,b)=> (a.access_date < b.access_date) ? -1 : ((a.access_date > b.access_date) ? 1 : 0)) || [];
		let list = [];
		/* Convert Array to Elements */
		for (let i = 0; i < tree.length; i++) {

			/* */
			let item = this.AccessLogTreeItem(tree[i], i);

			/* */
			list.push(item);

		}

		/* */
		let toggle = (event, id) => {

			/* */
			let target = document.getElementById(id);
			let body = target.querySelector(".access-log-body");
			/* */
			if (body.classList.contains("access-log-collapse")) {
				/* */

				/* */
				body.classList.remove("access-log-collapse");

			} else {
				/* */
				/* */
				body.classList.add("access-log-collapse");

			}
		};

		/* */
		return (
			<div id="top" className="access-log">
				<div className="access-log-head">

					<Button
						onClick={(e) => { toggle(e, "top") }}
						variant="outlined"
						size="small"
					>
						{t("common:documents.document-info.access-log")}
					</Button>

				</div>
				<div className="access-log-body access-log-collapse">
					{list}
				</div>
			</div>
		);
	}

	/** [Element] */
	AccessLogTreeItem = (accessLog, index) => {

		/* */
		// let children = [];
		let unique = "access-log-{0}";

		/* */
		let accessDateObject = new Date(accessLog.access_date);
		const dateOptions = { timeZone: 'UTC', month: '2-digit',
		day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
		second:'2-digit', hourCycle: 'h23', };

		const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
		const p = dateFormatter.formatToParts(accessDateObject).reduce((acc, part) => {
			acc[part.type] = part.value;
			return acc;
		}, {});
		let accessDate = `${p.year}-${p.month}-${p.day} ${p.hour == 24 ? '00': p.hour }:${p.minute}:${p.second}`
		// console.log(accessDate2)
		// // accessDateObject.setHours(accessDateObject.getHours() + 3)
		// // let accessDateObject

		// let year = accessDateObject.getFullYear();
		// let month = ("00" + (accessDateObject.getMonth() + 1)).slice(-2);
		// let day = ("00" + accessDateObject.getDate()).slice(-2);
		// let hour = ("00" + accessDateObject.getHours()).slice(-2);
		// let minute = ("00" + accessDateObject.getMinutes()).slice(-2);
		// let second = ("00" + accessDateObject.getSeconds()).slice(-2);
		// let accessDate = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
		unique = unique.replace("{0}", accessLog.access_date.replace(" ", ""));

		/* */
		return (
			<div id={unique} className="org" key={`${index} -${unique}`}>
				<div className="access-log-head">
					<div className="lz-flex-1">
						<div className="lz-m-0-10">
							<span>{`${accessDate}: ${accessLog.email} (${accessLog.user_type_name})`}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	ReturnButton = () => {
		let { action, docInfo } = this.state;
		// console.log(action);
		if (action) {
			let ActionButton = action;
			return <ActionButton docInfo={docInfo} />;
		} else {
			return null;
		}
	}

	CertificateApplyForm = () => {
		let { t } = this.props;
		let { docInfo, appCert, certApply, token } = this.state;

		if (!certApply) {
			return null;
		}

		let fromProps = {
			as: "modal",
			data: null,
			doc_type: null,
			show: certApply,
			requiredFile: true,
			token: token,
			onClose: this.onCertificateApplyFormClose,
		};

		if (docInfo && appCert && certApply) {
			let signerInfo = docInfo.signer_info;
			let curDate = new Date();
			curDate = curDate.toISOString();

			let certData = {
				"user_id": signerInfo.user_id,
				"company_name": signerInfo.company_name,
				"name": signerInfo.name,
				"email": signerInfo.email,
				"company_id": docInfo.company_id,
				"tenant_id": docInfo.tenant_id,
				"application_date": curDate.substr(0, 10),
				"status": 0,
				"identity_verification_documents": 0,
				"credentials": "",
				"status_name": "",
				"file_name": "",
				"application_certificate_type": signerInfo.certificate_type_id,
				"operation": "insert_data",
				"document_id": docInfo.document_id,
			};
			// console.log(appCert)
			for (let cert of appCert.cert_type) {
				if (cert.id == signerInfo.certificate_type_id) {
					certData.application_certificate_label = t(`common:certificate.type.${cert.label}`);
					break;
				}
			}

			if (appCert.records.length > 0) {
				let certRequestInfo = appCert.records[0];
				for (let i in certRequestInfo) {
					certData[i] = certRequestInfo[i];
				}
				certData.operation = "update_data";
				certData.status_name = t(`common:${certData.status_name}`);
			}

			// console.log(certData);
			fromProps.doc_type = appCert.identity_doc_type;
			fromProps.data = certData;
			// fromProps.show = true;

		}
		// console.log(fromProps);
		return (
			<FormDetail {...fromProps} />
		);
	}

	async handleConfirmOtpDialog(isDocumentVerifyEmail) {
		// const user = await AuthService.GetCurrentLogin();
		if (!isDocumentVerifyEmail) {
			return this.setState({
				openOtpDialog: false,
				isVerifySoftware: false,
				isVerified: true
			});
		}

		this.setState({
			openOtpDialog: false,
			isVerifyEmail: false,
			isVerified: true
		});

		if (this.state.signingTypeFunc === 'GuestSignConfirmHandler') {
			this.GuestSignConfirmHandler();
		} else {
			this.SignConfirmHandler();
		}

	}


	async getCurrentUserAttributes(type) {
		// console.log('day la get curent', this, type);
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		// console.log(this.state.signingTypeFunc,(attributes['custom:mfa_type'] !== 'disable' && (attributes['custom:mfa_email_auth'].includes('document') && attributes['custom:mfa_type'] === 'email'
		// || (attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software'))));
		if (attributes['custom:mfa_type'] !== 'disable' &&
			((attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') ||
				(attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software'))) {
			this.UpdateModalDialog({
				props: {
					"show": false,
				},
			});
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {
				this.generateOtpDocument();
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
			switch (type) {
				case 'GuestSignConfirmHandler':
					this.setState({ signingTypeFunc: 'GuestSignConfirmHandler' });
					// this.GuestSignConfirmHandler();
					break;
				default:
					let { docInfo } = this.state;
					// this.goBackAfterSign(docInfo);
					this.retryDocumentSigning(docInfo, this.SignConfirmHandler);
					break;
			}
		} else {
			let { docInfo } = this.state;
			switch (type) {
				case 'GuestSignConfirmHandler':
					this.setState({ signingTypeFunc: 'GuestSignConfirmHandler' });
					this.GuestSignConfirmHandler();
					break;
				default:
					this.setState({ signingTypeFunc: 'SignConfirmHandler' });
					// this.goBackAfterSign(docInfo);
					this.retryDocumentSigning(docInfo, () => this.SignConfirmHandler());
					// this.SignConfirmHandler();
					break;
			}
		}
	}

	async generateOtpDocument() {
		const uri = API.url + "/mfa-otp/generate.json";
		const tokenID = await AuthService.GetTokenID();
		await axios.post(uri, {}, {
			headers: {
				Authorization: tokenID,
			},
		}).catch((e) => {
			console.log(e);
			// reject(error);
		});
	};

	async getCurrentUserAttributesGuest() {
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		if (attributes['custom:mfa_type'] !== 'disable') {
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {

				this.generateOtpDocument();
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
		}
	}

	async handleConfirmOtpGuest(isDocumentVerifyEmail) {
		// const user = await AuthService.GetCurrentLogin();
		if (!isDocumentVerifyEmail) {
			return this.setState({
				openOtpDialog: false,
				isVerifySoftware: false,
				isVerified: true
			});
		}

		this.setState({
			openOtpDialog: false,
			isVerifyEmail: false,
			isVerified: true
		});
	}

	DocumentPrintAlert() {
		let { t } = this.props;
		let {printAlertVisibility, printAlertResult} = this.state;
		return (
			<Snackbar
				open={printAlertVisibility}
				anchorOrigin={{vertical: "top", horizontal: "right"}}
				autoHideDuration={5000}
				onClose={() => {
					this.setState({
						printAlertVisibility: false
					});
				}}
			>
				<Alert
					onClose={() => {
						this.setState({
							printAlertVisibility: false
						});
					}}
					severity={printAlertResult ? "success" : "error"}
				>
					{printAlertResult ? t("common:document.document-for-printing-succeed") : t("common:document.document-for-printing-fail")}
				</Alert>
			</Snackbar>
		)
	}

	imprintControllerHandleMouseDown = (ev) => {
		this.setState({ isDraggingViewStatus: false });
		ev.target.style.cursor = 'grabbing';
		// console.log(ev.target);
		let targetBlock = ev.target;
		let shiftX = ev.clientX - ev.target.getBoundingClientRect().left;
		let shiftY = ev.clientY - ev.target.getBoundingClientRect().top;

		const handleMouseMove = (event) => {
			this.setState({ isDraggingViewStatus: true });
			document.body.style.userSelect = "none";
			let containerBlock = targetBlock.parentNode;
			let marginLeft = event.pageX - shiftX - containerBlock.getBoundingClientRect().left;
			let marginTop = event.pageY - shiftY - containerBlock.getBoundingClientRect().top;
			let parentWidth = containerBlock.offsetWidth;
			let parentHeight = containerBlock.offsetHeight;

			if (marginLeft < 0) {
				marginLeft = 0;
			} else if (marginLeft > parentWidth - targetBlock.offsetWidth) {
				marginLeft = parentWidth - targetBlock.offsetWidth;
			}
			if (marginTop < 0) {
				marginTop = 0;
			} else if (marginTop > parentHeight - targetBlock.offsetHeight) {
				marginTop = parentHeight - targetBlock.offsetHeight;
			}

			targetBlock.style.left = marginLeft + 'px';
			targetBlock.style.top = marginTop + 'px';
		}

		// handleMouseMove(ev);

		let moveHandlerTimeout = setTimeout(() => {
			// (2) move the ball on mousemove
			document.onmousemove = handleMouseMove;
		}, 100);

		// (3) drop the ball, remove unneeded handlers
		document.onmouseup = function (event) {
			clearTimeout(moveHandlerTimeout);
			document.onmousemove = null;
			document.onmouseup = null;
			document.body.style.userSelect = null;
		};
	};

	handleDragStart = (ev) => {
		return false;
	}

	handleMouseUp = (e) => {
		e.target.style.cursor = 'pointer'; // Reset cursor on mouse up
	};

	handleClick = (e) => {
		if (!this.state.isDraggingViewStatus) {
			this.pdfViewerRef.current?.updateShowFullPreview(true);
		}
	};

	onConfirmView = (newViewStatus) => {
		this.setState({ view_status: newViewStatus });
	}

	DocumentViewStatus = () => {
		let { t } = this.props;
		let { docInfo, isGuest, view_status } = this.state;
		const userInfoGet = userInfoState.get({noproxy: true});

		if (!docInfo?.document_view_required || docInfo?.isGuestCollaboration === false || (docInfo?.isGuestCollaboration && docInfo?.signed === 1)) {
			return null
		}
		console.log('isCreator', docInfo?.create_user == userInfoGet?.user_id)

		if(docInfo?.create_user == userInfoGet?.user_id){
			return null
		}
		const files = docInfo?.files || {};
		const filesCount = Object.keys(files).length;

		const viewedCount = Object.values(view_status).filter(value => value?.status == 1).length;

		// create a variable to compare viewedCount and total filesCount
		const isAllFilesViewed = viewedCount === filesCount;

		const viewedStatusClass = isAllFilesViewed ? 'viewed' : 'not-viewed';

		return (
			<div className="document-view-status"
				onMouseDown={this.imprintControllerHandleMouseDown}
				onDragStart={this.handleDragStart}
				onMouseUp={this.handleMouseUp}
				onClick={this.handleClick}
			>
				<img src={isAllFilesViewed ? PlaceTickIcon : PlaceIcon} alt="" className='checker-icon'/>
				<div className="viewed-number-status">
					<div className={`viewed-number ${viewedStatusClass}`}>{viewedCount}</div>/{filesCount}
				</div>

				<div className={`callout ${isAllFilesViewed ? 'callout-confirmed' : ''}`}>
					{!isAllFilesViewed ? (
						<>
							<p className="callout-title">
								{t("common:documents.general.view-callout")}
							</p>
							<p className="view-count">
								{t("common:documents.general.view-count", {count: filesCount})}
							</p>
						</>
					) : (
						<p className="callout-title">
							{t("common:documents.general.confirmed")}
						</p>
					)}

				</div>

			</div>
		)
	}

	render() {
		let { docInfo, PDFFiles, previewPdf, openOtpDialog, isVerifySoftware, isVerifyEmail, AttachmentFiles, isGuest, view_status } = this.state;
		let { LogoImage, DocumentDetailBody, GeneralModal, FormModal, ActionBar, ReturnButton, CertificateApplyForm, DocumentViewStatus } = this;
		let hasAttachments = AttachmentFiles && Object.keys(AttachmentFiles).length > 0;
		// console.log(docInfo);

		let DocTitle = null;
		if (docInfo) {
			DocTitle = `${docInfo.id}: ${docInfo.title}`;
		}

		const PDFFile = PDFFiles[previewPdf]
		if (PDFFile && PDFFile.url) {
			PDFFile.file = PDFFile.url;
		}

		return (
			<div className="document-detail-wrapper mb-0">
				<GetStatusInterval
					onGetStatus={this.handleGetDocumentStatus}
					timeout={5000}
				/>

				<div className="d-sm-flex header-wrapper sticky">
					<div className="header-left">
						<ReturnButton docInfo={docInfo} />
					</div>
					<div className="header-center document-title">
						{hasAttachments ? <img src={ClipIcon} className='attachment-icon'/> : <></>}
						{DocTitle}
					</div>
					<div className="header-right">
						<ActionBar />
					</div>
				</div>
				<div className='d-block d-sm-none m-3 d-sm-flex-alignCenter'>
					<div className="logo-horizontal mb-2">
						<LogoImage />
					</div>
					<div className="header-center document-title">
						{hasAttachments ? <img src={ClipIcon} className='attachment-icon'/> : <></>}
						{DocTitle}
					</div>
				</div>
				<div className="body-wrapper">
					<DocumentDetailBody />
				</div>

				{previewPdf !== null &&
					<FullPreview
						key="full-preview"
						hash={previewPdf}
						file={PDFFile}
						files={PDFFiles}
						attachments={AttachmentFiles}
						active={previewPdf !== null}
						onClose={(ev) => {
							this.setState({ previewPdf: null });
						}}
						isGuest={isGuest}
						view_status={view_status}
					/>
				}
				<GeneralModal />
				<FormModal />
				<CertificateApplyForm />
				<OtpDialog resendEmailCode={this.generateOtpDocument.bind(this)} isFromSigned={true} openOtpDialog={openOtpDialog} isVerifyEmailInDocument={isVerifyEmail} isVerifySoftware={isVerifySoftware} mfaChallengeName={''}
					mfaUser={''} callback={this.handleConfirmOtpDialog.bind(this)} />
				<DocumentViewStatus />

				{this.DocumentPrintAlert()}
			</div>
		);

	}

}

export default withTranslation()(DocumentDetail);
