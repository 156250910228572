//@ts-nocheck
import { useHookstate } from "@hookstate/core";
import {
  Button,
  Drawer,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { concatMap, from } from "rxjs";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";
import downloadFileUrl from "../../../Utils/download-file";
import DriveActionsBtn from "../../components/drive-actions-btn/drive-actions-btn";
import BaseTable from "../../components/drive-base-table/drive-base-table";
import DriveBreadcrumb from "../../components/drive-breadcrumb/drive-breadcrumb";
import BaseDialogDriveCrud from "../../components/drive-dialogs/drive-base-dialog";
import DriveConfirmDialog from "../../components/drive-dialogs/drive-confirm-base";
import DriveCreateFolderDialog from "../../components/drive-dialogs/drive-create-dialog";
import DriveEditFolderDialog from "../../components/drive-dialogs/drive-edit-dialog";
import DriveMoveCopyDialog from "../../components/drive-dialogs/drive-move-copy-dialog";
import DrivePreviewDialog from "../../components/drive-dialogs/drive-preview-dialog";
import DriveShareDialog from "../../components/drive-dialogs/drive-share-dialog";
import DriveUploadDialog from "../../components/drive-dialogs/drive-upload-dialog";
import DriveFileInfo from "../../components/drive-file-info/drive-file-info";
import { DriveDragPreview } from "../../components/drive-nav/drive-drag-preview";
import DriveSearch from "../../components/drive-search/drive-search";
import { downloadFileActions } from "../../data-access/actions";
import {
  deleteFromTrash,
  downloadItems,
  generatDownloadPresignedUrl,
  getFilesPreview,
  getFoldersDetail,
  getFoldersTree,
  getTrashDetail,
  moveItem,
  moveToTrash,
  recycleTrash,
  searchItemFolder,
} from "../../data-access/api";
import {
  getCompanyFolders,
  getCompanyFoldersContent,
} from "../../data-access/company-folders/company-folders.api";
import {
  currentSelectedCompanyFolderState,
  driveNavCompanyTree,
  selectedFoldersCompany,
} from "../../data-access/company-folders/company-folders.state";
import {
  downloadCompleteFolder,
  getCompleteFolders,
} from "../../data-access/complete-folders/complete-folders.api";
import { completeFolderSearchState } from "../../data-access/complete-folders/complete-folders.state";
import {
  breabCrumbState,
  currentFolderTypeState,
  dragTargetState,
  driveDetailLoading,
  driveFileDrawer,
  driveFoldersDetail,
  driveFoldersNav,
  expandNodeState,
  folderDetailSearch,
  folderDetailSearchState,
  globalAnchorContextMenu,
  isCompleteFolderState,
  isDraggingGlobalState,
  isOpenShareDialog,
  isShowTableDragFile,
  isTableDragging,
  notificationAlertState,
  progessZippingState,
  scrollLoadingState,
  searchObjectFolder,
} from "../../data-access/state";
import mappedDataToTreeView, {
  parseUrlToObjectKey,
} from "../../utils/mappedDataToTreeView";
import { mappedTypeToQuery } from "../../utils/mappedTypeToQuery";
import { progessCallback } from "../../utils/progess-callback";
import searchContentFolderUtils from "../../utils/search-content-folder";
// import DriveShareDialog from "../drive-dialogs/drive-share-dialog";

export default function DriveFolders({ fromDocumentSelect, fromRoot }) {
  const { folderId } = useParams();
  const { t } = useTranslation("common");
  const scrollLoading = useHookstate(scrollLoadingState);
  const showAlertWarning = useHookstate({
    open: false,
    title: t("common:drive.dialog.alert_dialog", "Alert"),
    message: t(
      "common:drive.message.error.cant_delete_default",
      "You cannot delete default folder (Company folder, private folder, complete folder, trash folder)"
    ),
  });
  const isDraggingGlobal = useHookstate(isDraggingGlobalState);
  const tableDriveHeader = [
    {
      key: "name",
      title: t("common:drive.table_header.name", "名前"),
      type: "icon",
      width: "30%",
      sort: true,
      sortCallback: () => {
        // orderBy.set("name");
        return onSort("name");
      },
    },
    {
      key: "memo",
      title: t("common:drive.table_header.note", "メモ"),
      type: "string",
      width: "15%",
      sort: true,
      sortCallback: () => {
        // orderBy.set("name");
        return onSort("memo");
      },
    },
    {
      key: "updated_date",
      title: t("common:drive.table_header.updated_date", "更新日"),
      type: "dateIso",
      width: "15%",
      sort: true,
      // @ts-ignore
      // @ts-ignore
      sortCallback: () => {
        // orderBy.set("name");
        return onSort("updated_date");
      },
    },
    {
      key: "size",
      title: t("common:drive.table_header.size", "サイズ"),
      type: "size",
      width: "15%",
      sort: true,
      // @ts-ignore
      // @ts-ignore
      sortCallback: () => {
        // orderBy.set("name");
        return onSort("size");
      },
    },
    {
      key: "extension",
      title: t("common:drive.table_header.format_type", "形式"),
      type: "format_type",
      width: "10%",
      sort: true,
      sortCallback: () => {
        // orderBy.set("name");
        return onSort("extension");
      },
    },
  ];
  const order = useHookstate({
    name: "asc",
    updated_date: "desc",
    size: "asc",
    extension: "asc",
    memo: "asc"
  });
  const { search, pathname } = useLocation();
  // const tableRef = useRef(null);
  const isTrashFolder = useHookstate(false);
  const isCompanyFolder = useHookstate(false);
  const currentFolderType = useHookstate(currentFolderTypeState);
  const dropTarget = useHookstate(-1);
  const dragTarget = useHookstate(dragTargetState);
  const orderList = useHookstate([
    "name asc",
    "updated_date desc",
    "size asc",
    "extension asc",
    "memo asc"
  ]);
  const folderDetailList = useHookstate(driveFoldersDetail);
  const isShowTableDrag = useHookstate(isShowTableDragFile);
  const selectedListFile = useHookstate([]);
  const currentRowSelectContextMenu = useHookstate(null);
  const anchorContextMenu = useHookstate(null);
  const globalAnchorContext = useHookstate(globalAnchorContextMenu);
  const openUploadDialog = useHookstate(false);
  const uploadDialogType = useHookstate("uploadFile");
  const openCreateFolderDialog = useHookstate(false);
  const openMoveCopyDialog = useHookstate(false);
  const openShareDialog = useHookstate(isOpenShareDialog);
  const driveDetailLoad = useHookstate(driveDetailLoading);
  const driveTree = useHookstate(driveFoldersNav);
  const navigate = useHistory();
  const detailSearch = useHookstate(folderDetailSearch);
  const searchObject = useHookstate(searchObjectFolder);
  const isEnableKeyHoverEdit = useHookstate(false);
  const completeFolderSearch = useHookstate(completeFolderSearchState);
  const isEnableSelectAble = useHookstate(true);
  const recycleConfirmDialog = useHookstate({
    open: false,
    title: t("common:drive.dialog.title.confirm.recycle"),
    message: t(
      "common:drive.dialog.message.confirm.recycle",
      "is exist ! Do you want to overwrite it ?"
    ),
    existing_folders_name: [],
    existing_files_name: [],
  });
  const isCompleteFolder = useHookstate(isCompleteFolderState);
  const previewDialog = useHookstate({
    open: false,
    files: [],
    files_sort: [],
    data: {},
    fileType: "",
    fileUrl: "",
    fileName: "",
    row: {},
  });
  const confirmDialog = useHookstate({
    open: false,
    title: "",
    message: "",
    callback: onClickDelete,
    loading: false,
  });
  // console.log(driveTree)
  const editClickState = useHookstate({
    openDialog: false,
    data: null,
  });
  const folderDetailSearchList = useHookstate(folderDetailSearchState);
  // const driveSelectedRow = useHookstate(null);
  // const openEditDialog = useHookstate(false);

  //Event drag drop
  function onFileDragEnter() {
    // console.log("enter");
    isShowTableDrag.set(true);
  }

  function onFileDragLeave() {
    // console.log("leave");
    isShowTableDrag.set(false);
  }

  function onFileDragOver() {
    // console.log("dragover");
    isShowTableDrag.set(true);
  }

  function onFileDrop() {
    // console.log("drio");
    isShowTableDrag.set(false);
  }

  function parseQuery(queryString) {
    let query = {};
    let pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  //function handle fetch api get folder detail
  function submitSearch(folderIndex, page) {
    // if (folderId === )
    // console.log("folder", searchObject.get({stealth:true}));
    const queryType = parseQuery(search);
    if (!page) selectedListFile.set([]);

    driveDetailLoad.set(true);
    // console.log(detailSearch.get({ stealth: true }));
    if (fromDocumentSelect) {
      driveDetailLoad.set(true);
      isTrashFolder.set(false);
      if (!page) detailSearch.page_number.set((v) => 1);
      isEnableKeyHoverEdit.set(false);
      isCompleteFolder.set(false);
      isCompanyFolder.set(true);
      const breab = breabCrumbState.get({ stealth: true });
      console.log("submit ", detailSearch.get({stealth: true}), folderIndex, page);
      getCompanyFolders({
        drive_folder_id: breab[breab.length - 1]?.id,
        ...detailSearch.get({ stealth: true, noproxy: true }),
      })
        .then((r) => {
          if (!folderIndex && folderId) {
            folderIndex = driveTree
              .get({ stealth: true, noproxy: true })
              .findIndex(
                (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
              );
          }
          currentFolderType.set(driveTree.value[folderIndex]?.type);
          const data = r?.data?.payload
            ?.reduce((arr, item) => {
              if (
                !item.document_id &&
                !arr.find((b) => b.drive_folder_id == item.drive_folder_id)
              ) {
                arr.push(item);
              }
              return arr;
            }, [])
            .map(mappedDataToTreeView);
          folderDetailList?.data?.merge({
            [breab[breab.length - 1]?.id
              ? breab[breab.length - 1]?.id
              : "company"]: data,
            company: data,
          });
          detailSearch.offset_folder_number.set((v) => v + data?.length);
        })
        .finally(() => driveDetailLoad.set(false));
      return;
    }
    if (!folderId) {
      if (
        !driveFoldersDetail.data[folderId ? folderId : "root"]?.get({
          stealth: true,
        })
      ) {
        driveFoldersDetail.data.merge({
          [folderId ? folderId : "root"]: [],
        });
      }
      getFoldersDetail(
        folderId,
        detailSearch.get({ stealth: true, noproxy: true })
      )
        .then((r) => {
          breabCrumbState.set((v) => {
            return [
              {
                fileType: "folder",
                id: "drive",
                parent: 0,
                name: "common:drive.tree_nav.root_folder",
                droppable: false,
                draggable: false,
                notShowDropDownIcon: true,
              },
              ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
            ];
          });
          const dataRoot = driveTree
            .get({ stealth: true, noproxy: true })
            .filter((a) => a.parent === "drive");
          // console.log(dataRoot);
          isEnableKeyHoverEdit.set(true);
          folderDetailList.data.merge(
            {
              root: [
                ...dataRoot,
                ...r?.data?.payload?.child?.folders.map(mappedDataToTreeView),
                ...r?.data?.payload?.child?.files.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id) && item.type !== 9) {
                  arr.push(item);
                }
                return arr;
              }, []),
            }
            // data.map((a, i) => ({ ...a, id: i }))
          );
          folderDetailList?.data["root"].set((v) =>
            [
              ...r?.data?.payload?.child?.folders.map(mappedDataToTreeView),
              ...r?.data?.payload?.child?.files.map(mappedDataToTreeView),
              ...v,
            ].reduce((arr, item) => {
              if (!arr.find((a) => a.id === item.id) && item.type !== 9) {
                arr.push(item);
              }
              return arr;
            }, [])
          );
        })
        .finally(() => driveDetailLoad.set(false));
      return;
    }
    // console.log(driveTree.value[folderIndex], isNaN(+folderId), folderId);
    switch (queryType?.type) {
      case "company":
        isTrashFolder.set(false);
        if (!page) detailSearch.page_number.set((v) => 1);
        isEnableKeyHoverEdit.set(true);
        isCompleteFolder.set(false);
        isCompanyFolder.set(true);
        isEnableSelectAble.set(true);
        getCompanyFoldersContent({
          drive_folder_id: folderId,
          ...detailSearch.get({ stealth: true, noproxy: true }),
        })
          .then((r) => {
            if (!folderIndex && folderId) {
              folderIndex = driveTree
                .get({ stealth: true, noproxy: true })
                .findIndex(
                  (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                );
            }
            currentFolderType.set(driveTree.value[folderIndex]?.type);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
              ];
            });
            folderDetailList?.data?.merge({
              [folderId ? folderId : "root"]: [
                ...r?.data?.payload?.child?.folders.map((a) =>
                  mappedDataToTreeView(a, false, true)
                ),
                ...r?.data?.payload?.child?.files.map((a) =>
                  mappedDataToTreeView(a, false, true)
                ),
              ],
            });
            detailSearch.offset_folder_number.set(
              (v) => v + r?.data?.payload?.child?.folders.length
            );
            detailSearch.offset_file_number.set(
              (v) => v + r?.data?.payload?.child?.files.length
            );
          })
          .finally(() => driveDetailLoad.set(false));
        break;
      case "complete":
        isCompanyFolder.set(false);
        isTrashFolder.set(false);
        if (!page) {
          completeFolderSearch.page_number.set((v) => 1);
        } else {
          completeFolderSearch.page_number.set(page);
        }
        isEnableKeyHoverEdit.set(false);
        isEnableSelectAble.set(true);
        // driveDetailLoad.set(true);
        isCompleteFolder.set(true);
        getCompleteFolders(queryType.function_type, {
          ...completeFolderSearch.get({ stealth: true }),
          search: detailSearch.search.get({ noproxy: true }),
        })
          .then((r) => {
            if (!folderIndex && folderId) {
              folderIndex = driveTree
                .get({ stealth: true, noproxy: true })
                .findIndex(
                  (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                );
            }
            currentFolderType.set(driveTree.value[folderIndex]?.type);
            const compeleteFind = driveTree
              .get({ stealth: true, noproxy: true })
              .find((a) => a.type === 5);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                { ...compeleteFind },
                {
                  ...driveTree.value[folderIndex],
                },
              ];
            });
            folderDetailList?.data?.merge({
              [folderId ? folderId : "root"]: r.data.payload.map((a) => ({
                ...a,
                id: a.document_id,
                parent: driveTree.value[folderIndex].id,
                format_type: "zip",
              })),
            });
          })
          .finally(() => driveDetailLoad.set(false));
        break;
      case "completep":
        isCompanyFolder.set(false);
        isTrashFolder.set(false);
        if (!page) {
          detailSearch.page_number.set((v) => 1);
        } else {
          detailSearch.page_number.set(page);
        }
        isCompleteFolder.set(true);
        isEnableKeyHoverEdit.set(false);
        isEnableSelectAble.set(true);
        if (
          !folderDetailList.data[folderId ? folderId : "root"]?.get({
            stealth: true,
          })
        ) {
          folderDetailList.data.merge({
            [folderId ? folderId : "root"]: [],
          });
        }
        getFoldersDetail(
          folderId,
          detailSearch.get({ stealth: true, noproxy: true })
        )
          .then((r) => {
            if (!folderIndex && folderId) {
              folderIndex = driveTree
                .get({ stealth: true, noproxy: true })
                .findIndex(
                  (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                );
            }
            currentFolderType.set(driveTree.value[folderIndex]?.type);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
              ];
            });
            // folderDetailList?.data?.merge({
            //   [folderId ? folderId : "root"]:
            //     r?.data?.payload?.map(mappedDataToTreeView) || [],
            // });
            folderDetailList.data[folderId ? folderId : "root"].set((v) =>
              [
                ...v,
                ...(r?.data?.payload?.child?.folders?.map(
                  mappedDataToTreeView
                ) || []),
                ...(r?.data?.payload?.child?.files?.map(mappedDataToTreeView) ||
                  []),
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            );
            detailSearch.offset_folder_number.set(
              (v) => v + r?.data?.payload?.child?.folders.length
            );
            detailSearch.offset_file_number.set(
              (v) => v + r?.data?.payload?.child?.files.length
            );
          })
          .finally(() => driveDetailLoad.set(false));
        break;
      case "trash":
        isCompanyFolder.set(false);
        isTrashFolder.set(true);
        if (!page) detailSearch.page_number.set((v) => 1);
        isEnableKeyHoverEdit.set(true);
        isCompleteFolder.set(false);
        isEnableSelectAble.set(true);
        console.log("trash");
        getTrashDetail(detailSearch.get({ stealth: true, noproxy: true }))
          .then((r) => {
            if (!folderIndex && folderId) {
              folderIndex = driveTree
                .get({ stealth: true, noproxy: true })
                .findIndex(
                  (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                );
            }
            currentFolderType.set(driveTree.value[folderIndex]?.type);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                {
                  drive_folder_id: "trash",
                  parent_id: null,
                  name: "common:drive.tree_nav.trash",
                  title: "",
                  type: 11,
                  updated_date: "",
                  parent: "drive",
                  droppable: false,
                  draggable: false,
                  fileType: "trash",
                  id: "trash",
                  extension: "folder",
                  format_type: "trash",
                  notShowDropDownIcon: true,
                },
              ];
            });
            folderDetailList.data[folderId ? folderId : "root"].set((v) =>
              [
                // ...(!folderId ? dataRoot : []),
                ...(r?.data?.payload?.folders.map(mappedDataToTreeView) || []),
                ...(r?.data?.payload?.files.map(mappedDataToTreeView) || []),
                // ...v,
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            );

            detailSearch.offset_folder_number.set(
              (v) => v + r?.data?.payload?.folders.length
            );
            detailSearch.offset_file_number.set(
              (v) => v + r?.data?.payload?.files.length
            );
          })
          .finally(() => driveDetailLoad.set(false));
        break;
      default:
        isTrashFolder.set(false);
        isEnableSelectAble.set(true);
        if (!page) detailSearch.page_number.set((v) => 1);
        isEnableKeyHoverEdit.set(true);
        isCompleteFolder.set(false);
        isCompanyFolder.set(false);
        // driveDetailLoad.set(true);
        // console.log();
        if (
          !driveFoldersDetail.data[folderId ? folderId : "root"]?.get({
            stealth: true,
          })
        ) {
          driveFoldersDetail.data.merge({
            [folderId ? folderId : "root"]: [],
          });
        }
        getFoldersDetail(
          folderId,
          detailSearch.get({ stealth: true, noproxy: true })
        )
          .then((r) => {
            if (!folderIndex && folderId) {
              folderIndex = driveTree
                .get({ stealth: true, noproxy: true })
                .findIndex(
                  (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                );
            }
            currentFolderType.set(driveTree.value[folderIndex]?.type);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
              ];
            });
            // console.log(r.data.payload);
            const dataRoot = driveTree
              .get({ stealth: true, noproxy: true })
              .filter((a) => a.parent === "drive");
            folderDetailList.data[folderId ? folderId : "root"].set((v) =>
              [
                ...(!folderId ? dataRoot : []),
                ...(r?.data?.payload?.child?.folders.map(
                  mappedDataToTreeView
                ) || []),
                ...(r?.data?.payload?.child?.files.map(mappedDataToTreeView) ||
                  []),
                // ...v,
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            );

            detailSearch.offset_folder_number.set(
              (v) => v + r?.data?.payload?.child?.folders.length
            );
            detailSearch.offset_file_number.set(
              (v) => v + r?.data?.payload?.child?.files.length
            );
          })
          .finally(() => driveDetailLoad.set(false));
        break;
    }
    // if (queryType && queryType?.type === "company") {
    // } else {
    //   console.log(queryType, queryType.type);
    // }
  }

  //function handle download folder or files
  function onClickDownload() {
    const isCompleteFolder = driveTree
      .get({ stealth: true, noproxy: true })
      .find((a) => a.id == folderId);
    let selectList = selectedListFile.get({ stealth: true });
    if (
      selectList.some(
        (v) =>
          [1, 2, 3, 4, 5, 6, 7, 11].includes(v.type) && v.extension === "folder"
      )
    ) {
      showAlertWarning.open.set(true);
      showAlertWarning.message.set(
        t(
          "common:drive.message.error.cant_download_default",
          "You cannot download default folder (Company folder, private folder, complete folder)"
        )
      );
      globalAnchorContext.set(null);
      return;
    }
    if (selectList.length === 0) {
      selectList = [currentRowSelectContextMenu.get({ stealth: true })];
    }
    const selectMap = selectList.reduce(
      (arr, item) => {
        if (item.document_id) {
          arr.document_id_list.push(item.document_id);
        } else if (item.extension === "folder") {
          arr.folders.push(item.id);
        } else {
          arr.files.push(item.id);
        }
        return arr;
      },
      {
        files: [],
        folders: [],
        document_id_list: [],
      }
    );

    if (isCompleteFolder && [1, 2, 3, 4, 5].includes(isCompleteFolder?.type)) {
      const fileDownload = {};
      progessZippingState.open.set(true);
      progessZippingState.message.set("drive.message.zipping");
      const listDownload = selectList.map((a) => {
        fileDownload[a.document_id] = a.name;
        return downloadCompleteFolder(a.document_id);
      });
      Promise.all(listDownload).then((res) => {
        from(res.map((a) => a.data.payload.url))
          .pipe(
            concatMap((r) => {
              const urlSplit = r.split("/");
              return from(
                downloadFileActions(
                  r,
                  fileDownload[urlSplit[urlSplit.length - 2]],
                  progessCallback,
                  "not"
                )
              );
            })
          )
          .subscribe({
            complete: () => {
              progessZippingState.message.set("drive.message.zipping");
              progessZippingState.open.set(false);
            },
          });
      });
      globalAnchorContext.set(null);
      return;
    }
    // if (selectList.length > 0) {
    progessZippingState.open.set(true);
    downloadItems({
      drive_file_id_list: selectMap.files,
      drive_folder_id_list: selectMap.folders,
      document_id_list: selectMap.document_id_list,
    }).then((r) => {
      progessZippingState.message.set("drive.message.zipping");

      // let aLink = document.createElement("a");
      // aLink.href = r.data.payload.url;
      // aLink.click();
      downloadFileUrl(r.data.payload.url, "zip").finally(() => {
        progessZippingState.message.set("drive.message.downloading");
        progessZippingState.open.set(false);
      });

      // downloadFileActions(
      //   r.data.payload.url,
      //   `download_${selectList
      //     .map((a) => a.id)
      //     .join("-")}-${new Date().toISOString()}.zip`,
      //   progessCallback
      // ).then((r) => {
      //   progessZippingState.message.set("drive.message.zipping");
      //   progessZippingState.open.set(false);
      // });
    });
    globalAnchorContext.set(null);
    return;
  }

  //function handle share delivery folder or files
  function onClickShare() {
    selectedListFile.set((v) =>
      currentRowSelectContextMenu.get({ stealth: true }) && v.length === 0
        ? [...v, { ...currentRowSelectContextMenu.get({ stealth: true }) }]
        : [...v]
    );
    openShareDialog.set(true);

    currentRowSelectContextMenu.set(null);
    globalAnchorContext.set(null);
  }
  
  function deleteFromTrashExecute(datas, selectList) {
    deleteFromTrash({
      drive_file_id_list: datas.files,
      drive_folder_id_list: datas.folders,
      document_id_list: datas.document_id_list,
    })
      .then(() => {
        folderDetailList.data[folderId ? folderId : "root"].set((v) => {
          return v.filter(
            (a) =>
              !datas.folders.includes(a.id) &&
              !datas.files.includes(a.id) &&
              !datas.original_file.includes(a.id)
          );
        });
        driveTree.set((v) => v.filter((a) => !datas.folders.includes(a.id)));
        confirmDialog.open.set(false);
      })
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          type: "error",
          message: `${t(
            "common:drive.message.error.delete",
            "Delete error please try again!"
          )} ${e?.response?.data?.message || e}`,
        });
      })
      .finally(() => confirmDialog.loading.set(false));
  }

  function moveToTrashExecute(datas, selectList) {
    if (
      !folderDetailList.data[folderId ? folderId : "root"]?.get({
        stealth: true,
      })
    ) {
      folderDetailList.data.merge({
        [folderId ? folderId : "root"]: [],
      });
    }
    moveToTrash({
      drive_file_id_list: datas.files,
      drive_folder_id_list: datas.folders,
      document_id_list: datas.document_id_list,
    })
      .then(() => {
        // console.log(
        //   driveFoldersDetail.data[folderId ? folderId : "root"].get({
        //     stealth: true,
        //   })
        // );
        selectedListFile.set([]);

        getFoldersTree({
          page_number: 1,
          page_size: 100,
          parent_id: folderId || "null",
        }).then((r) => {
          if (r.data.payload)
            driveFoldersNav.set((v) => {
              const newData = [
                ...v,
                ...r.data.payload.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (!arr.find((b) => b.id == item.id)) {
                  arr.push(item);
                }
                return arr;
              }, []);

              if (
                newData?.filter((a) => a.parent_id == folderId)?.length === 0
              ) {
                const idx = newData.findIndex((b) => b.id == folderId);
                if (idx > -1) {
                  newData[idx].notShowDropDownIcon = 1;
                  newData[idx].is_empty = 1;

                  newData[idx].size =
                    driveFoldersDetail.data[newData[idx].id].get({
                      stealth: true,
                      noproxy: true,
                    })?.length || 0;
                }
              }
              return newData;
            });
        });

        driveFoldersDetail.data[folderId ? folderId : "root"].set((v) => {
          return (
            v?.filter(
              (a) =>
                !datas.files.includes(a.id) &&
                !datas.folders.includes(a.id) &&
                !datas.original_file.includes(a.id)
            ) || []
          );
        });
        driveTree.set((v) => {
          const newData = v.filter((a) => {
            // console.log(
            //   a.id == folderId &&
            //     driveFoldersDetail.data[a.id].get({
            //       stealth: true,
            //       noproxy: true,
            //     })?.length === 0
            // );
            if (
              a.id == folderId &&
              driveFoldersDetail.data[a.id].get({
                stealth: true,
                noproxy: true,
              })?.length === 0
            ) {
              a = {
                ...a,
                is_empty: 1,
                notShowDropDownIcon: 1,
                size: 0,
              };
            }
            return !datas.folders.includes(a.id);
          });
          // console.log(newData);
          return newData;
        });
        driveFileInfo.open.set(false);
        // const tree = driveTree.get({ stealth: true, noproxy: true });
        // if (treeIdx > -1) {
        // }
        if (searchObject.isSearching.get({ stealth: true })) {
          folderDetailSearchList.set((v) =>
            v.filter((a) => !selectList.find((c) => c.id === a.id))
          );
        }
        confirmDialog.open.set(false);
      })
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          type: "error",
          message: `${t(
            "common:drive.message.error.delete",
            "Delete error please try again!"
          )} ${e?.response?.data?.message || e}`,
        });
      })
      .finally(() => confirmDialog.loading.set(false));
  }

  //function handle delete folder or files
  function onClickDelete() {
    const currentContext = currentRowSelectContextMenu.get({ stealth: true });
    const selectList =
      selectedListFile.get({ stealth: true }).length > 0
        ? selectedListFile.get({ stealth: true })
        : [currentContext];
    // console.log(selectList);
    if (
      selectList.some(
        (v) =>
          [1, 2, 3, 4, 5, 6, 7, 11].includes(v?.type) &&
          v.extension === "folder"
      )
    ) {
      showAlertWarning.message.set(
        t(
          "common:drive.message.error.cant_delete_default",
          "You cannot delete default folder (Company folder, private folder, complete folder)"
        )
      );
      showAlertWarning.open.set(true);
      globalAnchorContext.set(null);
      return;
      // return alert(
      //   t(
      //     "common:drive.message.error.cant_delete_default",
      //     "You cannot delete default folder (Company folder, private folder, complete folder, trash folder)"
      //   )
      // );
    }

    const datas = selectList.reduce(
      (arr, item) => {
        if (item.document_id && item.type == 10) {
          arr.document_id_list.push(item.document_id);
          arr.original_file.push(item.id);
        } else if (item.extension === "folder") {
          arr.folders.push(item.id);
        } else {
          arr.files.push(item.id);
        }
        return arr;
      },
      {
        folders: [],
        files: [],
        document_id_list: [],
        original_file: [],
      }
    );
    // console.log(selectList);
    if (selectList.length > 0) {
      confirmDialog.loading.set(true);
      if (folderId === "trash") {
        deleteFromTrashExecute(datas, selectList);
      } else {
        moveToTrashExecute(datas, selectList);
      }
      // Promise.all([
      //   datas.folders.length > 0
      //     ? deleteFolder(datas.folders)
      //     : new Promise((resolve) => resolve(true)),
      //   datas.files.length > 0
      //     ? deleteFile(datas.files)
      //     : new Promise((resolve) => resolve(true)),
      // ])
      //   .then(() => {})
    }
    globalAnchorContext.set(null);
    currentRowSelectContextMenu.set(null);
  }

  //function handle on click create folder or files
  function onClickCreate(e, type) {
    // console.log(e, type);
    switch (type) {
      case "uploadFile":
        uploadDialogType.set(type);
        openUploadDialog.set(true);
        break;
      case "uploadFolder":
        uploadDialogType.set(type);
        openUploadDialog.set(true);
        break;
      case "createFolder":
        openCreateFolderDialog.set(true);
      default:
        break;
    }
    globalAnchorContext.set(null);
  }

  function onCheckSelectedAll(checked) {
    if (checked) {
      if (searchObject.isSearching.get({ stealth: true, noproxy: true })) {
        selectedListFile.set([
          ...folderDetailSearchList.get({
            steath: true,
            noproxy: true,
          }),
        ]);
      } else {
        selectedListFile.set([
          ...folderDetailList.data[folderId ? folderId : "root"].get({
            steath: true,
            noproxy: true,
          }),
        ]);
      }
    } else {
      selectedListFile.set([]);
    }
    driveFileInfo.open.set(false);
  }

  function onClickRow(/** @type {any} */ ev, /** @type {any} */ row) {
    if (
      selectedListFile
        .get({ stealth: true, noproxy: true })
        .find((a) => a.id == row.id) &&
      selectedListFile.get({ stealth: true }).length === 1
    ) {
      if (fromDocumentSelect) {
        selectedFoldersCompany.set(null);
      }
    } else {
      if (fromDocumentSelect) {
        selectedFoldersCompany.set(row);
      }
    }
    if (ev && "checked" in ev?.target && !fromDocumentSelect) {
      if (ev?.target?.checked) {
        selectedListFile.set((v) => {
          return [...v.filter((a) => a.id !== row.id), { ...row }];
        });
      } else {
        selectedListFile.set((v) => {
          return v.filter((a) => a.id !== row.id);
        });
      }
    } else {
      if (
        selectedListFile
          .get({ stealth: true, noproxy: true })
          .find((a) => a.id == row.id) &&
        selectedListFile.get({ stealth: true }).length === 1
      ) {
        selectedListFile.set([]);
        if (fromDocumentSelect) {
          selectedFoldersCompany.set(null);
        }
      } else {
        selectedListFile.set([{ ...row }]);
        if (fromDocumentSelect) {
          selectedFoldersCompany.set(row);
        }
      }
    }
    // if(fromDocumentSelect && sea)
    if (fromDocumentSelect && searchObject.isSearching.get({ stealth: true })) {
      breabCrumbState.set([]);
      // console.log(JSON.parse(row.parents).reverse());
      const parents = JSON.parse(row.parents);
      let newArray = [];

      for (let i = parents.length - 1; i >= 0; i--) {
        newArray.push(parents[i]);
      }
      breabCrumbState.set([
        driveNavCompanyTree.get({ stealth: true }).find((a) => a.parent === 0),
        ...newArray.slice(0, newArray.length - 1).map((a) => ({
          isCompany: true,
          ...a,
          id: a.drive_folder_id,
          type: row.type,
        })),
      ]);
      // console.log(parents,newArray, breabCrumbState.get({noproxy:true}))
    }
    if (
      fromDocumentSelect ||
      ([1, 2, 3, 4, 5, 6, 7, 11].includes(row?.type) &&
        row.extension === "folder") ||
      folderId === "trash"
    )
      return;
    // console.log(ev);
    if (
      selectedListFile.get({ stealth: true }).length > 1 ||
      selectedListFile.get({ stealth: true }).length === 0
    ) {
      driveFileInfo.open.set(false);
    } else {
      // driveFileInfo.open.set(false);
      driveFileInfo.dataDetail.set({
        ...row,
        id: row?.id,
        document_id: row?.document_id,
        parent: row?.parent,
        parent_id: row?.parent_id,
        memo: row?.memo || "",
        transaction_date: row?.transaction_date || "",
        amount: parseFloat(row?.amount || 0) || 0,
        currency: row?.currency || "JPY",
        counterparty_name: row?.counterparty_name || "",
      });
      driveFileInfo.open.set(true);
    }
  }

  function onEditClick(row) {
    // e.preventDefault();
    editClickState.data.set({ ...row });
    editClickState.openDialog.set(true);
  }

  function onCopyRow() {
    openMoveCopyDialog.set(true);
  }

  function handleContextMenu(event, row) {
    // console.log(event, row);
    if (
      [1, 2, 3, 4, 5, 7, 11].includes(row?.type) &&
      row?.extension === "folder"
    ) {
      return;
    }
    currentRowSelectContextMenu.set(() => ({ ...row }));
    anchorContextMenu.set(
      anchorContextMenu.get({ stealth: true, noproxy: true }) === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    event.preventDefault();
    // console.log(row, currentRowSelectContextMenu.get({stealth: true}));
  }

  //function handle on close contextmenụ
  function handleClose(isReset) {
    anchorContextMenu.set(null);
    if (!isReset) currentRowSelectContextMenu.set(null);
  }

  //function handle on sort
  function onSort(key) {
    order.set((v) => {
      const cloneV = { ...v };
      cloneV[key] = v[key] === "asc" ? "desc" : "asc";
      const firstOrder = orderList[0].get({ stealth: true });
      if (firstOrder && firstOrder.includes(key)) {
        orderList[0].set((a) => {
          const splitA = a.split(" ");
          return `${key} ${splitA[1] === "asc" ? "desc" : "asc"}`;
        });
      } else {
        const currentKeyIndex = orderList
          .get({ stealth: true })
          .findIndex((a) => a.includes(key));
        orderList.set((a) => {
          a[currentKeyIndex] = `${key} ${cloneV[key]}`;
          let b = a[0];
          a[0] = a[currentKeyIndex];
          a[currentKeyIndex] = b;
          return a;
        });
      }
      const orderComplete = orderList.get({ stealth: true }).join(", ");
      orderComplete.replace("name", "title");
      completeFolderSearch.order_by.set(
        orderList
          .get({ stealth: true })
          .filter((f) => f !== "extension asc" && f !== "extension desc")
          .join(", ")
      );
      detailSearch.order_by.set(orderComplete);
      searchObject.order_by.set(orderComplete);
      return cloneV;
    });
    const folderIndex = driveTree.value.findIndex(
      (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
    );
    detailSearch.page_number.set(1);
    if (searchObject.isSearching.get()) {
      searchObject.isStop.set(0);
      searchObject.offset_folder_number.set(0);
      searchObject.offset_file_number.set(0);
      searchObject.offset_document_number.set(0);
      searchContentFolder();
    } else {
      detailSearch.offset_folder_number.set(0);
      detailSearch.offset_file_number.set(0);
      submitSearch(folderIndex);
    }
  }

  function handleSearchKeyEnter(event) {
    const { keyCode, target } = event;

    const { value: searchString } = target;
    // console.log(search);
    if (keyCode === 13) {
      driveFileInfo.open.set(false);
      selectedListFile.set([]);
      folderDetailSearchList.set([]);
      searchObject.offset_document_number.set(0);
      searchObject.offset_folder_number.set(0);
      searchObject.offset_file_number.set(0);
      searchObject.isStop.set(0);
      if (isTrashFolder.get({ stealth: true })) {
        detailSearch.offset_document_number.set(0);
        detailSearch.offset_folder_number.set(0);
        detailSearch.offset_file_number.set(0);
        return submitSearch();
      }
      if (searchString) {
        searchObject.isSearching.set(1);
      } else {
      }
      if (fromDocumentSelect) {
        selectedFoldersCompany.set(null);
        // searchObject.isSearching.set(false);
        searchObject.isStop.set(0);
        searchObject.search.set(searchString);
        folderDetailSearchList.set([]);
        detailSearch.search.set(searchString);
        searchContentFolder();
      } else {
        searchObject.search.set(searchString);
        const searchOb = searchObject.get({ stealth: true });
        navigate.push({
          pathname: pathname,
          search:
            search +
            "&" +
            new URLSearchParams({
              order_by: searchOb.order_by,
              offset_document_number: searchOb.offset_document_number,
              page_size: searchOb.page_size,
              page_number: searchOb.page_number,
              total: searchOb.total,
              search: searchOb.search,
              offset_folder_number: searchOb.offset_folder_number,
              offset_file_number: searchOb.offset_file_number,
              isSearching: searchOb.isSearching,
              isStop: searchOb.isStop,
            }).toString(),
        });
        // searchContentFolder();
      }
      // submitSearch();
      //   this.handleSearchDocumentMultiField(searchString);
    }
  }

  function searchContentFolder(page) {
    searchContentFolderUtils(
      page,
      searchObject,
      folderId,
      scrollLoading,
      driveDetailLoad,
      breabCrumbState,
      fromDocumentSelect
    );
    // searchObject.isSearching.set(true);
    // if (page) {
    //   searchObject.page_number.set(page);
    // } else {
    //   searchObject.page_number.set(1);
    //   searchObject.isStop.set(0);
    // }
  }

  function handleChangeSearch(event) {
    detailSearch.search.set(event.target.value);
  }

  function handleClearSearch() {
    searchObject.offset_document_number.set(0);
    searchObject.offset_folder_number.set(0);
    searchObject.offset_file_number.set(0);
    searchObject.search.set("");
    searchObject.isStop.set(0);
    folderDetailSearchList.set([]);
    detailSearch.search.set("");
    if (isTrashFolder.get({ stealth: true })) return submitSearch();
    // searchObject.isSearching.set(0);
    // console.log(parseQuery(search));
    navigate.push({
      pathname: pathname,
      search:
        "?" +
        new URLSearchParams({
          type: parseQuery(search)?.type,
        }).toString(),
    });
    searchContentFolder();
    // this.handleSearchDocumentMultiField("");
  }

  function onConfirmDialogClose(e, reason, isOk) {
    // console.log(isOk, confirmDialog.get({ noproxy: true, stealth: true }));
    if (isOk) {
      confirmDialog.get({ noproxy: true, stealth: true }).callback();
    } else {
      confirmDialog.open.set(false);
    }
  }

  function onHoldShiftMultiSelect(row, previousIndex, currentIndex) {
    if (fromDocumentSelect) {
      // console.log(row);
      onClickRow(null, row);
      return;
    }
    const checkedList = [];
    const list = searchObject.isSearching.get({ stealth: true, noproxy: true })
      ? folderDetailSearchList.get({ stealth: true, noproxy: true })
      : folderDetailList.data.get({ stealth: true, noproxy: true })[folderId];
    const currentIndexRow = list[previousIndex];
    const checkedCurrent = !!selectedListFile
      .get({ stealth: true })
      .find((a) => a.id === row.id);
    list.forEach((a, i) => {
      if (previousIndex < currentIndex) {
        if (i > previousIndex && i < currentIndex) {
          // const findIdx = selectedListFile
          //   .get({ stealth: true })
          //   .findIndex(
          //     (b) =>
          //       b.id === a.id && (b?.url ? b?.url === a.url : !a.url && !b?.url)
          //   );
          if (checkedCurrent) {
            checkedList.push(a.id);
          } else {
            selectedListFile.merge([a]);
          }
        }
      } else {
        if (i > currentIndex && i < previousIndex) {
          // const findIdx = selectedListFile
          //   .get({ stealth: true })
          //   .findIndex(
          //     (b) =>
          //       b.id === a.id && (b?.url ? b?.url === a.url : !a.url && !b?.url)
          //   );
          if (checkedCurrent) {
            checkedList.push(a.id);
          } else {
            selectedListFile.merge([a]);
          }
        }
      }
    });
    selectedListFile.set((v) => {
      return v.filter((a) => !checkedList.includes(a.id));
    });
    const newIndex = selectedListFile
      .get({ stealth: true })
      .findIndex(
        (a) =>
          a.id === row.id &&
          (row?.url ? row?.url === a.url : !a.url && !row?.url)
      );
    if (newIndex > -1) {
      selectedListFile.set((v) => {
        v.splice(newIndex, 1);
        const selectedIndexCurrentSelectRow = v.findIndex(
          (a) =>
            a.id === currentIndexRow.id &&
            (currentIndexRow?.url
              ? currentIndexRow?.url === a.url
              : !a.url && !currentIndexRow?.url)
        );
        if (selectedIndexCurrentSelectRow > -1) {
          v.splice(selectedIndexCurrentSelectRow, 1);
        }
        return v;
      });
    } else {
      const selectedIndexCurrentSelectRow = selectedListFile
        .get({ stealth: true })
        .findIndex(
          (a) =>
            a.id === currentIndexRow.id &&
            (currentIndexRow?.url
              ? currentIndexRow?.url === a.url
              : !a.url && !currentIndexRow?.url)
        );
      if (selectedIndexCurrentSelectRow === -1) {
        selectedListFile.merge([{ ...currentIndexRow }]);
      }
      selectedListFile.merge([{ ...row }]);
    }
    if (
      fromDocumentSelect ||
      ([1, 2, 3, 4, 5, 6, 7, 11].includes(row?.type) &&
        row.extension === "folder")
    )
      return;
    if (
      selectedListFile.get({ stealth: true }).length > 1 ||
      selectedListFile.get({ stealth: true }).length === 0
    ) {
      driveFileInfo.open.set(false);
    } else {
      driveFileInfo.dataDetail.set({
        ...row,
        id: row.id,
        document_id: row.document_id,
        memo: row.memo || "",
        transaction_date: row.transaction_date || "",
        amount: parseFloat(row.amount || 0),
        currency: row.currency || "JPY",
        counterparty_name: row.counterparty_name || "",
      });
      driveFileInfo.open.set(true);
    }
  }

  function onHoldControlMultiSelect(row) {
    if (fromDocumentSelect) {
      onClickRow(null, row);
      return;
    }
    selectedListFile.set((v) => {
      const index = v.findIndex(
        (a) =>
          a.id === row.id &&
          (row?.url ? row?.url === a.url : !a.url && !row?.url)
      );
      if (index > -1) {
        v.splice(index, 1);
      } else {
        v.push(row);
      }
      return v;
    });
    if (
      fromDocumentSelect ||
      ([1, 2, 3, 4, 5, 6, 7, 11].includes(row?.type) &&
        row.extension === "folder")
    )
      return;
    if (
      selectedListFile.get({ stealth: true }).length > 1 ||
      selectedListFile.get({ stealth: true }).length === 0
    ) {
      driveFileInfo.open.set(false);
    } else {
      driveFileInfo.dataDetail.set({
        ...row,
        id: row.id,
        document_id: row.document_id,
        memo: row.memo || null,
        transaction_date: row.transaction_date || null,
        amount: parseFloat(row.amount || 0),
        currency: row.currency || "JPY",
        counterparty_name: row.counterparty_name || null,
      });
      driveFileInfo.open.set(true);
    }
  }

  function onEndReached() {
    if (
      searchObject.isSearching.get({ stealth: true }) &&
      !isTrashFolder.get({ stealth: true })
    ) {
      return searchContentFolder(1);
    }
    if (
      folderDetailList.data[folderId ? folderId : "root"]?.get({
        stealth: true,
      })?.length ===
        folderDetailList.total.get({ stealth: true, noproxy: true }) &&
      !fromDocumentSelect
    ) {
      return;
    }
    detailSearch.page_number.set((v) => v + 1);
    const folderIndex = driveTree.value.findIndex(
      (a) => a?.id === (isNaN(+folderId) ? folderId : +folderId)
    );
    currentFolderType.set(driveTree.value[folderIndex]?.type);
    if (fromDocumentSelect) {
      const breab = breabCrumbState.get({ stealth: true });
      detailSearch.offset_folder_number.set(v => folderDetailList?.data["company"].get({stealth:true})?.length || 0)
      console.log("endreach ", detailSearch.get({ stealth: true, noproxy: true }));
      if (
        folderDetailList.data[
          breab[breab.length - 1]?.drive_folder_id || "company"
        ]?.get({
          stealth: true,
        })?.length ===
        folderDetailList.total.get({ stealth: true, noproxy: true })
      ) {
        detailSearch.page_number.set((v) => 1);
        // detailSearch.offset_folder_number.set((v) => 0);
        return;
      }
      // console.log(breab[breab.length - 1]);
      getCompanyFolders({
        drive_folder_id: breab[breab.length - 1]?.drive_folder_id,
        ...detailSearch.get({ stealth: true, noproxy: true }),
      })
        .then((r) => {
          if (r?.data?.payload?.length > 0) {
            folderDetailList?.data["company"].set((v) =>
              [
                ...v,
                ...r?.data?.payload?.map((a) =>
                  mappedDataToTreeView(a, false, true)
                ),
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            );
            detailSearch.offset_folder_number.set((v) => v + r?.data?.payload?.length);
          } else {
            detailSearch.page_number.set((v) => 1);
            // detailSearch.offset_folder_number.set((v) => 0);
          }
          if (r.data.payload.length === 0) {
            folderDetailList.total.set(
              folderDetailList.get({ steath: true, noproxy: true }).data[
                "company"
              ]?.length || 0
            );
          } else {
            folderDetailList.total.set(
              folderDetailList.get({ steath: true, noproxy: true }).data[
                "company"
              ]?.length + folderDetailList.page_size.get({ stealth: true })
            );
          }
        })
        .finally(() => scrollLoading.set(false));
      return;
    }
    const queryType = parseQuery(search);
    switch (queryType?.type) {
      case "company":
        isTrashFolder.set(false);
        scrollLoading.set(true);
        isEnableKeyHoverEdit.set(true);
        isEnableSelectAble.set(true);
        isCompleteFolder.set(false);
        isCompanyFolder.set(true);
        getCompanyFoldersContent({
          drive_folder_id: folderId,
          ...detailSearch.get({ stealth: true, noproxy: true }),
        })
          .then((r) => {
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
              ];
            });
            if (
              r.data.payload?.child?.folders.length > 0 ||
              r.data.payload?.child?.files.length > 0
            ) {
              folderDetailList.data[folderId ? folderId : "root"].set((v) =>
                [
                  ...v,
                  ...r?.data?.payload?.child?.folders.map((a) =>
                    mappedDataToTreeView(a, false, true)
                  ),
                  ...r?.data?.payload?.child?.files.map((a) =>
                    mappedDataToTreeView(a, false, true)
                  ),
                ].reduce((arr, item) => {
                  if (!arr.find((a) => a.id === item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, [])
              );

              detailSearch.offset_folder_number.set(
                (v) => v + r?.data?.payload?.child?.folders.length
              );
              detailSearch.offset_file_number.set(
                (v) => v + r?.data?.payload?.child?.files.length
              );
            } else {
              detailSearch.page_number.set((v) => 1);

              detailSearch.offset_folder_number.set((v) => 0);
              detailSearch.offset_file_number.set((v) => 0);
            }
            if (
              r.data.payload?.child?.folders.length === 0 &&
              r.data.payload?.child?.files.length === 0
            ) {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length || 0
              );
            } else {
              folderDetailList.total.set(
                folderDetailList.page_size.get({ stealth: true }) +
                  folderDetailList.get({ steath: true, noproxy: true }).data[
                    folderId ? folderId : "root"
                  ]?.length
              );
            }
          })
          .finally(() => scrollLoading.set(false));
        break;
      case "complete":
        isCompanyFolder.set(false);
        isTrashFolder.set(false);
        isEnableSelectAble.set(true);
        completeFolderSearch.page_number.set((v) => v + 1);
        scrollLoading.set(true);
        getCompleteFolders(queryType.function_type, {
          ...completeFolderSearch.get({ stealth: true }),
          search: detailSearch.search.get({ noproxy: true }),
        })
          .then((r) => {
            const compeleteFind = driveTree
              .get({ stealth: true, noproxy: true })
              .find((a) => a.type === 5);
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                { ...compeleteFind },
                {
                  ...driveTree.value[folderIndex],
                },
              ];
            });
            if (r.data.payload.length > 0) {
              folderDetailList.data[folderId ? folderId : "root"].merge(
                r.data.payload.map((a) => ({
                  ...a,
                  id: a.document_id,
                  parent: driveTree.value[folderIndex].id,
                  format_type: "zip",
                }))
              );
            }
            if (r.data.payload.length === 0) {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length || 0
              );
            } else {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length + folderDetailList.page_size.get({ stealth: true })
              );
            }
          })
          .finally(() => scrollLoading.set(false));
        break;
      case "completep":
        isEnableSelectAble.set(true);
        isCompanyFolder.set(false);
        isTrashFolder.set(false);
        isCompleteFolder.set(true);
        break;
      case "trash":
        isCompanyFolder.set(false);
        isTrashFolder.set(true);
        scrollLoading.set(true);
        isEnableSelectAble.set(true);
        getTrashDetail(detailSearch.get({ stealth: true, noproxy: true }))
          .then((r) => {
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                {
                  drive_folder_id: "trash",
                  parent_id: null,
                  name: "common:drive.tree_nav.trash",
                  title: "",
                  type: 11,
                  updated_date: "",
                  parent: "drive",
                  droppable: false,
                  draggable: false,
                  fileType: "trash",
                  id: "trash",
                  extension: "folder",
                  format_type: "trash",
                  notShowDropDownIcon: true,
                },
              ];
            });
            if (
              r?.data?.payload?.folders.length > 0 ||
              r?.data?.payload?.files.length > 0
            ) {
              folderDetailList.data[folderId ? folderId : "root"].set((v) =>
                [
                  ...v,
                  ...(r?.data?.payload?.folders.map(mappedDataToTreeView) ||
                    []),
                  ...(r?.data?.payload?.files.map(mappedDataToTreeView) || []),
                ].reduce((arr, item) => {
                  if (!arr.find((a) => a.id === item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, [])
              );

              detailSearch.offset_folder_number.set(
                (v) => v + r?.data?.payload?.folders.length
              );
              detailSearch.offset_file_number.set(
                (v) => v + r?.data?.payload?.files.length
              );
            } else {
              detailSearch.page_number.set((v) => 1);
              detailSearch.offset_folder_number.set((v) => 0);
              detailSearch.offset_file_number.set((v) => 0);
            }
            if (
              r?.data?.payload?.folders.length === 0 &&
              r?.data?.payload?.files.length === 0
            ) {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length || 0
              );
            } else {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length + folderDetailList.page_size.get({ stealth: true })
              );
            }
          })
          .finally(() => scrollLoading.set(false));
        break;
      default:
        isCompanyFolder.set(false);
        isTrashFolder.set(false);
        scrollLoading.set(true);
        isEnableSelectAble.set(true);
        if (
          !driveFoldersDetail.data[folderId ? folderId : "root"]?.get({
            stealth: true,
          })
        ) {
          driveFoldersDetail.data.merge({
            [folderId ? folderId : "root"]: [],
          });
        }
        getFoldersDetail(
          folderId,
          detailSearch.get({ stealth: true, noproxy: true })
        )
          .then((r) => {
            breabCrumbState.set((v) => {
              return [
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...r.data.payload?.parent?.map(mappedDataToTreeView).reverse(),
              ];
            });

            if (
              r?.data?.payload?.child?.folders.length > 0 ||
              r?.data?.payload?.child?.files.length > 0
            ) {
              const dataRoot = driveTree
                .get({ stealth: true, noproxy: true })
                .filter((a) => a.parent === "drive");
              folderDetailList.data[folderId ? folderId : "root"].set((v) =>
                [
                  ...(!folderId ? dataRoot : []),
                  ...v,
                  ...(r?.data?.payload?.child?.folders.map(
                    mappedDataToTreeView
                  ) || []),
                  ...(r?.data?.payload?.child?.files.map(
                    mappedDataToTreeView
                  ) || []),
                ].reduce((arr, item) => {
                  if (!arr.find((a) => a.id === item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, [])
              );

              detailSearch.offset_folder_number.set(
                (v) => v + r?.data?.payload?.child?.folders.length
              );
              detailSearch.offset_file_number.set(
                (v) => v + r?.data?.payload?.child?.files.length
              );
            } else {
              detailSearch.page_number.set((v) => 1);
              detailSearch.offset_folder_number.set((v) => 0);
              detailSearch.offset_file_number.set((v) => 0);
            }
            if (
              r?.data?.payload?.child?.folders.length === 0 &&
              r?.data?.payload?.child?.files.length === 0
            ) {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length || 0
              );
            } else {
              folderDetailList.total.set(
                folderDetailList.get({ steath: true, noproxy: true }).data[
                  folderId ? folderId : "root"
                ]?.length + folderDetailList.page_size.get({ stealth: true })
              );
            }
          })
          .finally(() => scrollLoading.set(false));
        break;
    }
    // let page = completeFolderSearch.page_number.get({ stealth: true });

    // submitSearch(findIdx, page);
  }

  function recycleOverwrite(e, reason, isOk) {
    if (reason === "backdropClick") recycleConfirmDialog.open.set(false);
    if (isOk) {
      onRecycle(true);
      recycleConfirmDialog.open.set(false);
      recycleConfirmDialog.message.set(
        t(
          "common:drive.dialog.message.confirm.recycle",
          "is exist ! Do you want to overwrite it ?"
        )
      );
      return;
    }
    recycleConfirmDialog.open.set(false);
    recycleConfirmDialog.message.set(
      t(
        "common:drive.dialog.message.confirm.recycle",
        "is exist ! Do you want to overwrite it ?"
      )
    );
  }

  function onRecycle(overwrite) {
    const currentContext = currentRowSelectContextMenu.get({ stealth: true });
    const selectList =
      selectedListFile.get({ stealth: true }).length > 0
        ? selectedListFile.get({ stealth: true })
        : [currentContext];
    driveFileInfo.open.set(false);
    const datas = selectList.reduce(
      (arr, item) => {
        if (item.document_id) {
          arr.document_id_list.push(item.document_id);
          arr.original_file.push(item.id);
        } else if (item.extension === "folder") {
          arr.folders.push(item.id);
        } else {
          arr.files.push(item.id);
        }
        return arr;
      },
      {
        folders: overwrite
          ? recycleConfirmDialog.existing_folders_name.get({
              stealth: true,
              noproxy: true,
            })
          : [],
        original_file: [],
        files: overwrite
          ? recycleConfirmDialog.existing_files_name.get({
              stealth: true,
              noproxy: true,
            })
          : [],
        document_id_list: [],
      }
    );
    recycleTrash({
      drive_file_id_list: datas.files,
      drive_folder_id_list: datas.folders,
      document_id_list: datas.document_id_list,
      overwrite: overwrite || false,
      ...(overwrite
        ? {
            existing_folders_name:
              recycleConfirmDialog.existing_folders_name.get({
                stealth: true,
                noproxy: true,
              }),
            existing_files_name: recycleConfirmDialog.existing_files_name.get({
              stealth: true,
              noproxy: true,
            }),
          }
        : {}),
    })
      .then((r) => {
        if (
          r.data.payload.existing_files_name.length > 0 ||
          r.data.payload.existing_folders_name.length > 0
        ) {
          recycleConfirmDialog.existing_files_name.set(
            r.data.payload.existing_files_name.map((a) => a.drive_file_id)
          );
          recycleConfirmDialog.existing_folders_name.set(
            r.data.payload.existing_folders_name.map((a) => a.drive_folder_id)
          );
          recycleConfirmDialog.message.set(
            (v) =>
              `${[
                ...r.data.payload.existing_folders_name,
                ...r.data.payload.existing_files_name,
              ]
                .map((a) => a.name)
                .join(", ")} ${v}`
          );
          recycleConfirmDialog.open.set(true);
        } else {
          folderDetailList.data[folderId ? folderId : "root"].set((v) => {
            return v.filter(
              (a) =>
                !datas.folders.includes(a.id) &&
                !datas.files.includes(a.id) &&
                !datas.original_file.includes(a.id)
            );
          });
        }
      })
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          type: "error",
          message: `${t(
            "common:drive.message.error.recycle",
            "Recycle error please try again!"
          )} ${e?.response?.data?.message || e}`,
        });
      });
    globalAnchorContext.set(null);
    // .finally(() => confirmDialog.loading.set(false));
  }

  function handleDragStart(e, _item, ref) {
    // console.log(e);
    if (
      ([1, 2, 3, 4, 5, 6, 7].includes(_item?.type) &&
        _item.extension === "folder") ||
      fromDocumentSelect
    ) {
      return;
    }
    isDraggingGlobal.set(true);
    isTableDragging.set(true);
    e.dataTransfer.setData(
      "text",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
    // console.log(tableRef);
    const listSelected = selectedListFile.get({ stealth: true, noproxy: true });
    const mapSelected = listSelected.map((a) => {
      return renderToString(
        <DriveDragPreview
          monitorProps={{
            ref: document.querySelector,
            item: a,
          }}
        />
      );
    });
    if (listSelected.length > 1) {
      dragTarget.set(listSelected);
      const doc = document.createElement("div");
      doc.innerHTML = mapSelected.join("");
      doc.id = "drag-ghost";
      doc.textNode = "Dragging";
      doc.style.position = "absolute";
      doc.style.top = "-1000px";
      document.body.appendChild(doc);
      e.dataTransfer.setDragImage(doc, 0, 0);
    } else {
      selectedListFile.set([{ ..._item }]);
      dragTarget.set([_item]);
      const doc = document.createElement("div");
      doc.innerHTML = [_item]
        .map((a) => {
          return renderToString(
            <DriveDragPreview
              monitorProps={{
                ref: document.querySelector,
                item: a,
              }}
            />
          );
        })
        .join("");
      doc.id = "drag-ghost";
      doc.textNode = "Dragging";
      doc.style.position = "absolute";
      doc.style.top = "-1000px";
      document.body.appendChild(doc);
      e.dataTransfer.setDragImage(doc, 0, 0);
    }
    // e.dataTransfer;
  }

  function handleDragLeave(e, _item) {
    // getAllFileEntries(e.dataTransfer.items).then((r) => console.log(r));
    e.dataTransfer.setData(
      "text",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
  }

  function handleDragEnd(e, _item) {
    console.log(e);
    dropTarget.set(-1);
    e.dataTransfer.setData(
      "types",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
    e.dataTransfer.setData(
      "text",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
    document.querySelector("#drag-ghost")?.remove();
    isDraggingGlobal.set(false);
    driveFileInfo.open.set(false);
    dragTargetState.set([]);
  }

  function handleDragOver(e, _item, ok) {
    // dropTarget.set(-1);
    const dt = e?.dataTransfer;

    if (
      dt.types &&
      (dt.types.indexOf
        ? dt.types.indexOf("Files") != -1
        : dt.types.contains("Files"))
    ) {
      dropTarget.set(-1);
    }
    if (_item?.extension !== "folder" || _item?.document_id) return;
    // console.log(e, _item);
    dropTarget.set(_item.id);
    e.dataTransfer.setData(
      "text",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
  }

  function handleDrop(e, _item) {
    // console.log(e?.dataTransfer, _item);
    dropTarget.set(-1);
    // console.log(_item, e, dragTargetState.get({ stealth: true }));
    // if (!_item?.document_id && !_item.format_type !== 'folder') return;
    isTableDragging.set(false);
    if (_item?.extension !== "folder" || _item?.document_id) {
      setTimeout(() => {
        dragTarget.set([]);
      }, 1);
      return;
    }
    e.dataTransfer.setData(
      "text",
      JSON.stringify({
        data: _item,
        type: "tableDrag",
      })
    );
    if (
      !dragTargetState
        .get({ stealth: true, noproxy: true })
        .map((a) => a.id)
        .includes(_item.id)
    ) {
      const moveOrCopyList = dragTargetState
        .get({ stealth: true, noproxy: true })
        .reduce(
          (arr, item) => {
            if (item?.document_id) {
              arr.document_id_list.push(item.document_id);
            } else if (item.format_type === "folder") {
              arr.drive_folder_id_list.push(item.id);
            } else {
              arr.drive_file_id_list.push(item.id);
            }
            return arr;
          },
          {
            document_id_list: [],
            drive_file_id_list: [],
            drive_folder_id_list: [],
          }
        );
      moveItem(moveOrCopyList, _item.id || null).then((r2) => {
        if (!r2) return;
        driveFoldersDetail?.data[!folderId ? "root" : folderId]?.set((v) => {
          return v?.filter(
            (a) =>
              !moveOrCopyList.drive_folder_id_list.includes(a.id) &&
              !moveOrCopyList.drive_file_id_list.includes(a.id)
          );
        });

        const tree = driveTree.get({ stealth: true, noproxy: true });
        // if (treeIdx > -1) {
        // }
        driveTree.forEach((a) => {
          if (tree.find((b) => b.parent_id == a?.get({ steath: true })?.id)) {
            a.set((c) => {
              return {
                ...c,
                is_empty: 0,
                notShowDropDownIcon: 0,
              };
            });
          } else {
            a.set((c) => {
              return {
                ...c,
                notShowDropDownIcon: c.is_empty,
              };
            });
          }
        });
        getFoldersTree({
          page_number: 1,
          page_size: 50,
          parent_id: _item.id === "drive" ? "null" : _item.id,
        }).then((r) => {
          if (r?.data?.payload?.length > 0) {
            driveTree.set((v) => {
              const newData = [
                ...v?.filter((a) => {
                  const condition =
                    !moveOrCopyList.drive_folder_id_list.includes(a.id);
                  if (!condition) {
                    const oldParent = v.findIndex((b) => b.id === a.parent_id);
                    if (oldParent > -1) {
                      v[oldParent].size = v[oldParent].size - 1;
                      if (
                        v.filter((c) => c.parent_id === a.parent_id)?.length < 2
                      ) {
                        v[oldParent]["notShowDropDownIcon"] = 1;
                        v[oldParent]["is_empty"] = 1;
                      }
                    }
                  }
                  return condition;
                  // return !moveOrCopyList.drive_folder_id_list.includes(a.id);
                }),
                ...r.data.payload.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (!arr.find((b) => b.id == item.id)) {
                  arr.push(item);
                }
                return arr;
              }, []);
              // console.log(r?.data?.payload);
              return newData;
            });
          }
        });
        driveFileInfo.open.set(false);
        if (searchObject.isSearching.get({ stealth: true })) {
          searchObject.offset_document_number.set(0);
          searchObject.offset_folder_number.set(0);
          searchObject.offset_file_number.set(0);
          searchObject.isStop.set(0);
          detailSearch.offset_folder_number.set((v) => 0);
          detailSearch.offset_file_number.set((v) => 0);
          return searchContentFolder();
        }
      });
    }

    setTimeout(() => {
      dragTarget.set([]);
    }, 1);
  }

  function onDoubleClickRow(ev, rowSelect) {
    const row = { ...rowSelect };
    // console.log(row);
    expandNodeState.merge(+row.parent_id);
    expandNodeState.merge(row.id);
    if (
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.metaKey ||
      isTrashFolder.get({ stealth: true })
    ) {
      return;
    }
    if (row.format_type === "trash" && !fromDocumentSelect) {
      getFoldersTree({
        page_number: 1,
        page_size: 50,
        parent_id: row.id,
      }).then((r) => {
        if (r?.data?.payload?.length > 0) {
          driveTree.set((v) => {
            const newData = [
              ...v,
              ...r.data.payload.map(mappedDataToTreeView),
            ].reduce((arr, item) => {
              if (item.id == row.id) {
                item["is_empty"] = false;
              }
              if (!arr.find((b) => b.id == item.id)) {
                arr.push(item);
              }
              return arr;
            }, []);
            return newData;
          });
        }
      });
      driveFileDrawer.open.set(false);
      driveFileDrawer.dataDetail.set({});
      navigate.push(
        `/folders/${row.id}?type=${mappedTypeToQuery(row.type)}${
          [1, 2, 3, 4].includes(row.type) ? `&function_type=${row.type}` : ""
        }`
      );
    }
    detailSearch.page_number.set(1);
    if (
      (row.format_type === "folder" || row?.extension === "folder") &&
      !fromDocumentSelect
    ) {
      if (row.type !== 9 && row.type !== 6) {
        getFoldersTree({
          page_number: 1,
          page_size: 50,
          parent_id: row.parent_id,
        }).then((r) => {
          if (r.data.payload.length > 0) {
            driveTree.set((v) => {
              const newData = [
                ...v,
                ...r.data.payload.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (!arr.find((b) => b.id == item.id)) {
                  arr.push(item);
                }
                return arr;
              }, []);
              return newData;
            });
          }
        });
      } else {
        getCompanyFolders({
          drive_folder_id: row.parent_id,
          page_number: 1,
          page_size: 100,
          offset_folder_number: 0,
          search: "",
        }).then((r) => {
          if (r?.data?.payload?.length > 0) {
            driveTree.set((v) => {
              const newData = [
                ...v,
                ...r.data.payload.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (!arr.find((b) => b.id == item.id)) {
                  arr.push(item);
                }
                return arr;
              }, []);
              return newData;
            });
          }
        });
        // console.log(breabCrumbState.get({ stealth: true }));
      }

      currentFolderTypeState.set(row.type);
      // console.log(row);
      // .finally(() => loadingTreeIndex.set(-1));

      driveFileDrawer.open.set(false);
      driveFileDrawer.dataDetail.set({});
      navigate.push(
        `/folders/${row.id}?type=${mappedTypeToQuery(row.type)}${
          [1, 2, 3, 4].includes(row.type) ? `&function_type=${row.type}` : ""
        }`
      );
      return;
    } else {
      currentFolderTypeState.set(row.type);
      if (searchObject.isSearching.get({ stealth: true })) {
        // return;
      } else {
        if (row.type === 9) {
          // breabCrumbState.set([]);
          driveDetailLoad.set(true);
          currentSelectedCompanyFolder.set(row.id);
          detailSearch.page_number.set(1);
          detailSearch.offset_folder_number.set(0);
          // console.log(driveNavCompanyTree.get({ stealth: true }));

          getCompanyFolders({
            drive_folder_id: row.id || null,
            ...detailSearch.get({ stealth: true, noproxy: true }),
          })
            .then((r) => {
              selectedFoldersCompany.set(null);
              driveNavCompanyTree.set((v) => {
                const newData = [
                  ...v,
                  ...r.data.payload.map((a) => mappedDataToTreeView(a)),
                  ...[
                    {
                      ...row,
                      notShow: true,
                    },
                  ].map((a) => mappedDataToTreeView(a)),
                ].reduce((arr, item) => {
                  if (!arr.find((b) => b.id == item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
                return newData;
              });
              folderDetailList?.data?.merge({
                ["company" ? "company" : "root"]: r?.data?.payload
                  ?.filter((a) => !a.document_id)
                  .map(mappedDataToTreeView),
              });
              if (fromDocumentSelect) {
                breabCrumbState.set((v) => {
                  // const dataBreab =
                  return [
                    ...v,
                    {
                      ...row,
                    },
                  ].map((b) => ({ ...b, isCompany: true }));
                });
              }
            })
            .finally(() => driveDetailLoad.set(false));
        }
      }
    }
    if (fromDocumentSelect) return;
    // console.log(row);)
    previewDialog.row.set(row);
    switch (row.format_type) {
      case "image":
        previewDialog.fileType.set(row.format_type);
        previewDialog.fileName.set(row.name);
        previewDialog.open.set(true);
        previewDialog.fileUrl.set("");

        generatDownloadPresignedUrl([parseUrlToObjectKey(row.url).key])
          .then((r) => {
            // console.log(r);
            previewDialog.fileUrl.set(r.data.payload[0]);
            // viewUrl.set(r.data.paylo/ad[0]);
          })
          .catch((e) => {
            previewDialog.fileUrl.set("error");
          });
        break;
      case "pdf":
        previewDialog.fileType.set(row.format_type);
        previewDialog.fileUrl.set("");
        generatDownloadPresignedUrl([parseUrlToObjectKey(row.url).key])
          .then((r) => {
            // console.log(r);

            previewDialog.fileUrl.set(r.data.payload[0]);
            // viewUrl.set(r.data.paylo/ad[0]);
          })
          .catch((e) => {
            previewDialog.fileUrl.set("error");
          });
        previewDialog.fileName.set(row.name);
        previewDialog.row.set(row);
        previewDialog.open.set(true);
        break;
      case "zip":
        previewDialog.fileType.set(row.format_type);
        previewDialog.fileUrl.set("");
        previewDialog.row.set(row);
        getFilesPreview({
          document_id: row?.document_id,
          drive_file_id: row?.id,
        })
          .then((r) => {
            const files = r.data.payload.files;
            const filesSort = r.data.payload.files_sort ?? [];
            const fileArray = filesSort.map((item) => ({
              ...files[item],
            }));

            previewDialog.row.set((prev) => {
              return {
                ...prev,
                files: files,
              };
            });

            previewDialog.fileUrl.set(fileArray[0].url);
            previewDialog.files.set(files);
            previewDialog.files_sort.set(filesSort);
          })
          .catch((e) => {
            previewDialog.fileUrl.set("error");
          });
        previewDialog.fileName.set(row.name);
        previewDialog.open.set(true);
        break;
      default:
        previewDialog.open.set(true);
        break;
    }
  }

  const headerActionComp = (
    <div className="drive_table_header_action">
      <div className="folder_header_search">
        <DriveSearch
          className="search-round-container-drive"
          handleSearchKeyEnter={handleSearchKeyEnter}
          searchValue={detailSearch.search.get({ noproxy: true })}
          handleChangeSearch={handleChangeSearch}
          handleClearSearch={handleClearSearch}
          placeholder={t("common:drive.search.placeholder")}
          // ファイルおよびフォルダを検索
        />
        <DriveActionsBtn
          downloadButtonCallback={
            selectedListFile.get({ noproxy: true }).length > 0 &&
            !isTrashFolder.get() &&
            !fromDocumentSelect
              ? onClickDownload
              : undefined
          }
          shareButtonCallback={
            ((selectedListFile.get({ noproxy: true }).length > 0 &&
              isEnableKeyHoverEdit.get()) ||
              (selectedListFile.get({ noproxy: true }).length > 0 &&
                !folderId)) &&
            !isTrashFolder.get() &&
            !fromDocumentSelect
              ? onClickShare
              : undefined
          }
          recycleCallback={
            selectedListFile.get({ noproxy: true }).length > 0 &&
            isTrashFolder.get()
              ? () => onRecycle()
              : undefined
          }
          isCompanyFolder={fromDocumentSelect}
          deleteButtonCallback={
            (selectedListFile.get({ noproxy: true }).length > 0 &&
              isEnableKeyHoverEdit.get() &&
              !fromDocumentSelect) ||
            (selectedListFile.get({ noproxy: true }).length > 0 &&
              !folderId &&
              !fromDocumentSelect)
              ? () => {
                  if (
                    selectedListFile
                      .get({ stealth: true })
                      .some(
                        (v) =>
                          [1, 2, 3, 4, 5, 6, 7, 11].includes(v?.type) &&
                          v.extension === "folder"
                      )
                  ) {
                    showAlertWarning.message.set(
                      t(
                        "common:drive.message.error.cant_delete_default",
                        "You cannot delete default folder (Company folder, private folder, complete folder, trash folder)"
                      )
                    );
                    showAlertWarning.open.set(true);
                    return;
                  }
                  const currentContext = currentRowSelectContextMenu.get({
                    stealth: true,
                  });
                  // const selectList = (
                  //   selectedListFile.get({ stealth: true }).length > 0
                  //     ? selectedListFile.get({ stealth: true })
                  //     : [currentContext]
                  // ).reduce((arr, item) => {
                  //   if (item.extension === "folder") {
                  //     arr.push(item.id);
                  //   }
                  //   return arr;
                  // }, []);
                  if (
                    (selectedListFile.get({ stealth: true }).length > 0
                      ? selectedListFile.get({ stealth: true })
                      : [currentContext]
                    ).some((a) => a.size > 0 && a.extension === "folder")
                  ) {
                    confirmDialog.message.set(
                      t(
                        "common:drive.delete.warning.have_file",
                        "このフォルダの中にはファイルまたはフォルダが含まれています。本当に削除しますか？"
                      )
                    );
                  } else {
                    confirmDialog.message.set(
                      t("common:drive.delete_confirm.message")
                    );
                  }
                  confirmDialog.merge({ callback: onClickDelete });
                  confirmDialog.title.set(
                    `${t("common:drive.delete_confirm.title")}`
                  );
                  // confirmDialog.message.set(
                  //   t("common:drive.delete_confirm.message")
                  // );
                  confirmDialog.open.set(true);
                  return;
                }
              : undefined
          }
          createButtonCallback={
            (isEnableKeyHoverEdit.get() || !folderId) && !isTrashFolder.get()
              ? onClickCreate
              : null
          }
        />
      </div>
      <div className="folder_header_breadcrumb">
        {searchObject.isSearching.get() ? (
          <div className="drive_search_result_breab">
            <MaterialSymbolIcon
              disableInteractive={true}
              placement="top"
              tooltip={t("common:drive.tooltip.go_back", "Back to folder")}
              onClick={(e) => {
                searchObject.isSearching.set(0);
                searchObject.offset_document_number.set(0);
                searchObject.offset_folder_number.set(0);
                searchObject.offset_file_number.set(0);
                searchObject.isStop.set(0);
                searchObject.search.set("");
                detailSearch.offset_folder_number.set((v) => 0);
                detailSearch.offset_file_number.set((v) => 0);
                detailSearch.search.set("");
                if (!fromDocumentSelect) {
                  navigate.push({
                    pathname: pathname,
                    search:
                      "?" +
                      new URLSearchParams(
                        parseQuery(search)?.function_type
                          ? {
                              type: parseQuery(search)?.type,

                              function_type: parseQuery(search)?.function_type,
                            }
                          : {
                              type: parseQuery(search)?.type,
                            }
                      ).toString(),
                  });
                }
                submitSearch("");
              }}
              iconName={"arrow_back"}
            />
            <span>{t("common:drive.text.search_result", "Search result")}</span>
          </div>
        ) : (
          <DriveBreadcrumb />
        )}
      </div>
    </div>
  );
  const currentSelectedCompanyFolder = useHookstate(
    currentSelectedCompanyFolderState
  );
  const driveFileInfo = useHookstate(driveFileDrawer);

  useEffect(() => {
    // console.log(fromDocumentSelect);
    if (fromDocumentSelect) {
      isCompanyFolder.set(true);
    }
  }, [fromDocumentSelect]);

  useEffect(() => {
    // console.log()
    if (search) {
      const parse = parseQuery(search || "");
      if (parse?.isSearching === "1") {
        Object.keys(parse).forEach((a) => {
          if (a !== "search" && a !== "type") {
            parse[a] = isNaN(+parse[a])
              ? parse[a].replaceAll("+", " ")
              : +parse[a];
            console.log(a, parse[a]);
          }
        });
        searchObject.set((v) => {
          return {
            ...parse,
          };
        });
        console.log(parse);
        searchContentFolder(parse?.isStop);
        setTimeout(() => {
          detailSearch.search.set(parse.search);
        }, 1000);
      } else {
        searchObject.isSearching.set(0);
        searchObject.offset_document_number.set(0);
        searchObject.offset_folder_number.set(0);
        searchObject.offset_file_number.set(0);
        searchObject.isStop.set(0);
        searchObject.search.set("");
        detailSearch.offset_folder_number.set((v) => 0);
        detailSearch.offset_file_number.set((v) => 0);
        detailSearch.search.set("");
        // if (!fromDocumentSelect) {
        //   navigate.push({
        //     pathname: pathname,
        //     search:
        //       "?" +
        //       new URLSearchParams({
        //         type: parseQuery(search)?.type,
        //       }).toString(),
        //   });
        // }
        // if (isTrashFolder.get({ stealth: true })) return;
        // submitSearch("");
      }
    }
  }, [search]);

  useEffect(() => {
    // folderDetailList.data.set([]);
    isCompanyFolder.set(false);
    openShareDialog.set(false);
    folderDetailSearchList.set([]);
    if (!search.includes("isSearching")) {
      searchObject.isSearching.set(0);
      searchObject.offset_document_number.set(0);
      searchObject.offset_folder_number.set(0);
      searchObject.offset_file_number.set(0);
      searchObject.isStop.set(0);
      detailSearch.offset_folder_number.set((v) => 0);
      detailSearch.offset_file_number.set((v) => 0);
    }
    if (folderId) {
      detailSearch.search.set("");
      detailSearch.offset_folder_number.set((v) => 0);
      detailSearch.offset_file_number.set((v) => 0);
      submitSearch();
    } else {
      if (!folderId) {
        const dataRoot = driveTree
          .get({ stealth: true, noproxy: true })
          .filter((a) => a.parent === "drive");
        isEnableKeyHoverEdit.set(true);
        folderDetailList.data.merge(
          {
            root: dataRoot,
          }
          // data.map((a, i) => ({ ...a, id: i }))
        );
        folderDetailList.total.set(dataRoot.length || 0);
        driveDetailLoad.set(false);
        const folderIndex = driveTree.value.findIndex(
          (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
        );
        detailSearch.offset_folder_number.set((v) => 0);
        detailSearch.offset_file_number.set((v) => 0);
        detailSearch.search.set("");
        submitSearch(folderIndex);
      }
      isCompleteFolder.set(false);
    }
    dropTarget.set(-1);
  }, [folderId]);

  useEffect(() => {
    orderList.set(() => [
      "name asc",
      "updated_date desc",
      "size asc",
      "extension asc",
      "memo asc"
    ]);
    order.set((v) => ({
      name: "asc",
      updated_date: "desc",
      size: "asc",
      extension: "asc",
      memo: "asc"
    }));
    detailSearch.search.set("");
    // console.log(order);
  }, [folderId]);

  return (
    <>
      <DriveFileInfo
        open={driveFileInfo.open.get()}
        dataDetail={driveFileInfo.dataDetail.get({ noproxy: true })}
        onClose={() => driveFileInfo.open.set(false)}
      />
      <BaseDialogDriveCrud
        handleClose={() => showAlertWarning.open.set(false)}
        open={showAlertWarning.open.get()}
        title={showAlertWarning.title.get()}
        dialogContentComponent={
          <Typography variant="body1">
            {showAlertWarning.message.get()}
          </Typography>
        }
        dialogActionComponent={
          <>
            <Button
              variant="outlined"
              onClick={() => showAlertWarning.open.set(false)}
            >
              {t("common:drive.button.close")}
              {/* 閉じる */}
            </Button>
          </>
        }
      />
      <DriveConfirmDialog
        {...confirmDialog.get({ noproxy: true })}
        onClose={onConfirmDialogClose}
      />
      <DriveConfirmDialog
        {...recycleConfirmDialog.get({ noproxy: true })}
        onClose={recycleOverwrite}
      />
      <DriveEditFolderDialog
        data={editClickState.data.get({ noproxy: true })}
        open={editClickState.openDialog.get({ noproxy: true })}
        onClose={() => editClickState.openDialog.set(false)}
      />
      <DriveUploadDialog
        open={openUploadDialog.get({ noproxy: true })}
        type={uploadDialogType.get({ noproxy: true })}
        currentFolderId={folderId}
        folderType={currentFolderType.get()}
        onClose={() => openUploadDialog.set(false)}
      />
      <DriveCreateFolderDialog
        currentParentId={folderId}
        fromDocumentSelect={fromDocumentSelect}
        currentSelectedCompanyFolder={currentSelectedCompanyFolder.get()}
        folderType={currentFolderType.get()}
        open={openCreateFolderDialog.get({ noproxy: true })}
        onClose={() => openCreateFolderDialog.set(false)}
      />
      <DriveMoveCopyDialog
        folderType={currentFolderType.get()}
        isCompleteFolder={isCompleteFolder.get({ noproxy: true })}
        selectedList={
          selectedListFile.get({ noproxy: true })?.length > 0
            ? selectedListFile.get({ noproxy: true })
            : currentRowSelectContextMenu.get({ noproxy: true })
            ? [currentRowSelectContextMenu.get({ noproxy: true })]
            : []
        }
        folderId={folderId}
        open={openMoveCopyDialog.get({ noproxy: true })}
        onClose={() => {
          openMoveCopyDialog.set(false);
          currentRowSelectContextMenu.set(null);
        }}
      />
      <DriveShareDialog
        isDragging={isDraggingGlobal.get()}
        open={openShareDialog.get({ noproxy: true })}
        onClose={() => openShareDialog.set(false)}
        selectedItems={selectedListFile.get({ noproxy: true })}
      />
      <Drawer
        anchor={"right"}
        className="safari_fix_drawer"
        open={previewDialog.open.get({ noproxy: true })}
        onClose={() => {
          previewDialog.open.set(false);
        }}
      >
        <DrivePreviewDialog
          handleClose={() => {
            previewDialog.set({
              open: false,
              files: [],
              data: {},
              fileType: "",
              fileUrl: "",
              fileName: "",
              row: {},
            });
          }}
          open={previewDialog.open.get({ noproxy: true })}
          files={previewDialog.files.get({ noproxy: true })}
          files_sort={previewDialog.files_sort.get({ noproxy: true })}
          fileUrl={previewDialog.fileUrl.get({ noproxy: true })}
          row={previewDialog.row.get({ noproxy: true })}
          fileName={previewDialog.fileName.get({ noproxy: true })}
          fileType={previewDialog.fileType.get({ noproxy: true })}
        />
      </Drawer>
      {searchObject.isSearching.get() ? (
        // here is base table for search UI
        <BaseTable
          loading={driveDetailLoad.get({ noproxy: true })}
          enableSelectable={isEnableSelectAble.get({ noproxy: true })}
          selectRowKey={"id"}
          enableVirtualScrolled={true}
          endReached={onEndReached}
          overscan={200}
          isCompleteFolder={isCompleteFolder.get()}
          dragAndDropRow={{
            enable: true,
            handleDragStart,
            handleDragLeave,
            handleDragEnd,
            handleDragOver,
            handleDrop,
            dropTarget,
            dragTarget,
          }}
          scrollLoading={scrollLoading.get()}
          totalRecord={searchObject.total.get()}
          selectedRows={selectedListFile.get({ noproxy: true })}
          isShowTableDragFile={isShowTableDrag.get({ noproxy: true })}
          onFileDragEnter={onFileDragEnter}
          onFileDragLeave={onFileDragLeave}
          handleContextMenu={handleContextMenu}
          onFileDragOver={onFileDragOver}
          keyHoverEdit={
            isEnableKeyHoverEdit.get({ noproxy: true }) &&
            !isTrashFolder.get() &&
            !fromDocumentSelect
              ? "name"
              : undefined
          }
          isCompanyFolder={fromDocumentSelect}
          onEditClick={onEditClick}
          onCheckSelectedAll={onCheckSelectedAll}
          onHoldShiftMultiSelect={onHoldShiftMultiSelect}
          onHoldControlMultiSelect={onHoldControlMultiSelect}
          onFileDrop={onFileDrop}
          headerActionsComponent={headerActionComp}
          order={order.get({ noproxy: true })}
          tableHeader={[
            ...tableDriveHeader,
            {
              key: "parents",
              title: t("common:drive.table_header.folder_location", "Location"),
              type: "parseJson",
              // width: "30%",
              sort: true,
            },
          ]}
          tableRows={folderDetailSearchList.get({ noproxy: true })}
          onDblClickRowHandle={onDoubleClickRow}
          onClickRowHandle={onClickRow}
        />
      ) : (
        <BaseTable
          loading={driveDetailLoad.get({ noproxy: true })}
          enableSelectable={isEnableSelectAble.get({ noproxy: true })}
          selectRowKey={"id"}
          enableVirtualScrolled={true}
          isCompleteFolder={isCompleteFolder.get()}
          endReached={onEndReached}
          overscan={200}
          dragAndDropRow={{
            enable: true,
            handleDragStart,
            handleDragLeave,
            handleDragEnd,
            handleDragOver,
            handleDrop,
            dropTarget,
            dragTarget,
          }}
          scrollLoading={scrollLoading.get()}
          totalRecord={folderDetailList.total.get()}
          selectedRows={selectedListFile.get({ noproxy: true })}
          isShowTableDragFile={isShowTableDrag.get({ noproxy: true })}
          onFileDragEnter={onFileDragEnter}
          onFileDragLeave={onFileDragLeave}
          handleContextMenu={handleContextMenu}
          onFileDragOver={onFileDragOver}
          keyHoverEdit={
            isEnableKeyHoverEdit.get({ noproxy: true }) &&
            !isTrashFolder.get() &&
            !fromDocumentSelect
              ? "name"
              : undefined
          }
          isCompanyFolder={fromDocumentSelect}
          onEditClick={onEditClick}
          onCheckSelectedAll={onCheckSelectedAll}
          onHoldShiftMultiSelect={onHoldShiftMultiSelect}
          onHoldControlMultiSelect={onHoldControlMultiSelect}
          onFileDrop={onFileDrop}
          headerActionsComponent={headerActionComp}
          order={order.get({ noproxy: true })}
          tableHeader={tableDriveHeader}
          tableRows={
            folderDetailList.data.get({ noproxy: true })[
              folderId ? folderId : fromDocumentSelect ? "company" : "root"
            ]
          }
          onDblClickRowHandle={onDoubleClickRow}
          onClickRowHandle={onClickRow}
        />
      )}

      {isCompleteFolder.get() || isTrashFolder.get() ? null : (
        <Menu
          open={globalAnchorContext.get({ noproxy: true }) !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            globalAnchorContext.get({ noproxy: true }) !== null
              ? {
                  top: globalAnchorContext.get({ noproxy: true }).mouseY,
                  left: globalAnchorContext.get({ noproxy: true }).mouseX,
                }
              : undefined
          }
        >
          {isCompleteFolder.get() ? null : (
            <MenuItem
              onClick={(e) => {
                return (isEnableKeyHoverEdit.get() || !folderId) &&
                  !isTrashFolder.get()
                  ? onClickCreate(e, "uploadFile")
                  : null;
              }}
            >
              <MaterialSymbolIcon iconName={"upload_file"} />
              {t("common:drive.button.menu.upload_file")}
              {/* ファイルアップロード */}
            </MenuItem>
          )}
          {isCompleteFolder.get() ? null : (
            <MenuItem
              onClick={(e) => {
                return (isEnableKeyHoverEdit.get() || !folderId) &&
                  !isTrashFolder.get()
                  ? onClickCreate(e, "uploadFolder")
                  : null;
              }}
            >
              <MaterialSymbolIcon iconName={"drive_folder_upload"} />
              {t("common:drive.button.menu.upload_folder")}
              {/* フォルダアップロード */}
            </MenuItem>
          )}
          {isCompleteFolder.get() ? null : (
            <MenuItem
              onClick={(e) => {
                return (isEnableKeyHoverEdit.get() || !folderId) &&
                  !isTrashFolder.get()
                  ? onClickCreate(e, "createFolder")
                  : null;
              }}
            >
              <MaterialSymbolIcon iconName={"folder"} />
              {t("common:drive.button.menu.create_folder")}
              {/* フォルダ作成 */}
            </MenuItem>
          )}
        </Menu>
      )}
      <Menu
        open={anchorContextMenu.get({ noproxy: true }) !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorContextMenu.get({ noproxy: true }) !== null
            ? {
                top: anchorContextMenu.get({ noproxy: true }).mouseY,
                left: anchorContextMenu.get({ noproxy: true }).mouseX,
              }
            : undefined
        }
      >
        {!isTrashFolder.get() && !fromDocumentSelect ? (
          <MenuItem
            onClick={(e) => {
              onClickDownload();
              return handleClose();
            }}
          >
            {t("common:drive.context_menu.download")}
            {/* ダウンロード */}
          </MenuItem>
        ) : null}
        {isTrashFolder.get() ? (
          <MenuItem
            onClick={(e) => {
              onRecycle();
              return handleClose();
            }}
          >
            {t("common:drive.context_menu.recycle")}
            {/* ダウンロード */}
          </MenuItem>
        ) : null}
        {(isEnableKeyHoverEdit.get() || isCompleteFolder.get()) &&
        !isTrashFolder.get() &&
        !fromDocumentSelect ? (
          <MenuItem
            onClick={(e) => {
              onCopyRow();
              return handleClose(true);
            }}
          >
            {t("common:drive.context_menu.move_copy")}
            {/* 移動またはコピー */}
          </MenuItem>
        ) : null}

        {(isEnableKeyHoverEdit.get() || !folderId) &&
        !isTrashFolder.get() &&
        !fromDocumentSelect ? (
          <MenuItem
            onClick={(e) => {
              onClickShare();
              return handleClose();
            }}
          >
            {t("common:drive.context_menu.share")}
            {/* シェア */}
          </MenuItem>
        ) : null}
        {(isEnableKeyHoverEdit.get() || !folderId) && !fromDocumentSelect ? (
          <MenuItem
            onClick={(e) => {
              const currentContext = currentRowSelectContextMenu.get({
                stealth: true,
              });
              const selectList =
                selectedListFile.get({ stealth: true }).length > 0
                  ? selectedListFile.get({ stealth: true })
                  : [currentContext];
              if (
                selectList.some((a) => a.size > 0 && a.extension === "folder")
              ) {
                confirmDialog.message.set(
                  t(
                    "common:drive.delete.warning.have_file",
                    "このフォルダの中にはファイルまたはフォルダが含まれています。本当に削除しますか？"
                  )
                );
              } else {
                confirmDialog.message.set(
                  t("common:drive.delete_confirm.message")
                );
              }
              confirmDialog.title.set(t("common:drive.delete_confirm.title"));
              confirmDialog.open.set(true);
              confirmDialog.merge({ callback: onClickDelete });
              return handleClose(true);
            }}
          >
            {isTrashFolder.get()
              ? t("common:drive.context_menu.delete_trash")
              : t("common:drive.context_menu.delete")}
            {/* ごみ箱 */}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
