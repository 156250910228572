import '../Assets/css/settings.css';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import OrganizationService from '../../Service/Organization';
import {withTranslation} from "react-i18next";
import {Form,} from 'react-bootstrap';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import logo from "../Assets/img/logo_figure.svg";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import List from '@mui/material/List';
import {userInfoState} from '../../drive-feature/data-access/state';
import {uploadToS3} from "../../drive-feature/data-access/api";
import GuestDetailPages from './Guests/GuestDetail';
import { groupMembersState } from './Users/GroupMemberChip';
import { hookstate } from '@hookstate/core';
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";
import DownloadIcon from "@mui/icons-material/Download";
import downloadFileUrl from '../../Utils/download-file';
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";

const approvalId = hookstate(null);
/** */
class Guest extends Layout {

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "guest";
		this.state.activeTab = 0;
		this.state.tabs = [
			{ "status": 0, "label": "common:settings.guest.active" },
			{ "status": 1, "label": "common:settings.guest.inactive" },
		];

		/* For Panigation */
		this.state.pagination = {
			total: 0,
			page_size: 100,
			page_number: 1,
		};

		/* For SearchBox */
		this.state.search = "";
		this.state.datalist = null;
		this.state.datalistLoading = true;
		this.state.organizations = null;

		this.state.fields = {};
		this.state.keyFields = {
			"family_name": "family_name",
			"first_name": "first_name",
			"family_name_kana": "family_name_kana",
			"first_name_kana": "first_name_kana",
			"company_name": "company_name",
			"mobile_number": "mobile",
			"email": "email",
			"approver_id": "approver_id",
		}

		this.state.user_info = null;
		this.state.approver_list = [];
		this.state.approver_list_pagination = {
			page_number: 1,
			page_size: 20,
			search: "",
		};
		this.state.approver_list_loading = false;
		this.state.fromCreate= false;
		this.state.showGuestDetail= false;
		this.state.guestDetail = null;
		this.state.enable_load_more_approver_list = true;
		this.state.approver_last_selection = JSON.parse(localStorage.getItem("approver_last_selection"));
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.guest.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {
		const user_info = userInfoState.get({noproxy:true});
		this.setState({ user_info });
		this.setStateFields();
		//
		if (
			user_info?.administrator_role == "0"
		) {
			this.handleGetInitialApproverList();
		}

		this.handleGetGuestList(user_info);

		/* */
		if (this.state.organizations === null) {
			OrganizationService.getList().then((response) => {

				/* */
				let { organizations } = this.state;

				/* */
				organizations = response || [];

				/* */
				this.setState({ organizations });

			}).catch((error) => {
				console.log("OrganizationService.getList.error", error);
			});
		}
	}

	componentDidUpdate = () => {
		/* */
	}

	handleGetGuestList = (user_info) => {
		/* */
		this.setState({
			datalistLoading: true,
		});
		let tab = this.state.activeTab;
		let {page_size, page_number} = this.state.pagination;
		console.log(page_number, page_size)
		/* */
		let data = {
			"tenant_id": null,
			"status": this.state.tabs[tab]['status'],
			"search": this.state.search,
			"page_size": page_size,
			"page_number": page_number,
		}

		if (
			user_info?.user_id &&
			user_info?.administrator_role == "0"
		) {
			data = {
				...data,
				"create_user": user_info?.user_id,
			};
		}

		/* */
		Service.getUserGuestList(data).then((response) => {

			/* */
			let { datalist, pagination } = this.state;

			/* */
			datalist = response.payload;

			/* */
			pagination.total = response.total;

			/* */
			this.setState({ datalist, pagination });

		}).catch((error) => {
			console.log(error.response);
		}).finally(() => {
			this.setState({
				datalistLoading: false,
			});
		});
	};

	handleChangeSearchGuest = (event) => {
		const user_info = userInfoState.get({noproxy:true});
		this.setState({
			search: event.target.value,
			pagination: {
				...this.state.pagination,
				page_number: 1,
			}
		}, () => {
			if (event.target.value.length === 0) {
				this.setState({
					total: 0,
					page_number: 1,
				})
				this.handleGetGuestList(user_info);
			}
		})
	};

	handleSearchGuestKeyEnter = (event) => {
		const { keyCode, target } = event;
		const user_info = userInfoState.get({noproxy:true});

		const { value: searchString } = target;

		if (keyCode === 13) {
			this.setState({
				search: searchString,
				total: 0,
				page_number: 1,
				pagination: {
					page_number: 1,
					page_size: 100
				}
			}, () => {
				// console.log(this.state)
				this.handleGetGuestList(user_info);
			})
		}
	};

	/** [Render] */
	render() {

		/* Search Function */
		let { t } = this.props;

		const user_info = userInfoState.get({noproxy:true});

		/* */
		const { Main, CsvFileUpload } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-guest" className="page-settings">
				<Suspense fallback={<></>}>
					<GuestDetailPages
					approver_id={approvalId}
					 activeTab={this.state.tabs[this.state.activeTab]['status']} fromCreate={this?.state?.fromCreate}
					showDetail={this?.state?.showGuestDetail} main={this} guestDetail={this.state.guestDetail} />
				</Suspense>

					{this.state.showGuestDetail ? null:<>{/* FilterTabs */}
					<div className="page-settings-header lz-flex">

						<div className="lz-flex-1">
							<div className="lz-m-10 lz-mb-0">
								<Button
									key="list-download1"
									sx={{ mr: 2, ml: 2 }}
									onClick={(ev) => {
										this.GuestListCsvFileDownload();
									}}
									startIcon={<DownloadIcon />}
									variant="outlined"
									size="small"
									disabled={!this.state.datalist}
								>
									{t('common:settings.general.list-download')}
								</Button>
								{user_info?.administrator_role !== "0" && (
									<Button
										key="list-download"
										variant="outlined"
										size='small'
										disabled={!this.state.datalist}
										startIcon={<UploadIcon/>}
										onClick={(ev) => {
											CsvFileUpload();
										}} className="btn-custom new-document">
										{t('common:settings.general.list-upload')}
									</Button>
								)}
							</div>
						</div>

						<div className="lz-flex-0">

							<div className="lz-m-10 lz-mb-0">
								<this.FilterTabs />
							</div>

						</div>

					</div>

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader lz-flex-column-sp">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								<div className="lz-flex flex-wrap">

									{/* Add-Guest Button */}

									<Button
										sx={{ borderRadius: 19 }}
										title="Action"
										variant="contained"
										className="btn-addGuest"
										startIcon={<AddIcon />}
										disabled={!this.state.datalist}
										onClick={() => { this.openGuestFormDialog() }}>
										<span>{t('common:settings.guest.add_guest')}</span>
									</Button>

									{/* Search-TextBox */}
									<div className="input-textbox">
										<input
											type="search"
											value={this.state.search}
											placeholder={t('common:settings.guest.search')}
											onChange={this.handleChangeSearchGuest}
											onKeyDown={this.handleSearchGuestKeyEnter}
										/>
									</div>

								</div>

							</div>

						</div>

						<div className="ki-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="body-wrapper-scroll">
						<this.IndexTable />
					</div>

					{/* BottomPagination */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div></>}


				</div>

			</Main>
		);

	}

	GuestListCsvFileDownload = async () => {
		const user_info = userInfoState.get({noproxy:true});
		/* */
		let { ModalBody, ModalAction, modalTitle, modalProps } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.general.csv-download";

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		ModalAction = [];

		/* */
		this.updateState({ modalClose: false, ModalBody, ModalAction, modalTitle, modalProps });
		let tab = this.state.activeTab;
		// let { UserInfo } = this.state;

		/* */
		let data = {
			"tenant_id": null,
			"status": this.state.tabs[tab]['status'],
			"search": this.state.search,
			"create_user": null,
		}

		if (
			user_info?.user_id &&
			user_info?.administrator_role == "0"
		) {
			data = {
				...data,
				"create_user": user_info?.user_id,
			};
		}
		/* */
		Service.DownloadCsvListGuest(data).then((response) => {


			downloadFileUrl(response.payload, 'csv').finally(()=>{
					/* */
					modalProps = { "show": false };
					/* */
					this.setState({ modalProps });
			});

		}).catch((error) => {
			/* */
			console.log("error", error);
		});
	}
	/** */
	CsvFileUpload = (validateMessage = null) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.general.csv-upload";

		let ValidateMessage = () => {
			if (validateMessage) {
				let messageArr = [];
				let messageKeys = Object.keys(validateMessage);
				let messageKey = messageKeys[0];
				let headerErrKey = messageKeys[1];
				let itemMap = validateMessage[messageKey];

				if (validateMessage[headerErrKey] !== '') {
					messageArr.push(
						<div key={headerErrKey}>
							<span>
								{t(`common:${validateMessage[headerErrKey]}`)}
							</span>
						</div>
					);
				} else {
					for (let [itemKey, itemLines] of Object.entries(itemMap)) {
						if (itemLines.length !== 0) {
							messageArr.push(
								<div key={itemKey}>
									<span>
										{t(`common:${messageKey}`, { item_name: t(`common:${itemKey}`), line: itemLines.join(',') })}
									</span>
								</div>
							);
						}
					}
				}
				return messageArr;
			} else {
				return null;
			}
		}

		/* Update body */
		modal.body = () => {
			return (
				<div>
					{/* */}
					<div className="">
						<Form.Group className="">
							<Form.Label>{t("common:settings.general.csv-file-select")}</Form.Label>
							<Form.Control type="file" id="csv-upload" name="upload" accept=".csv" />
						</Form.Group>
					</div>
					<div>

						<ValidateMessage />
					</div>
				</div>
			);
		}

		modal.form = {};
		modal.close = true;

		/* Add SubmitButton */
		modal.action = [(
			<Button sx={{ ml: 1 }} variant="contained" key="ok" onClick={(ev) => {
				this.GuestUserCsvFileUpload();
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });
	}

	/** [Element] */
	FilterTabs = () => {

		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { activeTab, pagination, datalist } = this.state;

				/* */
				activeTab = i
				pagination.page = 1;
				datalist = null;

				/* */
				this.setState({ activeTab, pagination, datalist }, () => this.handleGetGuestList(this.state.user_info));

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.page_size);

		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page_number = (pagination.page_number - 1);
				this.setState({ pagination, datalist }, () => this.handleGetGuestList(this.state.user_info));
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page_number = (pagination.page_number + 1);
				this.setState({ pagination, datalist }, () => this.handleGetGuestList(this.state.user_info));
			},
		}

		/* */
		if (pagination.page_number === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page_number === max || max === 0) {
			next["disabled"] = true;
		}

		/* */
		let start_list = 0;
		let last_list = 0;
		if (pagination.total > 0) {
			start_list = 1 + ((pagination.page_number - 1) * pagination.page_size);
			if (pagination.page_number === max) {
				last_list = pagination.total
			} else {
				last_list = start_list + (pagination.page_size - 1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	getCertificateInfo = (certificates, code) => {
		let cert = certificates.find(item => item.code === code);
		if (cert['user_id'] !== null) {
			return (<div>
				<div className='text-ellipsis certificates-name'>
					{cert['cert_name'] ?? ''}
				</div>
				<div className='text-ellipsis certificates-date'>
					{cert['cert_exp_date'] && cert['cert_start_date'] ?
						`${Moment(cert['cert_start_date']).format('YYYY.MM.DD')} - ${Moment(cert['cert_exp_date']).format('YYYY.MM.DD')}` : ''}
				</div>
			</div>)
		}
	}

	getGuestStatusInfo = (guest) => {
		let { t } = this.props;

		const approvalStatus = () => {
			switch (guest?.status) {
				case 3:
					return (
						<div>
							<p className={"approval-pending-label"}>{t("common:settings.guest.status-approval-pending")}</p>
						</div>
					);
				case 4:
					return (
						<div>
							<p className={"rejected-label"}>{t("common:settings.guest.status-rejected")}</p>
						</div>
					);
				default:
					return (
						<div>
						</div>
					);
			}
		}

		return (
			<div style={{
				flexDirection: "row",
				alignItems: "center"
			}}>
				{(guest?.collaboration_user_id && guest?.collaboration_tenant_id) && (
					<img className="logo-exist-email" src={logo} alt="icon"></img>
				)}

				{approvalStatus()}
			</div>
		);
	};

	handleApprovalAction = (guest, approve) => {
		let {t} = this.props;

		let {datalist, modal, user_info } = this.state;

		let data = {
			approver_id: guest.approver_id,
			user_id: guest.user_id,
			requester_id: guest.create_user,
			tenant_id: guest.tenant_id,
			approve: approve,
		};
		Service.guestApprovalAction(data).then((resp) => {
			if (resp.ok) {
				modal.body = approve ? t('common:settings.guest.approve-success') : t('common:settings.guest.reject-success');
				datalist = null
			}

			this.setState({modal, datalist}, () => {
				this.handleGetGuestList(user_info)
			});
		}).catch((err) => {
			modal.body = approve ? t('common:settings.guest.approve-fail') : t('common:settings.guest.reject-fail');
			if (err?.response?.data?.payload?.hasOwnProperty("error")) {
				modal.body = err.response.data.payload.error;
			} else if (err.response.data.payload.hasOwnProperty("message")) {
				modal.body = err?.response?.data?.payload?.message || err?.response?.data?.message;
			}
			modal.action = [
				<Button key="ok" variant="contained" onClick={(ev) => {
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, () => {
						this.handleApprovalAction(guest, approve);
					});
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			];

			this.setState({ modal });
		});
	}

	handleOpenModal = (guest, approve) => {
		let {t} = this.props;
		let { modal, formData } = this.state;

		modal.title = "common:settings.guest.guest-registration-approve";
		modal.body = `${approve ? "common:settings.guest.guest-registration-approve-confirm" : "common:settings.guest.guest-registration-reject-confirm"}`;
		modal.action = [
			(
				<Button key="ok" variant="contained" onClick={(ev) => {
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, () => {
						this.handleApprovalAction(guest, approve);
					});
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];

		modal.props.show = true;
		modal.props.size = "xl";
		modal.props.className = "layout modal-responsive";

		modal.props.centered = true;

		this.setState({ modal, formData });
	};

	guestStatusAction = (guest) => {
		let { t } = this.props;

		let {user_info} = this.state;

		if (guest?.status === 3 && guest.approver_id === user_info?.user_id) {
			return (
				<div className={"option-container"}>
					<Button
						onClick={() => this.handleOpenModal(guest, true)}
						className={"option"}
						style={{
							backgroundColor: "#1069D5",
							color: "#e2e6e9",
              width: "75px",
							marginRight: 8
						}}
					>
						{t('common:settings.guest.approve')}
					</Button>
					<Button
						onClick={() => this.handleOpenModal(guest, false)}
						className={"option"}
						style={{
							backgroundColor: "#e2e6e9",
							color: "#1069D5",
              width: "75px",
						}}
					>
						{t('common:settings.guest.reject')}
					</Button>
				</div>
			);
		}
	}

	/** [Element] */
	IndexTable = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist, datalistLoading } = this.state;
		/* */
		if (datalistLoading || datalist === null) {
			return <LoadingList />;
		}

		/* Prepare TableRows */
		let rows = [];

		/* Update TableRows with data from state */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let guest = datalist[i];

			/* */
			if (guest['additional_info'] == null) {
				guest['additional_info'] = {
					'company_name': '',
				};
			}

			let GuestLinkage = () => {
				if (guest.linkage_tenant_id && guest.linkage_user_id) {
					return (
						<div className="user-linkage-container">
							<img src={logo} className="user-linkage-icon" alt="paperlogic" />
						</div>
					);
				} else {
					return null;
				}
			}

			/* */
			rows.push(
				<tr className="item document-row-list clickable" key={i}>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						<div>
							<div className="text-ellipsis">
								{guest['user_id']}
							</div>
						</div>
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }} className="guest-linkage">
						<GuestLinkage />
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						<div>
							<div className="text-ellipsis">
								{guest['family_name']} {guest['first_name']}
							</div>
						</div>
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						<div>
							<div className="text-ellipsis">{guest['additional_info']['company_name']}
							</div>
						</div>
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						<div>
							<div className="text-ellipsis">
								{guest['email']}
							</div>
						</div>
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						{this.getCertificateInfo(guest['certificates'], 'personal-jcan')}
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						{this.getCertificateInfo(guest['certificates'], 'custom')}
					</td>
					<td onClick={() => { this.openGuestFormDialog(guest) }}>
						{this.getGuestStatusInfo(guest)}
					</td>
					<td>
						<div>
							{this.guestStatusAction(guest)}
						</div>
					</td>
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.user.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<ResizableTableWithCache id="datalist" className='guest-settings-list ' storageKey={`colWidths_Guest`}>
				<thead>
				<StateFragment state={shedOnScroll}>
					{s => (
						<tr className={`${s.get({noproxy: true}) && "shed"}`}>
							<th className='guest-user-id' id='guest-user-id'>
								<div>{t('common:settings.guest.id')}</div>
							</th>
							<th className='guest-linkage' id='guest-linkage'>
								<div></div>
							</th>
							<th className='guest-user-name' id='guest-user-name'>
								<div>{t('common:settings.guest.name')}</div>
							</th>
							<th className='guest-company-name' id='guest-company-name'>
								<div>{t('common:settings.guest.company_name')}</div>
							</th>
							<th className='guest-user-email' id='guest-user-email'>
								<div>{t('common:settings.guest.email')}</div>
							</th>
							<th className='guest-personal-jcan' id='guest-personal-jcan'>
								<div>{t('common:certificate.title.personal-jcan')}</div>
							</th>
							<th className='guest-custom-certificate' id='guest-custom-certificate'>
								<div>{t('common:certificate.title.custom-certificate')}</div>
							</th>
							<th className='guest-registration-status' id='guest-registration-status'>
								<div>{t('common:settings.guest.approval-status')}</div>
							</th>
							<th className='guest-registration-status-action' id='guest-registration-status-action'>
								<div/>
							</th>
						</tr>
					)}
				</StateFragment>

				</thead>
				<tbody>
				{rows}
				</tbody>
			</ResizableTableWithCache>
		)

	}



	/** [Element] */
	OrganizationSelector = () => {
		let { t } = this.props;
		let { formData, organizations } = this.state;

		if (organizations === null) {
			return <Loading />;
		}

		let OrgItems = [];

		/* */
		for (let item of organizations) {

			if (item.user_type !== 2) {
				continue;
			}

			let checked = false;
			if (formData.groups.includes(item['user_id'].toString())) {
				checked = true;
			}

			/* */
			OrgItems.push(
				<div className="org-selector-item" key={item['user_id']}>
					<label className="checkboxBtn">
						<input type="checkbox" name="organizations[]" value={item['user_id']} checked={checked} onChange={(ev) => {
							let { formData } = this.state;
							if (ev.target.checked) {
								formData.groups.push(ev.target.value);
							} else {
								var index = formData.groups.indexOf(ev.target.value);
								if (index !== -1) {
									formData.groups.splice(index, 1);
								}
							}
							this.setState({ formData });
						}} />
						<div>{item['name']}</div>
					</label>
				</div>
			);
		}

		/* */
		return (
			<div className="org-selector">
				<div className="org-selector-label">
					<div>{t('common:settings.user.organization')}</div>
				</div>
				<div className="org-selector-itemlist">
					{OrgItems}
				</div>
			</div>
		);

	}

	handleOpenApproverDialog = () => {
		let { modal } = this.state;

		modal = {
			props: {
				show: true,
				dialogClassName: "profile-dialog",
				centered: true,
			},
			title: "common:settings.guest.approver-settings",
			body: this.ApproverForm,
			action: this.ApproverModalAction,
		};

		this.setState({modal});
	};

	handleGetInitialApproverList = () => {
		let {approver_list, approver_list_pagination, approver_list_loading, enable_load_more_approver_list} = this.state;

		if (!approver_list_loading && enable_load_more_approver_list) {
			this.setState({
				approver_list_loading: true,
			});

			Service.getGuestApproverList(approver_list_pagination).then(resp => {
				const {payload, ok} = resp;

				let new_list = [];

				if (ok) {
					if (payload.length > 0) {
						if (approver_list_pagination.page_number === 1) {
							new_list = [...payload];
						} else {
							new_list = [...approver_list, ...payload];
						}
						this.setState({
							approver_list: new_list,
							approver_list_pagination: approver_list_pagination
						});
					} else if (payload.length === 0) {
						this.setState({enable_load_more_approver_list: false});
					}
				}
			}).catch((error) => {

			}).finally(() => {
				this.setState({approver_list_loading: false});
			});
		}
	};

	ApproverForm =() => {
		let { t } = this.props;

		let {approver_list, formData, approver_list_loading, approver_last_selection} = this.state;

		formData["approver_id"] = approver_last_selection?.user_id;
		approvalId.set(approver_last_selection?.user_id);

		const handleSelectApprover = (event, newValue) => {
			if (newValue) {
				// console.log(newValue);
				approvalId.set(newValue.user_id);
				formData["approver_id"] = newValue.user_id;
				localStorage.setItem("approver_last_selection", JSON.stringify(newValue));
				this.setState({
					formData,
					approver_last_selection: newValue,
				});
			}
		};

		const handleInputDebounce = (func, delay) => {
			let timeoutId;
			return function (...args) {
				clearTimeout(timeoutId);
				timeoutId = setTimeout(() => func.apply(this, args), delay);
			};
		}

		const handleFilterName = handleInputDebounce((name) => {
			let {approver_list_pagination} = this.state;
			this.setState({
				approver_list_pagination: {
					...approver_list_pagination,
					page_number: 1,
					search: name,
				},
				enable_load_more_approver_list: true,
			}, () => {
				this.handleGetInitialApproverList();
			})
		}, 1000);

		const handleInputChange = (event, newInputValue, reason) => {
			if (reason === 'clear') {
				let { approver_list_pagination } = this.state;
				this.setState({
					approver_list: [],
					approver_list_pagination: {
						...approver_list_pagination,
						page_number: 1,
						search: "",
					},
					approver_list_loading: false,
				}, () => {
					this.handleGetInitialApproverList();
				})
			}
		}
		const handleSearch = (event) => {
			handleFilterName(event.target.value);
		}

		const handleScroll = (ev) => {
			let { approver_list_pagination } = this.state;
			const target = ev.target;
			if (target) {
				if ((target.scrollHeight - target.scrollTop) === (target.clientHeight + 0.5)) {
					this.setState({
						approver_list_pagination: {
							...approver_list_pagination,
							page_number: approver_list_pagination.page_number + 1,
						}
					}, () => {
						this.handleGetInitialApproverList();
					});
				}
			}
		}

		const handleClose = () => {
			let {approver_list_pagination} = this.state;
			this.setState({
				approver_list_pagination: {
					...approver_list_pagination,
					page_number: 1,
					search: "",
				},
				approver_list_loading: false,
			})
		}

		const CustomList = React.forwardRef((props, ref) => {
			const { children, ...other } = props;
			return (
				<List {...other} ref={ref} onScroll={handleScroll}>
					{children}
					<div
						style={{
							height: 50
						}}
					/>
					{approver_list_loading && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CircularProgress size={20}/>
						</div>
					)}
				</List>
			);
		});

		return (
			<div className="add-approver-container">
				<span className={"add-approver-subtitle"}>
					{t("common:settings.guest.select-approver-desc")}
				</span>

				<div className={"select-approver-container"}>
					<span className={"select-approver-label"}>
						{t("common:settings.guest.approver")}
					</span>
					<Autocomplete
						classes={{popper: 'select-approver-popper'}}
						options={approver_list}
						value={approver_last_selection}
						noOptionsText={
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CircularProgress size={20}/>
							</div>
						}
						autoHighlight
						getOptionLabel={(item) => `${item.family_name} ${item.first_name}`}
						renderOption={(props, item) => (
							<Box component="li" {...props} key={item.user_id}>
								{item.family_name} {item.first_name}
							</Box>
						)}
						isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
						onChange={handleSelectApprover}
						onClose={handleClose}
						onInputChange={handleInputChange}
						ListboxComponent={CustomList}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={"Search"}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password',
								}}
								onChange={handleSearch}
							/>
						)}
					/>
				</div>
			</div>
		);
	};

	ApproverModalAction = () => {
		let { t } = this.props;

		return [
			(
				<Button
					sx={{ml: 1}}
					key="ok"
					variant="contained"
					onClick={() => {
						this.submitGuestForm();
					}}
				>
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];
	}

	/** [Action] */
	openGuestFormDialog = (guest = null) => {
		// let { t } = this.props;
		let { modal } = this.state;


		/* */

		if(!guest){
			modal.title = "common:settings.guest.dialog.registration.header";
			groupMembersState.set(this.state.organizations.reduce((arr, a)=> {
				if(a.user_type == 2){
					a.isActive = false;
					arr.push(a);
				}
				return arr;
			},  []));
			this.setState({showGuestDetail: true, guestDetail: null, fromCreate:true, modal})
			return;
		} else {
			modal.title = "common:settings.guest.dialog.update.header";
			groupMembersState.set(this.state.organizations.reduce((arr, a)=> {
				if(a.user_type == 2){
					if(guest.groups.find(b => b.id == a.user_id)){
						a.isActive = true;
					} else {
						a.isActive = false;
					}
					arr.push(a);
				}
				return arr
			},  []));
			this.setState({showGuestDetail: true, guestDetail: guest, fromCreate:false, modal})
			return;
		}

		// let lastSelectionApprover = parseInt(JSON.parse(localStorage.getItem("approver_last_selection"))?.user_id, 10);

		// modal = {
		// 	props: {
		// 		show: true,
		// 		dialogClassName: "profile-dialog",
		// 		centered: true,
		// 	},
		// 	title: "common:settings.guest.add_guest",
		// 	body: this.GuestForm,
		// 	action: () => this.GuestModalAction(guest),
		// 	form: {
		// 		id: "settings-user-form",
		// 		onSubmit: (ev) => { this.formSubmitHandler(ev, null, () => this.formSubmitCallback(ev, guest)) },
		// 		noValidate: true,
		// 	},
		// };

		// /* Default structure */
		// let { keyFields } = this.state;
		// let formData = {};
		// formData["user_id"] = null;
		// formData["approver_id"] = lastSelectionApprover;
		// formData["tenant_id"] = null;
		// formData["status"] = 0;
		// formData["groups"] = [];
		// for (let key in keyFields) {
		// 	formData[key] = "";
		// }

		// if (guest != null) {
		// 	formData["user_id"] = guest["user_id"];
		// 	formData["tenant_id"] = guest["tenant_id"];
		// 	formData["approver_id"] = guest["approver_id"];
		// 	formData["status"] = guest["status"];
		// 	let groups = guest["groups"];
		// 	if (typeof groups === "string") {
		// 		groups = JSON.parse(guest["groups"]);
		// 	}
		// 	for (let group of groups) {
		// 		formData["groups"].push(group.id);
		// 	}
		// 	for (let key in keyFields) {
		// 		formData[key] = guest[key];
		// 	}
		// 	formData["company_name"] = guest.additional_info.company_name || "";
		// 	modal.title = "common:settings.guest.update_guest";
		// }

		// this.setState({ formData }, this.setStateFields(() => {
		// 	this.setState({ modal, formValidate: false });
		// }));

	}

	formSubmitCallback = (ev, guest) => {
		const user_info = userInfoState.get({noproxy:true});
		if (user_info?.administrator_role == "0") {
			if (guest?.user_id != null) {
				this.submitGuestForm();
			} else {
				this.handleOpenApproverDialog();
			}
		} else if (user_info?.administrator_role == "1") {
			this.submitGuestForm();
		}
	}

	handleOpenEmailRequestDialog = (guest) => {
		let { modal } = this.state;
		let { t } = this.props;

		modal = {
			props: {
				show: true,
				dialogClassName: "profile-dialog",
				centered: true,
			},
			title: "common:general.resend-guest-approval-email",
			action: null,
			body: Loading,
		};

		this.setState({ modal }, () => {
			let { modal } = this.state;
			Service.ResendGuestApprovalEmail({
				guest_id: guest?.user_id,
			}).then(resp => {
				const {ok, payload} = resp;
				if (ok) {
					const {sent_result} = payload;
					modal.body = sent_result.ok ? t("common:settings.guest.resend-approval-mail-success") : t("common:settings.guest.resend-approval-mail-fail");
					modal.close = true;
					this.setState({ modal });
				}
			}).catch(err => {
				console.log("error", err.response);

				let errMessage = err.response.data.payload.message;
				if (err.response.data.payload.hasOwnProperty("error")) {
					errMessage = err.response.data.payload.error;
				}

				modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
				modal.close = true;

				this.setState({ modal });
			});
		});
	};

	GuestModalAction = (guest = null) => {
		let { t } = this.props;

		return [
			guest?.status === 3 && (
				<Button sx={{ ml: 1 }} variant="contained" onClick={() => this.handleOpenEmailRequestDialog(guest)}>
					<span>{t("common:general.resend-guest-approval-email")}</span>
				</Button>
			),
			(
				<Button sx={{ ml: 1 }} key="ok" type="submit" variant="contained" >
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];
	}

	GuestModalActionTryAgain = () => {
		let { t } = this.props;

		return [
			(
				<Button sx={{ ml: 1 }} key="ok" variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = this.GuestForm;
					modal.action = this.GuestModalAction;
					this.setState({ modal });
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)
		];
	}

	GuestForm = () => {
		let { t } = this.props;
		let { formData } = this.state;
		let { FieldControl, OrganizationSelector } = this;

		/* Lock Email TextBox */
		let emailReadOnly = false;
		if (formData['user_id']) {
			emailReadOnly = true;
		}

		return (
			<div className="form-container">
				<Form.Control type="hidden" name="user_id" defaultValue={formData['user_id']} />
				<Form.Control type="hidden" name="tenant_id" defaultValue={formData['tenant_id']} />

				<Form.Row>
					<FieldControl name="family_name" xs={12} md={6} required validate="true" />
					<FieldControl name="first_name" xs={12} md={6} required validate="true" />
				</Form.Row>

				<Form.Row>
					<FieldControl name="family_name_kana" xs={12} md={6} />
					<FieldControl name="first_name_kana" xs={12} md={6} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="company_name" xs={12} md={6} />
					<FieldControl name="mobile_number" xs={12} md={6} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="email" type="email" xs={12} md={12} required validate="true" readOnly={emailReadOnly} />
				</Form.Row>

				<Form.Row>
					<div className="col-xl-12">
						<OrganizationSelector />
					</div>
				</Form.Row>

				<Form.Row>
					{/* <Form.Group className="lz-mt-10 status" controlId="status">
						{(formData.status === 0 || formData.status === 1) && <Form.Check
							type="switch"
							name='status'
							value='status'
							onChange={this.onChangeHandler}
							label={t('common:settings.guest.active')}
							checked={[true, "true", 0, "0"].includes(formData["status"])}
						/>}
					</Form.Group> */}
          <Form.Group className="lz-mt-10" controlId="status">
            <Form.Check type="switch" name='status' value='status' onChange={this.onChangeHandler} label={t('common:settings.guest.active')} checked={[true, "true", 0, "0"].includes(formData["status"])}
						/>
          </Form.Group>
				</Form.Row>

			</div>
		);
	}

	/** [Event] */
	submitGuestForm = () => {
		let { t } = this.props;
		let { formData, datalist, modal, user_info } = this.state;

		/* */
		// let form = document.querySelector("form#settings-user-form");
		// let fields = form.elements;

		/* Preprae RequestBody */
		let body = {
			"user_id": formData["user_id"] ?? undefined,
			"family_name": formData["family_name"],
			"first_name": formData["first_name"],
			"family_name_kana": formData["family_name_kana"],
			"first_name_kana": formData["first_name_kana"],
			"company_name": formData["company_name"],
			"mobile_number": formData["mobile_number"],
			"email": formData["email"],
			"organizations": formData["groups"],
			"status": '1',
		};

		if (user_info.administrator_role == "0") {
			body = {
				...body,
				"approver_id": formData["approver_id"] ?? undefined,
			}
		}

		/* */
		if ([true, "true", 0, "0"].includes(formData["status"])) {
			body["status"] = '0';
		}

		modal.title = formData["user_id"] ? t('common:settings.guest.update_guest') : t('common:settings.guest.add_guest')
		modal.body = Loading;
		modal.action = null;
		modal.close = false;

		/* */
		this.setState({ modal }, () => {
			let { modal } = this.state;
			Service.setUserGuest(body).then(resp => {
				datalist = null;
				// modal.props.show = false;
				modal.close = true;

				modal.body = t(`common:documents.info.guest-registration-success`);
				this.setState({ datalist, modal }, () => this.handleGetGuestList(this.state.user_info));
			}).catch(err => {
				console.log("error", err.response);

				let errMessage = err?.response?.data?.message;
				if (err?.response?.data?.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}

				modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
				modal.action = this.GuestModalActionTryAgain;
				modal.close = true;

				this.setState({ modal });
			});
		});

	}

	GuestUserCsvFilePreProcess = async (key) => {
		let { modal } = this.state;
		let { t } = this.props;

		Service.guestPreProcessCsvUpload({
			key: key
		})
			.then((result) => {
				const {ok, payload} = result;
				if (ok) {
					/* */
					let duplicatedGuests = payload.guests_duplicated;
					let guestIdEmailInvalid = payload.guestId_email_invalid;
					let guestIdInvalid = payload.guestId_invalid;
					let emailInvalid = payload.email_invalid;

					if (
						(guestIdEmailInvalid && guestIdEmailInvalid.length > 0) ||
						(guestIdInvalid && guestIdInvalid.length > 0) ||
						(emailInvalid && emailInvalid.length > 0)
					) {
						let SendMessage = () => {
							let messageArr = [];
							
							if (guestIdEmailInvalid.length > 0) {
								messageArr.push(
									<div>
										<p>{guestIdEmailInvalid.length === 1 ? `${t('common:settings.guest.combine-id-email-not-exist')}` : `${t('common:settings.guest.combine-id-email-not-exist', {count: guestIdEmailInvalid.length})}`}</p>
									</div>
								);
								messageArr.push(
									<div>
										{guestIdEmailInvalid.map((guest, index) => (
											<div
												key={`${guest.guest_id}${index}`}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "flex-start",
												}}
											>
												<p
													style={{
														marginRight: 24
													}}
												>
													{`${index + 1}. `}
												</p>
												<div>
													<p>{guest.guest_id} : {guest.email}</p>
												</div>
											</div>
										))}
									</div>
								);
							}

							if (guestIdInvalid.length > 0) {
								messageArr.push(
									<div>
										<p>{guestIdInvalid.length === 1 ? `${t('common:settings.guest.guest_id-invalid')}` : `${t('common:settings.guest.guest_id-invalid', {count: guestIdInvalid.length})}`}</p>
									</div>
								);
								messageArr.push(
									<div>
										{guestIdInvalid.map((guest, index) => (
											<div
												key={`${guest.guest_id}${index}_id`}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "flex-start",
												}}
											>
												<p
													style={{
														marginRight: 24
													}}
												>
													{`${index + 1}. `}
												</p>
												<div>
													<p>{guest.guest_id} : {guest.email}</p>
												</div>
											</div>
										))}
									</div>
								);
							}

							if (emailInvalid.length > 0) {
								messageArr.push(
									<div>
										<p>{emailInvalid.length === 1 ? `${t('common:settings.guest.guest-email-invalid')}` : `${t('common:settings.guest.guest-email-invalid', {count: emailInvalid.length})}`}</p>
									</div>
								);
								messageArr.push(
									<div>
										{emailInvalid.map((guest, index) => (
											<div
												key={`${index}_email`}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "flex-start",
												}}
											>
												<p
													style={{
														marginRight: 24
													}}
												>
													{`${index + 1}. `}
												</p>
												<div>
													<p>{ guest.email }</p>
												</div>
											</div>
										))}
									</div>
								);
							}
							
							return messageArr;
						}

						modal.title = t('common.general.error')

						modal.body = () => {
							return (
								<div className="">
									<SendMessage />
								</div>
							);
						};

						modal.close = true;

						this.setState({ modal });
					}

					else if (duplicatedGuests && duplicatedGuests.length > 0) {

						let SendMessage = () => {
							let messageArr = [];
							messageArr.push(
								<div>
									<p>{duplicatedGuests.length === 1 ? `${t('common:settings.guest.guest-exist')}` : `${t('common:settings.guest.guests-exist', {count: duplicatedGuests.length})}`}</p>
								</div>
							);
							messageArr.push(
								<div>
									{duplicatedGuests.map((guest, index) => (
										<div
											key={guest.user_id}
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "flex-start",
											}}
										>
											<p
												style={{
													marginRight: 24
												}}
											>
												{`${index + 1}. `}
											</p>
											<div>
												<p>{guest.additional_info.company_name} {guest.family_name} {guest.first_name}</p>
												<p>{guest.email}</p>
											</div>
										</div>
									))}
								</div>
							);
							messageArr.push(
								<div>
									<p>{t('common:settings.guest.register-confirm')}</p>
								</div>
							);
							return messageArr;
						}

						modal.body = () => {
							return (
								<div className="">
									<SendMessage />
								</div>
							);
						};

						modal.action = [(
							<Button sx={{ ml: 1 }} variant="contained" key="ok" onClick={(ev) => {
								this.GuestUserCsvFileProcess(key)
							}}>
								<span>{t("common:general.ok")}</span>
							</Button>
						)];

						modal.close = true;

						this.setState({ modal });
					} 
					
					else {
						this.GuestUserCsvFileProcess(key)
					}
				} else {
					this.CsvFileUpload(payload);
				}
			})
			.catch((error) => {
				/* */
				this.CsvFileUpload(error.response.data.payload);
				console.log("error", error);
				modal.close = true;
				/* */
				this.setState({ modal });
			})
	}

	GuestUserCsvFileProcess = async (key) => {
		let { modal, datalist } = this.state;
		let { t } = this.props;
		const user_info = userInfoState.get({noproxy:true});

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		modal.action = [];
		modal.close = false;

		/* */
		this.setState({ modal }, () => {
			Service.guestProcessCsvUpload({
				key: key
			})
				.then((result) => {
					const {ok, payload} = result;
					if (ok) {
						/* */
						datalist = null;
						/* */
						modal.body = () => {
							return (
								<div className="">
									<p>{t('common:general.registration-complete')}</p>
								</div>
							);
						};

						modal.close = true;
						/* */
						this.setState({ modal, datalist }, () => this.handleGetGuestList(user_info));
					} else {
						this.CsvFileUpload(payload);
					}
				})
				.catch((error) => {
					/* */
					this.CsvFileUpload(error.response.data.payload);
					console.log("error", error);
					modal.close = true;
					/* */
					this.setState({ modal });
				})
		});
	}

	/* */
	GuestUserCsvFileUpload = async () => {
		let { t } = this.props;
		const user_info = userInfoState.get({noproxy:true});
		/* */
		let { modal, datalist } = this.state;
		/* */
		let form = document.querySelector("#csv-upload");

		/* File required */
		if (form['files']['length'] === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		modal.action = [];
		modal.close = false;

		/* */
		this.setState({ modal });
		/* */
		const selectedFile = form.files[0];

		Service.getMultiPresignedUrl({
			files: [{
				file_name: selectedFile.name,
				file_type: selectedFile.type,
			}]
		})
			.then((resp) => {
				const {ok, payload} = resp;
				if (ok) {
					const {key, url} = payload[0];
					uploadToS3(
						url,
						form.files[0],
						selectedFile.type,
						(progressEvent) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total
							);
							if (percentCompleted === 100) {
								this.GuestUserCsvFilePreProcess(key);
							}
						})
				}
			})
			.catch((error) => {
				/* */
				console.log("error", error);
				modal.close = true;
				/* */
				this.setState({ modal });
			})
	}

}

export default withTranslation()(Guest);
