import {withTranslation} from 'react-i18next';
import Layout from '../../Layout';
import "../Assets/css/layout.css";
import "../Assets/css/pdf-preview.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faMinus, faPlus, faPrint} from "@fortawesome/free-solid-svg-icons";
import {Document, Page, pdfjs} from "react-pdf";
import React, { createRef } from "react";
import Service from "../../Service";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DownloadIcon from "@mui/icons-material/Download";
import {LocalPrintshop} from "@mui/icons-material";
import axios from "axios";
import bg404 from "../Assets/img/404.png";
import {Alert, CircularProgress, Snackbar} from "@mui/material";
import DocumentWithObserver from './DocumentWithObserver';
import LoadingSpin from "../Assets/img/icons/loading-spin.svg"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class DocumentPreviewForPrinting extends Layout {

    constructor(props) {
        super(props)
        this.state.document_id = props.match.params.document_id;
		this.state.token = props.match.params?.token || null;
        this.state.loading = true;
        this.state.PDFFiles = {};
        this.state.previewScale = 100;
        this.state.selectedFile = "";
        this.state.keepPDFPassword = null;
        this.state.PDFLoadSuccess = false;
        this.state.currentPageInfo = 1;
        this.state.observerRef = createRef();
        this.state.errorMessage = "";
        this.state.showLoadingDownload = false;
        this.state.showLoadingPrint = false;
        this.state.pagesRendered = 0;
        this.state.showLoadingPDFLoad = false;
        this.pdfDocOptions = {
            cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
        };
        this.state.documentAlertVisibility = false;
    }

    NavLeft = () => {
        const { LogoImage } = this;

        return (
            <div className="logo-horizontal" style={{ width: "200px" }}>
                <LogoImage />
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.state.token){
            Service.previewForPrintingGuest(this.state.token)
            .then((result) => {
                const {payload, ok, message} = result;

                if (ok) {
                    let {file_list} = payload;
                    let listObject = {};
                    file_list.map((item) => {
                        listObject = {...listObject, [item.hash]: item};
                    });
                    this.setState({
                        PDFFiles: listObject,
                        selectedFile: file_list[0].hash,
                    });
                } else {
                    this.setState({
                        errorMessage: message,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                this.setState({ loading: false});
            });
        } else {
            Service.previewForPrinting(this.state.document_id)
            .then((result) => {
                const {payload, ok, message} = result;

                if (ok) {
                    let {file_list} = payload;
                    let listObject = {};
                    file_list.map((item) => {
                        listObject = {...listObject, [item.hash]: item};
                    });
                    this.setState({
                        PDFFiles: listObject,
                        selectedFile: file_list[0].hash,
                    });
                } else {
                    this.setState({
                        errorMessage: message,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                this.setState({ loading: false});
            });
        }
        
    }

    handleScrollPdfPreview = (ev) => {
        let { PDFFiles, selectedFile } = this.state;
        let PDFFile = PDFFiles[selectedFile];

        let pageContainer = ev.target.querySelector(".react-pdf__Document");

        let currentPage = null;
        for (let i in PDFFile.pages) {
            let page = PDFFile.pages[i];
            let realTop = page.view.percentage.top / 100 * pageContainer.offsetHeight;
            if ((realTop - (ev.target.offsetHeight / 2)) > ev.target.scrollTop) {
                break;
            }
            currentPage = page;
            currentPage.pageNumber = i;
            PDFFiles[selectedFile].currentPage = parseInt(i);
            this.setState({ PDFFiles });
        }
    }

    handleDecreasePreviewSize= () => {
        let { previewScale } = this.state;
        previewScale -= 10;
        if (previewScale < 10) {
            previewScale = 10;
        }
        this.setState({ previewScale });
    }

    handleIncreasePreviewSize= () => {
        let { previewScale } = this.state;
        previewScale += 10;
        if (previewScale > 1000) {
            previewScale = 1000;
        }
        this.setState({ previewScale });
    };

    handlePrintDocument = () => {
        const {PDFFiles, selectedFile} = this.state;
        let { url } = PDFFiles[selectedFile];
        this.setState({showLoadingPrint: true})
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            // this.setState({showLoadingPrint: false})
            let src = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
            const iframe = document.createElement('iframe');
            iframe.id = 'print-frame'
            iframe.style.display = 'none'
            iframe.src = src
            document.body.appendChild(iframe)
            setTimeout(function () {
                iframe.contentWindow.print()
            }, 100)
        }).finally(()=>{
            this.setState({showLoadingPrint: false})
        });

    }

    handleDownloadDocument = () => {
        const {PDFFiles, selectedFile} = this.state;
        let {url, name} = PDFFiles[selectedFile];
        this.setState({showLoadingDownload: true})
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            // this.setState({showLoadingDownload: false})
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link?.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }).finally(()=>{
            this.setState({showLoadingDownload: false})
        });
    }

    Main = (props) => {
        let { loading } = this.state;
        const { Nav, Body, MainLoading } = this;

        if (loading) {
            return <MainLoading />;
        }

        return (
            <div className="main contrainer-wrapper">
                <div className="layout no-menu">

                    <div className="layout-right">

                        <Nav />

                        <Body {...props} />

                        <div className="right-bottom">
                            <span className="licensce-info">© {new Date().getFullYear()} paperlogic co., ltd.</span>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    onDocumentLoadSuccess = async (pdfInfo) => {
        let { numPages } = pdfInfo;
        let { PDFFiles, selectedFile, keepPDFPassword } = this.state;
        if (!PDFFiles[selectedFile].initialDone) {

            PDFFiles[selectedFile].numPages = numPages;
            PDFFiles[selectedFile].currentPage = PDFFiles[selectedFile].currentPage || 1;
            let firstPageInfo = await pdfInfo.getPage(1)

            PDFFiles[selectedFile].firstPageView = {
                top: firstPageInfo.view[0],
                left: firstPageInfo.view[1],
                width: firstPageInfo.view[2],
                height: firstPageInfo.view[3],
            };

            let pageSpace = 20;
            let docHeight = 0;
            let pages = {};
            for (let i = 1; i <= numPages; i++) {

                let pageInfo = await pdfInfo.getPage(i);

                pages[i] = {
                    view: {
                        top: pageInfo.view[0],
                        left: pageInfo.view[1],
                        width: pageInfo.view[2],
                        height: pageInfo.view[3],
                    },
                };
                docHeight += pageInfo.view[3] + pageSpace;
            }
            docHeight += pageSpace;
            let currentHeight = pageSpace;
            for (let i in pages) {
                let view = pages[i].view;
                pages[i].view.top = currentHeight;
                pages[i].view.percentage = {
                    top: currentHeight / docHeight * 100,
                    height: view.height / docHeight * 100,
                };
                currentHeight += view.height + pageSpace
            }
            pages[1].view.top = 0;
            pages[1].view.percentage.top = 0;

            PDFFiles[selectedFile].pages = pages;
            PDFFiles[selectedFile].initialDone = true;

            if (keepPDFPassword) {
                PDFFiles[selectedFile].password = keepPDFPassword;
            }

            this.setState({ PDFFiles, keepPDFPassword: null });
        }
        this.setState({ PDFLoadSuccess: true });

    }

    onDocumentLoadError = () => {
        this.setState({
            documentAlertVisibility: true,
        });
    }

    onPasswordHandler = () => {
        //
    }

    PDFPageNoData = () => {
        let { t } = this.props;
        return (
            <div className="pdf-no-data">
                <span>{t("common:pdf.no-data")}</span>
            </div>
        );
    }

    generatePDFPages = () => {
        let { PDFFiles, selectedFile, previewScale, pagesRendered } = this.state;
        let numPages = PDFFiles[selectedFile].numPages;
        // let pages = [];
        const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages || 0);
		// console.log(pagesRenderedPlusOne)
		let pages = Array.from(
			new Array(pagesRenderedPlusOne),
			(el, i) => {
			  const isCurrentlyRendering = pagesRenderedPlusOne === i + 1;
			  const isLastPage = numPages === i + 1;
			  const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
			  return  (<div key={i} className="pdf-page-container">
                            <Page
                                onRenderSuccess={()=> {
                                    if(needsCallbackToRenderNextPage){
                                        this.setState(prevState => ({
                                            pagesRendered: prevState.pagesRendered + 1,
                                        }));
                                    }
                                }}
                                pageNumber={i + 1}
                                renderTextLayer={true}
                                scale={previewScale / 100}
                                onDragOver={this.allowDrop}
                                className={"pdf-custom-page scale-" + previewScale}>
                            </Page>
                        </div>)
			},
		  );
        // for (let i = 0; i < numPages; i++) {
        //     pages.push(
        //         <div key={i} className="pdf-page-container">
        //             <Page
        //                 pageNumber={i + 1}
        //                 renderTextLayer={true}
        //                 scale={previewScale / 100}
        //                 onDragOver={this.allowDrop}
        //                 className={"pdf-custom-page scale-" + previewScale}>
        //             </Page>
        //         </div>
        //     );
        // }
        return pages;
    }

    handleSelectFile = (ev) => {
        let { selectedFile } = this.state;
        let fileCode = ev.target.closest(".file-item").dataset.file;
        if (fileCode !== selectedFile) {
            this.setState({
                selectedFile: fileCode,
                PDFLoadSuccess: false
            });
        }
    }

    DocumentAlert() {
        let { t } = this.props;
        let {documentAlertVisibility} = this.state;
        return (
            <Snackbar
                open={documentAlertVisibility}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                autoHideDuration={5000}
                onClose={() => {
                    this.setState({
                        documentAlertVisibility: false
                    });
                }}
            >
                <Alert
                    onClose={() => {
                        this.setState({
                            documentAlertVisibility: false
                        });
                    }}
                    severity={"warning"}
                >
                    {t("common:document.document-expired")}
                </Alert>
            </Snackbar>
        )
    }

    ConfirmBody = () => {
        let { t } = this.props;
        let objFile = null;
        let { PDFFiles, selectedFile, PDFLoadSuccess, errorMessage} = this.state;
        let PDFPages = null;
        let totalPages = 1;
        let currentPage = 1;

        if (selectedFile.length > 0) {
            let PDFFile = PDFFiles[selectedFile];

            if (PDFFile) {
                objFile = PDFFile;
                if (PDFLoadSuccess) {
                    PDFPages = this.generatePDFPages();
                }
                totalPages = PDFFile.numPages;
                currentPage = PDFFile.currentPage;
                if (currentPage !== undefined) {
                    let ctrlPreview = document.querySelector(".full-preview .control-preview");
                    if (!ctrlPreview) {
                        setTimeout(() => {
                            do {
                                let ctrlPreview = document.querySelector(".full-preview .control-preview");
                            } while (!ctrlPreview);
                            let space = ctrlPreview.offsetHeight - PDFFile.pages[currentPage].view.height;
                            let scrollTop = PDFFile.pages[currentPage].view.top;
                            if (space > 0) {
                                scrollTop = scrollTop - (space / 2);
                            }
                            ctrlPreview.scrollTo(0, scrollTop);
                        }, 100);
                    }
                }
            }
        }

        let PageInfo = "Loading..."
        if (totalPages) {
            PageInfo = `${this.state.currentPageInfo}/${totalPages}`;
        }
        let fileOrder = Object.keys(PDFFiles);

        return (
            objFile !== null ? (
                <div className={"documentprepare-container"}>
                    <div className="full-preview" style={{ height: "unset", minHeight: "100%" }}>
                        <div ref={this.state.observerRef} className="control-preview">
                            <DocumentWithObserver file={objFile}
                             showLoading={this.state.showLoadingPDFLoad}
                                observerRef={this.state.observerRef}
                                setCurrentPage={(page)=> this.setState({currentPageInfo: page})}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onLoadError={this.onDocumentLoadError}
                                onPassword={this.onPasswordHandler}
                                options={this.pdfDocOptions}
                                loading={null}
                                noData={this.PDFPageNoData}>{PDFPages}</DocumentWithObserver>
                        </div>

                        <div className="control-container">
                            <div className="view-size-controller">
                                <div className="btn size-decrease" onClick={this.handleDownloadDocument}>
                                    {this.state.showLoadingDownload ? 
                                        <img src={LoadingSpin} className='spinner-icon' alt="Loading"/>
                                        : <DownloadIcon icon={faDownload} />
                                    }
                                   
                                </div>
                                <div className="btn size-increase" onClick={this.handlePrintDocument}>

                                    {this.state.showLoadingPrint ? 
                                        <img src={LoadingSpin} className='spinner-icon'  alt="Loading"/>
                                        : <LocalPrintshop icon={faPrint} />
                                    }
                                    
                                </div>
                                <div className="btn size-decrease" onClick={this.handleDecreasePreviewSize}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </div>
                                <div className="btn size-increase" onClick={this.handleIncreasePreviewSize}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                            </div>

                            <div className="page-index-info">
                                <span className="info-label">{PageInfo}</span>
                            </div>
                        </div>
                    </div>

                    <div className="file-panel upload">
                        <div className="file-upload-list">
                            <ul>
                                {fileOrder.length > 0 &&
                                    fileOrder?.map((key, index) => {
                                        let file = PDFFiles[key];
                                        return (
                                            <li
                                                key={index}
                                                data-file={key}
                                                className={`file-item ${key === selectedFile && 'active'}`}
                                            >
                                            <span
                                                className="file-name"
                                                title={file.name}
                                                onClick={this.handleSelectFile}
                                            >
                                                {file.name}
                                            </span>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                    </div>
                    {this.DocumentAlert()}
                </div>
            ): (
                <div className="page-error">
                    <div
                        className="errorPageElements"
                        style={{ textAlign: 'center' }}
                    >
                        <h5>Page not found.</h5>
                        {
                            errorMessage && <div style={{ whiteSpace: 'pre-line' }}>{errorMessage}</div>
                        }
                        <img
                            src={bg404} alt="Page not found."
                            style={{ width: '450px' }}
                        />
                    </div>
                </div>
            )
        );
    }

    render() {
        let { Main, ConfirmBody } = this;
        const {GeneralModal, FormModal} = this;

        return (
            <Main>
                <ConfirmBody />

                <GeneralModal/>

                <FormModal/>
            </Main>
        );
    }
}

export default withTranslation()(DocumentPreviewForPrinting);

