// @ts-nocheck
import React, { createRef, Suspense, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Pages/Assets/img/logo_figure.svg";
import { Col, Form, InputGroup, Modal } from "react-bootstrap";
import { notification as IconNotification, } from "../Pages/Assets/SVGIcons";
import Service from "../Service";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import Loading from "../Pages/Loading";
import ProfileAvatar from "./ProfileAvatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Divider, InputAdornment, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
import { UserInfoContext } from "../Context/UserInfoContext";
import Tooltip from "@mui/material/Tooltip";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageIcon from '@mui/icons-material/Language';
import { Close, Search } from "@mui/icons-material";
import {hookstate, StateFragment} from "@hookstate/core";
import { userInfoState } from "../drive-feature/data-access/state";
import {appLoadingShow, currentLang, shedOnScroll} from "../data-access/app.state";
import i18n from "../lang.init";
import { hideLoader, showLoader } from "../Router";
import { GetUserInfoNew } from "../data-access/api";
import { BusinessDetailField } from "../Pages/Main/BusinessNumberSearch";
import BaseSuggestionPopover from "../Components/base-suggestion/base-suggestion";
import Moment from 'moment';
import { valueSearchHost } from "../Pages/Documents/HostSetting";
import UrlHook from "./UrlHook";

export const documentMainList = hookstate({
	documents: null,
	creationType: null,
});

class Layout extends React.Component {
    static contextType = UserInfoContext;
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            formData: {},
            formValidate: {},
            requiredField: {},
            fieldValidate: {},
            pageState: {},
            translateKeyPrefix: "common:",
            modalTitle: "Modal Title",
            ModalBody: "Modal Body Message",
            ModalAction: [],
            modalProps: { show: false },
            modalClose: true,
            onExitedModal: null,
            loading: false,
            showHiddenMenu: false,
            HiddenMenuContent: null,
            msgValidate: "",
            isInvalid: {},
            isValid: {},
            modal: {
                props: {
                    show: false,
                },
                title: "Modal Title",
                body: "Modal Body or Message",
                action: null,
            },
            userInfoMenuAnchorEl: null,
            searchInput: "",
            refOcr: {
                // invoice_number: createRef(),
                received_date: createRef(),
                amount: createRef(),
                counter_party_name: createRef()
            },
            file: null,
            isSettingPage: false
            // active: props.type || props.match.params.type || "sender",
        };
        // console.log("constructor");
        this.state.UserInfo = userInfoState.get({stealth: true, noproxy:true});

        this.scrollContainerRef = React.createRef();

        // console.log(this.state.UserInfo);
        // this.GetLayoutResources();
    }
    _isMounted = false;

    get isMounted() {
        return this._isMounted;
    }
    set isMounted(val) {
        this._isMounted = val;
    }

    ProcessGuestCollaborationForward = (data) => {
        // Process Guest Collaboration Forward Order
        // Set all signers following a user_type=1 to also be user_type=1
        if (data.length > 0) {
            let foundUserType1 = false;
            for (let i = 0; i < data.length; i++) {
                if (foundUserType1 || (data[i]?.user_type == 1 && data[i]?.collaboration_tenant_id && data[i]?.signed == 1)) {
                    foundUserType1 = true;
                    data[i].user_type = 1;
                }
            }
        }
        return data;
    }

    GetLayoutResources = async () => {
        console.log("Layout: GetLayoutResources");
        // if (this.isMounted) {
        let UserInfo = userInfoState.get({noproxy:true});
        // await Service.GetUserInfo().then(resp => {
        //     UserInfo = resp;
        // }).catch(err => {
        //     console.log(err);
        // });

        // console.log(UserInfo);
        this.setState({ UserInfo }, this.afterGetLayoutResources);
        // }
    };

    afterGetLayoutResources = () => {
        window.onbeforeunload = null;
        console.log("Layout => afterGetLayoutResources");
        if (this.componentDidMountExtends) {
            if (typeof this.componentDidMountExtends === "function") {
                this.componentDidMountExtends();
            }
        }
    }

    setPageObject = (page) => {
        let { t } = this.props;
        let { formData, fields, formValidate, fieldValidate, translateKeyPrefix } = this.state;

        page.props = this.props;
        page.title = `${translateKeyPrefix}${page.key}.title`;
        page.headerLabel = `${translateKeyPrefix}${page.key}.header`;
        page.headerInfo = t(page.headerLabel);

        if (!formValidate.hasOwnProperty(page.name)) {
            formValidate[page.name] = false;
        }
        if (!fieldValidate.hasOwnProperty(page.name)) {
            fieldValidate[page.name] = "";
        }

        page.fields = {};

        for (let i in page.formData) {
            if (!fields.hasOwnProperty(i)) {
                fields[i] = {
                    name: i,
                    value: "",
                    label: t(page.formData[i]),
                };
                if (page.hasOwnProperty("initialValues")) {
                    if (page.initialValues.hasOwnProperty(i)) {
                        fields[i].value = page.initialValues[i];
                    }
                }
            }
            page.fields[i] = fields[i];

            if (formData.hasOwnProperty(i)) {
                page.fields[i].value = formData[i];
            }

            page.formData[i] = page.fields[i].value;
            formData[i] = page.fields[i].value;
        }
        // this.setState({ formData });

        let { pageState } = this.state;
        if (!pageState.hasOwnProperty(page.name)) {
            pageState[page.name] = {
                completed: false,
            };
            this.setState({ pageState: pageState });
        }
        document.title = t(page.title || "common:welcome.title");
    };

    formSubmitHandler = (ev, page, fnCallback) => {
        ev.preventDefault();

        let { formValidate, fieldValidate, isInvalid } = this.state;
        if (page) {
            formValidate[page.name] = true;
            fieldValidate[page.name] = "was-validated";
        } else {
            formValidate = true;
            fieldValidate = {};
        }
        this.setState({ formValidate, fieldValidate }, () => {
            const form = ev.target;
            // console.log(form?.checkValidity(),fnCallback);
            for (let invalidField in isInvalid) {
                // console.log(isInvalid,invalidField,isInvalid[invalidField]);
                if (isInvalid[invalidField]) {
                    ev.stopPropagation();
                    return;
                }
            }
            // console.log(form?.checkValidity(), form, typeof fnCallback, fnCallback);
            if (form?.checkValidity && form?.checkValidity() === false) {
                ev.stopPropagation();

            } else {
                if (typeof fnCallback == "function") {
                    return fnCallback(ev);
                }
            }
            if (typeof fnCallback == "function" && !form?.checkValidity) {
                fnCallback(ev);
            }
        });
        // this.setState({ fieldValidate });
    };

    updateState = (state, cb) => {
        this.setState(state, cb);
    };

    ValidateMessage = (props) => {
        let { t } = this.props;
        let { field } = props;
        let { fieldValidate, activePage, msgValidate } = this.state;
        let tmpMsgValidate = "";
        const { name } = field;

        if (name === "amount" || name === "amount_from" || name === "amount_to" || name === "select_datenoti1") {
            tmpMsgValidate = field.msgValidate
        }

        if(name === 'temp_guests' || name === 'temp_signers' || name === 'temp_viewers') {
            tmpMsgValidate = field.msgValidate
        }

        if (!tmpMsgValidate || tmpMsgValidate === "") {
            let fieldTrans = t(`common:message.req.${field.label}`, field.label);
            tmpMsgValidate = t("common:message.input.required", { field_name: fieldTrans });
            if (activePage) {
                let pageName = activePage.name;

                if (fieldValidate[pageName].hasOwnProperty(field.name)) {
                    tmpMsgValidate = t(`common:${fieldValidate[pageName][field.name]}`);
                }
            } else if (fieldValidate.hasOwnProperty(field.name)) {
                tmpMsgValidate = t(`common:${fieldValidate[field.name]}`);
            }
            // if (field.name === "expiry_date") {
            //     msgValidate = t("common:message.input.required-expiry-date");
            // }
            // if (field.name === "select_datenoti1") {
            //     tmpMsgValidate = t("common:message.input.required-select_datenoti1");
            // }
        }

        return <span>{tmpMsgValidate}</span>;
    };

    FieldControl = (props) => {
        let { t } = this.props;
        let { name } = props;
        let { ValidateMessage } = this;
        let { formData, formValidate, fieldValidate, activePage, fields, msgValidate } = this.state;
        let newProps = { ...props };
        let isRequired = props?.required ?? true;

        let Prepend = () => { return null; }
        if (props.hasOwnProperty("prepend")) {
            Prepend = props.prepend;
            delete newProps.prepend;
        }

        let Append = () => { return null; }
        if (props.hasOwnProperty("append")) {
            Append = props.append;
            delete newProps.append;
        }

        let field = fields[name];
        if (!field) {
            return null;
        }

        if(name === 'temp_guests' || name === 'temp_signers' || name === 'temp_viewers') {
            field.msgValidate = props.msgvalidate
        }

        if(name === 'select_datenoti1') {
            newProps.onChange = (ev) => {
                console.log('onChange', ev);
                this.onChangeHandler(ev, null, field)
                let { isInvalid } = this.state;
                let repExpiry_date = formData.select_datenoti1.replaceAll("-", "/");
                let expiredDate = new Date(formData.expiry_date);
                let formatExpiredDate = Moment(expiredDate).format("DD/MM/YYYY");
                let currentDate = new Date();
                let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
                let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');
                if(!repExpiry_date) {
                    field.msgValidate = t("common:message.input.required-select_datenoti1")
                    isInvalid.select_datenoti1 = true
                }else if ((isNaN(calculateDateChk) || calculateDateChk < 0) && new Date(formData.select_datenoti1).getFullYear() > 1000) {
                    isInvalid.select_datenoti1 = true
                    field.msgValidate  = t("common:message.input.less-than-current-select_datenoti1")
                }else if (new Date(repExpiry_date) > expiredDate) {
                    field.msgValidate = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date : formatExpiredDate});
                    isInvalid.select_datenoti1 = true;
                } else {
                    isInvalid.select_datenoti1 = false;
                }
                this.setState({ formData, fields, isInvalid, msgValidate })
            }
        }

        if(name === 'expiry_date') {
            newProps.onChange = (ev) => {
                console.log('onChange', ev);
                this.onChangeHandler(ev, null, field)
                let { isInvalid } = this.state;
                let repExpiry_date = formData.select_datenoti1.replaceAll("-", "/");
                let expiredDate = new Date(formData.expiry_date);
                let formatExpiredDate = Moment(expiredDate).format("DD/MM/YYYY");
                if(formData.select_datenoti == 0){
                    if (new Date(repExpiry_date) > expiredDate) {
                        field.msgValidate = t("common:message.input.expiry_date_less_than_expiry_date_notification", { date : formatExpiredDate});
                        isInvalid.select_datenoti1 = true;
                    }else{
                        isInvalid.select_datenoti1 = false;
                    }
                }
                this.setState({ formData, fields, isInvalid, msgValidate })
            }
        }

        if (name === "amount" || name === "amount_from" || name === "amount_to") {
            newProps.style = { textAlign: 'right' };
            newProps.maxLength = "19";
            newProps.onInput = (e) => {
                if(typeof props.onInput === 'function'){
                    props.onInput(e)
                }
                const value = e.target.value;
                const parts = value.split('.');
                if (parts.length > 1 && parts[1].length > 6) {
                  e.target.value = `${parts[0]}.${parts[1].slice(0, 6)}`;
                }
                formData[e.target.name] = e.target.value;
                this.setState({ formData });
            }
            newProps.onBlur = (ev) => {
                let { isInvalid } = this.state;
                let val = ev.target.value;

                val = val.normalize('NFKC');
                val = val.replaceAll("ー", "-");
                val = val.replaceAll(",", "");
                val = val.trim();
                // console.log(val)
                if (isRequired && (!val || val === "")) {
                    let fieldTrans = t(`common:message.req.${field.label}`, field.label);
                    field.msgValidate = t("common:message.input.required", { field_name: fieldTrans });
                    isInvalid[name] = true;
                    if(activePage?.name === 'DocumentMultipleInfo' && name === 'amount' && this?.state?.formData?.preservation_require == 1){
                        isInvalid[name] = false;
                    }
                }
                else if (val !== '-' && (isNaN(val) || val === '00' || val === '-00')) {
                    field.msgValidate = t("common:message.input.invalid-amount");
                    isInvalid[name] = true;
                } else {
                    if (val.length > 0) {
                        isInvalid[name] = false;
                    }
                }
                if (!isInvalid[name]) {
                    val = this.localeStringCustom(val);
                }
                formData[ev.target.name] = val;

                this.setState({ formData,fields, isInvalid, msgValidate });
            }
            newProps.onClick = (ev) => {
                let { formData } = this.state;
                if (ev.target.value === "0" || ev.target.value === 0) {
                    formData[ev.target.name] = '';
                }
                formData[ev.target.name] = formData[ev.target.name].replaceAll(",", "");
                this.setState({ formData });
            }
            newProps.onKeyPress = (ev) => {
                if (ev.charCode === 32) {
                    ev.preventDefault();
                }
            };
            delete newProps.type;
        }
        if (newProps.hasOwnProperty("type") && newProps.type === "date") {
            newProps.max = "9999-12-31";
        }

        if (!props.hasOwnProperty("onChange") && !newProps.hasOwnProperty("onChange")) {
            newProps.onChange = (ev) => this.onChangeHandler(ev, null, field);
        }

        if (props.hasOwnProperty("change")) {
            // let curOnChange = newProps.onChange;
            newProps.onChange = (ev) => {
                // console.log(ev, name, activePage.name)
                // curOnChange(ev, props.change);

                this.onChangeHandler(ev, props.change, field);
            };
            delete newProps.change;
        }

        let groupProps = {};
        if (newProps.hasOwnProperty("key")) {
            groupProps.key = newProps.key;
            delete newProps.key;
        }
        if (newProps.hasOwnProperty("xs")) {
            groupProps.xs = newProps.xs;
            delete newProps.xs;
        }
        if (newProps.hasOwnProperty("md")) {
            groupProps.md = newProps.md;
            delete newProps.md;
        }
        if (newProps.hasOwnProperty("xl")) {
            groupProps.xl = newProps.xl;
            delete newProps.xl;
        }

        let validated = "";
        let validateField = fieldValidate;
        if (newProps.validate && [true, "true"].includes(newProps.validate)) {
            if (activePage) {
                let pageName = activePage.name;
                validateField = fieldValidate[pageName];
                if (formValidate[pageName]) {
                    validated = " was-validated";
                }
            } else if (formValidate) {
                validated = " was-validated";
            }
        }

        if (formValidate && validateField.hasOwnProperty(field.name)) {
            validated = " was-validated";
        }

        if (newProps.hasOwnProperty("validated") && [true, "true"].includes(newProps.validated)) {
            validated = " was-validated";
        }
        let optionLabel = props.optionLabel || {};

        return (
            <Form.Group {...groupProps} as={Col} controlId={field.name} className={`custom-form-outline${validated}`}>
                <Form.Label>
                    <span>{field.label}</span>
                    <span className={optionLabel.className}>{optionLabel.value}</span>
                </Form.Label>
                <InputGroup className="mb-2" hasValidation >
                    <Prepend />
                    <Form.Control
                        ref={this.state?.refOcr[field.name]}
                        name={field.name}
                        placeholder={field.label}
                        isValid={this.state.isInvalid.hasOwnProperty(field.name) && !this.state.isInvalid[field.name]}
                        isInvalid={this.state.isInvalid.hasOwnProperty(field.name) && this.state.isInvalid[field.name]}
                        value={[null, undefined].includes(formData[field.name]) ? "" : formData[field.name]}
                        {...newProps}
                    />
                    <Append />
                    <Form.Control.Feedback type="invalid">
                        <ValidateMessage field={field} />
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        );
    };

    prevPageStep = (ev, PageClass) => {
        let { processStepIndex } = this.state;
        let stepIndex = this.state.processStepIndex.indexOf(PageClass) - 1;
        // console.log('ev', ev,PageClass, stepIndex, processStepIndex)
        if (stepIndex > -1 && stepIndex < processStepIndex.length) {
            this.changePage(ev, processStepIndex[stepIndex], ()=>{
                if(processStepIndex[stepIndex].name === 'DocumentMultipleInfo'){
                    let { requiredField } = this.state;
                    requiredField["received_date"] = false;
                    requiredField["amount"] = false;
                    requiredField["counter_party_name"] = false;
                    if(this.state.formData?.preservation_require == 1){
                        requiredField["received_date"] = true;
                        requiredField["amount"] = true;
                        requiredField["counter_party_name"] = true;
                    }
                    this.setState({ requiredField }, ()=>{
                        // console.log(document.getElementById("received_date"))
                        document.getElementById("received_date")?.setAttribute("style", "");
                        document.getElementById("amount")?.setAttribute("style", "");
                        document.getElementById("counter_party_name")?.setAttribute("style", "");
                        // console.log(this.state.formData?.preservation_require)
                        if(this.state.formData?.preservation_require == 1){
                            document.getElementById('received_date')?.removeAttribute('required');
                            document.getElementById('amount')?.removeAttribute('required');
                            document.getElementById('counter_party_name')?.removeAttribute('required');
                            document.getElementById('received_date')?.checkValidity();
                            document.getElementById('amount')?.checkValidity();
                            document.getElementById('counter_party_name')?.checkValidity();
                        }
                    });
            }
            });
        }
    };

    nextPageStep = (ev, PageClass) => {
        let { processStepIndex } = this.state;
        let stepIndex = this.state.processStepIndex.indexOf(PageClass) + 1;
        if (stepIndex > -1 && stepIndex < processStepIndex.length) {
            this.changePage(ev, processStepIndex[stepIndex], ()=>{
                if(processStepIndex[stepIndex].name === 'DocumentMultipleInfo'){

                    let { requiredField } = this.state;
                    requiredField["received_date"] = false;
                    requiredField["amount"] = false;
                    requiredField["counter_party_name"] = false;
                    this.setState({ requiredField }, ()=>{
                        if(!requiredField["received_date"]) document.getElementById("received_date")?.setAttribute("style", "");
                        if(!requiredField["amount"]) document.getElementById("amount")?.setAttribute("style", "");
                        if(!requiredField["counter_party_name"]) document.getElementById("counter_party_name")?.setAttribute("style", "");
                    });
                }
            });
        }
    };

    changePage = (ev, PageClass, cb) => {
        this.setState({ activePage: PageClass }, cb ? ()=> cb():()=>{});
        // let PageProps = new PageClass(this);
        // this.setState({ PageProps: PageProps });
        // this.setLocationHash(PageProps.name);
    };

    setLocationHash = (step) => {
        // console.log(window.location)
        window.history.pushState({},'', `${window.location.pathname}#${step}`)
        // if(step && window.location.hash !== step) window.location.hash = step;
    };

    setFormData = (data) => {
        let { formData } = this.state;
        for (let i in data) {
            formData[i] = data[i];
        }

        this.setState({ formData });
    };

    localeStringCustom = (val) => {
        let isNegative = false
        if (val.startsWith('-')) {
            isNegative = true;
            val = val.substring(1);
        }
        let str = val.split('.');
        if (str.length > 0) {
            if (str[0] !== '0') {
                str[0] = str[0].replace(/^0+/, '').split(/(?=(?:...)*$)/).join(",");
            }
        }
        if (isNegative) {
            return '-' + str.join('.')
        }
        return str.join('.');
    }

    onChangeHandler = (ev, onChangeCB, field) => {
        let key = ev.target.name;
        let val = ev.target.value;
        if (ev.target.type === "checkbox") {
            val = ev.target.checked;
        }

        let { formData, msgValidate } = this.state;
        if (key === 'amount' || key === 'amount_from' || key === 'amount_to') {
            let { t } = this.props;
            if (val === "") {
                let fieldTrans = t(`common:message.req.${field.label}`, field.label);
                msgValidate = t("common:message.input.required", { field_name: fieldTrans });
            }
        }
        formData[key] = val;

        if (ev.target.required) {
            ev.target.closest(".has-validation").classList.add("was-validated");
        }
        // console.log(val);
        if(field && this.state.activePage){
            const { name } = field;
            let {requiredField, activePage, isInvalid} = this.state;
            // console.log(val,val == 1, name)
            if(name === 'preservation_require'){
                if(val == 1){
                    requiredField["received_date"] = false;
                    requiredField["amount"] = false;
                    requiredField["counter_party_name"] = false;
                    isInvalid.received_date = false
                    isInvalid.counter_party_name = false
                    isInvalid.amount = false
                    this.setState({requiredField: {...requiredField}, isInvalid}, ()=>{
                        document.getElementById('received_date')?.removeAttribute('required');
                        document.getElementById('amount')?.removeAttribute('required');
                        document.getElementById('counter_party_name')?.removeAttribute('required');
                        document.getElementById('received_date')?.setAttribute('style',  "")
                        document.getElementById('amount')?.setAttribute('style',  "")
                        document.getElementById('counter_party_name')?.setAttribute('style',  "")
                        document.getElementById('received_date')?.checkValidity();
                        document.getElementById('amount')?.checkValidity();
                        document.getElementById('counter_party_name')?.checkValidity();
                    });
                } else {
                    requiredField["received_date"] = false;
                    requiredField["amount"] = false;
                    requiredField["counter_party_name"] = false;
                    isInvalid.received_date = !this?.state?.formData?.received_date
                    isInvalid.counter_party_name = !this?.state?.formData?.counter_party_name
                    isInvalid.amount = !this?.state?.formData?.amount
                    this.setState({requiredField: {...requiredField}, isInvalid}, ()=>{
                        document.getElementById('received_date')?.setAttribute('required',  "true");
                        document.getElementById('amount')?.setAttribute('required',  "true");
                        document.getElementById('counter_party_name')?.setAttribute('required',  "true");
                        document.getElementById('received_date')?.setAttribute('style',  "")
                        document.getElementById('amount')?.setAttribute('style',  "")
                        document.getElementById('counter_party_name')?.setAttribute('style',  "")
                        document.getElementById('received_date')?.checkValidity();
                        document.getElementById('amount')?.checkValidity();
                        document.getElementById('counter_party_name')?.checkValidity();
                        console.log('run here')
                    });

                }

            }
            if((name === 'amount' || name === 'counter_party_name' || name === 'received_date') && activePage?.name === 'DocumentMultipleInfo'){
                // requiredField['amount'] = false;
                // requiredField['counter_party_name'] = false;
                document.getElementById(name)?.setAttribute('style',  "")
                // document.getElementById('amount')?.setAttribute('style',  "")
                // document.getElementById('counter_party_name')?.setAttribute('style',  "")

                this.setState({requiredField: {...requiredField}, isInvalid: {...isInvalid,[name]: false}}, ()=>{

                    if (![1, "1"].includes(this.state.formData.preservation_require)) {
                        document.getElementById(name)?.setAttribute('required',  "true");
                        document.getElementById(name)?.checkValidity()
                    } else {
                        document.getElementById(name)?.removeAttribute('required');
                        document.getElementById(name)?.checkValidity()
                    }
                });
            }
        }
        this.setState({ formData, msgValidate }, onChangeCB);
    };

    modalToggle = (toggle = null) => {
        let { modalProps, modal } = this.state;
        modalProps.show = toggle;
        modal.props.show = toggle;
        if (toggle !== true) {
            modalProps.show = false;
            modal.props.show = false;
        }

        this.setState({ modalProps, modal });
    };

    handleScroll(event) {
        const scrollPosition = event.target.scrollTop;
        if (scrollPosition > 50) {
            shedOnScroll.set(true);
        } else {
            shedOnScroll.set(false);
        }
    }


    /** please use function componentDidMountExtends intread */
    componentWillUnmount() {
        if( this.scrollContainerRef.current) this.scrollContainerRef.current.removeEventListener('scroll', this.handleScroll);
        this.isMounted = false;
    }

    /** please use function componentDidMountExtends intread */
    componentDidMount() {
        const currentPathname = window.location.pathname;
        if(currentPathname.startsWith("/settings")) this.setState({isSettingPage: true})
        else this.setState({isSettingPage: false})
        if( this.scrollContainerRef.current)this.scrollContainerRef.current.addEventListener('scroll', this.handleScroll);
        console.log("Layout->componentDidMount");
        this.isMounted = true;
        this.GetLayoutResources();

        // 	let { pageState, processStepNameString } = this.state;
        // 	let { history } = this.props;
        // 	// console.log(history);
        // 	history.listen((location, action) => {
        // 		let pageName = location.hash.substr(1);
        // 		let PageProps = this.state.activePage;
        // 		if (Object.keys(pageState).includes(pageName)) {
        // 			PageProps = processStepNameString[pageName];
        // 		}
        // 		this.changePage(null, PageProps);
        // 	});

        // 	window.onbeforeunload = (ev) => {
        // 		return "Are you sure?";
        // 	};
    }

    // componentWillUnmount() {
    // 	// let { pageState, processStepNameString } = this.state;
    // 	// let { history } = this.props;
    // 	// // console.log(history);
    // 	// history.listen((location, action) => {
    // 	// 	let pageName = location.hash.substr(1);
    // 	// 	let PageProps = this.state.activePage;
    // 	// 	if (Object.keys(pageState).includes(pageName)) {
    // 	// 		PageProps = processStepNameString[pageName];
    // 	// 	}
    // 	// 	this.changePage(null, PageProps);
    // 	// });
    // 	window.onbeforeunload = null;
    // 	// window.onbeforeunload = (ev) => {
    // 	// 	return "Are you sure?";
    // 	// };

    // }

    // componentDidUpdate(prevProps) {

    // 	// let CurrentForm = PageProps.render();
    // 	// console.log(this.props);
    // 	// document.title = this.props.t(this.state.activePage.title);
    // 	// console.log("componentDidUpdate", prevProps);
    // }

    MainLoading = () => {
        return (
            <div className="main-body-loading">
                <Loading />
            </div>
        );
    };

    LogoImage = () => {
        // let { UserInfo } = this.state;
        // console.log(UserInfo);

        return <StateFragment state={userInfoState}>{s => {
            const UserInfo = s.get({noproxy:true})
            // console.log(';diksjfolgsndối', this.state.UserInfo);
            if (!UserInfo || window.location.href.includes("/guest/document")) {
                return (
                    <div className="logo-wrapper">
                        <img src={logo} className="App-logo" alt="paperlogic" />
                    </div>
                );
            }

            return (
                <Link to={"/dashboard"} className="logo-wrapper">
                    <img src={logo} className="App-logo" alt="paperlogic" />
                </Link>
            );
        }}</StateFragment>

    };

    LayoutLeft = () => {
        let { t } = this.props;
        const { Menu, HiddenMenu, LogoImage, LanguesChange } = this;
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const toggleMenu = () => {
          setIsMenuOpen(!isMenuOpen);
        };

        return (
          <>
          <div className={`toggle-nav-button ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={`layout-left ${isMenuOpen ? "menu-open" : ""}`}>
                <div className="left-fixed-box">
                    <div className="left-head-box"></div>
                    <a className="logo-horizontal">
                        <LogoImage />
                    </a>

                    <div className="menu-container">
                        <div className="menu-box">
                            <Menu />
                            <HiddenMenu />
                        </div>

                        <div className="menu-bottom-container">
                            <div className="menu-bottom">
                                <div className="footerInfo">
                                    <LanguesChange />
                                    <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                                        {t("common:menu.terms-of-service")}
                                    </a>
                                    {/* <a href="https://paperlogic.co.jp/contact/" */}
                                    <a href="https://support.paperlogic.jp/contact/" target="_blank" rel="noopener noreferrer">
                                    {t("common:menu.contact-us")}
                                    </a>
                                </div>
                                <div className="copylight">
                                    <span>© {new Date().getFullYear()} paperlogic co., ltd.</span>
                                </div>
                                {/* <div className="copylight">
                                    <span>{t(`common:menu.version`)}: {localStorage.getItem("current-version")}</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
          </div>
          </>
        );
    };

    Main = (props) => {
        let { loading } = this.state;
        const { LayoutLeft, Nav, Body, GeneralModal, FormModal, MainLoading } = this;

        if (loading) {
            return <>  <StateFragment state={userInfoState}>{s => {
                // console.log(';diksjfolgsndối', this.state.UserInfo);
                if (s.get() && !this.state.UserInfo){
                    this.setState({UserInfo: s.get({noproxy: true})})
                }
            }}</StateFragment>
            <MainLoading /></>;
        }

        return (
            <div className="main contrainer-wrapper">
            <UrlHook></UrlHook>
            <Suspense fallback={<></>}>
                <div className="hidden d-none">
                    <BusinessDetailField
                        className={`hidden `}
                        field_name={'tsest'}
                        type={'tttt'}
                        value={0}
                        />
                            <BaseSuggestionPopover
                                anchorEl={null}
                                open={false}
                                items={[{name: 'test'}]}
                                handleClose={()=> {}}
                            />
                </div>
            </Suspense>

                <StateFragment state={userInfoState}>{s => {
                    // console.log(';diksjfolgsndối', s.get());
                    if (s.get() && !this.state.UserInfo){
                        this.setState({UserInfo: s.get({noproxy: true})})
                    }
                }}</StateFragment>
                <div className="layout">

                    <LayoutLeft />

                    <div className="layout-right">

                        <Nav {...props} />

                        <Body {...props} />

                        <div className="right-bottom"></div>

                    </div>

                    <GeneralModal />

                    <FormModal />

                </div>
            </div>
        );
    };

    ModalClose = () => {
        let { onExitedModal } = this.state;
        this.modalToggle(false);
        if (typeof onExitedModal === "function") {
            onExitedModal();
        }
    };

    ModalCloseButton = () => {
        let { t } = this.props;
        let { modalClose } = this.state;
        if (!modalClose) {
            return null;
        }

        return (
            <Button variant="outlined" onClick={this.ModalClose}>
                <span>{t("common:general.close")}</span>
            </Button>
        );
    };

    ModalHeader = () => {
        let { t } = this.props;
        let { modalTitle, modalClose } = this.state;

        return (
            <Modal.Header closeButton={modalClose}>
                <Modal.Title>{t(modalTitle)}</Modal.Title>
            </Modal.Header>
        );
    };

    ModalFooter = () => {
        let { ModalAction } = this.state;
        let { ModalCloseButton } = this;
        return (
            <Modal.Footer>
                <ModalCloseButton />

                {ModalAction}
            </Modal.Footer>
        );
    };

    GeneralModal = () => {
        // let { t } = this.props;
        let { ModalAction, modalProps, ModalBody } = this.state;
        let { ModalHeader, ModalCloseButton } = this;
        // console.log(modalProps);//
        return (
            <Modal {...modalProps} animation={false} onHide={this.ModalClose}>
                <ModalHeader />

                <Modal.Body>
                    <ModalBody />
                </Modal.Body>

                <Modal.Footer>
                    <ModalCloseButton />

                    {ModalAction}
                </Modal.Footer>
            </Modal>
        );
    };

    FormModal = () => {
        let { t } = this.props;
        let { modal } = this.state;

        let ModalTitle = t(modal.title || "");
        ModalTitle = t(`common:${ModalTitle}`);

        let BodyContent = modal.body || "";
        let ModalBody = BodyContent;
        if (typeof BodyContent == "string") {
            ModalBody = () => {
                let bodyMessage = BodyContent;
                // try to translate
                bodyMessage = t(bodyMessage);
                bodyMessage = t(`common:${bodyMessage}`);
                return bodyMessage;
            };
        }

        if (modal.close === false) {
            modal.props.backdrop = "static";
        }

        let ModalClose = (ev) => {
            this.modalToggle(false);
            // console.log(modal.onClose);
            if (typeof modal.onClose === "function") {
                modal.onClose(ev);
            }
        };

        let ModalCloseButton = () => {
            if (modal.close === false) {
                return null;
            }
            return (
                <Button variant="outlined" onClick={ModalClose}>
                    <span>{t("common:general.close")}</span>
                </Button>
            );
        };

        if (!modal.hasOwnProperty("close")) {
            modal.close = true;
        }

        let ActionButtons = () => {
            if (modal.action) {
                if (modal.action instanceof Array) {
                    return modal.action;
                } else {
                    return <modal.action />;
                }
            } else {
                return null;
            }
        };

        let BodyAction = () => {
            if (modal.bodyaction) {
                if (modal.bodyaction instanceof Array) {
                    return modal.bodyaction;
                } else {
                    return <modal.bodyaction />;
                }
            } else {
                return null;
            }
        };

        return (
            <Modal {...(modal.props || {})} animation={false} onHide={ModalClose}>
                <Form {...(modal.form || {})} onKeyDown={(ev) => {
                    if (ev.code === "Enter") {
                        ev.preventDefault();
                        return false;
                    }
                }}>
                    <Modal.Header closeButton={modal.close}>
                        <Modal.Title>{ModalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-action">
                            <BodyAction />
                        </div>
                        <ModalBody />
                    </Modal.Body>

                    <Modal.Footer>
                        <ModalCloseButton />

                        <ActionButtons />
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    };

    ShowErrorMessage = (errReponse, btnAction = []) => {
        let { modal } = this.state;

        let errMessage = errReponse.data.message;
        if (errReponse.data.hasOwnProperty("error")) {
            errMessage = errReponse.data.error;
        }

        modal.body = errMessage;
        modal.action = btnAction;
        this.setState({ modal });
    };

    Menu = (props) => {
        return (
            <div className="main contrainer-wrapper">
                {props.children}
            </div>
        );
    };

    HiddenMenuBody = () => {
        let { HiddenMenuContent } = this.state;
        let BodyContent = HiddenMenuContent;
        if (!HiddenMenuContent) {
            return null;
        } else if (typeof HiddenMenuContent === "string") {
            return HiddenMenuContent;
        }
        return <BodyContent />;
    };

    HiddenMenu = () => {
        let { showHiddenMenu } = this.state;

        let active = "";
        if (showHiddenMenu) {
            active = " active";
        }
        let { HiddenMenuBody } = this;

        return (
            <div className={`menu-hidden${active}`}>
                <HiddenMenuBody />
            </div>
        );
    };

    ProfileAvatar = ProfileAvatar;


    setLang = (lang) => {

        userInfoState.set((v) => ({ ...v, language: lang }));
        localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy:true})))
        currentLang.set(lang);
        // console.log(lang)
        i18n.changeLanguage(lang).then(()=>{
            this.setState({ settinglangMenu: null });
        });
        Service.UpdateLanguage(lang)
            .then((resp) => {
                // window.location.reload()
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    LanguesChange = (props) => {
        let { t } = this.props;
        let { settinglangMenu } = this.state;
        let loading = " data-loading";
        const openlang = Boolean(settinglangMenu);
        const handleClick = (event) => {
            this.setState({ settinglangMenu: event.currentTarget });
        };

        const handleClose = () => {
            this.setState({ settinglangMenu: null });
        };
        return (
            <div>
                <Button
                    id="menu-lang-button"
                    aria-controls={openlang ? "menulang" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openlang ? "true" : undefined}
                    onClick={handleClick}
                >
                    <div className="">
                        <div className="info-context">
                            <LanguageIcon fontSize="small" />
                            <span className="name">
                                {t("common:main.general.setting-lang")}
                            </span>
                        </div>
                    </div>
                </Button>
                <Menu
                    id="menulang"
                    anchorEl={settinglangMenu}
                    open={openlang}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "menu-lang-button",
                    }}
                    className={"profile-menu" + loading}
                    color="gray"
                >
                    <MenuItem lang="ja" onClick={() => this.setLang("ja")}
                    >
                        {t("common:main.general.japanlang")}
                    </MenuItem>
                    <Divider />
                    <MenuItem lang="en" onClick={() => this.setLang("en")}>
                        {t("common:main.general.englang")}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    UserInfoMenu = (props) => {
        let { t } = this.props;
        let { userInfoMenuAnchorEl } = this.state;
        // let { ProfileAvatar } = this;
        return <StateFragment state={userInfoState}>{s => {
            const UserInfo = s.get({noproxy:true});
            // console.log(';diksjfolgsndối', this.state.UserInfo);
            let UserFullname = "";
            let TenantName = "";
            let loading = " data-loading";
            let iconProps = {};
            if (UserInfo) {
                UserFullname = `${UserInfo.family_name} ${UserInfo.first_name}`;
                TenantName = `${UserInfo.company_name}`;
                loading = "";
                // iconProps.avatar = UserInfo.profile_image;
                iconProps.avatar = localStorage.getItem("profile_image");
            }
            const open = Boolean(userInfoMenuAnchorEl);
            const handleClick = (event) => {
                this.setState({ userInfoMenuAnchorEl: event.currentTarget });
            };
            const handleClose = () => {
                this.setState({ userInfoMenuAnchorEl: null });
            };
            return (
                <div>
                    <Button
                        id="menu-button"
                        aria-controls={open ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        className={"profile-menu" + loading + " profile"}
                    >
                        <div className="profile-icon">
                            <ProfileAvatar {...iconProps} />
                        </div>
                        <div className="profile-info">
                            <div className="info-context">
                                <span className="name">{UserFullname}</span>
                                <span className="tenant">{TenantName}</span>
                            </div>
                        </div>
                    </Button>
                    <Menu
                        id="menu"
                        anchorEl={userInfoMenuAnchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "menu-button",
                        }}
                        className={"profile-menu" + loading}
                        color="gray"
                    >
                        <Link to={"/settings/profile"}>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <ManageAccountsIcon color="primary" />
                                </ListItemIcon>
                                {t("common:main.general.user-setting")}
                            </MenuItem>
                        </Link>
                        <Divider />
                        <Link to={"/logout"}>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <LogoutIcon color="primary" />
                                </ListItemIcon>
                                {t("common:auth.general.logout")}
                            </MenuItem>
                        </Link>
                    </Menu>
                </div>
            );
        }}</StateFragment>

    };

    handleSearchKeyEnter = (event) => {
        const { keyCode, target } = event;

        const { value: searchString } = target;

        if (keyCode === 13) {
            this.setState({
                searchInput: searchString,
            })
            this.handleSearchDocumentMultiField(searchString);
        }
    };

    handleChangeSearch = (event) => {
        this.setState({
            searchInput: event.target.value,
        })
    };

    handleClearSearch = () => {
        this.setState({
            searchInput: "",
        })
        this.handleSearchDocumentMultiField("");
    };

    NavLeft = () => {
        const { function_type } = this.state;

        let { t } = this.props;

        return (
            (
                function_type === 1 ||
                function_type === 2 ||
                function_type === 3 ||
                function_type === 4
            ) && (
                <div className={"search-round-container"}>
                    <TextField
                        placeholder={t("common:documents.general.search.place-holder")}
                        className={"search-round"}
                        variant="outlined"
                        size={"small"}
                        sx={{
                            "& fieldset": {
                                border: 'none',
                            },
                        }}
                        value={this.state.searchInput}
                        onChange={this.handleChangeSearch}
                        onKeyDown={this.handleSearchKeyEnter}
                        style={{
                            borderRadius: 100,
                        }}
                        InputProps={{
                            style: {
                                height: 34,
                                fontSize: 14,
                                borderTopRightRadius: 100,
                                borderBottomRightRadius: 100,
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                this.state?.searchInput?.length > 0 && (
                                    <InputAdornment
                                        className="header-search-close"
                                        position="end"
                                        onClick={this.handleClearSearch}
                                    >
                                        <Close />
                                    </InputAdornment>
                                )
                            )
                        }}
                    />
                </div>
            )
        );
    };

    SystemNotification = () => {
        let { UserInfo } = this.state;
        let systemNotification = "";

        if (UserInfo) {
            systemNotification = UserInfo.content;
        }
        return (
            <div className="system-notification">
                {systemNotification}
            </div>
        );
    };

    TenantSwitch = () => {
        // let { t } = this.props;
        // let { UserInfo } = this.state;
       return <StateFragment state={userInfoState}>{s=>{
            const UserInfo = s.get({noproxy: true});
            // console.log('UserInfo', UserInfo)
            if (!UserInfo) {
                return null;
            }

            if (UserInfo.tenants.length === 1) {
                return (
                    <div className="icon tenant-switch">
                        <span>{UserInfo.tenant_name}</span>
                    </div>
                );
            }

            let TenantList = [];
            for (let tenant of UserInfo.tenants) {
                // console.log(tenant);
                TenantList.push(
                    <MenuItem value={tenant.id} key={tenant.id}>
                        {tenant.name}
                    </MenuItem>
                );
            }
            // console.log(UserInfo)
            return (
                <div className="icon tenant-switch">
                    <Box py={1} px={2}>
                        <Select
                            value={UserInfo.tenant_id}
                            style={{ color: "#007BFF" }}
                            onChange={(ev) => {
                                // console.log(document.querySelector('#loader').add('loader--hide'))
                                // loader?
                                appLoadingShow.set(true);
                                let tenant_id = parseInt(ev.target.value);
                                if('serviceWorker' in navigator){
                                    navigator.serviceWorker.ready.then((registration) => {
                                      if(registration?.active){
                                          // registration.dispatchEvent
                                          registration.active.postMessage(
                                              "cache-clear-logout"
                                          );
                                      }
                                    });
                                }
                                Service.TenantSwitch({ tenant_id }).then((resp) => {
                                    // Service.deleteUserControl().then((response) => {
                                    //     // window.location.reload();
                                          const apiPromises = [
                                            GetUserInfoNew(),
                                          ];
                                          Promise.all(apiPromises)
                                          .finally(() => {
                                            localStorage.removeItem('prev_host_signer_list');
                                            localStorage.removeItem('prev_viewer_list');
                                            localStorage.removeItem('prev_verification_viewer_list');
                                            localStorage.removeItem('prev_use_digital_certificate');
                                            if (this.props?.history){
                                                this.props.history.go(0);
                                            } else {
                                                window.location.reload();
                                            }
                                          });
				                        // const user = localStorage.getItem('user-info');
                                        // if (user){
                                        // }

                                    // }).catch((error) => {
                                    //     //console.log("CATCH", error);
                                    // })
                                }).catch((err) => {
                                    appLoadingShow.set(false)
                                    //console.log("error", err.response);
                                });
                            }}
                            renderValue={() => UserInfo.tenant_name}
                            variant={"standard"}
                        >
                            {TenantList}
                        </Select>
                    </Box>
                </div>
            );
        }}</StateFragment>

    };

    Nav = (props) => {
        let { NavLeft, TenantSwitch, UserInfoMenu, SystemNotification } = this;
        let { t } = this.props;

        return (
            <div className="layout-right-top">
                <NavLeft />

                <div className="top-middle">
                    <SystemNotification />
                </div>

                <div className="top-right">
                    <div className="icon notification" style={{ display: "none" }}>
                        <IconNotification />
                    </div>
                    <Tooltip title={t("common:tooltip.common.go-to-faq")} arrow>
                        <IconButton
                            aria-label="FAQ"
                            href={window.location.pathname.includes('guest/document/')? "https://support.paperlogic.jp/manual/manual-24301/":"https://support.paperlogic.jp/"}

                            target="_blank"
                        >
                            <HelpOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <TenantSwitch />
                    <UserInfoMenu />
                </div>
            </div>
        );
    };

    Body = (props) => {
        // console.log(props);
        // console.log(props.children);
        var BodyContent = props.children;
        if (!BodyContent && this.state.activePage) {
            let { activePage } = this.state;
            let PageProps = new activePage(this);
            BodyContent = PageProps.render();
        }

        return (
            <div className={this.state.isSettingPage ? "right-body page-setting": "right-body"} ref={this.scrollContainerRef}>
                {BodyContent}
            </div>
        );
    };

    render() {
        const { Main } = this;

        return <Main />;
    }
}

export default Layout;
