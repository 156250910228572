// @ts-nocheck
import React from 'react';
import { Link } from "react-router-dom";
import {
	Col,
	Form,
	Tabs,
	Tab,
	Container,
} from 'react-bootstrap';
// import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Service from '../../Service';
import PDFViewer from '../Fragments/PDFViewer';
import Loading from '../Loading';
import ProfileAvatar from '../../Layout/ProfileAvatar';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import VerifiedIcon from '@mui/icons-material/Verified';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PeopleIcon from '@mui/icons-material/People';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { userInfoState } from '../../drive-feature/data-access/state';
import { replacerFunc } from '../../Utils/init_observer';
import ClipIcon from '../Assets/img/icons/clip_icon_primary.svg';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SwitchCustomize, { Android12Switch } from '../../Components/switch-customize/switch-customize';
import { valueSearchHost } from './HostSetting';
import { valueSearchGuest } from './GuestSetting';
import { valueSearchViewer } from './ViewerSetting';
import { word2PdfValidateData } from './TemplateData';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LoadingProgress from '../Fragments/PDFViewer/LoadingProgress';
import ModalUpload, { modalState, modalUploadProps } from '../Components/ModalUpload/ModalUpload';
import {
	documentProcessState,
	DocumentProcessStatus,
} from "../../Components/document-process-state/document-process-state";
import {documentMainList} from "../../Layout";

export default class Determine {

	static get key() {
		return "determine";
	}
	static get name() {
		return "Determine";
	}
	static get code() {
		return Determine.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = Determine.key;
		this.name = Determine.name;
		this.code = Determine.code;
		this.formData = {};
		// console.log('resp docInfo', this.objMain.state.formData ,this.formData);
		this.documentProcessId = window.crypto.randomUUID();

		this.objMain.setPageObject(this);
		// console.log(this.objMain.formData);
		// this.pdfDocOptions = {
		// 	cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
		// 	cMapPacked: true,
		// };
	}

	formSubmitCallback = (ev) => {
		// this.formData.term_condition = "Accepted";
		// this.objMain.setFormData(this.formData);
		// this.objMain.setActivePage(UserForm);
	};

	pageSubmit = (ev) => {
		// console.log(ev);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	onChangeFile = (ev, key) => {
		let { PDFFiles, selectedFile } = this.objMain.state;
		if (key !== selectedFile) {
			if (!PDFFiles[selectedFile].file) {
				this.loadPDFFile();
			}
			this.objMain.updateState({ selectedFile: key });
			this.objMain.updateState({ PDFLoadSuccess: false });
		}
	}

	AttributeElement = (props) => {
		let {attributes} = props;
		let attrElement = [];
		for (let i in attributes) {
			let attr = attributes[i];
			attrElement.push(
				<Form.Row key={attr.key + "-" + i}>
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{attr.key}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{height: "auto"}}>
						<span style={{whiteSpace: "normal", wordBreak: "break-all"}} className="form-control document-info-value">{attr.value}</span>
					</Form.Group>
				</Form.Row >
			);
		}

		return attrElement;
	}

	renderDigitalSignatureControl = (signer, company_seal) => {
		if (company_seal) {
			return "";
		} else {
			let has_digital_certificate = false;
			let use_digital_certificate = false;
			if (signer.hasOwnProperty("has_digital_certificate")) {
				if ([true, "true"].includes(signer.has_digital_certificate)) {
					has_digital_certificate = true;
					if ([true, "true"].includes(signer.use_digital_certificate)) {
						use_digital_certificate = true;
					}
				}
			}
			return (
				<Form.Check
					type="switch"
					// value={"digital-signature-" + signer.id}
					// onChange={ev => { this.setDigitalCertificateFlag(ev, signer.id) }}
					id={"digital-signature-" + signer.id}
					label={"JCAN"}
					checked={use_digital_certificate}
					disabled={!has_digital_certificate}
				/>
			);
		}
	}

	submitDocumentData = (ev) => {
		let { formData } = this.objMain.state;
		Service.CreateDocument(formData).then(resp => {
			// console.log(resp);
		}).catch(err => {
			//console.log(err.response);
		});
	}

	dateToString = (value) => {
		// console.log(value);
		try {
			value = new Date(value);
			return value.toISOString();
		} catch (ex) {
			return value.toString();
		}
	}

	DocumentSavingLogBody = () => {
		let { t } = this.props;
		let { DocumentSavingLog } = this.objMain.state;
		let logs = [];
		for (let i in DocumentSavingLog) {
			logs.push(
				<p key={i} className="log-link">{t(DocumentSavingLog[i])}</p>
			)
		}
		return logs;
	}

	checkContractCapacity(callback) {
		let { t } = this.props;

		let { modalTitle, ModalBody, ModalAction, modalProps, UserInfo } = this.objMain.state;

		if (UserInfo['over_contract_capacity']) {
			modalTitle = "common:tenant.checking-contract-capacity";
			modalProps = {
				"show": true,
				"centered": true,
				"backdrop": "static",
			}
			ModalBody = () => {
				return (
					<div>{t(`common:tenant.over-contract-capacity`)}</div>
				);
			};
			ModalAction = [];
			ModalAction.push(
				<Button
					key="ok"
					sx={{ml: 1}}
					variant="contained"
					component={Link}
					onClick={(ev) => {
						callback();
					}}
				>
					<span>{t("common:general.myself-accept")}</span>
				</Button>
			);
			this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
		} else {
			callback();
		}
	}

	onSubmit = (ev, draft = false) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps, UserInfo, formData, creationType } = this.objMain.state;

		if (creationType === "sender") {
			let countGuest = Object.keys(formData.guests).length;
			if (countGuest === 0) {
				modalTitle = "common:documents.determine.saving";
				modalProps = {
					"show": true,
					"centered": true,
					"backdrop": true,
				}
				ModalBody = () => {
					return (
						<div>{t("common:documents.please-select-guest")}</div>
					);
				};

				this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
				return;
			}
		}

		modalTitle = "common:documents.determine.saving";
		modalProps = {
			"show": true,
			"centered": true,
			"backdrop": true,
		}

		if (draft) {
			ModalBody = () => {
				return (
					<div>{t("common:documents.save.draft-confirm-question")}</div>
				);
			};
		} else if (parseInt(UserInfo.user_id) === parseInt(formData.verify_user) && parseInt(formData.function_type) === 4) {
			ModalBody = () => {
				return (
					<div>{t("common:documents.save.timestamp-confirm-question")}</div>
				);
			};
		} else {
			ModalBody = () => {
				return (
					<div>{t("common:documents.save.send-mail-confirm-question")}</div>
				);
			};
		}
		ModalAction = [
			(<Button
				key="ok"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={(ev) => {
					this.onSubmitConfirm(ev, draft);
				}}>
				<span>{t("common:general.confirm")}</span>
			</Button>
			),
		];
		this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	onSubmitConfirm = (ev, draft = false) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps, creationType, formData, PDFFiles } = this.objMain.state;
		// modalTitle = "common:documents.determine.saving";
		// ModalBody = Loading;
		// modalProps.backdrop = "static";
		// ModalAction = [];
		// this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
		documentProcessState.set((prevState) => ({
			...prevState,
			[this.documentProcessId]: {
				upload: {
					index: 0,
					total: Object.keys(PDFFiles || {}).length,
				},
				status: DocumentProcessStatus.UPLOADING,
				data: {
					...this.objMain.state,
				}
			}
		}))

		window.onbeforeunload = null;
		this.objMain.unlisten();
		this.props.history.push(`/${creationType}`);
		this.GetCurrentUserInfo(draft);
	}

	GetCurrentUserInfo = async (draft) => {
		let { creationType, creationMode, PDFFiles, formData, UserInfo } = this.objMain.state;
		// Service.GetUserInfo().then(async resp => {
		if ((creationType === "verification" && (creationMode === "multiple" || creationMode === "by-template")) || (creationType === "sender" && creationMode === "batch")) {
			let { ModalBody, modalTitle, modalProps } = this.objMain.state;
			let { t } = this.props;
			let multiFormData = [];
			let viewers = [];
			let signers = {};
			let auto_verify = false;

			if(creationType === "verification" && (creationMode === "multiple" || creationMode === "by-template")){
				for (let user of Object.values(formData.viewers)) {
					viewers.push(user.id);
				}
			}
			if(creationType === "sender" && creationMode === "batch"){
				for (let user of Object.values(formData.viewers)) {
					console.log('viewers', user);
					viewers.push({
						user_id: user.id,
						send_mail: user.send_mail == 1 ? 1 : 0
					});
				}
			}


			for (let user of Object.values(formData.signers)) {
				if(creationType === "sender" && creationMode === "batch"){
					let cert_type = parseInt(formData.certificate_type);
					let self_certificate = formData.use_digital_certificate && user.self_certificate;
					// console.log('hello', self_certificate, cert_type, formData.company_seal)
					signers[user.id] = {
						id: user.id,
						user_type: formData.company_seal ? 2 : 0,
						name: user.name,
						email: user.email,
						self_certificate: self_certificate,
						certificate_type: cert_type,
						use_sign_imprint: false,
						sign_detail: null,
					};
				} else {
					signers[user.id] = {
						id: user.id,
						user_type: 0,
						name: user.name,
						email: user.email,
						self_certificate: false,
						certificate_type: 0,
						use_sign_imprint: false,
						sign_detail: null,
					};
				}

				if(creationType === "sender" && creationMode === "batch"){
					signers[user.id].sign_seq = user.sign_seq
				}

				if (user.id === UserInfo.user_id && !draft) {
					auto_verify = true;
				}
			}

			let listEmailGuestInCsv = [];
			if(creationType === "sender" && creationMode === "batch"){
				for (let key in PDFFiles) {
					let PDFFile = PDFFiles[key];
					console.log('PDFFile.formData.guests', PDFFile.formData.guests);
					if(PDFFile.formData.guests && typeof PDFFile.formData.guests === 'string' && PDFFile.formData.guests?.replace(/;/g, ",")?.length > 0) {
						listEmailGuestInCsv = [...listEmailGuestInCsv, ...PDFFile.formData.guests?.replace(/;/g, ",")?.split(",")]
					}
				}
				const listGuestSearch = listEmailGuestInCsv.length > 0 ? await Service.ValidateWord2PdfData("sender", [], listEmailGuestInCsv, []) : null;
				if(listGuestSearch){
					word2PdfValidateData.set({
						guests: listGuestSearch?.payload?.guests
					})
				}else{
					word2PdfValidateData.set({
						guests: null
					})
				}
			}

			let fileIndexProccesse = 1;
			// modalProps.show =  false;
			// this.objMain.updateState({modalProps})
			// modalUploadProps.set({
			// 	"show": true,
			// 	"centered": true,
			// 	"backdrop": true,
			// });
			// modalState.modalTitle.set(modalTitle);
			// modalState.fileLength.set(Object.keys(PDFFiles || {}).length);

			const tempMultiFormData = Object.keys(PDFFiles || {}).map(key => PDFFiles[key]).map(item => ({
				doc_title: item?.formData?.doc_title
			}))

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				data: {
					...this.objMain.state,
					multiFormData: tempMultiFormData,
				},
			}))

			for (let key in PDFFiles) {
				// modalState.fileUploadIndex.set(fileIndexProccesse);
				// modalState.process.set(0);
				// documentListSnackbarState.set({
				// 	open: true,
				// 	message: this.snackBarMessageComponent(Object.keys(PDFFiles || {}).length, fileIndexProccesse),
				// })

				documentProcessState?.[this.documentProcessId].set(prevState => ({
					...prevState,
					upload: {
						index: fileIndexProccesse,
						total: Object.keys(PDFFiles || {}).length,
					}
				}))

				let PDFFile = PDFFiles[key];
				let tempFormData = {};
				tempFormData.tenant_id = UserInfo.tenant_id;

				tempFormData.drive_folder_id = PDFFile.formData.drive_folder_id;
				tempFormData.shared_folders_path = PDFFile.formData.shared_folders_path;
				tempFormData.is_applying_guest_mfa = PDFFile.formData['is_applying_guest_mfa'] ?? false;
				tempFormData.document_type = PDFFile.formData.document_type;
				tempFormData.doc_title = PDFFile.formData.doc_title;
				tempFormData.amount = PDFFile.formData.amount.toString().replaceAll(",", "");
				if(PDFFile.formData.invoice_number && typeof PDFFile.formData.invoice_number === 'object'){
					tempFormData.invoice_number = PDFFile.formData.invoice_number?.registrated_number;
				}else{
					tempFormData.invoice_number = PDFFile.formData.invoice_number;
				}
				tempFormData.currency = PDFFile.formData.currency;
				tempFormData.product = PDFFile.formData.product;
				tempFormData.reference = PDFFile.formData.reference;
				tempFormData.filebox_shortcut = PDFFile.formData.filebox_shortcut;

				tempFormData.function_type = 4; // PDFFile.formData.function_type; // 1:SiGNER, 2:SeNDER, 4:VeRIFICATION

				console.log(tempFormData.received_date);
				console.log(PDFFile.formData.received_date);
				tempFormData.received_date = PDFFile.formData.received_date;
				tempFormData.counter_party_name = PDFFile.formData.counter_party_name;
				tempFormData.preservation_require = PDFFile.formData.preservation_require;

				tempFormData.doc_attributes = {};
				for (let attr of PDFFile.formData.doc_attributes) {
					tempFormData.doc_attributes[attr.key] = attr.value;
				}

				tempFormData.signers = signers;

				tempFormData.viewers = viewers;

				tempFormData.files = {};
				let newFile = {
					hash: key,
					name: PDFFile.name,
					size: PDFFile.size,
					type: PDFFile.type,
					temp: PDFFile.temp,
					lastModified: this.dateToString(PDFFile.lastModified),
					lastModifiedDate: this.dateToString(PDFFile.lastModifiedDate),
					regenerate_cover: PDFFile.regenerate_cover,
				};

				if (!newFile.temp) {
					let tempInfo = await Service.FileUpload(PDFFile.file, "", "", "", (progress) => {
						// modalState.process.set(progress)
						}).catch(err => {
						//console.log(err.response);
					});
					if (!tempInfo) {
						return;
					}
					fileIndexProccesse++;
					newFile.temp = tempInfo.temp;
				}


				if (PDFFile.hasOwnProperty("password")) {
					newFile.password = PDFFile.password;
				}

				tempFormData.files[key] = newFile;

				if (PDFFile.attachments) {
					tempFormData.attachments = {};
					for (const attachment of PDFFile.attachments) {
						let newAttachmentFile = {
							hash: attachment.hash,
							name: attachment.name,
							size: attachment.size,
							type: attachment.type,
							temp: attachment.temp,
							lastModified: this.dateToString(attachment.lastModified),
							lastModifiedDate: this.dateToString(attachment.lastModifiedDate),
						};

						if (!newAttachmentFile.temp) {
							let tempAttachmentInfo = await Service.FileUpload(attachment.file);
							if (!tempAttachmentInfo) {
								return;
							}
							newAttachmentFile.temp = tempAttachmentInfo.temp;
						}

						if (attachment.hasOwnProperty("password")) {
							newAttachmentFile.password = attachment.password;
						}

						tempFormData.attachments[attachment.hash] = newAttachmentFile;
					}
				}

				if(creationType === 'verification' || (creationType === "sender" && creationMode === "batch")) {
					tempFormData.files[key] = null;
					if(PDFFiles[key].fileNameExist){
						const newKey = key.slice(newFile.name.length)
						newFile.hash = newKey
						tempFormData.files[newKey] = newFile;
						delete tempFormData.files[key];
					}else{
						tempFormData.files[key] = newFile;
					}
				}

				tempFormData.auto_verify = auto_verify;
				tempFormData.save_as_draft = draft;
				tempFormData.auto_send_email = !draft;
				tempFormData.hosts = {};

				if(creationType === "sender" && creationMode === "batch"){
					tempFormData.function_type = 2;
					let counter_parties = {};
					tempFormData.receivers = {};
					let guestMaster = {};
					let cert_type = parseInt(formData.certificate_type);
					let self_certificate = !!formData.use_digital_certificate;
					tempFormData.certificate_type = cert_type;
					tempFormData.use_digital_certificate = self_certificate;
					if(word2PdfValidateData.get({noproxy: true}).guests){
						for (let user of word2PdfValidateData.get({noproxy: true}).guests) {
							guestMaster[user.user_id] = user;
							if (!guestMaster[user.email] || guestMaster[user.email].user_id < user.user_id) {
								guestMaster[user.email] = user;
							}
						}
					}

					if(typeof PDFFile.formData.guests === 'string' && PDFFile.formData.guests.replace(/;/g, ",").length > 0){
						for (let email of PDFFile.formData.guests.replace(/;/g, ",").split(",")) {
							let user = guestMaster[email];
							if (user) {
								if (user.company_name && user.company_name !== 'null') {
									counter_parties[user.company_name] = user.company_name;
								} else {
									counter_parties[user.name] = user.name;
								}
								tempFormData.receivers[user.id] = {
									id: user.id,
									name: user.name,
									email: user.email,
									company_name: user.company_name,
								};
							}
						}
					}
					counter_parties = Object.values(counter_parties);
					tempFormData.counter_party_name = counter_parties.join(", ");
				}

				multiFormData.push(tempFormData);
				// await this.BuildFormData(resp, draft);
			}

			console.log(multiFormData);
			this.DocumentBulkSave(multiFormData);

		} else {
			await this.BuildFormData(UserInfo, draft);
			this.DocumentSave(this.formData, draft);
		}
		// }).catch(err => {
		// 	//console.log(err);
		// });
	}

	BuildSigners = () => {
		let { creationType, formData, PDFFiles, imprints } = this.objMain.state;
		let { signers, guests } = formData;

		let signInfo = {};
		for (let fileKey in imprints) {
			let fileImprint = Object.values(imprints[fileKey]);
			// console.log(JSON.stringify(fileImprint));
			for (let imprint of fileImprint) {
				let user = imprint.user;
				if (!signInfo.hasOwnProperty(user.id)) {
					signInfo[user.id] = {
						optional: [],
						signature: [],
					};
				}

				let oFile = PDFFiles[fileKey];

				let bottomPosition = 0;

				let initSignature = user?.sign_details?.signature?.find(item => item.file === fileKey);

				if (initSignature && initSignature?.position) {
					bottomPosition = initSignature?.position?.bottom;
				}

				if (oFile?.pages?.[imprint.page]?.view?.height) {
					let view = oFile.pages[imprint.page].view;
					bottomPosition = view.height - imprint.top - imprint.defaultHeight;
				}

				let detail = {
					file: fileKey,
					page: imprint.page,
					position: {
						top: imprint.top,
						left: imprint.left,
						bottom: bottomPosition,
						width: imprint.defaultWidth,
						height: imprint.defaultHeight,
					},
				};

				if (imprint.type === "signature") {
					signInfo[user.id].signature.push(detail);
				} else {
					detail.type = imprint.type;
					signInfo[user.id].optional.push(detail);
				}
			}
		}
		// console.log(signInfo);

		this.formData.signers = {};
		if (formData.company_seal) {
			let hostGroups = [];
			for (let user of Object.values(signers)) {
				hostGroups.push(user.id);
				this.formData.signers[user.id] = {
					id: user.id,
					user_type: 2,
					name: user.name,
					email: user.email,
					self_certificate: true,
					certificate_type: 2, // formData.certificate_type,
					approvers: {},
					use_sign_imprint: signInfo.hasOwnProperty(user.id),
					sign_detail: signInfo[user.id],
					sign_seq: user.sign_seq,
				};
			}
		} else {
			for (let user of Object.values(signers)) {

				let cert_type = parseInt(formData.certificate_type);
				let self_certificate = formData.use_digital_certificate && user.self_certificate;
				if (self_certificate) {
					if (!formData.hasOwnProperty("sign_level") || formData["sign_level"].toString() === "1" || !["signer", "circle"].includes(creationType)) {
						if (!user?.available_certs?.includes(cert_type)) {
							self_certificate = false;
						}
					}
				}

				this.formData.signers[user.id] = {
					id: user.id,
					user_type: 0,
					name: user.name,
					email: user.email,
					self_certificate: self_certificate,
					certificate_type: self_certificate ? cert_type : 0,
					use_sign_imprint: signInfo.hasOwnProperty(user.id),
					sign_detail: signInfo[user.id],
					sign_seq: user.sign_seq,
				};
			}
		}

		let counter_parties = {};
		if(!guests){
			guests = {}
		}
		if (["sender"].includes(creationType)) {
			this.formData.receivers = {};
			for (let user of Object.values(guests)) {
				if (user.company_name) {
					counter_parties[user.company_name] = user.company_name;
				} else {
					counter_parties[user.name] = user.name;
				}
				this.formData.receivers[user.id] = {
					id: user.id,
					name: user.name,
					email: user.email,
					company_name: user.company_name,
					sign_seq: user.sign_seq,
				};
			}
		} else {
			for (let user of Object.values(guests)) {
				if (user.company_name) {
					counter_parties[user.company_name] = user.company_name;
				} else {
					counter_parties[user.name] = user.name;
				}
				this.formData.signers[user.id] = {
					id: user.id,
					user_type: 1,
					name: user.name,
					email: user.email,
					self_certificate: user.certificate_type ? true : false,
					certificate_type: user.certificate_type,
					use_sign_imprint: signInfo.hasOwnProperty(user.id),
					sign_detail: signInfo[user.id],
					sign_seq: user.sign_seq,
				};
			}
		}

		counter_parties = Object.values(counter_parties);
		if (!["verification", "circle"].includes(creationType)) {
			this.formData.counter_party_name = counter_parties.join(", ");
		}
	}

	BuildFormData = async (UserInfo, draft) => {
		// let { DocumentSavingLogBody } = this;
		let { t } = this.props;
		let { formData, PDFFiles, document_id, creationType, filesSort, AttachmentFiles, ModalBody, modalTitle, modalProps} = this.objMain.state;
		let { viewers } = formData;
		// console.log('formData', formData);

		valueSearchHost.set('');
		valueSearchGuest.set('');
		valueSearchViewer.set('');
		//JSON.parse(JSON.stringify(formData));
		this.formData = {};

		if (document_id) {
			this.formData.document_id = document_id;
		}

		this.formData.tenant_id = UserInfo.tenant_id;
		this.formData.drive_folder_id = formData.drive_folder_id;
		this.formData.shared_folders_path = formData.shared_folders_path;
		this.formData.is_applying_guest_mfa = formData?.is_applying_guest_mfa ?? false;
		this.formData.document_type = formData.document_type;
		this.formData.doc_title = formData.doc_title;
		if(formData.invoice_number && typeof formData.invoice_number === 'object'){
			this.formData.invoice_number = formData.invoice_number?.registrated_number
		}else{
			this.formData.invoice_number = formData.invoice_number;
		}
		this.formData.amount = formData.amount.toString().replaceAll(",", "");
		this.formData.currency = formData.currency;
		this.formData.product = formData.product;
		this.formData.reference = formData.reference;
		this.formData.filebox_shortcut = formData.filebox_shortcut;
		this.formData.drive_folder_id = formData.drive_folder_id;
		this.formData.function_type = formData.function_type; // 1:SiGNER, 2:SeNDER, 4:VeRIFICATION
		this.formData.files_sort = filesSort;
		this.formData.document_view_required = formData.document_view_required;
		this.formData.shared_folders_path = formData.shared_folders_path;
		this.formData.is_revision = formData?.is_revision;
		// if (formData?.sign_level) this.formData.sign_level = formData.sign_level;
		// this.formData.dateofnoti = formData.dateofnoti;

		// if (["signer", "sender"].includes(creationType)) {
		if (creationType === "signer") {
			this.formData.contract_date = formData.contract_date;
			this.formData.expiry_date = formData.expiry_date;
			this.formData.effective_date = formData.effective_date;
			this.formData.certificate_type = formData.certificate_type;
			this.formData.company_seal = formData.company_seal;
			this.formData.use_digital_certificate = formData.use_digital_certificate;
			this.formData.dateofnoti = formData.dateofnoti;
			this.formData.select_datenoti = formData.select_datenoti1;
			// this.formData.counter_party_name = formData.counter_party_name;
		} else if (creationType === "sender") {
			this.formData.contract_date = formData.contract_date;
			this.formData.expiry_date = formData.expiry_date;
			this.formData.effective_date = formData.effective_date;
			this.formData.certificate_type = formData.certificate_type;
			this.formData.company_seal = formData.company_seal;
			this.formData.use_digital_certificate = formData.use_digital_certificate;
			// this.formData.counter_party_name = formData.supplier_counter_party_name;
		} else if (creationType === "verification") {
			this.formData.received_date = formData.received_date;
			this.formData.counter_party_name = formData.counter_party_name;
			this.formData.preservation_require = formData.preservation_require;
		} else if (creationType === "circle") {
			this.formData.document_create_date = formData.document_create_date;
			this.formData.received_date = formData.document_create_date;
			this.formData.certificate_type = formData.certificate_type;
			this.formData.company_seal = formData.company_seal;
			this.formData.use_digital_certificate = formData.use_digital_certificate;
			this.formData.content = formData.content;
			this.formData.counter_party_name = formData.counter_party_name;
		}

		this.formData.doc_attributes = {};
		for (let attr of formData.doc_attributes) {
			this.formData.doc_attributes[attr.key] = attr.value;
		}

		this.BuildSigners();

		if (creationType === "verification" && !draft) {
			let verify_uesr = Object.values(this.formData.signers)[0];
			if (verify_uesr.id === UserInfo.user_id) {
				this.formData.auto_verify = true;
			}
		}

		this.formData.viewers = [];
		for (let user of Object.values(viewers)) {
			this.formData.viewers.push({
				user_id: user.id,
				send_mail: user.send_mail ?? 0
			});
		}

		let fileUploadIndex = 1;
		this.formData.files = {};
		modalProps.show =  false;
		this.objMain.updateState({modalProps})
		// modalUploadProps.set({
		// 	"show": true,
		// 	"centered": true,
		// 	"backdrop": true,
		// });
		// modalState.modalTitle.set(modalTitle);
		// modalState.fileLength.set(Object.keys(PDFFiles || {}).length);

		for (let key in PDFFiles) {
			// modalState.process.set(0);
			// modalState.fileUploadIndex.set(fileUploadIndex);
			// documentListSnackbarState.set({
			// 	open: true,
			// 	message: this.snackBarMessageComponent(Object.keys(PDFFiles || {}).length, fileUploadIndex),
			// })

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				upload: {
					index: fileUploadIndex,
					total: Object.keys(PDFFiles || {}).length,
				}
			}))

			let oFile = PDFFiles[key];

			let newFile = {
				hash: key,
				name: oFile.name,
				// temp: tempInfo.temp,
				// url: tempInfo.url,
				// bucket: tempInfo.bucket,
				size: oFile.size,
				type: oFile.type,
				lastModified: this.dateToString(oFile.lastModified),
				lastModifiedDate: this.dateToString(oFile.lastModifiedDate),
			};
			if (!oFile.url) {

				let tempInfo = await Service.FileUpload(oFile.file, "", "", "", (progress) => {
					// modalState.process.set(progress)
				}).catch(err => {
					//console.log(err.response);
				});
				if (!tempInfo) {
					return;
				}
				newFile.temp = tempInfo.temp;
				fileUploadIndex++;
				// console.log(tempInfo);
			}

			if (oFile.hasOwnProperty("password")) {
				newFile.password = oFile.password;
			}
			this.formData.files[key] = newFile;
		}

		this.formData.attachments = {};
		for (let key in AttachmentFiles) {
			let oFile = AttachmentFiles[key];

			let newFile = {
				hash: key,
				name: oFile.name,
				temp: oFile.temp,
				url: oFile.url,
				// bucket: tempInfo.bucket,
				size: oFile.size,
				type: oFile.type,
				lastModified: this.dateToString(oFile.lastModified),
				lastModifiedDate: this.dateToString(oFile.lastModifiedDate),
			};
			if (!oFile.temp) {
				let tempInfo = await Service.FileUpload(oFile.file).catch(err => {
					//console.log(err.response);
				});
				if (!tempInfo) {
					return;
				}
				newFile.temp = tempInfo.temp;
				// console.log(tempInfo);
			}

			if (oFile.hasOwnProperty("password")) {
				newFile.password = oFile.password;
			}
			this.formData.attachments[key] = newFile;
		}

		this.formData.save_as_draft = draft;
		this.formData.auto_send_email = !draft;
		this.formData.hosts = {};

		this.formData.create_user_id = UserInfo.user_id;
		// console.log(!formData.hasOwnProperty("sign_level"), creationType, formData)
		if (["signer", "circle"].includes(creationType) && !formData.hasOwnProperty("sign_level")) {
			this.formData.sign_level = this.objMain.checkSignLevel(this.formData.signers);
		} else {
			this.formData.sign_level = formData.sign_level;
		}

		if(this.formData.sign_level == 0 && creationType !== "sender"){
			Object.keys(this.formData.signers || '{}').map((key) => {
				if(this.formData.signers[key]){
					this.formData.signers[key].certificate_type = 0;
					this.formData.signers[key].self_certificate = false;
				}
			})
		}

		// delete this.formData.guests;
		// this.DocumentSave(this.formData);
		// return this.formData
	}

	DocumentSave = (formData, draft) => {
		// console.log(formData)
		let { ModalBody, modalTitle, modalProps } = this.objMain.state;
		let { t } = this.props;
		modalUploadProps.set({
			"show": false,
			"centered": true,
			"backdrop": true,
		});
		modalProps.show = false;
		// ModalBody = () => <>
		// 	<Loading/>
		// </>
		// modalTitle = "documents.determine.creating-document";
		// this.objMain.updateState({ ModalBody, modalTitle, modalProps });
		documentProcessState?.[this.documentProcessId].set(prevState => ({
			...prevState,
			status: DocumentProcessStatus.PROCESSING,
			data: {
				...this.objMain.state,
			},
			onRetry: async () => {
				documentProcessState?.[this.documentProcessId].set(prevState => ({
					...prevState,
					status: DocumentProcessStatus.PROCESSING,
				}));
				let { UserInfo } = this.objMain.state;
				await this.BuildFormData(UserInfo, draft);
				this.DocumentSave(this.formData, draft);
			},
		}))
		// return
		Service.SaveDocument(formData).then(resp => {
			let { modalProps, ModalBody, ModalAction, creationType } = this.objMain.state;
			// console.log(resp);
			let message = "documents.determine.save-request-complete";
			const userInfoGet = userInfoState.get({noproxy: true});
			console.log('userInfoGet', userInfoGet);
			if (resp?.data?.document && documentMainList) {
				const {documents: prevDocuments, creationType: prevCreateType} = documentMainList.get({noproxy: true});
				if (creationType === prevCreateType) {
					documentMainList.documents.set(docs => [resp.data.document, ...docs]);
					userInfoState.set((v) => ({
						...v,
					}));
					localStorage.setItem(
						"user-info",
						JSON.stringify(userInfoState.get({ noproxy: true }))
					);
				}
			}
			if (!draft && userInfoGet){
				switch (formData?.function_type) {
					case 1:
						if (Object.keys(formData.signers).includes(`${userInfoGet.user_id}`) && formData.signers[`${userInfoGet.user_id}`].sign_seq == 1){
							userInfoState.set((v) => ({
								...v,
								signed_document_signer: userInfoGet.signed_document_signer + 1,
							}));
							localStorage.setItem(
								"user-info",
								JSON.stringify(userInfoState.get({ noproxy: true }))
							);
						}

						break;
					case 2:
						if (Object.keys(formData.signers).includes(`${userInfoGet.user_id}`) && formData.signers[`${userInfoGet.user_id}`].sign_seq == 1){
							userInfoState.set((v) => ({
								...v,
								signed_document_sender: userInfoGet.signed_document_sender + 1,
							}));
							localStorage.setItem(
								"user-info",
								JSON.stringify(userInfoState.get({ noproxy: true }))
							);
						}
						break;
					case 3:
						if (Object.keys(formData.signers).includes(`${userInfoGet.user_id}`)){
							userInfoState.set((v) => ({
								...v,
								signed_document_circle: userInfoGet.signed_document_circle + 1,
							}));
							localStorage.setItem(
									"user-info",
									JSON.stringify(userInfoState.get({ noproxy: true }))
							);
						}
						break;
					// case 4:
					// 	userInfoState.set((v) => ({
					// 		...v,
					// 		signed_document_verification:
					// 			userInfoGet.signed_document_verification + 1,
					// 	}));
					// 	localStorage.setItem("user-info", JSON.stringify(userInfoState.get({ noproxy: true })));
					// 	break;
					default:
						break;
				}
			}
			if (resp.hasOwnProperty("message")) {
				message = resp.message;
			}
			modalProps.backdrop = "static";
			ModalBody = () => {
				return (
					<div>{t(`common:${message}`)}</div>
				);
			};
			ModalAction = [];
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }}
					variant="contained"
					component={Link}
					to={`/${creationType}`}
					onClick={(ev) => {
						window.onbeforeunload = null;
						this.objMain.unlisten();
						// window.location.href = `/${creationType}`;
					}}
				>
					<span>{t("common:general.ok")}</span>
				</Button>
			);
			// window.onbeforeunload = null;
			// this.objMain.unlisten();
			// this.props.history.push(`/${creationType}`);
			// documentListSnackbarState.set({
			// 	open: true,
			// 	message: this.snackBarMessageComponent(10, 10, t("common:documents.determine.save-request-complete")),
			// })
			// this.objMain.updateState({ modalClose: false, ModalBody, ModalAction });
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
			}))
		}).catch(err => {
			//console.log(err.response);
			let { ModalBody, ModalAction } = this.objMain.state;

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err?.response?.data?.error;
			}
			if(err?.response?.data?.errors?.length > 0){
				if(err?.response?.data?.error === 'documents.save.validation-error'){
					ModalBody = () => {
						return ( <>
							<h4>{t(`common:${err?.response?.data?.error}`)}</h4>
							{err?.response?.data?.errors.map((err) => <div>{err?.key} : {err?.message}</div>)}
						</> );
					};
				}
				if(err?.response?.data?.error === 'documents.save.users-permission-deny'){
					ModalBody = () => {
						return ( <>
							<h4>{t(`common:${err?.response?.data?.error}`)}</h4>
							{err?.response?.data?.errors.map((err) => <><div>{err?.user_id} - {err?.email}</div></>)}
						</> );
					};
				}
			}else{
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}

				ModalBody = () => {
					return (
						<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
					);
				};
			}
			ModalAction = [];
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={async (ev) => {
					ModalBody = Loading;
					ModalAction = [];
					let { UserInfo } = this.objMain.state;
					this.objMain.updateState({ ModalBody, ModalAction: [] });
					await this.BuildFormData(UserInfo, draft);
					this.DocumentSave(this.formData, draft);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			);
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
			}))
			// this.objMain.updateState({ ModalBody, ModalAction });
		});
	}

	handleDocumentSaveApi = async (multiFormData) => {
		let { creationType, creationMode } = this.objMain.state;

		if (creationType === "verification" && creationMode === "by-template") {
			return await new Promise((resolve, reject) => {
				Service.BatchVerification(multiFormData).then(async resp => {
					resolve(resp.data);
				}).catch(error => {
					reject(error);
				});
			});
		} else if(creationType === "sender" && creationMode === "batch"){
			return await new Promise((resolve, reject) => {
				Service.BatchSender(multiFormData).then(async resp => {
					resolve(resp.data);
				}).catch(error => {
					reject(error);
				});
			});
		} else {
			return await new Promise((resolve, reject) => {
				Service.BulkSaveDocument(multiFormData).then(async resp => {
					resolve(resp.data);
				}).catch(error => {
					reject(error);
				});
			});
		}
	}

	DocumentBulkSave = (multiFormData) => {
		let { t } = this.props;
		let { ModalBody, modalTitle, modalProps} = this.objMain.state;
		modalUploadProps.set({
			"show": false,
			"centered": true,
			"backdrop": true,
		});
		modalProps.show = false;
		// ModalBody = () => <>
		// 	<Loading/>
		// </>
		// modalTitle = "documents.determine.creating-document";
		// this.objMain.updateState({ ModalBody, modalTitle, modalProps});
		documentProcessState?.[this.documentProcessId].set(prevState => ({
			...prevState,
			status: DocumentProcessStatus.PROCESSING,
			data: {
				...this.objMain.state,
				multiFormData: multiFormData,
			},
			onRetry: () => this.DocumentBulkSave(multiFormData),
		}))
		this.handleDocumentSaveApi(multiFormData).then(resp => {
			// console.log(resp);
			let { modalProps, ModalBody, ModalAction, creationType } = this.objMain.state;
			let message = "documents.determine.save-complete";
			if (resp.hasOwnProperty("message")) {
				message = resp.message;
			}
			modalProps.backdrop = "static";
			if(resp.data?.error_add?.length > 0){
				ModalBody = () => {
					return (<>
						<h4>{t(`common:${resp.data?.error}`)}</h4>
						{resp.data?.error_add.map((err) => {<div>{err.title}</div>})}
					</>
				)};
			}else{
				ModalBody = () => {
					return (
						<div>{t(`common:${message}`)}</div>
					);
				};
			}
			ModalAction = [];
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }}
					variant="contained"
					component={Link}
					to={`/${creationType}`}
					onClick={(ev) => {
						window.onbeforeunload = null;
						this.objMain.unlisten();
						// window.location.href = `/${creationType}`;
					}}
				>
					<span>{t("common:general.ok")}</span>
				</Button>
			);
			// documentListSnackbarState.set({
			// 	open: true,
			// 	message: this.snackBarMessageComponent(10, 10),
			// })
			// this.objMain.updateState({ modalClose: false, ModalBody, ModalAction });
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
			}))
			const {documents: prevDocuments, creationType: prevCreateType} = documentMainList.get({noproxy: true});
			if(resp?.success_add?.length > 0 && creationType === prevCreateType){
				documentMainList.documents.set(docs => [
					...resp?.success_add.map(item => ({
						...item.document
					})),
					...docs,
				]);
				const userInfoGet = userInfoState.get({noproxy: true});
				if (userInfoGet){
					switch (creationType) {
						case 'sender':
							let senderCount = 0;
							multiFormData.forEach((item) => {
								if (Object.keys(item.signers).includes(`${userInfoGet.user_id}`) && item.signers[`${userInfoGet.user_id}`].sign_seq == 1){
									senderCount++;
								}
							})
							if (senderCount >= 0) {
								userInfoState.set((v) => ({
									...v,
									signed_document_sender: userInfoGet.signed_document_sender + senderCount,
								}));
								localStorage.setItem(
									"user-info",
									JSON.stringify(userInfoState.get({ noproxy: true }))
								);
							}
							break;
						case 'verification':
							let verificationCount = 0;
							multiFormData.forEach((item) => {
								if (Object.keys(item.signers).includes(`${userInfoGet.user_id}`) && item.signers[`${userInfoGet.user_id}`].sign_seq == 1){
									verificationCount++;
								}
							})
							if (verificationCount >= 0) {
								userInfoState.set((v) => ({
									...v,
									signed_document_verification: userInfoGet.signed_document_verification + verificationCount,
								}));
								localStorage.setItem(
									"user-info",
									JSON.stringify(userInfoState.get({ noproxy: true }))
								);
							}
							break;
						default:
							break;
					}
				}
			}
		}).catch(err => {
			//console.log(err.response);
			let { ModalBody, ModalAction } = this.objMain.state;

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			if(err?.response?.data?.errors?.length > 0){
				if(err?.response?.data?.error === 'documents.save.validation-error'){
					ModalBody = () => {
						return ( <>{err?.response?.data?.errors.map((errs) => <>
									<h4>{t(`common:${err?.response?.data?.error}`)}</h4>
									<div>
										<h5>{errs?.title}</h5>
										{errs?.error.map((err) => <div>{err?.key} : {err?.message}</div>)}
									</div></>)}
								</>);
					};
				}else if(err?.response?.data?.error === 'documents.save.users-permission-deny'){
					ModalBody = () => {
						return ( <>
							<h4>{t(`common:${err?.response?.data?.error}`)}</h4>
							{err?.response?.data?.errors.map((err) => <><div>{err?.user_id} - {err?.email}</div></>)}
						</> );
					};
				}else{
					ModalBody = () => {
						return (<>{err?.response?.data?.errors.map((errs) => <>
									<div>
										<h5>{errs?.title}</h5>
										{errs?.error ? errs?.error?.map((err) => <div>{t(err?.error)}</div>) : <></>}
									</div></>)}
								</>);
					};
				}
			}else{
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				}

				ModalBody = () => {
					return (
						<div>{t(`common:${errMessage || 'message.error.server_unknown_error'}`)}</div>
					);
				};
			}
			ModalAction = [];
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={async (ev) => {
					ModalBody = Loading;
					ModalAction = [];
					this.objMain.updateState({ ModalBody, ModalAction: [] });
					this.DocumentBulkSave(multiFormData);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			);
			// this.objMain.updateState({ ModalBody, ModalAction });
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
			}))
		});
	}

	loadPDFFile = async () => {
		let { PDFFiles, selectedFile, document_id } = this.objMain.state;
		if (!PDFFiles[selectedFile].file && document_id && selectedFile) {
			let fileObject = await Service.getDocumentFile(document_id, selectedFile).catch(err => {
				//console.log(err.resonse);
			});
			if (!PDFFiles[selectedFile].file) {
				PDFFiles[selectedFile].file = fileObject;
				this.objMain.updateState({ PDFFiles });
			}
		}
	}

	FileListChangeCallback = (fileKey) => {
		let { PDFFiles, selectedFile, formData } = this.objMain.state;
		selectedFile = fileKey;
		let PDFFile = PDFFiles[fileKey];
		// formData = PDFFile.formData;
		formData = {...JSON.parse(JSON.stringify(PDFFile.formData, replacerFunc())), ...formData};
		for (let i in formData) {
			let newData = PDFFile.formData[i];
			// console.log(newData, i);
			if((i === 'shared_folders_path' || i === 'drive_folder_id') && !newData){
				newData = null;
			}
			if(newData === undefined) continue;
			// if(i !== 'shared_folders_path')
			formData[i] = newData;
		}
		// console.log(PDFFile, formData)
		// console.log("24214124", PDFFile, formData);

		this.objMain.updateState({ PDFFile, selectedFile, formData });
	}

	handleChangeFileOrder(filesSortArray, parent) {
		parent.objMain.updateState({filesSort: [...filesSortArray]});
	}

    componentDidUpdate() {
		let { PDFFiles, selectedFile, PDFFile, creationType, creationMode, filesSort, docInfo,formData } = this.objMain.state;

		console.log("PDFFi5e", PDFFile, formData);
		// this.objMain.setState((prevState) => ({
		// 	formData: {...prevState.formData, ...PDFFile.formData }
		// }));
    	// let CurrentForm = PageProps.render();
    	// console.log(this.props);
    	// document.title = this.props.t(this.state.activePage.title);
    	// console.log("componentDidUpdate", prevProps);
    }
	CurrentPDFPreview = () => {
		let { PDFFiles, selectedFile, PDFFile, creationType, creationMode, filesSort, docInfo,formData } = this.objMain.state;

		// console.log("PDFFile", PDFFile);
		if (!PDFFile) {

			if (!PDFFiles[selectedFile]) {
				return null;
			}
			if (!PDFFiles[selectedFile].file && !PDFFiles[selectedFile].url) {
				return null;
			}

			PDFFile = PDFFiles[selectedFile];
		}
		// console.log("PDFFil3214214214e", PDFFile, formData);
		// this.objMain.setState((prevState) => ({
		// 	formData: {...prevState.formData, ...PDFFile.formData }
		// }));
		let PDFProps = {
			showAll: false,
			scale: 0.6,
			fit: true,
			files: PDFFiles,
			// isFileLoading: isFileLoading,
			objMain: this.objMain,
			docInfo: docInfo,
			isThumbnail: true,
			updateInfo: ["signer", "sender", "circle"].includes(creationType),
			filesSort: filesSort,
			handleChangeFileOrder: (filesSortArray) => this.handleChangeFileOrder(filesSortArray, this)
			// file: PDFFile,
			// info: true,
			// files: PDFFiles
			// .file,
			// password: PDFFile.password,
		};

		if (["signer", "sender", "circle"].includes(creationType)) {
			PDFProps.files = PDFFiles;
			PDFProps.list = true;
			if (creationMode === "batch") {
				PDFProps.fileChangeCB = this.senderBatchFileChangeCallback;
				PDFProps.file = PDFFile
			}
		} else if (creationType === "verification") {
			PDFProps.file = PDFFile;
			PDFProps.info = true;
			if (creationMode === "multiple" || creationMode === "by-template") {
				PDFProps.files = PDFFiles;
				PDFProps.list = true;
				PDFProps.info = false;
				PDFProps.fileChangeCB = this.FileListChangeCallback;
			}
		}
		// console.log("PDFProps", PDFProps);
		return <PDFViewer {...PDFProps} />
	}

	senderBatchFileChangeCallback = (fileKey) => {
		let { PDFFiles, selectedFile, formData } = this.objMain.state;
		selectedFile = fileKey;
		let PDFFile = PDFFiles[fileKey];
		formData = {...formData, ...JSON.parse(JSON.stringify(PDFFile.formData, replacerFunc()))};

		this.objMain.updateState({ PDFFile, selectedFile, formData });
	}


	UserListBlockLabel = (props) => {
		let { users, viewer } = props;
		// let { formData } = this.objMain.state;
		let UserItems = [];
		// console.log(users);
		let sort_users = Object.values(users);

		sort_users.sort(function (a, b) {
			return a.sign_seq - b.sign_seq;
		});

		for (let user of sort_users) {
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}
			// let DigiCert = this.renderDigitalSignatureControl(user, formData.company_seal);
			UserItems.push(
				<Form.Group xs={12} md={6} as={Col} key={user.id} className="">
					<div className={`user-info user${user.user_type}`} >
						{/* <NoAvatar className="display-avatar" /> */}
						<div className="display-avatar">
							<ProfileAvatar {...iconProps} />
						</div>
						<div className="user-info-detail">
							<div className="top-info">
								{/* <span>{user.id + " " + user.name} </span> */}
								<span>{user.name} </span>
							</div>
							<div className="bottom-info">
								<span>{user.email} </span>
							</div>
						</div>
						<div className="digital-signature">
							{/* {DigiCert} */}
						</div>
						{viewer ? <div className="receive-mail-viewer">
							<div
								className={user['send_mail'] && user['send_mail'] == 1 ? 'icon-bell-active' : 'icon-bell'}
							>
								{user['send_mail'] && user['send_mail'] == 1 ? <NotificationsIcon /> : <NotificationsNoneIcon />}
							</div>
						</div> : <></>}
						{/* <button type="button" className="btn-icon" onClick={(ev) => { this.unAssignUser(ev, user.id) }}> */}
						{/* <Close /> */}
						{/* </button> */}
					</div>
				</Form.Group>
			);
		}

		return UserItems;
	}

	DocdateOfNotice = () => {
		let { t, name } = this.props;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData } = this.objMain.state;
		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i == 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i == 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			let radioProps = {
				"name": name,
				"onChange": (ev) => {
					// let { FieldControl } = this.objMain;
					if (formData.dateofnoti === 0) {
						formData.select_datenoti = "";
					}
					formData.dateofnoti = parseInt(ev.target.value);
					this.objMain.setFormData(formData);

				},
			};
			ItemElements.push(
				<FormControlLabel key={i} value={i} label={label} disabled={true} className="date-noti label" control={<Radio {...radioProps} />} />
			);
		}

		return (
			<RadioGroup row name="dateofnoti" aria-labelledby="demo-row-radio-buttons-group-label" value={formData.dateofnoti}>
				{ItemElements}
			</RadioGroup>
		);
	}

	SignerFieldInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;
		let { DocdateOfNotice } = this;
		let chkDatenotiSelected = "";

		if (formData.dateofnoti == '0') {
			chkDatenotiSelected = "block"
		} else {
			chkDatenotiSelected = "none"
		}
		// console.log(formData.dateofnoti);
		// console.log(chkDatenotiSelected);
		if (creationType !== "signer") {
			return null;
		}
		let lbNoti = "";
		if (formData.dateofnoti !== 0) {
			lbNoti = formData.dateofnoti + " 日前 ";
		} else {
			lbNoti = formData.select_datenoti1;
		}
		let RowItems = [
			(
				<Form.Row key="contract-date">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.contract-date")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.contract_date}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="expiry-date">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.expiry-date")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.expiry_date}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="effective-date">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.effective-date")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.effective_date}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="counter-party-name">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.contract-counter-party-name")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.counter_party_name}</span>
					</Form.Group>
				</Form.Row >
			),
			// (
			// 	<Form.Row key="date-noti-">
			// 		<Form.Group xs={4} as={Col} className="info-label">
			// 			<span className="document-info-key">{t("common:documents.document-info.date-noti-")}</span>
			// 		</Form.Group>
			// 		<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
			// 			<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{lbNoti}</span>
			// 		</Form.Group>
			// 	</Form.Row >
			// )
			(
				// <FormControl key="dateofnoti" className="date-noti label">
				<Form.Row >
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t(`common:documents.document-info.select_datenoti`)}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<DocdateOfNotice />
					</Form.Group>

				</Form.Row>

				// </FormControl>
			),
			(<Form.Row >
				<Form.Group xs={4} as={Col} className="info-label" style={{ display: chkDatenotiSelected }}>
					<span className="document-info-key"></span>
				</Form.Group>
				<Form.Group xs={8} as={Col} style={{ height: "auto", display: chkDatenotiSelected }} >
					<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.select_datenoti1}</span>
				</Form.Group>
			</Form.Row>
			)
		];

		return RowItems;
	}

	SenderFieldInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;

		if (creationType !== "sender") {
			return null;
		}

		let RowItems = [
			(
				<Form.Row key="counter-party-name">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.supplier-name")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.supplier_counter_party_name}</span>
					</Form.Group>
				</Form.Row >
			)
		];

		// let counter_parties = {};
		// let users = formData.guests;
		// for (let i in formData.guests) {
		// 	let user = users[i];
		// 	if (user.company_name) {
		// 		counter_parties[user.company_name] = user.company_name;
		// 	}
		// }
		// counter_parties = Object.values(counter_parties);
		// // console.log(counter_parties);
		// RowItems.push(
		// 	<Form.Row key={`counter-parties`}>
		// 		<Form.Group xs={4} as={Col} className="info-label">
		// 			<span className="document-info-key">{t("common:documents.document-info.supplier-name")}</span>
		// 		</Form.Group>
		// 		<Form.Group xs={8} as={Col}>
		// 			<span className="form-control document-info-value">{counter_parties.join(", ")}</span>
		// 		</Form.Group>
		// 	</Form.Row >
		// );

		// for (let i in counter_parties) {
		// 	let counter_party_name = counter_parties[i];
		// 	if (i.toString() === '0') {
		// 		RowItems.push(
		// 			<Form.Row key={`counter-party-${counter_party_name}`}>
		// 				<Form.Group xs={4} as={Col} className="info-label">
		// 					<span className="document-info-key">{t("common:documents.document-info.counter-party-list")}</span>
		// 				</Form.Group>
		// 				<Form.Group xs={8} as={Col}>
		// 					<span className="form-control document-info-value">{counter_party_name}</span>
		// 				</Form.Group>
		// 			</Form.Row >
		// 		);
		// 	} else {
		// 		RowItems.push(
		// 			<Form.Row key={`counter-party-${counter_party_name}`}>
		// 				<Form.Group xs={4} as={Col} className="info-label">
		// 					<span className="document-info-key"></span>
		// 				</Form.Group>
		// 				<Form.Group xs={8} as={Col}>
		// 					<span className="form-control document-info-value">{counter_party_name}</span>
		// 				</Form.Group>
		// 			</Form.Row >
		// 		);
		// 	}
		// }

		return RowItems;
	}

	CircleFieldInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;

		if (creationType !== "circle") {
			return null;
		}

		let RowItems = [
			(
				<Form.Row key="document-create-date">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.document-create-date")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.document_create_date}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="document-counter-party">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.supplier-name")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.counter_party_name}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="content">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.content")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.content}</span>
					</Form.Group>
				</Form.Row >
			)
		];

		return RowItems;
	}

	VerificationInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;

		if (creationType !== "verification") {
			return null;
		}

		let preservationRequireVal = formData.preservation_require;
		let preservationRequireLabel = t("common:documents.document-info.other");
		if (preservationRequireVal.toString() === "2") {
			preservationRequireLabel = t("common:documents.document-info.new-article-seven");
		} else if (preservationRequireVal.toString() === "3") {
			preservationRequireLabel = t("common:documents.document-info.scanner-storage");
		}

		let RowItems = [
			(
				<Form.Row key="preservation-require">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.preservation-require")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{preservationRequireLabel}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="received-date">
					<Form.Group xs={4} as={Col} className="info-label">
						{/* <span className="document-info-key">{t("common:documents.document-info.received-date")}</span> */}
						<span className="document-info-key">{t("common:documents.document-info.deal-date")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value">{formData.received_date}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="counter-party-name">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.counter-party-name")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.counter_party_name}</span>
					</Form.Group>
				</Form.Row >
			)
		];

		return RowItems;
	}

	AmountItemInfo = () => {
		let { t } = this.props;
		let { formData } = this.objMain.state;

		let Amount = (formData.amount || "0").toString();
		Amount = Amount.split(".");
		Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		Amount = Amount.join(".") + " " + (formData.currency || "JPY");

		let RowItems = [
			(
				<Form.Row key="amount">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.amount")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col}>
						<span className="form-control document-info-value align-right">{Amount}</span>
					</Form.Group>
				</Form.Row >
			)
		];

		return RowItems;
	}

	CommonItemInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;

		if (creationType === "circle") {
			return null;
		}


		let RowItems = [
			(
				<Form.Row key="product">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.product")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.product}</span>
					</Form.Group>
				</Form.Row >
			),
			(
				<Form.Row key="reference">
					<Form.Group xs={4} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.reference")}</span>
					</Form.Group>
					<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
						<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.reference}</span>
					</Form.Group>
				</Form.Row >
			),
			(
			<Form.Row key="shared_folders_path">
			<Form.Group xs={4} as={Col} className="info-label">
				<span className="document-info-key">{t("common:documents.document-info.shared_folders_path")}</span>
			</Form.Group>
				<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
				<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.shared_folders_path}</span>
			</Form.Group>
		</Form.Row >

			)
		];

		return RowItems;
	}

	SignLevelInfo = () => {
		let { t } = this.props;
		let { formData, creationType } = this.objMain.state;

		if (!["signer", "circle"].includes(creationType)) {
			return null;
		}

		let signLevel = formData.sign_level;
		console.log('sign_lvel',formData)
		if (["signer", "circle"].includes(creationType) && !formData.hasOwnProperty("sign_level")) {
			this.BuildSigners();
			signLevel = this.objMain.checkSignLevel(this.formData.signers);
		}

		let SignLevelLabel = t("common:documents.document-info.sign-level-unknown");
		if (signLevel.toString() === "0") {
			SignLevelLabel = t("common:documents.document-info.sign-level-soft");
		} else if (signLevel.toString() === "1") {
			SignLevelLabel = t("common:documents.document-info.sign-level-mixed");
		} else if (signLevel.toString() === "2") {
			SignLevelLabel = t("common:documents.document-info.sign-level-strong");
		}

		return (
			<Form.Row>
				<Form.Group xs={4} as={Col} className="info-label">
					<span className="document-info-key">{t("common:documents.document-info.sign-level")}</span>
				</Form.Group>
				<Form.Group xs={8} as={Col}>
					<span className="form-control document-info-value">{SignLevelLabel}</span>
				</Form.Group>
			</Form.Row >
		);
	}

	DocumentInfoTab = () => {
		let { t } = this.props;
		let { formData, PageData } = this.objMain.state;
		let { SignerFieldInfo, SignLevelInfo, SenderFieldInfo, CircleFieldInfo, VerificationInfo, AmountItemInfo, CommonItemInfo, AttributeElement } = this;

		let doc_type = { label: formData.document_type };
		for (let type of PageData.doc_types) {
			if (type.id === parseInt(formData.document_type)) {
				doc_type = type;
				break;
			}
		}
		let DocumentType = t("common:" + doc_type.label);
		// console.log(DocumentType);

		// let Amount = (formData.amount || "0").toString();
		// Amount = Amount.split(".");
		// Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		// Amount = Amount.join(".") + " " + (formData.currency || "JPY");

		return (
			<Tab eventKey="doc-info" className="doc-info" title={t("common:documents.determine.doc-info")} draggable="false">
				<Container fluid="md" className={"container-fluid"} >
					{/* <Form.Row className="signer-list-wrapper"> */}
					<Form.Row>
						<Form.Group xs={4} as={Col} className="info-label">
							<span className="document-info-key">{t("common:documents.document-info.document-type")}</span>
						</Form.Group>
						<Form.Group xs={8} as={Col}>
							<span className="form-control document-info-value">{DocumentType}</span>
						</Form.Group>
					</Form.Row >

					<SignLevelInfo />

					<Form.Row>
						<Form.Group xs={4} as={Col} className="info-label">
							<span className="document-info-key">{t("common:documents.document-info.doc-title")}</span>
						</Form.Group>
						<Form.Group xs={8} as={Col} style={{ height: "auto" }}>
							<span style={{ whiteSpace: "normal", wordBreak: "break-all" }} className="form-control document-info-value">{formData.doc_title}</span>
						</Form.Group>
					</Form.Row >
					<VerificationInfo />
					<AmountItemInfo />
					<SignerFieldInfo />
					<SenderFieldInfo />
					<CircleFieldInfo />

					<CommonItemInfo />
					<AttributeElement attributes={formData.doc_attributes} />

				</Container>
			</Tab>
		);
	}


	HostInfoTab = () => {
		let { t } = this.props;
		let { UserListBlockLabel } = this;
		let { formData, creationType } = this.objMain.state;

		if (!["signer", "sender", "circle", "verification"].includes(creationType)) {
			return null;
		}
		// console.log('signer', formData.signers)

		return (
			<Tab eventKey="host-info" title={t("common:documents.determine.host-info")}>
				< Container fluid="md" className={"container-fluid"} >
					<Form.Row className="signer-list-wrapper">
						<UserListBlockLabel users={formData.signers} />
					</Form.Row>
				</Container>
			</Tab>
		);
	}

	GuestInfoTab = () => {
		let { t } = this.props;
		let { UserListBlockLabel } = this;
		let { formData, creationType, creationMode } = this.objMain.state;

		if (!["signer", "sender", "circle"].includes(creationType)) {
			return null;
		}
		if(creationType === "sender" && creationMode === 'batch') {
			return null;
		}
		return (
			<Tab eventKey="guest-info" title={creationType == 'sender' ? t("common:documents.determine.sender-guest-info") : t("common:documents.determine.guest-info")}>
				<Container fluid="md" className={"container-fluid"} >
					<Form.Row className="signer-list-wrapper">
						<UserListBlockLabel users={formData.guests} />
					</Form.Row>
				</Container>
			</Tab>
		);
	}

	ViewerInfoTab = () => {
		let { t } = this.props;
		let { UserListBlockLabel } = this;
		let { formData, creationType } = this.objMain.state;

		if (!["signer", "sender", "circle", "verification"].includes(creationType)) {
			return null;
		}

		return (
			<Tab eventKey="viewer-info" title={t("common:documents.determine.viewer-info")}>
				<Container fluid="md" className={"container-fluid"} >
					<Form.Row className="signer-list-wrapper">
						<UserListBlockLabel users={formData.viewers} viewer={true} />
					</Form.Row>
				</Container>
			</Tab>
		);
	}

	FileAttachments = () => {
		let { creationType, AttachFiles } = this.objMain.state;
		let fileKeys = Object.keys(AttachFiles);

		if (creationType === "verification" || fileKeys.length === 0) {
			return null;
		}

		let fileList = fileKeys.map((key) => {
			let AttFile = AttachFiles[key];
			return (
				<li key={key} className="file-item">
					<span className="file-name" title={AttFile.name}>{AttFile.name}</span>
				</li>
			);
		});

		return (
			<div className="file-attachments">
				<ul>
					{fileList}
				</ul>
			</div>
		);

	}

	checkPendingGuestExisting = () => {
		let guests = this.objMain.state.formData.guests;

		if (guests) {
			for (let guestId of Object.keys(guests)) {
				if (guests[guestId].status === 3) {
					return true;
				}
			}
		}
		return false;
	}

	render() {

		let { t } = this.props;
		let { FileAttachments, CurrentPDFPreview } = this;
		let {RenderDialogPreviewAttachment} = this.objMain;
		let { creationType, UserInfo, formData, AttachmentFiles } = this.objMain.state;

		console.log("creationType", creationType);

		let autoTimestamp = false;
		if (creationType === "verification" && parseInt(formData.verify_user) === UserInfo.user_id) {
			autoTimestamp = true;
		}
		// console.log(creationType, formData);
		return (

			<div className={this.code + "-container form-wrapper confirmation-info"}>
				<ModalUpload />
				<RenderDialogPreviewAttachment />
				<Form
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className={this.code + "-form full-form"}
				>

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					<div className="form-body flex-column">


						<Tabs defaultActiveKey="doc-info" transition={false} id="uncontrolled-tab-example">

							{this.DocumentInfoTab()}

							{this.HostInfoTab()}
							{this.GuestInfoTab()}
							{this.ViewerInfoTab()}

						</Tabs>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									color="info"
									size="large"
									onClick={(ev) => this.onSubmit(ev, true)}>
									<span className="btn-label">{t('common:documents.general.save-as-draft')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								{this.checkPendingGuestExisting() ? (
									<p
										style={{
											whiteSpace: 'pre-line',
											color: "#f3821d",
										}}
									>
										{t("common:documents.info.guest-registration-not-complete")}{'\n'}
										{t("common:documents.info.guest-registration-not-complete-2")}{'\n'}
									</p>
								) : (
									<Button
										variant="contained"
										size="large"
										endIcon={autoTimestamp ? <VerifiedIcon /> : <SendIcon />}
										onClick={(ev) => this.onSubmit(ev)}>
										<span className="btn-label">{t(`common:documents.general.confirm${autoTimestamp ? "-and-timestamp" : ""}`)}</span>
									</Button>
								)}
							</div>
						</div>

					</div>

				</Form>

				<div className="file-panel preview">


					<div className={"file-preview-zone", AttachmentFiles ? "h-60": ""}>

						<CurrentPDFPreview />

					</div>

					<FileAttachments />

					{AttachmentFiles ? <div className="attachment-wrapper">
						<div  className='attachment-upload-preview'>
							<div className='attachment-upload-box'>
								<img src={ClipIcon} alt="" />
								<span>{t('common:document.upload-attachment')}</span>
							</div>
						</div>
						<div className="file-attachment-list-preview">
						{
							AttachmentFiles ? Object.keys(AttachmentFiles).map((key) => {
								return (
									<div className='attachment-item-preview' data-file={key} onClick={() => this.objMain.previewAttachment(AttachmentFiles[key])}>
										<span
											className="file-name"
											title={AttachmentFiles[key]?.name}
										>
											{AttachmentFiles[key]?.name}
										</span>
									</div>
								)
							}): <></>
						}
						</div>
					</div>: <></>}
				</div>

			</div>

		);

	}

}
