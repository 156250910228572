import {hookstate, useHookstate} from "@hookstate/core";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, { useCallback, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import {editErrorDocumemt} from "../../Pages/Documents";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./document-process-state.css";
import Tooltip from "@mui/material/Tooltip";
import { debounce } from "../../drive-feature/utils/hooks";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export const documentProcessState = hookstate(null);
/*
* status=0: Error
* status=1: Upload file
* status=2: Document Signing
* status=3: Success
* */

/*
* documentProcessStateData type
* {
*  [id]: {
*       upload: {
*         index: 1,
*         total: 10,
*      },
*      status: 1,
*      result,
*      data,
* }
*/

export const DocumentProcessStatus = {
    ERROR: 0,
    UPLOADING: 1,
    PROCESSING: 2,
    SUCCESS: 3,
}

function CollapsibleText({text}) {
    const [open, setOpen] = React.useState(false);

    const minCharacter = 70;

    useEffect(() => {
        if (text.length <= minCharacter) {
            setOpen(true);
        }
    }, [text]);

    return (
        <Typography
            className={"document-process-title"}
            style={{
                cursor: "pointer",
                display: "inline-block",
                color: "#fff",
            }}
            onClick={() => setOpen(!open)}
        >
            {open ? text : text.slice(0, minCharacter)} {!open ? <IconButton
                className={'document-process-title-dots'}
                onClick={() => setOpen(!open)}
            >
                <MoreHorizIcon scale={0.3}/>
            </IconButton> : <></>}
        </Typography>
    );
}

export default function DocumentProcessStateComponent() {
    const {t} = useTranslation("common");

    const openModal = useHookstate(true);
    const documentProcessStateData = useHookstate(documentProcessState);
    const navigate = useHistory();

    const handleToggleModal = () => {
        openModal.set(!openModal.get());
    }

    const handleRemoveKeyFromTheList = (key: string) => {
        documentProcessStateData.set((prevState) => {
            delete prevState?.[key];
            return prevState;
        });
    }

    console.log("documentProcessStateData", documentProcessStateData.get({noproxy: true}));

    const getStatusComponent = (data, key) => {
        const commonStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "8px 16px 8px 16px",
        };

        let documentTitleList = [data?.data?.formData?.doc_title];

        if (data?.data?.multiFormData && data?.data?.multiFormData?.length > 0) {
            documentTitleList = data?.data?.multiFormData?.map((item) => item.doc_title);
        }

        if (data?.data?.creationMode === 'by-template' && data?.data?.formRecords && data?.data?.formRecords?.length > 0) {
            documentTitleList = data?.data?.formRecords?.map((item) => item.doc_title);
        }

        switch (data?.status) {
            case DocumentProcessStatus.ERROR:
                let errorTitle = '', errMessage =t(`common:${data?.result?.response?.data?.message || 'message.error.server_unknown_error'}`);
                if (documentTitleList.length > 1) {
                    errorTitle = t(`common:documents.document-info.documents-in-error`, { document_title: documentTitleList.join(', ') });
                } else {
                    errorTitle = t(`common:documents.document-info.document-in-error`, { document_title: documentTitleList?.[0] });
                }

                if (data?.result?.response?.data?.hasOwnProperty("error")) {
                    errMessage = data?.result?.response?.data?.error;
                }
                if (data?.result?.response?.data?.errors?.length > 0) {
                    if(data?.result?.response?.data?.error === 'documents.save.validation-error'){
                        errMessage = data?.result?.response?.data?.error;
                        data?.result?.response?.data?.errors.forEach(item => {
                            errMessage += ` ${item?.key} - ${item?.message} \n`;
                        })
                    }
                    if (data?.result?.response?.data?.error === 'documents.save.users-permission-deny') {
                        errMessage = data?.result?.response?.data?.error;
                        data?.result?.response?.data?.errors.forEach(item => {
                            errMessage += ` ${item?.user_id} - ${item?.email} \n`;
                        })
                    }
                } else {
                    if (data?.result?.code === 'ECONNABORTED' || (data?.result?.response?.status === 504 && data?.result?.response?.data?.message === 'Endpoint request timed out')) {
                        errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
                    }
                }

                errorTitle += `${errMessage ? `: ${errMessage}` : ''}`;

                if(data.errorTitle){
                    errorTitle = data.errorTitle;
                }
                return (
                    <Tooltip title={errorTitle} >
                        <div style={{
                            ...commonStyle,
                            backgroundColor: "#e0463b",
                        }}>
                            <CollapsibleText text={errorTitle}/>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                {!data?.isSign ? <IconButton
                                    style={{
                                        marginLeft: 8,
                                        color: "#fff"
                                    }}
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => {
                                        editErrorDocumemt.set(data?.data);
                                        navigate?.push(`/${data?.data?.creationType}/documents/create`, {
                                            isEditErrorDocument: true
                                        })
                                        handleRemoveKeyFromTheList(key);

                                    }}
                                >
                                    <EditIcon/>
                                </IconButton>:null}

                                <IconButton
                                    style={{
                                        color: "#fff"
                                    }}
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => {
                                        data?.onRetry();
                                    }}
                                >
                                    <ReplayIcon/>
                                </IconButton>
                            </div>
                        </div>
                    </Tooltip>
                );
            case DocumentProcessStatus.UPLOADING:
                let uploadingTitle = '';
                if (documentTitleList.length > 1) {
                    uploadingTitle = t('common:documents.document-info.documents-in-uploading', { document_title: documentTitleList.join(', ') });
                } else {
                    uploadingTitle = t('common:documents.document-info.document-in-uploading', { document_title: documentTitleList?.[0] });
                }
                return (
                    <div style={{
                        ...commonStyle,
                        padding: "16px 24px 16px 16px",
                    }}>
                        <Typography
                            className={"document-process-title"}
                            color={"white"}
                        >
                            {uploadingTitle}: {data?.upload?.index}/{data?.upload?.total}
                        </Typography>
                        <CircularProgress
                            size={24}
                            variant="determinate"
                            value={data?.upload?.index / data?.upload?.total * 100}
                        />
                    </div>
                );
            case DocumentProcessStatus.PROCESSING:
                let processingTitle = '';
                if (documentTitleList.length > 1) {
                    processingTitle = t('common:documents.document-info.documents-in-processing', { document_title: documentTitleList.join(', ') });
                } else {
                    processingTitle = t('common:documents.document-info.document-in-processing', { document_title: documentTitleList?.[0] });
                }
                if(data?.processingTitle){
                    processingTitle = data.processingTitle;
                }
                return (
                    <div style={{
                        ...commonStyle,
                        padding: "16px 24px 16px 16px",
                    }}>
                        <Typography
                            className={"document-process-title"}
                            color={"white"}
                        >{processingTitle}</Typography>
                        <CircularProgress
                            size={24}
                        />
                    </div>
                );
            case DocumentProcessStatus.SUCCESS:
                let successTitle = '';
                if (documentTitleList.length > 1) {
                    successTitle = t('common:documents.document-info.documents-in-success', { document_title: documentTitleList.join(', ') });
                } else {
                    successTitle = t('common:documents.document-info.document-in-success', { document_title: documentTitleList?.[0] });
                }
                if(data?.successTitle){
                    successTitle = data.successTitle;
                }
                return (
                    <div style={{
                        ...commonStyle,
                        backgroundColor: "#078c07",
                    }}>
                        <Typography
                            className={"document-process-title"}
                            color={"white"}
                        >{successTitle}</Typography>
                        {(data?.data?.creationMode === 'by-template' && data?.data?.formRecords && data?.data?.formRecords?.length > 0) ? (
                            <></>
                        ) : (
                            <div>
                                  {data?.isSign ? (
                                      <></>
                                  ) : (
                                      <Tooltip title={t('common:documents.document-info.open-document', { document_title: documentTitleList.join(', ') })}>
                                        <IconButton
                                            style={{
                                                color: "#fff"
                                            }}
                                            aria-label="delete"
                                            onClick={() => {
                                                if (data?.isEvaluate) {
                                                    handleRemoveKeyFromTheList(key);
                                                    navigate?.push(`/evaluation`)
                                                    return;
                                                }
                                                let documentIdList = [data?.result?.data?.document_id];
                                                if (data?.result?.success_add && data?.result?.success_add.length > 0) {
                                                    documentIdList = data?.result?.success_add.map(item => item.document_id);
                                                }
                                                if (documentIdList.length === 1) {
                                                    navigate?.push(`/documents/detail/${documentIdList[0]}`)
                                                } else {
                                                    documentIdList.forEach((documentId) => {
                                                        window.open(`/documents/detail/${documentId}`, '_blank', 'noopener,noreferrer');
                                                    })
                                                }
                                                handleRemoveKeyFromTheList(key);
                                            }}
                                        >
                                            <ArrowForwardIcon/>
                                        </IconButton>
                                      </Tooltip>
                                  )}
                            </div>
                        )}
                    </div>
                );
            default:
                return <></>;
        }
    };

    function hiddenProcessDialog(){

        documentProcessStateData.set(null);
    }
    const callFuncD = useCallback(
        debounce(hiddenProcessDialog, 5000),
        []
    );
    useEffect(()=>{
        let val = documentProcessStateData?.get({stealth:true,noproxy: true});
        if(val){
           let dataEntries = Object.keys(val).reduce((arr, i, idx) => {
            if(val?.[i]?.status == DocumentProcessStatus.SUCCESS){
                arr.push(i);
            }
            return arr;
           },[]);

           if(dataEntries.length === Object.keys(val).length){
             callFuncD();
           }
        }
    },[documentProcessStateData]);

    const renderStatusComponents = () => {
        const dataEntries = documentProcessStateData.get({noproxy: true}) ?? {};

        return Object.keys(dataEntries).map((key) => {
            const data = dataEntries[key];
            return (
                <div
                    key={key}
                    className={"document-process-item"}
                >
                    {getStatusComponent(data, key)}
                </div>
            );
        });
    };

    const modalTitle = () => {
        const dataEntries = documentProcessStateData.get({noproxy: true}) ?? {};
        const totalDocument = Object.keys(dataEntries).length;
        const processingDocuments = Object.values(dataEntries).filter(data => data.status === DocumentProcessStatus.UPLOADING || data.status === DocumentProcessStatus.PROCESSING).length;
        const errorDocuments = Object.values(dataEntries).filter(data => data.status === DocumentProcessStatus.ERROR).length;

        if (processingDocuments && processingDocuments > 0) {
            return processingDocuments === 1
                ? t('common:documents.document-info.document-processing')
                : t('common:documents.document-info.documents-processing', { total: processingDocuments });
        }

        if (errorDocuments && errorDocuments > 0) {
            return errorDocuments === 1
                ? t('common:documents.document-info.document-error')
                : t('common:documents.document-info.documents-error', { total: errorDocuments });
        }

        return totalDocument === 1
            ? t('common:documents.document-info.document-complete')
            : t('common:documents.document-info.documents-complete', { total: totalDocument });
    }

    const modalBackgroundColor = () => {
        const dataEntries = documentProcessStateData.get({noproxy: true}) ?? {};
        const successDocuments = Object.values(dataEntries).filter(data => data.status === DocumentProcessStatus.SUCCESS).length;
        const errorDocuments = Object.values(dataEntries).filter(data => data.status === DocumentProcessStatus.ERROR).length;

        if (errorDocuments && errorDocuments > 0) {
            return "#e0463b";
        }

        if (successDocuments && successDocuments > 0) {
            return "#078c07";
        }

        return "#1b1b1b";
    }

    return (
        <>
            {(documentProcessStateData.get({noproxy: true}) && Object.keys(documentProcessStateData.get({noproxy: true}) ?? {}).length > 0) ? (
                <div className={'document-process-container'}>
                    <div
                        className={'document-process-header'}
                        style={{
                            backgroundColor: modalBackgroundColor(),
                        }}
                    >
                        <Typography
                            className={'document-process-header-title'}
                            color={"#fff"}
                        >
                            {modalTitle()}
                        </Typography>
                        <div className={'document-process-header-icon-container'}>
                            <IconButton
                                aria-label="delete"
                                className="document-process-header-icon"
                                onClick={handleToggleModal}
                            >
                                {openModal.get({noproxy: true}) ? <ExpandMore/> : <ExpandLess/>}
                            </IconButton>

                            {Object.values(documentProcessStateData.get({noproxy: true}) ?? {}).filter(data => data.status === 1 || data.status === 2).length > 0 ? (
                                <></>
                            ) : (
                                <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    className="document-process-header-icon"
                                    onClick={() => {
                                        documentProcessStateData.set(null);
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            )}
                        </div>
                    </div>

                    {openModal.get({noproxy: true}) ? (
                        <div style={{
                            boxSizing: "border-box",
                            maxHeight: 240,
                            overflow: "auto",
                            position: "relative",
                        }}>
                            {renderStatusComponents()}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    )
}
