// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { Tree } from "@minoru/react-dnd-treeview";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popper,
  TextField,
  useAutocomplete,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getFoldersTree } from "../../data-access/api";
import { getCompanyFolders } from "../../data-access/company-folders/company-folders.api";
import {
  expandNodeTreeSearchState,
  selectedRowsTreeSearch,
  userInfoState,
} from "../../data-access/state";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import DragDropFile from "../drag-upload/drag-upload";
import {
  DriveDragPreview,
  DriveDragPreviewMultiple,
} from "../drive-nav/drive-drag-preview";
import DriveNavNode from "../drive-nav/drive-nav-node";
// import DriveNavNode from "./drive-nav-node";

export default function TreeSearchAutocomplete({
  treeData,
  searchInputCb,
  loading,
  defaultTree,
  selectedCb,
  inputValueProp
}) {
  // const treeData = tree;
  const { t } = useTranslation("common");
  // const getData = treeData.get({ noproxy: true });
  const currentInputValue = useHookstate(inputValueProp);
  // const inTs = currentInputValue.get({ noproxy: true });
  // console.log(inTs);
  const [isOpen, setIsOpen] = useState(false);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    inputValue,
    focused,
    setAnchorEl,
    anchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: [],
    multiple: true,
    freeSolo: true,
    options: defaultTree,
    open: isOpen,
    onClose: () => setIsOpen(true),
    inputValue: currentInputValue.get({ noproxy: true }),
    getOptionLabel: (option) => `${option.title} - ${option.name}`,
  });
  const currentInputProps = getInputProps();
  // const history = useHistory();
  // const defaultFolder = useHookstate(false);
  const { folderId } = useParams();
  const loadingTreeIndex = useHookstate(-1);
  const currentFolderId = useHookstate(0);
  // const location = useLocation();
  // const [treeData, setTreeData] = useState(flattenTree(data));
  const isDragging = useHookstate(false);
  const isCtrlPressing = useHookstate(false);

  const selectedNodes = useHookstate(selectedRowsTreeSearch);
  const ref = useRef(null);
  // const selectedNodes = useHookstate([]);
  const expandedNodes = useHookstate(
    // flattenDataMock.map(a => findAncestors(a, flattenDataMock))
    expandNodeTreeSearchState
    // findAncestryById(dataDriveTreeDefault, folderId, "id").map((a) => a.id)
  );
  const openAutocompleteTree = useHookstate({
    anchorEl: null,
  });
  // const isShowTableDrag = useHookstate(isShowTableDragFile);

  useEffect(() => {
    if (treeData.value.length > 0) {
      if (!treeData.get({ stealth: true })?.find((a) => a.id == folderId)) {
        expandedNodes.set(["drive", "delivery"]);
      } else {
        // expandedNodes.set([
        //   ...findAncestors(
        //     folderId ? (isNaN(+folderId) ? folderId : +folderId) : "drive",
        //     treeData.value
        //   ),
        //   folderId ? (isNaN(+folderId) ? folderId : +folderId) : "drive",
        // ]);
      }
    }
  }, [treeData, folderId]);
  useEffect(() => {
    const findCompany = treeData.value?.find((a) => a.type === 6);
    if (findCompany?.name !== userInfoState.value?.tenant_name) {
      const findCompanyIdx = treeData.value.findIndex((a) => a.type === 6);
      if (findCompanyIdx > -1) {
        treeData[findCompanyIdx].set((v) => ({
          ...v,
          name: userInfoState.get({ stealth: true })?.tenant_name,
        }));
      }
    }
  }, [treeData, userInfoState]);
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current?.openAll();
  //   }
  //   // if (expandedNodes.length === 0) {
  //   //   ref.current?.open(["drive"]);
  //   // }
  // }, [expandedNodes, ref.current]);
  useEffect(() => {
    if (ref.current) {
      ref.current?.openAll();
    }
  }, [treeData, ref.current]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e?.key?.toLowerCase() === "escape") {
        selectedNodes.set([]);
      } else if (e.ctrlKey || e.metaKey) {
        isCtrlPressing.set(true);
      }
    };

    const handleKeyUp = (e) => {
      if (
        e?.key?.toLowerCase() === "control" ||
        e?.key?.toLowerCase() === "meta"
      ) {
        isCtrlPressing.set(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  const [heightPopper, setHeightPoper] = useState(0)
  const [isFullHeight, setIsFullHeight] = useState(true)
  const handleSingleSelect = (node) => {
    selectedNodes.set([node]);
    // console.log(node.name);
    currentInputValue.set(node.name);
  };

  const handleClick = (e, node) => {
    handleSingleSelect(node);
  };

  // /**HTMLDivElement */
  const searchInputRef = useRef();
  useEffect(() => {
    currentFolderId.set(folderId);
  }, [folderId]);

  //handle show popper top or bot
  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const inputTop = searchInputRef?.current?.getBoundingClientRect()?.top;
    if(viewportHeight - inputTop - 40 > heightPopper){
      setIsFullHeight(true)
    }else{
      setIsFullHeight(false)
    }
  }, [searchInputRef, heightPopper]);
  const textFieldRef = useRef();
  // console.log(treeData.get({ noproxy: true }).sort((a,b)=> a.parent_id - b.parent_id))
  return (
    <>
      <DragDropFile
        onDragEnter={() => {}}
        onDragLeave={() => {}}
        onDragOver={() => {}}
        onDrop={() => {}}
      />
      <div {...getRootProps()} ref={searchInputRef}>
        {/* <label {...getInputLabelProps()}>Customized hook</label> */}
        <div
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          onClick={(e) => {
            // console.log(e.currentTarget, e.target, e);
            openAutocompleteTree.anchorEl.set(e.target);
          }}
        >
          {value.map((option, index) => {
            let tagProps = getTagProps({ index });
            if (!option) return <></>;
            return (
              <div {...tagProps}>
                <span>{option.title}</span>
                <CloseIcon onClick={tagProps.onDelete} />
              </div>
              // <Tag label={option.title}  />
            );
          })}
          <TextField
            // {...params}
            // {...currentInputProps}
            onClick={() => setIsOpen(true)}
            onChange={(ev) => {
              // console.log('onchange', ev.target.value)
              currentInputValue.set(ev.target.value);
              if (
                ev.target.value !==
                selectedNodes.get({ stealth: true })[0]?.name
              ) {
                selectedNodes.set([]);
              }
              searchInputCb(ev.target.value);
              // return currentInputProps.onChange(ev, ev.target.value, "");
            }}
            size="small"
            InputProps={{
              ...currentInputProps.InputProps,
              className: "MuiAutocomplete-inputRoot",
              endAdornment: (
                <>
                  {loading.get() ? (
                    <CircularProgress
                      sx={{
                        position: "absolute",
                        right: 35,
                      }}
                      color="inherit"
                      size={20}
                    />
                  ) : null}
                  <div
                    className="MuiAutocomplete-endAdornment"
                    style={{
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>

                    {/* {params?.InputProps?.endAdornment} */}
                  </div>
                </>
              ),
            }}
            inputProps={{
              ...currentInputProps,
              value: currentInputValue.get({ noproxy: true }),
            }}
            // inputRef={currentInputProps.itemRef}
            fullWidth={true}
            value={currentInputValue.get({ noproxy: true })}
          />
          {/* <input {...getInputProps()} /> */}
        </div>
        <div className={"app_drive_node"} {...getListboxProps()}>
          {/* {JSON.stringify(groupedOptions)} */}
          {/* {groupedOptions.length > 0  ? }  */}
          <Popper
            // id="mouse-over-popover"
            // sx={{
            //   pointerEvents: "none",
            // }}
            className="popper_tree_search"
            ref={el => {
              if (!el) return;
              let prevValue = JSON.stringify(el.getBoundingClientRect());
              const handle = setInterval(() => {
                let nextValue = JSON.stringify(el.getBoundingClientRect());
                if (nextValue === prevValue) {
                  clearInterval(handle);
                  setHeightPoper(el.getBoundingClientRect().height)
                } else {
                  prevValue = nextValue;
                }
              }, 100);}}
            sx={{
              zIndex: 999999,
              backgroundColor: "white",
              position: "absolute !important",
              top: `${
                isFullHeight ? searchInputRef?.current?.getBoundingClientRect()?.top + 40 : searchInputRef?.current?.getBoundingClientRect()?.top - 60 - heightPopper
              }px !important`,
              left: `${
                searchInputRef?.current?.getBoundingClientRect()?.left
              }px !important`,
              width: `${
                searchInputRef?.current?.getBoundingClientRect()?.width
              }px !important`,
              maxHeight: "calc(50vh - 100px) !important",
              height: "100%",
            }}
            disablePortal={false}
            open={groupedOptions.length > 0}
            role={undefined}
            placement="bottom-start"
            transition
            // disablePortal
            // placement={"bottom"}
            // transition
            anchorEl={searchInputRef?.current}
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
            // transformOrigin={{
            //   vertical: "top",
            //   horizontal: "left",
            // }}
            // // onClose={handlePopoverClose}
            // disableRestoreFocus
          >
            {groupedOptions.length > 0 ? (
              <>
              <Tree
                // extraAcceptTypes={[NativeTypes.TEXT, NativeTypes.FILE]}
                ref={ref}
                rootId={"drive"}
                insertDroppableFirst={true}
                // initialOpen={true}
                tree={treeData.get({ noproxy: true })}
                classes={{
                  root: "treeRoot treeRootSearch",
                  draggingSource: "draggingSource",
                  dropTarget: "dropTarget",
                }}
                // onDrop={handleDrop}
                canDrag={(node, options) => {
                  // node = selectedNodes.get({stealth:true})[0];
                  // console.log(node, selectedNodes.get({stealth:true}));
                  return false;
                }}
                canDrop={(tree, options) => {
                  return false;
                }}
                dragPreviewRender={(monitorProps) => {
                  // console.log(monitorProps);
                  if (selectedNodes.get().length > 1) {
                    return (
                      <DriveDragPreviewMultiple
                        dragSources={selectedNodes.get({
                          noproxy: true,
                          stealth: true,
                        })}
                      />
                    );
                  }

                  return (
                    <DriveDragPreview
                      ref={monitorProps.item.ref}
                      monitorProps={monitorProps}
                    />
                  );
                }}
                render={(node, options) => {
                  const selected = selectedNodes
                    .get()
                    .some((selectedNode) => selectedNode.id === node.id);
                  // console.log(node, options);
                  return (
                    <DriveNavNode
                      node={node}
                      {...options}
                      disableToolTip={true}
                      hightLightText={currentInputValue.get({ noproxy: true })}
                      loadingTreeIndex={loadingTreeIndex.get()}
                      onToggle={(e, id, isOpen, type) => {
                        if (
                          !isOpen &&
                          id !== "drive" &&
                          type !== 6 &&
                          type !== 9
                        ) {
                          loadingTreeIndex.set(id);
                          getFoldersTree({
                            page_number: 1,
                            page_size: 50,
                            parent_id: id,
                          })
                            .then((r) => {
                              if (r?.data?.payload?.length > 0) {
                                treeData.set((v) => {
                                  const newData = [
                                    ...v,
                                    ...r.data.payload.map(mappedDataToTreeView),
                                  ].reduce((arr, item) => {
                                    if (item.id == id) {
                                      item["is_empty"] = false;
                                    }
                                    if (!arr.find((b) => b.id == item.id)) {
                                      arr.push(item);
                                    }
                                    return arr;
                                  }, []);
                                  return newData;
                                });
                              }
                            })
                            .finally(() => {
                              if (
                                loadingTreeIndex.get({
                                  stealth: true,
                                  noproxy: true,
                                }) > -1
                              ) {
                                loadingTreeIndex.set(-1);
                              }
                            });
                        }
                        // console.log(type);
                        if (!isOpen && (type === 6 || type === 9)) {
                          loadingTreeIndex.set(id);
                          getCompanyFolders({
                            drive_folder_id: id,
                            page_number: 1,
                            page_size: 100,
                            offset_folder_number: 0,
                            search: "",
                          })
                            .then((r) => {
                              if (r?.data?.payload?.length > 0) {
                                treeData.set((v) => {
                                  const newData = [
                                    ...v,
                                    ...r.data.payload.map(mappedDataToTreeView),
                                  ].reduce((arr, item) => {
                                    // if (item.id == id) {
                                    //   item["is_empty"] = false;
                                    // }
                                    if (!arr.find((b) => b.id == item.id)) {
                                      arr.push(item);
                                    }
                                    return arr;
                                  }, []);
                                  return newData;
                                });
                              }
                            })
                            .finally(() => {
                              if (
                                loadingTreeIndex.get({
                                  stealth: true,
                                  noproxy: true,
                                }) > -1
                              ) {
                                loadingTreeIndex.set(-1);
                              }
                            });
                        }
                        return options.onToggle(e);
                      }}
                      isSelected={selected}
                      isDragging={selected && isDragging.get()}
                      onClick={handleClick}
                    />
                  );
                }}
              />
              <Box sx={{
                top: isFullHeight ? searchInputRef?.current?.getBoundingClientRect()?.top + heightPopper+ 'px' : searchInputRef?.current?.getBoundingClientRect()?.top - 60 + 'px',
                left: `${
                  searchInputRef?.current?.getBoundingClientRect()?.left
                }px !important`,
                width: `${
                  searchInputRef?.current?.getBoundingClientRect()?.width
                }px !important`,
              }}
              className="bottom-fixed" 
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpen(false)
                    selectedCb(selectedNodes)
                    }}
                >
                  {t("common:general.confirm")}
                </Button>
              </Box> 
              </>
            ) : (
              <div>NO DATA</div>
            )}
          </Popper>
        </div>
      </div>
      {/* </DndProvider> */}
    </>
  );
}
