// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import { isAncestor, Tree } from "@minoru/react-dnd-treeview";
import { useEffect, useRef } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  copyItem,
  getFoldersTree,
  moveItem,
  moveToTrash,
} from "../../data-access/api";
import { getCompanyFolders } from "../../data-access/company-folders/company-folders.api";
import {
  breabCrumbState,
  dragTargetState,
  driveDetailLoading,
  driveFileDrawer,
  driveFoldersDetail,
  driveFoldersNav,
  expandNodeState,
  folderDetailSearch,
  isCompleteFolderState,
  isDraggingGlobalState,
  isOpenShareDialog,
  isShowTableDragFile,
  notificationAlertState,
  scrollLoadingState,
  searchObjectFolder,
  selectedRowsNav,
  userInfoState,
} from "../../data-access/state";
import { findAncestors } from "../../utils/find-parent";
import handleUploadOnDrop from "../../utils/handle-upload-drop";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import { mappedTypeToQuery } from "../../utils/mappedTypeToQuery";
import searchContentFolderUtils from "../../utils/search-content-folder";
import DragDropFile from "../drag-upload/drag-upload";
import DriveConfirmDialog from "../drive-dialogs/drive-confirm-base";
import {
  DriveDragPreview,
  DriveDragPreviewMultiple,
} from "./drive-drag-preview";
import DriveNavNode from "./drive-nav-node";
const loadingTreeIndexState = hookstate(-1);
export default function DriveNav({ fromDocumentSelect }) {
  const { t } = useTranslation("common");
  const history = useHistory();
  // const defaultFolder = useHookstate(false);
  const { folderId } = useParams();
  const loadingTreeIndex = useHookstate(loadingTreeIndexState);
  const currentFolderId = useHookstate(0);
  // const location = useLocation();
  // const [treeData, setTreeData] = useState(flattenTree(data));
  const treeData = useHookstate(driveFoldersNav);
  const isDragging = useHookstate(false);
  const isCtrlPressing = useHookstate(false);

  const selectedNodes = useHookstate(selectedRowsNav);
  const ref = useRef(null);
  const openDeleteConfirm = useHookstate({
    open: false,
    title: t("common:drive.delete_confirm.title"),
    message: t("common:drive.delete_confirm.message") || "",
    callback: dropDelete,
    loading: false,
  });
  const currentSelectedNodes = useHookstate([]);
  const draggedItems = useHookstate({
    files: [],
    folders: [],
    enable: false,
    document_id_list: [],
  });
  // const selectedNodes = useHookstate([]);
  const expandedNodes = useHookstate(
    // flattenDataMock.map(a => findAncestors(a, flattenDataMock))
    expandNodeState
    // findAncestryById(dataDriveTreeDefault, folderId, "id").map((a) => a.id)
  );
  const isShowTableDrag = useHookstate(isShowTableDragFile);
  useEffect(() => {
    if (treeData.value.length > 0) {
      if (!treeData.get({ stealth: true })?.find((a) => a.id == folderId)) {
        expandedNodes.set(["drive", "delivery"]);
      } else {
        // expandedNodes.set([
        //   ...findAncestors(
        //     folderId ? (isNaN(+folderId) ? folderId : +folderId) : "drive",
        //     treeData.value
        //   ),
        //   folderId ? (isNaN(+folderId) ? folderId : +folderId) : "drive",
        // ]);
      }
    }
  }, [treeData, folderId]);
  useEffect(() => {
    const findCompany = treeData.value?.find((a) => a.type === 6);
    if (findCompany?.name !== userInfoState.value?.tenant_name) {
      const findCompanyIdx = treeData.value.findIndex((a) => a.type === 6);
      if (findCompanyIdx > -1) {
        treeData[findCompanyIdx].set((v) => ({
          ...v,
          name: userInfoState.get({ stealth: true })?.tenant_name,
        }));
      }
    }
  }, [treeData, userInfoState]);
  useEffect(() => {
    if (expandedNodes && ref.current) {
      ref.current?.open([
        isNaN(+folderId) ? folderId : +folderId,
        ...expandedNodes.value,
      ]);
    }
    if (expandedNodes.length === 0) {
      ref.current?.open(["drive"]);
    }
  }, [expandedNodes, ref.current]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e?.key?.toLowerCase() === "escape") {
        selectedNodes.set([]);
      } else if (e.ctrlKey || e.metaKey) {
        isCtrlPressing.set(true);
      }
    };

    const handleKeyUp = (e) => {
      if (
        e?.key?.toLowerCase() === "control" ||
        e?.key?.toLowerCase() === "meta"
      ) {
        isCtrlPressing.set(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleSingleSelect = (node) => {
    selectedNodes.set([node]);
  };

  const handleMultiSelect = (clickedNode) => {
    const selectedIds = selectedNodes.get({ stealth: true }).map((n) => n.id);

    // ignore if the clicked node is already selected
    if (selectedIds.includes(clickedNode.id)) {
      return;
    }

    // ignore if ancestor node already selected
    if (
      selectedIds.some((selectedId) => {
        return isAncestor(
          treeData.get({ stealth: true, noproxy: true }),
          selectedId.id,
          clickedNode.id
        );
      })
    ) {
      return;
    }

    selectedNodes.set((v) => {
      return [
        ...v.filter((selectedNode) => {
          return (
            !isAncestor(
              treeData.get({ stealth: true, noproxy: true }),
              clickedNode.id,
              selectedNode.id
            ) && ![1, 2, 3, 4, 5, 6, 7].includes(selectedNode.type)
          );
        }),
        clickedNode,
      ];
    });
    // console.log(selectedNodes.get({ stealth: true }));
  };

  const handleClick = (e, node) => {
    if (e.ctrlKey || e.metaKey) {
      handleMultiSelect(node);
    } else {
      handleSingleSelect(node);
      if (node.id !== folderId) {
        if (node.id === "drive") {
          driveFileDrawer.open.set(false);
          driveFileDrawer.dataDetail.set({});
          history.push(`/drive`);
        } else if (node.id === "delivery") {
          driveFileDrawer.open.set(false);
          driveFileDrawer.dataDetail.set({});
          history.push(`/delivery`);
        } else if (node.id === "delivery/list") {
          driveFileDrawer.open.set(false);
          driveFileDrawer.dataDetail.set({});
          history.push(`/delivery`);
        } else {
          driveFileDrawer.open.set(false);
          driveFileDrawer.dataDetail.set({});
          history.push(
            `/folders/${node.id}?type=${mappedTypeToQuery(node.type)}${
              [1, 2, 3, 4].includes(node.type)
                ? `&function_type=${node.type}`
                : ""
            }`
          );
        }
      }
    }
  };

  const handleDragStart = (node) => {
    if (fromDocumentSelect) return;
    const isSelectedNode = selectedNodes
      .get({ stealth: true })
      .some((n) => n.id === node.id);
    isDragging.set(true);
    // console.log(isOpenShareDialog);
    if (isOpenShareDialog.get({ stealth: true, noproxy: true })) {
      isDraggingGlobalState.set(true);
    }
    if (!isCtrlPressing.get({ stealth: true }) && isSelectedNode) {
      return;
    }

    if (!isCtrlPressing.get({ stealth: true })) {
      selectedNodes.set([node]);
      return;
    }

    if (!selectedNodes.get({ stealth: true }).some((n) => n.id === node.id)) {
      selectedNodes.set((v) => [...v, node]);
    }
  };

  const handleDragEnd = (e) => {
    isDragging.set(false);
    isCtrlPressing.set(false);
    selectedNodes.set([]);
    isShowTableDragFile.set(false);
    isDraggingGlobalState.set(false);
  };

  const functionInitStateAfterMove = (
    r,
    selectedFolders,
    dropTargetId,
    selected
  ) => {
    if (r?.data?.payload?.length > 0) {
      const payloadMap = r.data.payload.map(mappedDataToTreeView);
      treeData.set((v) => {
        const newData = [
          ...v.filter((a) => {
            const condition = !selectedFolders.includes(a.id);
            if (!condition) {
              const oldParent = v.findIndex((b) => b.id === a.parent_id);
              if (oldParent > -1) {
                v[oldParent].size = v[oldParent].size - 1;
                if (v.filter((c) => c.parent_id === a.parent_id)?.length < 2) {
                  v[oldParent]["notShowDropDownIcon"] = 1;
                  v[oldParent]["is_empty"] = 1;
                }
              }
            }
            return condition;
          }),
          ...payloadMap,
        ].reduce((arr, item) => {
          const findIdx = arr.findIndex((c) => c.id == item.id);
          if (findIdx === -1) {
            arr.push(item);
          }
          return arr;
        }, []);
        newData.forEach((a) => {
          const findSelected = newData.findIndex((b) => b.id === a.parent_id);
          // console.log(findSelected, v, selected);
          // if (findSelected) {
          //   a.parent_id = dropTargetId;
          //   a.parent = dropTargetId;
          // }
          if (findSelected > -1) {
            newData[findSelected] = {
              ...newData[findSelected],
              is_empty: 0,
              notShowDropDownIcon: 0,
            };
          } else {
            newData[findSelected] = {
              ...newData[findSelected],
              is_empty: 1,
              notShowDropDownIcon: 1,
            };
            // a = { ...a, is_empty: 1, notShowDropDownIcon: 1 };
          }

          // console.log(
          //   newData[findSelected]?.id,
          //   newData[findSelected]?.notShowDropDownIcon
          // );
        });
        return newData.sort((a, b) => {
          if (a.type === 7) {
            return -1;
          } else if (![1, 2, 3, 4, 5].includes(a.type)) {
            return 0;
          } else {
            return a.type - b.type;
          }
        });
      });
    }
  };
  const handleDrop = (newTree, options, aaa) => {
    // return
    const { dropTargetId, dropTarget } = options;
    isShowTableDragFile.set(false);

    if (
      options?.monitor?.getItem()?.dataTransfer &&
      !options.monitor.getItem()?.text
    ) {
      return handleUploadOnDrop(options.monitor.getItem(), t, dropTargetId);
    }
    // console.log(treeData, dropTargetId, newTree);
    // treeData.set([]);
    let selected = selectedNodes.get({ stealth: true, noproxy: true });
    // console.log(selected.find((a) => a.parent == dropTargetId || dropTargetId == a.id),dropTargetId,selected);
    // return
    // console.log(options);
    // console.log(options);
    const draggedTable = dragTargetState.get({ stealth: true, noproxy: true });
    currentSelectedNodes.set([...selected]);
    // console.log(selected,dropTargetId,dropTarget, selected.some((a) => a.parent == dropTargetId || dropTargetId == a.id));
    // return
    const allAnchestor = findAncestors(
      dropTarget.id
        ? isNaN(+dropTarget.id)
          ? dropTarget.id
          : +dropTarget.id
        : "company",
      driveFoldersNav.get({ stealth: true, noproxy: true })
    );
    // return console.log(selected, draggedTable)
    if (draggedTable?.length > 0) {
      selected = draggedTable;
      if (
        selected.some(
          (a) =>
            a.parent == dropTargetId ||
            dropTargetId == a.id ||
            a.id == dropTarget.parent_id ||
            allAnchestor.includes(a.id)
        )
      )
        return;
      console.log(selected);
      currentSelectedNodes.set([...selected]);
      const newSelected = selected.reduce(
        (arr, item) => {
          if (item.document_id) {
            arr.document_id_list.push(item);
          } else if (item.extension === "folder") {
            arr.folders.push(item);
          } else {
            arr.files.push(item);
          }
          return arr;
        },
        {
          files: [],
          folders: [],
          document_id_list: [],
        }
      );
      const selectFolders = newSelected.folders.map((a) => a.id);
      const selectedFiles = newSelected.files.map((a) => a.id);
      const selectDocuments = newSelected.document_id_list.map(
        (a) => a.document_id
      );
      if (isCompleteFolderState.get({ stealth: true })) {
        const completeList = selected.map((a) => a.id);
        copyItem(
          {
            document_id_list: selected.map((a) => a.id),
            drive_file_id_list: [],
            drive_folder_id_list: [],
          },
          dropTargetId === "drive" ? null : dropTargetId
        ).then((r) => {
          if (!r) return;
          driveFoldersDetail?.data[
            dropTargetId === "drive" ? "root" : dropTargetId
          ]?.merge([...selected]);

          const tree = treeData.get({ stealth: true, noproxy: true });
          // if (treeIdx > -1) {
          // }
          treeData.forEach((a) => {
            if (tree.find((b) => b.parent_id == a?.get({ steath: true })?.id)) {
              a.set((c) => {
                return {
                  ...c,
                  is_empty: 0,
                  notShowDropDownIcon: 0,
                };
              });
            } else {
              a.set((c) => {
                return {
                  ...c,
                };
              });
            }
          });
          notificationAlertState.set({
            show: true,
            type: "success",
            message: `${t(
              "common:drive.message.success.copy_folder",
              "Copy folder success!"
            )}`,
          });
          getFoldersTree({
            page_number: 1,
            page_size: 50,
            parent_id: dropTargetId === "drive" ? "null" : dropTargetId,
          }).then((r) => {
            if (r?.data?.payload?.length > 0) {
              treeData.set((v) => {
                const newData = [
                  ...r.data.payload.map(mappedDataToTreeView),
                  ...v?.filter((a) => !completeList.includes(a.id)),
                ].reduce((arr, item) => {
                  if (item.id == dropTargetId) {
                    item["is_empty"] = false;
                  }
                  if (!arr.find((b) => b.id == item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
                // console.log(r?.data?.payload);
                return newData.sort((a, b) => {
                  if (a.type === 7) {
                    return -1;
                  } else if (![1, 2, 3, 4, 5].includes(a.type)) {
                    return 0;
                  } else {
                    return a.type - b.type;
                  }
                });
              });
            }
          });
        });
        // .catch((e) => {
        //   notificationAlertState.set({
        //     show: true,
        //     type: "error",
        //     message: `${t(
        //       dropTargetId === "trash"
        //         ? "common:drive.message.error.copy_to_trash"
        //         : "common:drive.message.error.copy_folder",
        //       dropTargetId === "trash"
        //         ? "Cannot copy to trash folder !"
        //         : `Copy folder error! ${e?.response?.data?.message}`
        //     )}`,
        //   });
        // });
        // onClose();
        return;
        // .finally(() => copyLoading.set(false));
      }
      if (dropTarget.id !== "trash") {
        // const selectFolders = selected.map((a) => a.id);
        if (
          !driveFoldersDetail?.data[!folderId ? "root" : folderId]?.get({
            stealth: true,
          })
        ) {
          driveFoldersDetail?.data.merge({
            [!folderId ? "root" : folderId]: [],
          });
        }
        const moveOrCopyList = {
          document_id_list: selectDocuments,
          drive_file_id_list: selectedFiles,
          drive_folder_id_list: selectFolders,
        };

        moveItem(
          moveOrCopyList,
          dropTargetId === "drive" ? null : dropTargetId
        ).then((r) => {
          if (!r) return;
          driveFileDrawer.open.set(false);
          driveFileDrawer.dataDetail.set({});
          // console.log(r);
          driveFoldersDetail?.data[!folderId ? "root" : folderId]?.set((v) => {
            return v?.filter(
              (a) =>
                !selectFolders.includes(a.id) &&
                !selectedFiles.includes(a.id) &&
                !selectDocuments.includes(a.document_id)
            );
          });
          if (searchObjectFolder.isSearching.get({ stealth: true })) {
            searchObjectFolder.offset_document_number.set(0);
            searchObjectFolder.offset_folder_number.set(0);
            searchObjectFolder.offset_file_number.set(0);
            searchObjectFolder.isStop.set(0);
            folderDetailSearch.offset_folder_number.set((v) => 0);
            folderDetailSearch.offset_file_number.set((v) => 0);
            searchContentFolderUtils(
              undefined,
              searchObjectFolder,
              folderId,
              scrollLoadingState,
              driveDetailLoading,
              breabCrumbState,
              false
            );
          }
          if (dropTarget.type === 6) {
            getCompanyFolders({
              page_number: 1,
              page_size: 100,
              offset_folder_number: 0,
              drive_folder_id: dropTargetId,
              search: "",
            }).then((r) => {
              functionInitStateAfterMove(
                r,
                selectFolders,
                dropTargetId,
                selected
              );
            });
          } else {
            getFoldersTree({
              page_number: 1,
              page_size: 50,
              parent_id: dropTargetId === "drive" ? "null" : dropTargetId,
            }).then((r) => {
              functionInitStateAfterMove(
                r,
                selectFolders,
                dropTargetId,
                selected
              );
            });
          }
        });
      } else {
        draggedItems.files.set(selectedFiles);
        draggedItems.folders.set(selectFolders);
        draggedItems.document_id_list.set(selectDocuments);
        draggedItems.enable.set(true);
        // console.log(selected, options.monitor.getItem());
        if (
          selected.some((a) => a.size > 0 && a.extension === "folder") ||
          (options.monitor.getItem()?.size > 0 &&
            options.monitor.getItem()?.extension === "folder")
        ) {
          openDeleteConfirm.message.set(
            t(
              "common:drive.delete.warning.have_file",
              "このフォルダの中にはファイルまたはフォルダが含まれています。本当に削除しますか？"
            )
          );
        } else {
          openDeleteConfirm.message.set(
            t("common:drive.delete_confirm.message")
          );
        }
        openDeleteConfirm.open.set(true);
      }

      return;
    }
    if (
      selected.some(
        (a) =>
          a.parent == dropTargetId ||
          dropTargetId == a.id ||
          a.id == dropTarget.parent_id
      )
    )
      return;
    if (dropTargetId === "drive") {
      return;
    }
    if (dropTarget.id !== "trash") {
      // const selectFolders = selected.map((a) => a.id);
      // const selectedFiles = selected.files.map((a) => a.id);

      // if (treeIdx > -1) {
      // }
      if (
        !driveFoldersDetail?.data[!folderId ? "root" : folderId]?.get({
          stealth: true,
        })
      ) {
        driveFoldersDetail?.data.merge({
          [!folderId ? "root" : folderId]: [],
        });
      }
      const newSelected = selected.reduce(
        (arr, item) => {
          if (item.document_id) {
            arr.document_id_list.push(item);
          } else if (item.extension === "folder") {
            arr.folders.push(item);
          } else {
            arr.files.push(item);
          }
          return arr;
        },
        {
          files: [],
          folders: [],
          document_id_list: [],
        }
      );
      const selectedFolders = newSelected.folders.map((a) => a.id);
      const selectedFiles = newSelected.files.map((a) => a.id);
      const selectDocuments = newSelected.document_id_list.map(
        (a) => a.document_id
      );
      const moveOrCopyList = {
        document_id_list: selectDocuments,
        drive_file_id_list: selectedFiles,
        drive_folder_id_list: selectedFolders,
      };
      // console.log(selectedFiles, moveOrCopyList, dropTargetId);

      moveItem(
        moveOrCopyList,
        dropTargetId === "drive" ? null : dropTargetId
      ).then((r) => {
        if (!r) return;
        driveFoldersDetail?.data[!folderId ? "root" : folderId]?.set((v) => {
          return v?.filter(
            (a) =>
              !selectedFolders.includes(a.id) &&
              !selectedFiles.includes(a.id) &&
              !selectDocuments.includes(a.document_id)
          );
        });
        const tree = treeData.get({ stealth: true, noproxy: true });
        if (searchObjectFolder.isSearching.get({ stealth: true })) {
          searchObjectFolder.offset_document_number.set(0);
          searchObjectFolder.offset_folder_number.set(0);
          searchObjectFolder.offset_file_number.set(0);
          searchObjectFolder.isStop.set(0);
          folderDetailSearch.offset_folder_number.set((v) => 0);
          folderDetailSearch.offset_file_number.set((v) => 0);
          searchContentFolderUtils(
            undefined,
            searchObjectFolder,
            folderId,
            scrollLoadingState,
            driveDetailLoading,
            breabCrumbState,
            false
          );
        }
        // if (treeIdx > -1) {
        // }
        if (dropTarget.type === 6) {
          getCompanyFolders({
            drive_folder_id: dropTargetId,
            page_number: 1,
            page_size: 100,
            offset_folder_number: 0,
            search: "",
            order_by: "name asc",
          }).then((r) => {
            functionInitStateAfterMove(
              r,
              selectedFolders,
              dropTargetId,
              selected
            );
          });
        } else {
          getFoldersTree({
            page_number: 1,
            page_size: 50,
            parent_id: dropTargetId === "drive" ? "null" : dropTargetId,
          }).then((r) => {
            functionInitStateAfterMove(
              r,
              selectedFolders,
              dropTargetId,
              selected
            );
          });
        }
      });
    } else {
      // console.log(currentSelectedNodes.get({stealth:true}))
      if (
        currentSelectedNodes
          .get({ stealth: true, noproxy: true })
          .some((a) => a.size > 0 && a.extension === "folder")
      ) {
        openDeleteConfirm.message.set(
          t(
            "common:drive.delete.warning.have_file",
            "このフォルダの中にはファイルまたはフォルダが含まれています。本当に削除しますか？"
          )
        );
      } else {
        openDeleteConfirm.message.set(t("common:drive.delete_confirm.message"));
      }
      openDeleteConfirm.open.set(true);
    }
  };
  function onConfirmDialogClose(e, reason, isOk) {
    if (isOk) {
      openDeleteConfirm.get({ noproxy: true, stealth: true }).callback();
    } else {
      openDeleteConfirm.open.set(false);
    }
    dragTargetState.set([]);
  }

  function dropDelete() {
    const selectedMap = currentSelectedNodes
      .get({ stealth: true, noproxy: true })
      .map((a) => a.id);
    if (draggedItems.enable.get({ stealth: true })) {
      openDeleteConfirm.loading.set(true);
      moveToTrash({
        drive_file_id_list: draggedItems.files.get({
          stealth: true,
          noproxy: true,
        }),
        drive_folder_id_list: draggedItems.folders.get({
          stealth: true,
          noproxy: true,
        }),
        document_id_list: draggedItems.document_id_list.get({
          stealth: true,
          noproxy: true,
        }),
      })
        .then((r) => {
          if (draggedItems.folders.get({ stealth: true }).length > 0) {
            // const tree = treeData.get({ stealth: true, noproxy: true });
            // const firstParent = treeData
            //   .get({ stealth: true, noproxy: true })
            //   .filt((a) => a.parent_id == folderId);
            // if (treeIdx > -1) {
            // }
            // treeData.forEach((a) => {
            //   if (tree.find((b) => b.parent_id == a.get({ steath: true }).id)) {
            //     a.set((c) => {
            //       return {
            //         ...c,
            //         notShowDropDownIcon: 0,
            //       };
            //     });
            //   } else {
            //     a.set((c) => {
            //       return {
            //         ...c,
            //         notShowDropDownIcon: 1,
            //       };
            //     });
            //   }
            // });
          }
          getFoldersTree({
            page_number: 1,
            page_size: 100,
            parent_id: folderId || "null",
          }).then((r) => {
            if (r.data.payload)
              driveFoldersNav.set((v) => {
                const newData = [
                  ...v,
                  r.data.payload.map(mappedDataToTreeView),
                ].reduce((arr, item) => {
                  if (!arr.find((b) => b.id == item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
                // console.log(newData?.filter((a) => a.parent_id == folderId));
                if (
                  newData?.filter((a) => a.parent_id == folderId)?.length === 0
                ) {
                  const idx = newData.findIndex((b) => b.id == folderId);
                  if (idx > -1) newData[idx].notShowDropDownIcon = 1;
                }
                return newData.sort((a, b) => {
                  if (a.type === 7) {
                    return -1;
                  } else if (![1, 2, 3, 4, 5].includes(a.type)) {
                    return 0;
                  } else {
                    return a.type - b.type;
                  }
                });
              });
          });

          driveFoldersDetail.data[
            currentFolderId.get({ stealth: true })
              ? currentFolderId.get({ stealth: true })
              : "root"
          ].set((v) => {
            return (
              v?.filter(
                (a) =>
                  !draggedItems.files
                    .get({
                      stealth: true,
                      noproxy: true,
                    })
                    .includes(a.id) &&
                  !draggedItems.folders
                    .get({
                      stealth: true,
                      noproxy: true,
                    })
                    .includes(a.id) &&
                  !draggedItems.document_id_list
                    .get({ stealth: true, noproxy: true })
                    .includes(a.document_id)
              ) || []
            );
          });
          openDeleteConfirm.open.set(false);

          treeData.set((v) => {
            return v.filter((a) => {
              if (
                a.id == currentFolderId.get({ stealth: true }) &&
                driveFoldersDetail.data[
                  currentFolderId.get({ stealth: true })
                    ? currentFolderId.get({ stealth: true })
                    : "root"
                ].get({ stealth: true, noproxy: true }).length === 0
              ) {
                a = { ...a, is_empty: 1, notShowDropDownIcon: 1 };
              }
              return !selectedMap.includes(a.id);
            });
          });
          draggedItems.files.set([]);
          draggedItems.folders.set([]);
          draggedItems.document_id_list.set([]);
          draggedItems.enable.set(false);
        })
        .catch((e) => {
          notificationAlertState.set({
            open: true,
            type: "error",
            message: `${t("common:drive.alert.move_to_trash.error")}`,
          });
        })
        .finally(() => openDeleteConfirm.loading.set(false));
      return;
    }
    openDeleteConfirm.loading.set(true);
    moveToTrash({
      drive_file_id_list: [],
      drive_folder_id_list: selectedMap,
      document_id_list: [],
    })
      .then((r) => {
        openDeleteConfirm.open.set(false);

        driveFoldersDetail.data[
          currentFolderId.get({ stealth: true })
            ? currentFolderId.get({ stealth: true })
            : "root"
        ].set((v) => {
          const updatedData =
            v?.filter((a) => !selectedMap.includes(a.id)) || [];

          return updatedData;
        });
        treeData.set((v) => {
          const newData = v.filter((a) => {
            if (
              selectedMap.includes(a.id) &&
              driveFoldersDetail.data[a.id].get({
                stealth: true,
                noproxy: true,
              })?.length === 0
            ) {
              a = { ...a, is_empty: 1, notShowDropDownIcon: 1 };
            }
            return !selectedMap.includes(a.id);
          });
          if (newData?.filter((a) => a.parent_id == folderId)?.length === 0) {
            const idx = newData.findIndex((b) => b.id == folderId);
            if (idx > -1) newData[idx].notShowDropDownIcon = 1;
          }

          return newData;
        });
      })
      .catch((e) => {
        notificationAlertState.set({
          open: true,
          type: "error",
          message: `${t("common:drive.alert.move_to_trash.error")}`,
        });
      })
      .finally(() => openDeleteConfirm.loading.set(false));
  }
  useEffect(() => {
    currentFolderId.set(folderId);
  }, [folderId]);
  // console.log(treeData.get())
  return (
    <>
      <DriveConfirmDialog
        {...openDeleteConfirm.get({ noproxy: true })}
        onClose={onConfirmDialogClose}
      />
      {!folderId ? null : (
        <DragDropFile
          onDragEnter={() => {}}
          onDragLeave={() => {}}
          onDragOver={() => {}}
          onDrop={() => {}}
        />
      )}
      <div className={"app_drive_node"}>
        <Tree
          extraAcceptTypes={[NativeTypes.TEXT, NativeTypes.FILE]}
          ref={ref}
          rootId={0}
          insertDroppableFirst={true}
          initialOpen={["drive", "delivery"]}
          tree={treeData.get({ noproxy: true })}
          classes={{
            root: "treeRoot",
            draggingSource: "draggingSource",
            dropTarget: "dropTarget",
          }}
          onDrop={handleDrop}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          canDrag={(node, options) => {
            // node = selectedNodes.get({stealth:true})[0];
            // console.log(node, selectedNodes.get({stealth:true}));
            switch (node.type) {
              case 5:
              case 1:
              case 2:
              case 3:
              case 4:
                return false;
              default:
                break;
            }
            return !!node.draggable;
          }}
          canDrop={(tree, options) => {
            const searchNodes = selectedNodes.get({ stealth: true });
            // console.log(options?.dropTarget)
            if (
              options?.monitor?.getItem()?.dataTransfer &&
              options?.dropTarget?.droppable
            ) {
              return true;
            }
            if (
              searchNodes.some(
                (selectedNode) => selectedNode.id === options.dropTargetId
              )
            ) {
              return false;
            }
            if (options.dropTargetId === "complete") {
              return false;
            }
            const findTree = tree.find((a) => a.id === options.dropTargetId);
            return findTree?.droppable || findTree?.fileType === "trash";
          }}
          dragPreviewRender={(monitorProps) => {
            // console.log(monitorProps);
            if (selectedNodes.get().length > 1) {
              return (
                <DriveDragPreviewMultiple
                  dragSources={selectedNodes.get({
                    noproxy: true,
                    stealth: true,
                  })}
                />
              );
            }

            return (
              <DriveDragPreview
                ref={monitorProps.item.ref}
                monitorProps={monitorProps}
              />
            );
          }}
          render={(node, options) => {
            const selected = selectedNodes
              .get()
              .some((selectedNode) => selectedNode.id === node.id);
            // console.log(node, options);
            return (
              <DriveNavNode
                node={node}
                {...options}
                loadingTreeIndex={loadingTreeIndex.get()}
                onToggle={(e, id, isOpen, type) => {
                  // console.log(id)
                  if (id === "trash") return;
                  if (!isOpen && id !== "drive" && type !== 6 && type !== 9) {
                    if (typeof id === "string" && id.includes("delivery")) {
                      return options.onToggle(e);
                    }
                    loadingTreeIndex.set(id);
                    getFoldersTree({
                      page_number: 1,
                      page_size: 50,
                      parent_id: id,
                    })
                      .then((r) => {
                        if (r?.data?.payload?.length > 0) {
                          treeData.set((v) => {
                            const newData = [
                              ...v,
                              ...r.data.payload.map(mappedDataToTreeView),
                            ].reduce((arr, item) => {
                              if (item.id == id) {
                                item["is_empty"] = false;
                              }
                              if (!arr.find((b) => b.id == item.id)) {
                                arr.push(item);
                              }
                              return arr;
                            }, []);
                            return newData.sort((a, b) => {
                              if (a.type === 7) {
                                return -1;
                              } else if (![1, 2, 3, 4, 5].includes(a.type)) {
                                return 0;
                              } else {
                                return a.type - b.type;
                              }
                            });
                          });
                        }
                      })
                      .finally(() => {
                        if (
                          loadingTreeIndex.get({
                            stealth: true,
                            noproxy: true,
                          }) > -1
                        ) {
                          loadingTreeIndex.set(-1);
                        }
                      });
                  }
                  // console.log(type);
                  if (!isOpen && (type === 6 || type === 9)) {
                    if (typeof id === "string" && id.includes("delivery")) {
                      return options.onToggle(e);
                    }
                    loadingTreeIndex.set(id);
                    getCompanyFolders({
                      drive_folder_id: id,
                      page_number: 1,
                      page_size: 100,
                      offset_folder_number: 0,
                      search: "",
                    })
                      .then((r) => {
                        if (r?.data?.payload?.length > 0) {
                          treeData.set((v) => {
                            const newData = [
                              ...v,
                              ...r.data.payload.map(mappedDataToTreeView),
                            ].reduce((arr, item) => {
                              // if (item.id == id) {
                              //   item["is_empty"] = false;
                              // }
                              if (!arr.find((b) => b.id == item.id)) {
                                arr.push(item);
                              }
                              return arr;
                            }, []);
                            return newData.sort((a, b) => a.type - b.type);
                          });
                        }
                      })
                      .finally(() => {
                        if (
                          loadingTreeIndex.get({
                            stealth: true,
                            noproxy: true,
                          }) > -1
                        ) {
                          loadingTreeIndex.set(-1);
                        }
                      });
                  }
                  return options.onToggle(e);
                }}
                isSelected={selected}
                isDragging={selected && isDragging.get()}
                onClick={handleClick}
              />
            );
          }}
        />
      </div>
    </>
  );
}
