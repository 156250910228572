// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal } from "react-bootstrap";
import { readString } from "react-papaparse";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import Service from "../../Service";
import Loading from "../Loading";

export default class BatchSenderCsv {
  static get key() {
    return "batch-sender-csv";
  }
  static get name() {
    return "BatchSenderCsv";
  }
  static get code() {
    return BatchSenderCsv.name.toLowerCase();
  }

  constructor(objMain) {
    this.objMain = objMain;
    this.key = BatchSenderCsv.key;
    this.name = BatchSenderCsv.name;
    this.code = BatchSenderCsv.code;

    this.formData = {
      param_info: "param-info",
    };

    this.objMain.setPageObject(this);
    this.headerLabelObject = {
      document_type: "書類の種類",
      // "preservation_require": "保存要件",
      doc_title: "書類タイトル",
      // "received_date": "取引年月日",
      // "counter_party_name": "相手先名",
      amount: "金額",
      currency: "通貨",
      // "invoice_number": "インボイス制度登録番号",
      product: "商品名",
      reference: "お客様書類ID",
      shared_folders_path: "フォルダへの保存先",
      doc_attributes: "摘要",
      guests: "ゲスト",
      pdf_name: "PDF名",
    };

    this.headerLabel = [
      "書類の種類",
      // "保存要件",
      "書類タイトル",
      // "取引年月日",
      // "相手先名",
      "金額",
      "通貨",
      // "インボイス制度登録番号",
      "商品名",
      "お客様書類ID",
      "フォルダへの保存先",
      "摘要",
      "ゲスト",
      "PDF名",
    ];
  }

  isJPDate(str) {
    const regex = /^(\d{4})年(\d{1,2})月(\d{1,2})日$/;
    return regex.test(str);
  }

  convertJPStringToDate(str) {
    const parts = str.split(/年|月|日/);
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2]);
    const date = new Date(year, month, day);
    return date;
  }

  registerFiles = (file) => {
    const { PageData } = this.objMain.state;
    let { t } = this.props;

    const isNumber = (s) => {
      return /^\d+(\.\d+)?$/.test(s);
    };
    const convertPresevationRequire = (value, convertToId = true) => {
      if (convertToId) {
        if (isNumber(value)) {
          return value;
        }
        const index = PageData.preservation_require.findIndex(
          (item) => item.ja_label === value
        );
        if (index >= 0) {
          return PageData.preservation_require[index].id;
        } else {
          return "";
        }
      } else {
        if (isNumber(value)) {
          const index = PageData.preservation_require.findIndex(
            (item) => item.id === value
          );
          if (index >= 0) {
            return PageData.preservation_require[index].ja_label;
          } else {
            return "";
          }
        } else {
          return value;
        }
      }
    };
    const validateDocumentType = (value) => {
      const index = PageData.doc_types.findIndex(
        (item) => item.ja_label === value
      );
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    };

    const convertDocumentType = (value, convertToId = true) => {
      if (convertToId) {
        if (isNumber(value)) {
          return value;
        }
        const index = PageData.doc_types.findIndex(
          (item) => item.ja_label === value
        );
        if (index >= 0) {
          return PageData.doc_types[index].id;
        } else {
          return "";
        }
      } else {
        if (isNumber(value)) {
          const index = PageData.doc_types.findIndex(
            (item) => item.id === value
          );
          if (index >= 0) {
            return PageData.doc_types[index].ja_label;
          } else {
            return "";
          }
        } else {
          return value;
        }
      }
    };
    const parseDocumentAttributes = (str) => {
      try {
        const result = [];
        let modifyString = str ? str.replace(/\\/g, "").replace(/'/g, '"') : '{}';
        let obj = JSON.parse(modifyString);
        Object.keys(obj).forEach((key) => {
          result.push({
            key: key,
            value: obj[key],
          });
        });

        return result;
      } catch (e) {
        this.objMain.setState({
          showDuplicateAlert: true,
          modalTitle: `${t("sender.batch.invalid-row")}`,
          modalInvalidRows: [`${t("sender.batch.row")}: ${ this.headerLabelObject.doc_attributes}`],
          modalConfirm: () => {
            this.objMain.setState({
              showDuplicateAlert: false,
              modalTitle: "",
              modalInvalidRows: [],
            });
          },
        });
        console.log("parseDocumentAttributes error", e);
        return [];
      }
    };

    const validateAndConvertDate = (input) => {
      const possibleFormats = [
        "YYYY/MM/DD",
        "YYYY/M/DD",
        "YYYY/MM/D",
        "YYYY/M/D",
        "YYYY.MM.DD",
        "YYYY.M.DD",
        "YYYY.MM.D",
        "YYYY.M.D",
        "YYYY-MM-DD",
        "YYYY-M-DD",
        "YYYY-MM-D",
        "YYYY-M-D",
        "YYYYMMDD",
        "YYYY年MM月DD日",
        "DD/MM/YYYY",
        "MM/DD/YYYY",
      ];
      const dateString = String(input)?.trim();
      let formattedDate = "";

      if (dateString) {
        possibleFormats.forEach((format) => {
          if (moment(dateString, format, true).isValid()) {
            formattedDate = moment(dateString).format("YYYY-MM-DD");
          } else {
            if (this.isJPDate(dateString)) {
              formattedDate = moment(
                this.convertJPStringToDate(dateString)
              ).format("YYYY-MM-DD");
            }
          }
        });
      }

      return formattedDate;
    };

    const processString = (input) => {
      return (input ?? "").toString().trim();
    };

    const processGuestString = (input, listGuestSearch) => {
      const trimmedInput = (input ?? "").toString().trim();
    
      if (trimmedInput?.length > 0) {
        const listGuestInput = trimmedInput.replace(/;/g, ",").split(",").map(id => id.trim());
        const listGuest = listGuestSearch.payload?.guests || [];
        
        const processedGuests = listGuestInput.map(guestId => {
          const guest = listGuest.find(g => String(g.id) === guestId);
          
          return guest ? `${guestId}: ${guest.full_name ?? guest.email}` : guestId;
        });
        
        return processedGuests.join(", ");
      }
    
      return trimmedInput;
    };

    const validateRequiredField = (row) => {
      const fields = [
        "document_type",
        "doc_title",
        "pdf_name",
      ];
      for (const field of fields) {
        if (!row[field]) {
          return field; // Return the name of the invalid field
        }

        if (
          row["doc_attributes"]?.length === 0 &&
          row["doc_attributes_text"]?.length > 0
        ) {
          console.log(
            "doc_attributes",
            row["doc_attributes"],
            row["doc_attributes_text"]
          );
          return "doc_attributes"; // Return the name of the invalid field
        }
      }

      return null; // Return null if all fields are valid
    };

    const validateGuestEmail = (guests, listGuestSearch) => {      
      if (guests?.length > 0) {
        const listGuestEmail = guests.replace(/;/g, ",").split(",");
        const listGuestEmailSearch = listGuestSearch.payload?.guests
          ? listGuestSearch.payload?.guests?.map((guest) => guest.email)
          : [];

        const listGuestIds = listGuestSearch.payload?.guests
          ? listGuestSearch.payload?.guests?.map((guest) => String(guest.id))
          : [];
        const emailPromises = listGuestEmail?.map((input) => {
          if (listGuestEmailSearch.includes(input) || listGuestIds.includes(input)) return 1;
          else return 0;
        });
        return emailPromises;
      } else {
        return [];
      }
    };

    if (file) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        readString(reader.result, {
          worker: true,
          header: true,
          dynamicTyping: false,
          skipEmptyLines: "greedy",
          newline: "\\n",
          complete: async (results) => {
            let csvData = results.data;

            console.log("csvData", csvData);

            if (csvData?.length === 0) {
              return;
            }
            this.objMain.setState({ loading: true });

            let listGuestInCsv = [];
            csvData?.map((item) => {
              Object.keys(item).forEach((key) => {
                if (key) item[key.trim()] = item[key];
              });
              const guests = item[this.headerLabel[8]]
              if (guests?.length > 0) {
                const listGuestEmail = guests.replace(/;/g, ",").split(",") || [];
                
                listGuestInCsv = [...listGuestInCsv, ...listGuestEmail]
              }
            })
            const listGuestSearch = await Service.ValidateWord2PdfData(
              "sender",
              [],
              [...new Set(listGuestInCsv)],
              []
            );

            const asyncCsvFormData = csvData?.map(async (item) => {
              Object.keys(item).forEach((key) => {
                if (key) item[key.trim()] = item[key];
              });

              return {
                document_type: convertDocumentType(item[this.headerLabel[0]]),
                document_type_validate: validateDocumentType(
                  item[this.headerLabel[0]]
                ),
                document_type_text: convertDocumentType(
                  item[this.headerLabel[0]],
                  false
                ),
                function_type: 2,
                // preservation_require: convertPresevationRequire(item[this.headerLabel[1]]),
                // preservation_require_text: convertPresevationRequire(item[this.headerLabel[1]], false),
                doc_title: processString(item[this.headerLabel[1]]),
                // received_date: validateAndConvertDate(item[this.headerLabel[3]]), // transaction date
                // counter_party_name: processString(item[this.headerLabel[4]]),
                amount: processString(item[this.headerLabel[2]]),
                currency: processString(item[this.headerLabel[3]]),
                // invoice_number: processString(item[this.headerLabel[7]]), // invoice system registration number
                product: processString(item[this.headerLabel[4]]), // product name
                reference: processString(item[this.headerLabel[5]] ?? ""), // customer document ID
                shared_folders_path: processString(item[this.headerLabel[6]]), // save location in folder
                // shared_folders_path: processString(item[this.headerLabel[6]]), // save location in folder
                doc_attributes: parseDocumentAttributes(
                  item[this.headerLabel[7]]
                ), // summary
                doc_attributes_text: processString(item[this.headerLabel[7]]), // summary
                guests: processString(item[this.headerLabel[8]]),
                guests_text: processGuestString(item[this.headerLabel[8]], listGuestSearch),
                validate_guests: validateGuestEmail(
                  item[this.headerLabel[8]], listGuestSearch
                ),
                pdf_name: processString(item[this.headerLabel[9]]),
                row_data_status: 0,
                display_imprint: true,
              };
            });
            const csvFormData = await Promise.all(asyncCsvFormData);
            this.objMain.setState({ loading: false });            

            const pdfNames = {};
            let hasDuplicate = false;
            csvFormData.forEach((element) => {
              if (pdfNames[element.pdf_name]) {
                hasDuplicate = true;
                return;
              }
              pdfNames[element.pdf_name] = true;
            });

            let invalidateRows = [];

            csvFormData.forEach((row, index) => {
              if(!row.currency) row.currency = 'JPY'
              const invalidField = validateRequiredField(row);
              if (invalidField) {
                invalidateRows.push({
                  row: index + 1,
                  invalidField: invalidField,
                });
              }
            });

            if (hasDuplicate) {
              this.objMain.setState({
                showDuplicateAlert: true,
                modalTitle: t("common:sender.batch.duplicate-pdf-name"),
                modalConfirm: () => {
                  this.objMain.setState({
                    showDuplicateAlert: false,
                    modalTitle: "",
                    modalInvalidRows: [],
                  });
                  window.location.href = "/sender";
                },
              });
              return;
            }

            if (invalidateRows?.length > 0) {
              this.objMain.setState({
                showDuplicateAlert: true,
                modalTitle: `${t("sender.batch.invalid-row")}`,
                modalInvalidRows: invalidateRows?.map(
                  (item) =>
                    `${t("sender.batch.row")} ${item.row}: ${
                      this.headerLabelObject[item.invalidField]
                    }`
                ),
                modalConfirm: () => {
                  this.objMain.setState({
                    showDuplicateAlert: false,
                    modalTitle: "",
                    modalInvalidRows: [],
                  });
                },
              });
              return;
            }
            this.objMain.updateState({ csvVerificationList: csvFormData }, () => {
              this.handleProcessFile()
            });
            
            this.formSubmitCallback(ev);
          },
        });
      };
      reader.onerror = () => {
        //(reader.error);
      };
      reader.readAsText(file);
    }
  };

  formSubmitCallback = (ev) => {
    let { pageState } = this.objMain.state;
    pageState[this.name].completed = true;
    this.objMain.updateState({ pageState });
    this.objMain.nextPageStep(ev, this.constructor);
  };

  ValidateRequiredField = (row) => {
		const { PDFFiles } =  this.objMain.state
		const fields = ['document_type', 'doc_title', 'pdf_name'];
		const checkGuest = row.guests?.length === 0 || row.validate_guests?.every(v => v === 1);
		const checkFile = Object.values(PDFFiles).some(
			(v) => 
				v?.originalName === row.pdf_name 
				|| v.originalName?.substring(0, v.originalName?.lastIndexOf(".")) === row.pdf_name
			);

		return !fields.some(field => !row[field]) && checkGuest && checkFile;
	}

  handleProcessFile = () => {
		const {csvVerificationList, PDFFiles} = this.objMain.state;
    console.log('PDFFiles', PDFFiles);
    
    if(Object.keys(PDFFiles || '{}').length > 0){
      Object.keys(PDFFiles).forEach((key) => {
        let pdfObject = PDFFiles[key];
        let csvIndex = csvVerificationList.findIndex((item) => {
          const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
          if(isAnyExtFile){
            return item.pdf_name === pdfObject.originalName
          }else{
            const fileNameWithoutExt = pdfObject.originalName.substring(0, pdfObject.originalName.lastIndexOf("."));
            return item.pdf_name === fileNameWithoutExt
          }
          
        })
        console.log(csvIndex, csvVerificationList[csvIndex]);
        
        if(csvIndex >= 0 && this.ValidateRequiredField(csvVerificationList[csvIndex])){
          console.log(333333);
          
          csvVerificationList[csvIndex] = {
            ...csvVerificationList[csvIndex],
            row_data_status: 1
          }
        }
        if(csvIndex < 0){
          delete PDFFiles[key];
        }
      })

      console.log('csvVerificationList', csvVerificationList);
      
  
      this.objMain.updateState({
        csvVerificationList,
        PDFFiles
      })
    }
	}

  handleDownloadVerificationTemplate = () => {
    let file_name = "sender_template.csv";
    let BOM = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM
    let csv = this.headerLabel.join(",");
    csv += "\n";
    csv +=
      "請求書,○○請求書,10000,JPY,aaaaa,000000000,test/,\"{'key1':'value1','key2':'value2'}\",guest@gmail.com,001.pdf";

    let blob = new Blob([BOM, csv], { type: "text/csv;charset=utf-8;" });
    let link = document.createElement("a");
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", file_name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  FileUploadPanel = () => {
    let { t } = this.props;
    let { DocFile } = this.objMain.state;

    return (
      <div className={`file-upload-panel${DocFile ? "" : " no-file"}`}>
        <div className={`drop-zone-panel`}>
          <label
            htmlFor="pdf-upload"
            className="dropbox-area file-dropbox"
            onDragOver={(ev) => {
              ev.preventDefault();
            }}
            onDrop={(ev) => {
              ev.preventDefault();
              const file = ev.dataTransfer.files[0];
              this.registerFiles(file);
            }}
            onDragLeave={(ev) => {
              ev.preventDefault();
            }}
          >
            <div className="drop-area-inner">
              <FontAwesomeIcon icon={faFileCsv} />
            </div>
            <input
              type="file"
              name="pdf-upload"
              id="pdf-upload"
              accept=".csv"
              style={{ display: "none" }}
              onChange={(ev) => {
                let file = ev.target.files[0];
                this.registerFiles(file);
              }}
            />
          </label>
          <label className="info">
            {t("common:verification.upload-CSV-file")}
          </label>
        </div>
      </div>
    );
  };

  render() {
    let { DocFile, loading } = this.objMain.state;
    let { t } = this.props;

    let { FileUploadPanel } = this;

    return (
      <>
        <div className="full-preview">
         <Modal
            show={this.objMain.state.showDuplicateAlert}
            onHide={() => {
              this.objMain.setState({
                showDuplicateAlert: false,
                modalTitle: "",
                modalInvalidRows: [],
              });
            }}
            centered
          >
            <Modal.Body>
              <p>{this.objMain.state.modalTitle}</p>
              {this.objMain.state.modalInvalidRows && 
              this.objMain.state.modalInvalidRows?.length > 0 &&
                this.objMain.state.modalInvalidRows?.map((item) => (
                  <p>{item}</p>
                ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                type="submit"
                onClick={() => this.objMain.state.modalConfirm()}
              >
                {t("common:general.close")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Form
            id={"form_" + this.name}
            onSubmit={(ev) => {
              this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback);
            }}
            noValidate
            className="full-form"
            style={{ height: "100%" }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#cfcfd8",
                color: "#1a3163",
                borderRadius: 100,
                display: "flex",
                position: "absolute",
                padding: "5px 40px",
                top: 20,
                right: 10,
                zIndex: 100,
              }}
              onClick={this.handleDownloadVerificationTemplate}
            >
              {t("common:verification.download-template")}
            </Button>

            <div className="form-body flex-column">
              <div className={`template-new${DocFile ? "" : " active"}`}>
                <FileUploadPanel />
              </div>
            </div>

            <div className="form-foot"></div>
          </Form>
        </div>
        {loading ? <Loading /> : ""}
      </>
    );
  }
}
