// @ts-nocheck
import React, {useCallback, useEffect, useRef} from 'react';
import {Col, Container, Form, InputGroup, Tab, Tabs,} from 'react-bootstrap';

import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {defaultPageSize, signerSearch} from "./index";
import {Autocomplete, CircularProgress, Typography} from "@mui/material";
import {Virtuoso} from "react-virtuoso";
import {hookstate, StateFragment, useHookstate} from "@hookstate/core";
import Service from "../../Service";
import { debounce, throttle } from '../../drive-feature/utils/hooks';
export const isInitState = hookstate(false);
const loadingHandleGetMore = hookstate(false);
export const valueSearchViewer = hookstate('');
const handleGetMoreData = (page = 1, search = '', loading) => {

	if (!signerSearch["host-user"].hasMore.get({noproxy: true, stealth: true})) {
		return;
	}
	if(loading) loading.set(true);

	Service.GetHostUser(
		"verification",
		page,
		signerSearch["host-user"].page_size.get({noproxy: true, stealth: true}),
		search
	).then((data) => {

		if (data.ok) {
			if (Object.keys(data.payload).length === defaultPageSize) {
				signerSearch["host-user"].hasMore.set(true);
			} else {
				signerSearch["host-user"].hasMore.set(false);
			}
			signerSearch["host-user"].data.set((prevState) => {
				return {
					...prevState,
					...data.payload
				}
			});
			signerSearch["host-user"].page_number.set(page);
			signerSearch["host-user"].search.set(search);
			isInitState.set(false);
		}
	}).catch((e) => {
		throw e;
	}).finally(()=> {
		if(loading) loading.set(false);
	})
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const virtuosoRef = useRef(null);

	const { children, ...other } = props;

	const itemData= [];

	(children).forEach(
		(item) => {
			itemData.push(item);
		}
	);

	return (
		<div ref={ref}>
			<Virtuoso
				ref={virtuosoRef}
				data={children.map(item => item[1])}
				style={{ height: "300px" }}
				endReached={() => {
					handleGetMoreData(
						signerSearch["host-user"].page_number.get({noproxy: true, stealth: true}) + 1,
						signerSearch["host-user"].search.get({noproxy: true, stealth: true}),
					);
				}}
				components={{
					Item: ({ children, ...props }) => (
						<div {...props} {...other} style={{ margin: 0, padding: 0 }}>
							{children}
						</div>
					),
					List: React.forwardRef(({ style, children }, listRef) => {
						return (
							<div ref={listRef} style={style} role="listbox">
								{children}
							</div>
						);
					})
				}}
				overscan={1000}
				itemContent={(index, data) => {
					const dataSet = itemData[index];
					return (
						<Typography
							component="li"
							{...dataSet[0]}
							noWrap
							id={data.user_id}
						>
							{data.full_name}
						</Typography>
					);
				}}
			/>
		</div>
	);
});

export default class ViewerSetting {

	static get key() {
		return "viewer-setting";
	}
	static get name() {
		return "ViewerSetting";
	}
	static get code() {
		return ViewerSetting.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = ViewerSetting.key;
		this.name = ViewerSetting.name;
		this.code = ViewerSetting.code;
		this.formData = {
			company_seal: "common:documents.host-setting.company-seal",
			verify_user: "common:documents.viewer-setting.verifier",
			viewers: "common:documents.viewer-setting.viewers",
		};
		this.initialValues = {
			verify_user: this.objMain.state.UserInfo.user_id,
			viewers: {},
		}

		this.objMain.setPageObject(this);
		if (this.objMain.state.creationType === "verification") {
			this.headerInfo = this.props.t("common:documents.viewer-setting.verification-header");
		}
	}

	formSubmitCallback = (ev) => {
		let { pageState, allHosts, creationType,PDFFiles } = this.objMain.state;

		pageState[this.name].completed = true;

		if (creationType === "verification") {
			// console.log(this.formData.verify_user);
			let user_id = parseInt(this.formData.verify_user);
			// let verifier = allHosts[user_id];
			let verifier = signerSearch["host-user"].get({noproxy: true})?.data?.[user_id];
			verifier.self_certificate = false;
			verifier.certificate_type = 0;
			this.formData.signers = {}
			this.formData.signers[user_id] = verifier;
			for (const key in PDFFiles) {
				if(PDFFiles[key]?.formData){
					PDFFiles[key].formData.signers = this.formData.signers;
					PDFFiles[key].formData.viewers = this.formData.viewers;
				}
			}

		}
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState: pageState });
		this.objMain.updateState({ PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	};

	HostUserItems = () => {
		let { t } = this.props;
		let { allHosts } = this.objMain.state;
		let OptionItems = [];
		OptionItems.push(<option key="empty" value="">{t(`common:documents.viewer-setting.verifier`)}</option>);
		for (let i in allHosts) {
			let user = allHosts[i];
			OptionItems.push(<option key={user.id} value={user.id}>{`${user.name}`}</option>);
		}
		return OptionItems;
	}

	onVerifierChange = (ev) => {
		// let { formData } = this;
		let { allHosts, formData } = this.objMain.state;
		// console.log(formData);
		// let availableUsers = null;
		// let keyMap = this.objMapping(user_type);
		// let availableUsers = this.state[keyMap.available];
		// onVerifierChange
		// console.log(ev.target.value);
		let user_id = parseInt(ev.target.value);
		let verifier = allHosts[user_id];
		verifier.self_certificate = false;
		verifier.certificate_type = 0;
		formData.signers = {}
		formData.signers[user_id] = verifier;
		this.objMain.updateState({ formData });
	}

	getDataSearch(search, reason, loading){
		if(reason === 'reset') return;
		signerSearch["host-user"].hasMore.set(true);
		handleGetMoreData(1, search, loadingHandleGetMore);
	}

	VerificationForm = () => {
		let { t } = this.props;
		let { FieldControl,onChangeHandler } = this.objMain;
		let { creationType, formData, fields } = this.objMain.state;
		const childs = this.HostUserItems();
		const loading = useHookstate(loadingHandleGetMore);
		const isInit = useHookstate(isInitState);
		// console.log(allHosts,formData);

		const searchValue = useHookstate("");

		const viewerSigner = useHookstate(signerSearch);

		const allHostObject = viewerSigner["host-user"].get({noproxy: true})?.data ?? {};
		// useEffect(()=>{
		// 	if(creationType === 'verification' && isInit.value){
		// 		handleGetMoreData(1, '', loadingHandleGetMore);
		// 		isInit.set(false);
		// 	}
		// }, [isInit]);
		let verifierArray = [];
		Object.keys(allHostObject).forEach((key) => {
			verifierArray.push(allHostObject[key]);
		})
		const handleInput = useCallback(throttle(this.getDataSearch, 1000, {leading: false}),[]);
		// useEffect(()=>{
		// 	console.log(
		// 		searchValue.value
		// 	)
		// }, [searchValue])

		let defaultVerification = localStorage.getItem('defaultVerification');

		if (defaultVerification && defaultVerification !== formData['verify_user']) {
			if (Object.keys(allHostObject).includes(defaultVerification)) {
				formData['verify_user'] = defaultVerification;
				this.objMain.setFormData(formData);
			}
		}

		if (creationType === "verification") {
			return (
				<Autocomplete
					id="virtualize-demo"
					getOptionLabel={(option) => {
						return (typeof option === "string" || typeof option === "number")
							? allHostObject[option]?.full_name ?? ""
							: option?.full_name ?? "";
					}}
					sx={{ width: 300 }}
					onChange={(e, value) => {
						if (value?.user_id) {
							localStorage.setItem('defaultVerification', JSON.stringify(value?.user_id));
						}
						formData['verify_user'] = value?.user_id || "";
						this.objMain.setFormData(formData);
					}}
					inputValue={searchValue.get()}
					disableClearable={true}
					onInputChange={(event, newInputValue, reason) => {
						if(newInputValue === searchValue.get({stealth:true})){
							return
						}
						// console.log(formData['verify_user'], newInputValue, newInputValue, searchValue.get({stealth:true}))
						searchValue.set(newInputValue);
						if(reason === 'reset') return;
						return handleInput(newInputValue, reason, loading);
					}}
					ListboxComponent={ListboxComponent}
					options={verifierArray}
					value={formData?.['verify_user']}
					isOptionEqualToValue={(option, value) => {
						return option.user_id === +(value)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t("common:documents.viewer-setting.verifier")}
							style={{
								marginBottom: 10,
							}}
              				className='verification-identifier'
							InputLabelProps={{ shrink: true }}
							onKeyDown={e => {
								if (e.code === 'Enter') {
									e.preventDefault();
									e.stopPropagation();
								}
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
								  <React.Fragment>
									{loading.get() ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								  </React.Fragment>
								),
						  	}}
						/>
					)}
					renderOption={(props, option, state) => {
						return [props, option]
					}}
				/>
			);
		} else {
			return null;
		}

	}

	render() {

		let { t } = this.props;
		let { searchAvailableViewerGroups, searchViewerText, searchViewerGroupsText, creationType } = this.objMain.state;
		let { AvailableUser, SelectedUsers, MultipleUserSelectionBox, SearchInputChange, SearchViewerGroupsInputChange } = this.objMain;
		let { VerificationForm } = this;
		if(Object.keys(this.formData.viewers).length === 0){
			if(this.objMain.state.creationType === "verification"){
				if(localStorage.getItem('prev_verification_viewer_list') !== 'undefined')
				this.formData.viewers = JSON.parse(localStorage.getItem('prev_verification_viewer_list') || '{}');
			}else{
				if(localStorage.getItem('prev_viewer_list') !== 'undefined')
				this.formData.viewers = JSON.parse(localStorage.getItem('prev_viewer_list') || '{}');
			}
		}
		let { viewers } = this.formData;
		let AvailableGroupProps = {
			users: signerSearch["group-viewer"].data.get({noproxy: true}),
			user_type: "group-viewer",
			group_id: "group",
			by_group: false,
		};

		let AvailableUserProps = {
			users: signerSearch["viewer"].data.get({noproxy: true}),
			user_type: "viewer",
			// is_revision: this.objMain.state?.formData?.is_revision
		};
		// console.log(this.formData, 'oksađokaó')
		const allHostObject = signerSearch["host-user"].get({noproxy: true})?.data ?? {};

		return (

			<Container fluid="md" className={this.key + "-container form-wrapper container-fluid"} >

				<Form
					id={"form_" + this.name}
					noValidate
					className="container-fluid full-form"
				>

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					<div className="form-body flex-column">

						<VerificationForm />

						<Form.Row className="flex-full">

							<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
								<Form.Label>{t("common:documents.viewer-setting.viewers-list")}</Form.Label>
								<SelectedUsers users={viewers} user_type="viewer"  />
							</Form.Group>

							{/* {this.objMain.state?.formData?.is_revision ? <></> : */}
							 <Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
								<Form.Label>{t("common:documents.host-setting.available-viewers-list")}</Form.Label>


								<Tabs defaultActiveKey="veiwer-by-user" transition={false} id="viewers-selection">

									<Tab eventKey="veiwer-by-user" title={t("common:documents.host-setting.viewer-by-user")}>
										<InputGroup className="form-control">
											<div className="search-input-container">
											<StateFragment state={valueSearchViewer}>{s=>{
												return <TextField
												className="search-input-field"
												placeholder={t('common:documents.general.text-input-placeholder')}
												onChange={(e) =>{
													s.set(e.target.value || '');
													return  SearchInputChange(AvailableUserProps, e)
												}}
												onKeyDown={(e) => {
													if (e.key === 'Enter'){
														e.preventDefault();
														e.stopPropagation();
													}
												}}
												value={s.get()}
												defaultValue={searchViewerText}
												sx={{
													"& fieldset": { border: 'none' },
												}}
												InputProps={{
													startAdornment: (
														<InputAdornment>
															<IconButton>
																<SearchIcon />
															</IconButton>
														</InputAdornment>
													),
													style: {
														height: 40,
														paddingLeft: 5,
														fontSize: 15,
													},
												}}
											/>
											}}</StateFragment>
											</div>
											<AvailableUser users={signerSearch["viewer"].data.get({noproxy: true})} user_type="viewer" />
										</InputGroup >
									</Tab>

									<Tab eventKey="viewer-by-group" title={t("common:documents.host-setting.viewer-by-group")}>
										<InputGroup className="form-control">
											<div className="search-input-container">
												<TextField
													className="search-input-field"
													placeholder={t('common:documents.general.text-input-placeholder')}
													onChange={(e) => SearchViewerGroupsInputChange(AvailableGroupProps, e)}
													onKeyDown={(e) => {
														if (e.key === 'Enter'){
															e.preventDefault();
															e.stopPropagation();
														}
													}}
													defaultValue={searchViewerGroupsText}
													sx={{
														"& fieldset": { border: 'none' },
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment>
																<IconButton>
																	<SearchIcon />
																</IconButton>
															</InputAdornment>
														),
														style: {
															height: 40,
															paddingLeft: 5,
															fontSize: 15,
														},
													}}
												/>
											</div>
											<AvailableUser {...AvailableGroupProps} />
										</InputGroup >
									</Tab>
								</Tabs>
							</Form.Group>
							{/* } */}

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									size='large'
									endIcon={<ChevronRightIcon />}
									onClick={(ev) => {
										if ((creationType === "verification") && (!this.formData.verify_user || !Object.keys(allHostObject).includes(this.formData.verify_user.toString()))) {
											let { modal } = this.objMain.state;

											modal.title = t("common:documents.viewer-setting.verifier");
											modal.body = t("common:documents.viewer-setting.verifier-required");
											modal.props.show = true;
											modal.props.size = "xl";
											modal.props.className = "layout modal-responsive";
											modal.props.centered = true;

											this.objMain.updateState({ modal });
											return;
										}

										this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback)
									}}
								>
									<span className="btn-label">{t('common:documents.general.next')}</span>
								</Button>
							</div>
						</div>

					</div>

				</Form>

			</Container>

		);

	}

}
