import React, {useEffect, useRef} from "react";

export default function GetStatusInterval(props) {
    const { onGetStatus, timeout } = props;

    const isRunning = useRef(false);
    const intervalRef = useRef(null);

      const checkStatus = async () => {
        if (isRunning.current) return; // Skip if previous call is still running

        try {
          isRunning.current = true;
          await onGetStatus();
        } catch (error) {
          console.error('Status check failed:', error);
        } finally {
          isRunning.current = false;
        }
      };

      useEffect(() => {
        // Start the interval
        intervalRef.current = setInterval(checkStatus, timeout);

        // Cleanup interval on component unmount
        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        };
      }, [timeout]); // Recreate interval if timeOut changes

      return <></>;
}

GetStatusInterval.defaultProps = {};
