// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { Tree } from "@minoru/react-dnd-treeview";
import { useEffect, useRef } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { getCompanyFolders } from "../../data-access/company-folders/company-folders.api";
import {
  currentSelectedCompanyFolderState,
  driveNavCompanyTree,
} from "../../data-access/company-folders/company-folders.state";
import {
  breabCrumbState,
  currentFolderTypeState,
  driveDetailLoading,
  driveFoldersDetail,
  driveFoldersNav,
  expandNodeState,
  folderDetailSearch,
  searchObjectFolder,
  selectedRowsNav,
} from "../../data-access/state";
import { findAncestors } from "../../utils/find-parent";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import DragDropFile from "../drag-upload/drag-upload";
import {
  DriveDragPreview,
  DriveDragPreviewMultiple,
} from "./drive-drag-preview";
import DriveNavNode from "./drive-nav-node";

export default function DriveNavCompany() {
  const { t } = useTranslation("common");
  // const defaultFolder = useHookstate(false);
  const currentFolderId = useHookstate(currentSelectedCompanyFolderState);
  const folderId = currentFolderId.get();
  const loadingTreeIndex = useHookstate(-1);
  // const location = useLocation();
  // const [treeData, setTreeData] = useState(flattenTree(data));
  const treeData = useHookstate(driveNavCompanyTree);
  const isDragging = useHookstate(false);
  const isCtrlPressing = useHookstate(false);

  const selectedNodes = useHookstate(selectedRowsNav);
  const ref = useRef(null);

  // const selectedNodes = useHookstate([]);
  const expandedNodes = useHookstate(
    // flattenDataMock.map(a => findAncestors(a, flattenDataMock))
    expandNodeState
    // findAncestryById(dataDriveTreeDefault, folderId, "id").map((a) => a.id)
  );
  useEffect(() => {
    if (treeData.value.length > 0) {
      if (!treeData.get({ stealth: true })?.find((a) => a.id == folderId)) {
        expandedNodes.set(["company", "delivery"]);
      } else {
        expandedNodes.set([
          ...findAncestors(
            currentFolderId.value
              ? isNaN(+currentFolderId.value)
                ? currentFolderId.value
                : +currentFolderId.value
              : "company",
            treeData.value
          ),
          currentFolderId.value
            ? isNaN(+currentFolderId.value)
              ? currentFolderId.value
              : +currentFolderId.value
            : "company",
        ]);
      }
    }
  }, [treeData, currentFolderId]);

  useEffect(() => {
    if (expandedNodes && ref.current) {
      ref.current?.open([
        isNaN(+folderId) ? folderId : +folderId,
        ...expandedNodes.value,
      ]);
    }
    if (expandedNodes.length === 0) {
      ref.current?.open(["company"]);
    }
  }, [expandedNodes, ref.current]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e?.key?.toLowerCase() === "escape") {
        selectedNodes.set([]);
      } else if (e.ctrlKey || e.metaKey) {
        isCtrlPressing.set(true);
      }
    };

    const handleKeyUp = (e) => {
      if (
        e?.key?.toLowerCase() === "control" ||
        e?.key?.toLowerCase() === "meta"
      ) {
        isCtrlPressing.set(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleSingleSelect = (node) => {
    selectedNodes.set([node]);
  };

  const folderDetailList = useHookstate(driveFoldersDetail);

  const detailSearch = useHookstate(folderDetailSearch);

  const driveDetailLoad = useHookstate(driveDetailLoading);

  const handleClick = (e, node) => {
    driveDetailLoad.set(true);
    breabCrumbState.set([]);
    // console.log(breabCrumbState);
    searchObjectFolder.isSearching.set(0);
    searchObjectFolder.offset_document_number.set(0);
    searchObjectFolder.offset_folder_number.set(0);
    searchObjectFolder.offset_file_number.set(0);
    searchObjectFolder.isStop.set(0);
    searchObjectFolder.search.set("");
    detailSearch.offset_folder_number.set((v) => 0);
    detailSearch.offset_file_number.set((v) => 0);
    detailSearch.search.set("");
    detailSearch.page_number.set(1);
    currentFolderTypeState.set(node.type || 6);
    if (node.id === "company") {
      // currentSelectedCompanyFolderState.set(0);
      const defaultId = driveFoldersNav
        .get({ stealth: true })
        .find((a) => a.type === 6)?.id;
      currentSelectedCompanyFolderState.set(defaultId || 0);
      //   history.push(`/drive`);
      breabCrumbState.set((v) => {
        // const dataBreab =
        return [
          {
            ...node,
          },
        ].map((b) => ({ ...b, isCompany: true }));
      });
      getCompanyFolders({
        drive_folder_id: defaultId,
        non_files: 0,
        order_by: "name asc, updated_date desc, size asc, memo asc",
        page_size: 50,
				offset_folder_number: 0,
        type: null,
        page_number: 1,
        search: "",
      })
        .then((r) => {
          driveNavCompanyTree.set((v) => {
            const newData = [
              ...v,
              ...r.data.payload.map((a) => mappedDataToTreeView(a, true)),
              ...[
                {
                  ...node,
                  notShow: true,
                },
              ].map((a) => mappedDataToTreeView(a, true)),
            ].reduce((arr, item) => {
              if (!arr.find((b) => b.id == item.id)) {
                arr.push(item);
              }
              return arr;
            }, []);
            return newData;
          });
          folderDetailList?.data?.merge({
            ["company" ? "company" : "root"]: r?.data?.payload
              ?.filter((a) => !a.document_id)
              .map(mappedDataToTreeView),
          });
        })
        .finally(() => driveDetailLoad.set(false));
    } else {
      driveDetailLoad.set(true);
      currentSelectedCompanyFolderState.set(node.id);
      // console.log(
      //   findAncestors(
      //     node.id ? (isNaN(+node.id) ? node.id : +node.id) : "company",
      //     driveNavCompanyTree.get({ stealth: true, noproxy: true })
      //   )
      // );
      breabCrumbState.set((v) => {
        // const dataBreab =
        return [
          ...driveNavCompanyTree
            .get({ stealth: true })
            .filter((a) =>
              findAncestors(
                node.id ? (isNaN(+node.id) ? node.id : +node.id) : "company",
                driveNavCompanyTree.get({ stealth: true, noproxy: true })
              ).includes(a.id)
            ),
        ].map((b) => ({ ...b, isCompany: true }));
      });
      getCompanyFolders({
        drive_folder_id: node.id,
        non_files: 0,
        order_by: "name asc, updated_date desc, size asc, memo asc",
        page_size: 50,
				offset_folder_number: 0,
        type: null,
        page_number: 1,
        search: "",
        // ...detailSearch.get({ stealth: true, noproxy: true }),
      })
        .then((r) => {
          driveNavCompanyTree.set((v) => {
            const newData = [
              ...v,
              ...r.data.payload.map((a) => mappedDataToTreeView(a)),
              ...[
                {
                  ...node,
                  notShow: true,
                },
              ].map((a) => mappedDataToTreeView(a, true)),
            ].reduce((arr, item) => {
              if (!arr.find((b) => b.id == item.id)) {
                arr.push(item);
              }
              return arr;
            }, []);
            return newData;
          });
          folderDetailList?.data?.merge({
            ["company" ? "company" : "root"]: r?.data?.payload
              ?.filter((a) => !a.document_id)
              .map(mappedDataToTreeView),
          });
          breabCrumbState.set((v) => {
            // const dataBreab =
            return [
              driveNavCompanyTree
                .get({ stealth: true })
                .find((a) => a.parent === 0),
              ...v.filter((a) => a.parent !== 0),
              {
                ...node,
              },
            ].map((b) => ({ ...b, isCompany: true }));
          });
        })
        .finally(() => driveDetailLoad.set(false));
    }
    handleSingleSelect(node);
  };

  // console.log(treeData.get())
  return (
    <>
      <DragDropFile
        onDragEnter={() => {}}
        onDragLeave={() => {}}
        onDragOver={() => {}}
        onDrop={() => {}}
      />
      <div className={"app_drive_node"}>
        <Tree
          extraAcceptTypes={[NativeTypes.TEXT]}
          ref={ref}
          rootId={0}
          tree={treeData.get({ noproxy: true })}
          classes={{
            root: "treeRoot",
            draggingSource: "draggingSource",
            dropTarget: "dropTarget",
          }}
          canDrag={(node, options) => {
            switch (node.type) {
              case 5:
              case 1:
              case 2:
              case 3:
              case 4:
                return false;
              default:
                break;
            }
            return node.draggable;
          }}
          canDrop={(tree, options) => {
            const searchNodes = selectedNodes.get({ stealth: true });
            if (
              searchNodes.some(
                (selectedNode) => selectedNode.id === options.dropTargetId
              )
            ) {
              return false;
            }
            if (options.dropTargetId === "complete") {
              return false;
            }
            const findTree = tree.find((a) => a.id === options.dropTargetId);
            return findTree?.droppable || findTree?.fileType === "trash";
          }}
          dragPreviewRender={(monitorProps) => {
            // console.log(monitorProps);
            if (selectedNodes.get().length > 1) {
              return (
                <DriveDragPreviewMultiple
                  dragSources={selectedNodes.get({
                    noproxy: true,
                    stealth: true,
                  })}
                />
              );
            }

            return (
              <>
                <DriveDragPreview monitorProps={monitorProps} />
              </>
            );
          }}
          render={(node, options) => {
            const selected = selectedNodes
              .get()
              .some((selectedNode) => selectedNode.id === node.id);
            if (node.notShow) return <></>;
            return (
              <DriveNavNode
                node={node}
                {...options}
                loadingTreeIndex={loadingTreeIndex.get()}
                onToggle={(e, id, isOpen) => {
                  // console.log(isOpen);
                  if (!isOpen) {
                    loadingTreeIndex.set(id);
                    getCompanyFolders({
                      page_number: 1,
                      page_size: 100,
                      drive_folder_id: id,
                      search: "",
                      offset_folder_number: 0,
                      order_by: "name asc",
                    })
                      .then((r) => {
                        if (r?.data?.payload?.length > 0) {
                          treeData.set((v) => {
                            const newData = [
                              ...v,
                              ...r.data.payload.map(mappedDataToTreeView),
                            ].reduce((arr, item) => {
                              if (item.id == id) {
                                item["is_empty"] = false;
                              }
                              if (!arr.find((b) => b.id == item.id)) {
                                arr.push(item);
                              }
                              return arr;
                            }, []);
                            return newData;
                          });
                        }
                      })
                      .finally(() => {
                        if (
                          loadingTreeIndex.get({
                            stealth: true,
                            noproxy: true,
                          }) > -1
                        ) {
                          loadingTreeIndex.set(-1);
                        }
                      });
                  }
                  return options.onToggle(e);
                }}
                isSelected={selected}
                isDragging={selected && isDragging.get()}
                onClick={handleClick}
              />
            );
          }}
        />
      </div>
    </>
  );
}
