// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import Main from "./Main";
import { withTranslation } from "react-i18next";
import { Box, Select, MenuItem } from '@mui/material'
import Service from '../../Service';

import {
	Nav,
	NavDropdown,
	Form,
	Table,
	// Col,
	// InputGroup,
} from 'react-bootstrap';

import {
	faCheckCircle,
	faExclamationCircle,
	faExclamationTriangle,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from "../Loading";
import LoadingList from '../LoadingList';
import Error from '../Error';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import moment from "moment";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BaseTooltipSearch from "../../Components/base-tooltip-search/base-tooltip-search";
import {shedOnScroll} from "../../data-access/app.state";
import {StateFragment} from "@hookstate/core";
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";
import {
	documentProcessState,
	DocumentProcessStatus
} from "../../Components/document-process-state/document-process-state";
import {documentMainList} from "../../Layout";
import {userInfoState} from "../../drive-feature/data-access/state";

class Evaluation extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Evaluation";
		this.state.code = "evaluation";
		this.state.function_type = 7;
		this.state.searchOption.function_type = 7;
		this.state.searchOption.status_id = "";
		this.state.evaluates = null;
		this.state.evaluateDocList = {};
		this.documentProcessId = window.crypto.randomUUID();
		this.state.fields = {};
		this.state.formData = {};
		this.state.keyFields = {
			function_type: "function-type",
			document_type: "document-type",
			active_flg: "validity",
			doc_title: "doc-title",
			doc_id: "doc-id",
			min_amount: "min-amount",
			max_amount: "max-amount",
			currency: "currency",
			display_imprint: "display-imprint",
			contract_date: "contract-date",
			expiry_date: "expiry-date",
			min_received_date: "min-deal-date",
			max_received_date: "max-deal-date",
			counter_party: "counter-party-name",
			// product: "product",
			// reference: "reference",
			status_id: "document-status",
			filebox_shortcut: "shortcut-to-filebox",
			doc_attributes: "attributes",
			function_type_list: "function-type-list",
		}
		// console.log(props);
		// JSON.parse(JSON.stringify(this.state.searchOption));
		this.state.docSearchOption = {};
		for (let key in this.state.searchOption) {
			this.state.docSearchOption[key] = this.state.searchOption[key];
		}
		this.state.docSearchOption.status_id = "";
		this.state.docSearchOption.function_type = "";
		this.state.docSearchOption.random = 25;

		this.state.documentSuggestion = [];
		this.state.doc_title_loading = false;
		this.state.doc_title_open = { open: false, anchorEl: null };
		this.state.counter_party_loading = false;
		this.state.counter_party_open = { open: false, anchorEl: null };

		// for (let i of QueryParams.keys()) {
		// console.log(this.state.searchOption);

		this.setStateFields();
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:documents.document-info.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			} else {
				formData[key] = fields[key].value;
			}
		}

		this.setState({ fields }, cb);
	}

	CurrencyItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { currencies } = PageData;
		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	RandomSizeItems = (props) => {
		let { t } = this.props;
		let { name } = props;
		let { docSearchOption } = this.state;
		let random_size = [25, 50, 0];

		let OptionItems = [];
		for (let size of random_size) {
			let label = t("common:documents.evaluate.random-size-label", { size });
			if (size === 0) {
				label = t("common:documents.evaluate.manual");
			}
			let radioProps = {
				"name": name,
				"onChange": (ev) => {
					let { docSearchOption, evaluateDocList } = this.state;
					console.log(ev.target.value);
					docSearchOption.random = parseInt(ev.target.value);
					if (docSearchOption.random !== 0) {
						evaluateDocList = {};
					}
					this.setState({ docSearchOption, evaluateDocList });
				},
			};
			// <Form.Check key={size} type="radio" id={`inline-radio-${size}`}
			// 	value={size} label={label} name={name} inline
			// 	onChange={(ev) => {
			// 		console.log(ev.target.value);
			// 		docSearchOption.random = parseInt(ev.target.value);
			// 		this.setState({ docSearchOption });
			// 	}}
			// 	checked={parseInt(docSearchOption.random) === parseInt(size)} />
			OptionItems.push(
				<FormControlLabel key={`random-size-${size}`} value={size} label={label} control={<Radio {...radioProps} />} />
			);
		}

		return (
			<RadioGroup row name="row-radio-buttons-group" aria-labelledby="demo-row-radio-buttons-group-label" value={docSearchOption.random}>
				{OptionItems}
			</RadioGroup>
		);
	}

	FunctionTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { function_types } = PageData;

		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:document.function-type.all")}</option>);
		for (let func of function_types.filter(a => [1,2,3,4].includes(a.id))) {
			OptionItems.push(<option key={func.id} value={func.id}>{t(`common:document.function-type.${func.code}`)}</option>);
		}

		return OptionItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData, formData } = this.state;
		let { doc_types } = PageData;

		let OptionItems = [], document_type_options = doc_types
		OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);

		if (parseInt(formData.function_type)) {
			switch (parseInt(formData.function_type)) {
				case 1:
					document_type_options = doc_types.filter(x => [1,3].includes(x.id));
					break;
				case 2:
					document_type_options = doc_types.filter(x => ![1,2,9].includes(x.id));
					break;
				case 3:
					document_type_options = doc_types.filter(x => [2,3].includes(x.id));
					break;
				default:
					break;
			}
		}

		for (let doc_type of document_type_options) {
			OptionItems.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		// for (let i in doc_types) {
		// 	let doc_type = doc_types[i];
		// 	OptionItems.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		// }

		return OptionItems;
	}

	StatusItems = () => {
		let { t } = this.props;
		let { docStatus, formData } = this.state;

		let OptionItems = [], docStatusOptions = []
		OptionItems.push(<option key={"empty"} value="">{t("common:document.status.completed")}</option>);

		docStatusOptions = [ 2, 8, 12, 10 ]
		if (parseInt(formData.function_type)) {
			OptionItems = []
			switch (parseInt(formData.function_type)) {
				case 1:
					docStatusOptions = [2]
					formData.status_id = 2
					break;
				case 2:
					docStatusOptions = [8]
					formData.status_id = 8
					break;
				case 3:
					docStatusOptions = [12]
					formData.status_id = 12
					break;
				case 4:
					docStatusOptions = [10]
					formData.status_id = 10
					break;
				default:
					break;
			}
		}

		for (let i of docStatusOptions) {
			let status = docStatus[`${i}`];
			OptionItems.push(<option key={status?.id} value={status?.id}>{t("common:" + status?.label)}</option>);
		}

		return OptionItems;
	}

	ValidityItems = () => {
		let { t } = this.props;

		let validityList = [
			{
				id: 1,
				label: "common:documents.document-info.validity-only-valid"
			},
			{
				id: 0,
				label: "common:documents.document-info.validity-only-invalid"
			}
		];

		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:documents.document-info.validity-all")}</option>);
		for (let item of validityList) {
			OptionItems.push(<option key={item.id} value={item.id}>{t(item.label)}</option>);
		}

		return OptionItems;
	}

	handleGetDocumentSuggestion = (ev, search_field) => {
		let { formData } = this.state;

		this.setState({
		  [`${search_field}_loading`]: true
		});

		Service.getEvaluateSearchDocumentTitle({
			...formData,
			search_field,
			search_keyword: formData[`${search_field}`]
		}).then((response) => {
			if (response.ok) {
				let suggestion = response.payload;

				if (suggestion.length > 0) {
					this.setState({
						documentSuggestion: suggestion
					});

				} else {
					this.setState({
						[`${search_field}_open`]: {
							open: false,
							anchorEl: null,
						}
					})
				}
			}
		}).catch((error) => {
		  	console.log(error.response);
		}).finally(() => {
			this.setState({
				[`${search_field}_loading`]: false
			});
		});

	}

	handleInputDebounce = (func, delay) => {
		let timeoutId;
		return function (...args) {
		  	clearTimeout(timeoutId);
		  	timeoutId = setTimeout(() => func.apply(this, args), delay);
		};
	  }

	handleFilterName = this.handleInputDebounce((ev, search_field) => {
		this.handleGetDocumentSuggestion(ev, search_field);
		this.setState({
			[`${search_field}_open`]: {
				open: true,
				anchorEl: ev.target,
			}
		})
	}, 1000);

	handleCloseTooltipSearch = (data, search_field) => {
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				[search_field]: data.value
			},
			documentSuggestion: [],
		}), () => {
		  this.setState({
			[`${search_field}_open`]: {
			  	open: false,
			  	anchorEl: null,
			}
		  })
		});
	}

	handleChangeTooltipSearch = (ev, search_field) => {
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				[search_field]: ev.target.value
			},
		}), () => {
			if (ev.target.value.length > 0) {
				this.handleFilterName(ev, search_field);
			}
		});
	}

	SearchForm = () => {
		let { PageData, documentSuggestion, doc_title_open, doc_title_loading, counter_party_open, counter_party_loading } = this.state;
		let { FieldControl, FunctionTypeItems, DocumentTypeItems, CurrencyItems, StatusItems, ValidityItems, handleChangeTooltipSearch, handleCloseTooltipSearch } = this;

		if (!PageData) {
			return <Loading />;
		}

		return (
			<div className="form-container"
				onClick={() => {
					this.setState({
						doc_title_open: { open: false, anchorEl: null },
						counter_party_open: { open: false, anchorEl: null },
						documentSuggestion: [],
					})
				}}
			>

				<Form.Row>
					<FieldControl name="function_type" as="select" xs={12} md={3}>
						<FunctionTypeItems />
					</FieldControl>
					<FieldControl name="document_type" as="select" xs={12} md={3}>
						<DocumentTypeItems />
					</FieldControl>
					<FieldControl name="active_flg" as="select" xs={12} md={3}>
						<ValidityItems />
					</FieldControl>
				</Form.Row>

				<Form.Row>
					{/* <FieldControl name="doc_title" change={SearchDocumentTitle} xs={12} md={6}/> */}
					<FieldControl xs={12} md={6}  name="doc_title"
						onChange={(ev)=> { handleChangeTooltipSearch(ev, "doc_title")}}
					/>
					<BaseTooltipSearch
						onClick={undefined}
						offsetTop={80}
						className={'search_tooltip_class'}
						open={doc_title_open.open}
						anchorEl={doc_title_open.anchorEl}
						handleClose={(e, data) => { handleCloseTooltipSearch(data, "doc_title") }}
						items={documentSuggestion}
						loading={doc_title_loading}
					/>

					<FieldControl name="doc_id"  xs={12} md={3} />
					<FieldControl name="status_id" as="select" xs={12} md={3}>
						<StatusItems />
					</FieldControl>
				</Form.Row>

				{/* <span>~</span> */}
				<Form.Row>
					<FieldControl name="min_amount" type="number" step="0.01" xs={6} md={5} />
					<FieldControl name="max_amount" type="number" step="0.01" xs={6} md={5} />
					<FieldControl name="currency" as="select" xs={12} md={2}>
						<CurrencyItems />
					</FieldControl>
				</Form.Row>

				{/* for verification */}
				<Form.Row>
					<FieldControl name="min_received_date" type="date" xs={6} md={4} />
					<FieldControl name="max_received_date" type="date" xs={6} md={4} />
				</Form.Row>

				<Form.Row>
					<FieldControl md={12} xs={12} name="counter_party" xs={12} md={12}
						onChange={(ev)=> { handleChangeTooltipSearch(ev, "counter_party")}}
					/>
					<BaseTooltipSearch
						onClick={undefined}
						offsetTop={80}
						className={'search_tooltip_class'}
						open={counter_party_open.open}
						anchorEl={counter_party_open.anchorEl}
						handleClose={(e, data) => { handleCloseTooltipSearch(data, "counter_party") }}
						items={documentSuggestion}
						loading={counter_party_loading}
					/>
				</Form.Row>

				{/* <Form.Row>
					<FieldControl name="product" xs={12} md={6} />
					<FieldControl name="reference" xs={12} md={6} />
				</Form.Row> */}

			</div>
		);
	}

	BasicInfoActionButtons = () => {
		let { t } = this.props;
		let { PageData } = this.state;

		let Buttons = [];
		if (PageData) {
			Buttons.push(
				<Button key="manual" type="submit" name="manual_submit" sx={{ ml: 1 }} variant="contained" onClick={this.SubmitButtonHandler}>
					<span>{t("common:documents.evaluate.manual-selection")}</span>
				</Button>
			);
			// Buttons.push(
			// 	<Button key="random" type="submit" name="random_submit" sx={{ ml: 1 }} variant="contained" onClick={this.SubmitButtonHandler}>
			// 		<span>{t("common:documents.evaluate.random-selection", { random_value: docSearchOption.random })}</span>
			// 	</Button>
			// );
		}

		return Buttons;
	}

	SubmitButtonHandler = (ev) => {
		ev.target.closest("form").submited = ev.target.closest("[type=submit]").getAttribute("name");
	}

	HiddenMenuBody = () => {
		let { t } = this.props;
		let { evaluateDocList, docSearchOption } = this.state;

		let selectedDocs = Object.keys(evaluateDocList);

		let DocItems = selectedDocs.map((key) => {
			let doc = evaluateDocList[key];

			return (
				<li key={key} className="document-selected-item">
					<div className="document-info" >
						<span>{`${doc.id}: ${doc.title}`}</span>
					</div>
					<div className="document-action" >
						<CancelOutlinedIcon
							className="btn-remove" onClick={(ev) => {
								let { evaluateDocList } = this.state;
								if (evaluateDocList[doc.id]) {
									delete evaluateDocList[doc.id];
								}
								if (Object.keys(evaluateDocList).length == 0) {
									docSearchOption.function_type = ""
									docSearchOption.status_id = ""
								}
								this.setState({ evaluateDocList });
							}} />
					</div>
				</li>
			);
		});

		return [(
			<div key="selected-header" className="document-selected-header">
				{t("common:documents.evaluate.selected-list")}
			</div>
		), (
			<ul key="selected-boby" className="document-selected-list">
				{DocItems}
			</ul>
		)];
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;
		let { formData, docSearchOption } = this.state;
		for (let i in formData) {
			docSearchOption[i] = formData[i];
		}

		console.log(form.submited);
		console.log(docSearchOption);
		// ev.stopPropagation();
		if (form.submited === "random_submit") {
			this.setState({ docSearchOption }, this.EvaluateSaveRandomConfirm);
		} else /* for manual selection */ {
			let { modal } = this.state;
			modal.onClose = null;
			this.setState({ modal }, () => {
				let { modal } = this.state;
				modal.props.show = false;
				this.setState({ modal, docSearchOption, showDetail: true, showHiddenMenu: true }, this.SearchDocumentListData);
			});
		}
	}

	SearchDialog = () => {
		let { t } = this.props;
		let { modal } = this.state;

		modal.props = {
			show: true,
			centered: true,
			size: "lg",
		};

		modal.title = t('common:documents.evaluated-filter');

		modal.form = {
			id: "user-info-form",
			onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
			noValidate: true,
		};

		modal.action = this.BasicInfoActionButtons;
		modal.body = this.SearchForm;
		modal.onClose = (ev) => {
			let { modal, formData, docSearchOption } = this.state;

			for (let key in docSearchOption) {
				formData[key] = docSearchOption[key];
			}

			modal.onClose = null;
			this.setState({ modal, formData });
		};


		this.setState({ modal, formValidate: false }, () => {
			if (!this.state.PageData) {
				// for fix status list not show in search dialog
				// Service.GetPageData("verification").then(resp => {
				Service.GetPageData("evaluation").then(resp => {
					resp.func_types_index = {};
					resp.func_types_index[0] = {
						"id": 0,
						"code": "all",
					};
					if (resp.function_types) {
						for (let func of resp.function_types) {
							resp.func_types_index[func.id] = func;
						}
					}
					this.setState({ PageData: resp });
				});
			}
		});
	}

	GetDocumentListData = (searchOption) => {
		searchOption = this.PageCalculation(searchOption);
		this.setState({ evaluates: null, searchOption }, () => {
			// console.log(searchOption);
			Service.getEvaluateHistory(searchOption).then(resp => {
				let { evaluates} = this.state;
				let { documents } = documentMainList.get({noproxy: true})
				evaluates = resp.payload;
				searchOption.total = resp.total;
				searchOption = this.PageCalculation(searchOption);
				// console.log(evaluates);
				if (documents === null){
					documents = []
				}

				if (documents) {
					documentMainList.documents.set(() => [...documents]);
				}

				this.setState({ evaluates, searchOption });
			}).catch(err => {
				//console.log(err.response);
			});
		});
	}

	GetEvaluationDetail = (evaluate_id) => {
		Service.getEvaluationDetail({ evaluate_id }).then(resp => {
			documentMainList.documents.set(() => [...resp.payload])
			userInfoState.set((v) => ({...v}));
			localStorage.setItem(
				"user-info",
				JSON.stringify(userInfoState.get({ noproxy: true }))
			);
			// this.setState({ documents: evaluateDetail });
		}).catch(err => {
			//console.log(err.response);
		});
	}

	SearchDocumentListData = (docSearchOption) => {
		let { function_type, formData } = this.state;
		if (!docSearchOption) {
			docSearchOption = this.state.docSearchOption;
		}
		docSearchOption = this.PageCalculation(docSearchOption);
		if (docSearchOption.function_type) {
			docSearchOption.function_type_list = "";
		} else {
			if (function_type === 7) {
				docSearchOption.function_type_list = "1,2,3,4";
			} else {
				docSearchOption.function_type_list = "1,2,4";
			}
		}

		for (let key in docSearchOption) {
			formData[key] = docSearchOption[key];
		}

		documentMainList.documents.set(() => null);

		this.setState({ docSearchOption, formData }, () => {
			Service.getEvaluationSearchList(docSearchOption).then(resp => {
				docSearchOption.total = resp.total;
				docSearchOption = this.PageCalculation(docSearchOption);
				documentMainList.documents.set(() => [...resp.payload]);
				// console.log(documents);
				this.setState({ docSearchOption });
			}).catch(err => {
				//console.log(err.response);
			});
		});
	}

	EvaluateSaveRandomConfirm = () => {
		let { t } = this.props;
		let { modal } = this.state;

		modal.props = {
			show: true,
			centered: true,
		};

		modal.form = {};
		modal.action = [];
		modal.title = t('common:documents.evaluated-confirm');
		modal.body = t('common:documents.evaluated-confirm-question');
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				let { modal } = this.state;
				modal.title = t('common:documents.evaluated-on-process');
				modal.body = Loading;
				modal.form = {};
				modal.action = [];
				this.setState({ modal }, this.EvaluateSaveRandom);
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		this.setState({ modal });
	}

	EvaluateSaveRandom = () => {
		let { t } = this.props;
		let { modal, docSearchOption } = this.state;

		Service.startEvaluateRandom(docSearchOption).then(resp => {
			let { searchOption } = this.state;

			console.log(resp);

			modal.props.show = false;
			documentMainList.documents.set(() => null);
			this.setState({ modal, showDetail: false, showHiddenMenu: false, documents: null }, () => {
				this.GetDocumentListData(searchOption);
			});
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				isEvaluate: true,
				result: resp,
				successTitle: t('common:documents.evaluated-on-success')
			}));

		}).catch(err => {
			console.log(err.response);

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}
			modal.title = t('common:documents.evaluated-error');
			modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.title = t('common:documents.evaluated-on-process');
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.EvaluateSaveRandom);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
				isSign: true,
				errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
			}))
			this.setState({ modal });
		});
	}

	onDocumentFunctionTypeChangeHandler = (ev) => {
		let selected = parseInt(ev.target.value) || null;

		let { docSearchOption } = this.state;
		if (selected !== docSearchOption.function_type) {
			docSearchOption.function_type = selected;
			if (docSearchOption.function_type === null) {
				docSearchOption.function_type = "";
			}
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentStatusChangeHandler = (ev) => {
		let status = ev.target.value;
		if (status !== "all") {
			status = parseInt(ev.target.value);
		} else {
			status = "";
		}
		let { docSearchOption } = this.state;
		if (status !== docSearchOption.status_id) {
			docSearchOption.status_id = status;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentSortingHandler = (ev) => {
		let sort = parseInt(ev.target.value);
		let { docSearchOption } = this.state;
		if (sort !== docSearchOption.sorting_code) {
			docSearchOption.sorting_code = sort;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListChangeHandler = (ev) => {
		let page = parseInt(ev.target.value);
		let { docSearchOption } = this.state;
		let nextPage = page;
		if (nextPage !== docSearchOption.page_number) {
			docSearchOption.page_number = nextPage;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListLimitChangeHandler = (ev) => {
		let limit = parseInt(ev.target.value);
		let { docSearchOption } = this.state;
		if (limit !== docSearchOption.page_size) {
			let cursor = (docSearchOption.current - 1) * docSearchOption.page_size;
			docSearchOption.page_number = Math.floor(cursor / limit) === 0 ? 1 : Math.floor(cursor / limit);
			docSearchOption.page_size = limit;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	DocumentFunctionTypeFilter = () => {
		let { t } = this.props;
		let { docSearchOption, PageData } = this.state;
		let { function_types, func_types_index } = PageData;

		let FilterItems = [];
		FilterItems.push(<MenuItem value={0} key={0}>{t(`common:document.function-type.all`)}</MenuItem>)
		for (let func of function_types) {
			if ([1,2,3,4].includes(func.id)) {
				FilterItems.push(<MenuItem value={func.id} key={func.id}>{t(`common:document.function-type.${func.code}`)}</MenuItem>)
			}
		}

		if (parseInt(docSearchOption.function_type)) {
			switch (docSearchOption.function_type) {
				case 1:
					docSearchOption.status_id = 2;
					break;
				case 2:
					docSearchOption.status_id = 8;
					break;
				case 3:
					docSearchOption.status_id = 12;
					break;
				case 4:
					docSearchOption.status_id = 10;
					break;
				default:
					break;
			}
		}

		let funcActive = func_types_index[docSearchOption.function_type || 0];
		let title =  t(`common:document.function-type.${funcActive.code}`);
		if (isNaN(parseInt(docSearchOption.function_type))) {
			title = t(`common:document.function-type.all`)
		}
		return (
			<Box py={1} px={2}>
				<Select
					value={funcActive.id}
					onChange={this.onDocumentFunctionTypeChangeHandler}
					renderValue={() => title}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{FilterItems}
				</Select>
			</Box>
		);
	}

	DocumentStatusListFilter = () => {
		let { t } = this.props;
		let { docSearchOption, docStatus } = this.state;
		if (Object.keys(docStatus).length === 0) {
			return null;
		}

		let statusItems = [], statusOptions = []
		statusItems.push(<MenuItem value="completed" key="completed">{t("common:document.status.completed")}</MenuItem>);
		statusOptions = [ 2, 8, 12, 10 ]
		if (parseInt(docSearchOption.function_type)) {
			statusItems = []
			switch (parseInt(docSearchOption.function_type)) {
				case 1:
					statusOptions = [2]
					break;
				case 2:
					statusOptions = [8]
					break;
				case 3:
					statusOptions = [12]
					break;
				case 4:
					statusOptions = [10]
					break;
				default:
					break;
			}
		}

		for (let i of statusOptions) {
			let status = docStatus[`${i}`];
			statusItems.push(<MenuItem key={status.id} value={status.id}>{t("common:" + status.label)}</MenuItem>);
		}

		let active = docSearchOption.status_id;
		let title = t("common:document.status.completed");
		if (isNaN(parseInt(active))) {
			active = "completed";
		} else {
			let currentStatus = docStatus[active];
			title = t(`common:${currentStatus.label}`);
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={active}
					onChange={this.onDocumentStatusChangeHandler}
					renderValue={() => title}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{statusItems}
				</Select>
			</Box>
		);
	}

	DocumentListSorting = () => {
		let { t } = this.props;
		let { docSearchOption } = this.state;
		let sortList = {
			2: "common:search.sorting.decending.by-issued-date",
			1: "common:search.sorting.ascending.by-issued-date",
		};
		let sortItems = [];

		for (let i in sortList) {
			// sortItems.push(<NavDropdown.Item key={i} eventKey={i}>{t(sortList[i])}</NavDropdown.Item>)
			sortItems.push(<MenuItem value={i} key={i}>{t(sortList[i])}</MenuItem>);
		}

		return (
			// <Nav activeKey={docSearchOption.sorting_code} onSelect={this.onDocumentSortingHandler} className="mr-auto">
			// 	<NavDropdown title={t(sortList[docSearchOption.sorting_code])} id="basic-nav-dropdown">
			// 		{sortItems}
			// 	</NavDropdown>
			// </Nav>

			<Box py={1} px={2}>
				<Select
					value={docSearchOption.sorting_code}
					onChange={this.onDocumentSortingHandler}
					renderValue={() => t(sortList[`${docSearchOption.sorting_code}`])}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{sortItems}
				</Select>
			</Box>
		);
	}

	docListPrevPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextPage = docSearchOption.page_number - 1;
		if (nextPage >= 0) {
			docSearchOption.page_number = nextPage;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	docListNextPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextPage = docSearchOption.page_number + 1;
		if (nextPage <= docSearchOption.page) {
			docSearchOption.page_number = nextPage;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	// DocumentListNavigator = () => {
	// 	let { docSearchOption } = this.state;
	// 	let navItems = [];

	// 	for (let i = 0; i < docSearchOption.page; i++) {
	// 		let page = i + 1;
	// 		let from = i * docSearchOption.take;
	// 		let to = page * docSearchOption.take;
	// 		navItems.push(<NavDropdown.Item key={page} eventKey={page}>{`${page} : ${from}-${to}`}</NavDropdown.Item>)
	// 	}

	// 	return (
	// 		<Nav activeKey={docSearchOption.current} onSelect={this.onDocumentListChangeHandler} className="mr-auto">
	// 			<NavDropdown title={`${docSearchOption.current}/${docSearchOption.page}`} id="basic-nav-dropdown">
	// 				{navItems}
	// 			</NavDropdown>
	// 		</Nav>
	// 	);
	// }

	DocumentListNavigator = () => {
		let { docSearchOption } = this.state;
		let navItems = [];
		let currentFrom = 0;
		let pageTo = 0;
		let pageFrom = 0;
		let currentTo = 0;
		let total = docSearchOption.total;
		let page_number = docSearchOption.page_number;
		let page_size = docSearchOption.page_size;
		let current = docSearchOption.current;
		if (!docSearchOption.total) { return <CircularProgress size="1rem" /> }
		for (let i = 0; i < docSearchOption.page; i++) {
			let page = i + 1;
			if (total === 0) {
				pageFrom = 0;
			} else {
				pageFrom = ((page - 1) * page_size) + 1;
			}
			if (total < page * page_size) {
				pageTo = total
			} else {
				pageTo = page * page_size;

			}
			navItems.push(
				<MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
			);
		}
		if (total === 0) {
			currentFrom = 0;
		} else {
			currentFrom = ((current - 1) * page_size) + 1;
		}
		if (total < current * page_size) {
			currentTo = total
		} else {
			currentTo = current * page_size;
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={current}
					// onChange={this.onPageChangeHandler}
					onChange={this.onDocumentListChangeHandler}
					renderValue={() => `${currentFrom} - ${currentTo} / ${docSearchOption.total}`}
					variant={"standard"}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: 250,
								minWidth: 130
							},
						},
					}}
					style={{ color: "#007BFF" }}>
					{navItems}
				</Select>
			</Box>
		);
	}

	SelectAllHandler = (ev) => {
		let { documents, evaluateDocList } = this.state;
		if (documents && evaluateDocList) {
			for (let doc of documents) {
				evaluateDocList[doc.id] = doc;
			}
			this.setState({ evaluateDocList });
		}
	}

	UnselectAllHandler = (ev) => {
		let { documents, evaluateDocList } = this.state;
		if (documents && evaluateDocList) {
			for (let doc of documents) {
				delete evaluateDocList[doc.id];
			}
			this.setState({ evaluateDocList });
		}
	}

	EvaluateConfirm = () => {
		let { t } = this.props;
		let { modal, evaluateDocList, docSearchOption } = this.state;

		let selectedDocs = Object.keys(evaluateDocList);

		modal.props = {
			show: true,
			centered: true,
		};

		modal.form = {};
		modal.action = [];
		if (docSearchOption.random === 0 && selectedDocs.length === 0) {
			modal.title = t('common:documents.evaluated-warning');
			modal.body = t('common:documents.evaluated-warning-please-select');
		} else {
			modal.title = t('common:documents.evaluated-confirm');
			modal.body = t('common:documents.evaluated-confirm-question');
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal, docSearchOption } = this.state;
					modal.props.show = false;
					modal.title = t('common:documents.evaluated-on-process');
					modal.body = Loading;
					modal.form = {};
					modal.action = [];
					if (docSearchOption.random === 0) {
						this.performBatchVerification(t('common:documents.evaluated-on-process'), ()=>this.StartEvaluate());
						this.setState({ modal });
					} else {
						this.performBatchVerification(t('common:documents.evaluated-on-process'), ()=>this.EvaluateSaveRandom());
						this.setState({ modal });
					}
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			)];
		}

		this.setState({ modal });
	}

	StartEvaluate = (ev) => {
		let { t } = this.props;
		let { modal, evaluateDocList } = this.state;
		let { searchOption } = this.state;

		Service.startEvaluate({
			"documents": Object.keys(evaluateDocList),
		}).then(resp => {
			// console.log(resp);
			modal.props.show = false;
			documentMainList.documents.set(() => null);
			this.setState({ modal, showDetail: false, showHiddenMenu: false, documents: null, evaluateDocList: {} }, () => {
				this.GetDocumentListData(searchOption);
			});
			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.SUCCESS,
				result: resp,
				isEvaluate: true,
				successTitle: t('common:documents.evaluated-on-success')
			}));
		}).catch(err => {
			//console.log("error", err.response);

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}

			modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.StartEvaluate);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			documentProcessState?.[this.documentProcessId].set(prevState => ({
				...prevState,
				status: DocumentProcessStatus.ERROR,
				result: err,
				isSign: true,
				errorTitle: t(`common:${errMessage || 'message.error.server_unknown_error'}`)
			}))
			this.setState({ modal });
		});
	}

	performBatchVerification(processingTitle, cb){
		documentProcessState?.set(prevState => ({
			...prevState,
			[this.documentProcessId]:{
				status: DocumentProcessStatus.PROCESSING,
				processingTitle,
				data: {
					...this.state,
				},
				onRetry: () => this.performBatchVerification(processingTitle, cb),
			}
		}));
		if(cb) cb();
	}

	MoreActionButton = () => {
		let { t } = this.props;
		let { showHiddenMenu, docSearchOption } = this.state;
		let { RandomSizeItems } = this;

		if (!showHiddenMenu) {
			return null;
		}

		let ActionButtons = [];
		ActionButtons.push(
			<Tooltip key="btn-filter" title={t("common:tooltip.documents.evaluate.research")} arrow>
				<IconButton size="small" color="primary" onClick={this.SearchDialog}>
					<ManageSearchIcon fontSize="large" />
				</IconButton>
			</Tooltip>
		);
		ActionButtons.push(
			<FormControl key="evaluate-type" className="evaluate-option">
				<RandomSizeItems />
			</FormControl>
		);
		ActionButtons.push(
			<Button key="btn-confirm"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={this.EvaluateConfirm}
				startIcon={<SecurityOutlinedIcon />}
				style={{ marginRight: "10px" }}
			>
				{t("common:documents.evaluate.start-evaluate")}
			</Button>
		);

		// if (docSearchOption.random === 0) {
		// 	ActionButtons.push(
		// 		<Tooltip key="btn-select-all" title={t("common:tooltip.common.check-all")} arrow>
		// 			<IconButton size="small" color="primary" onClick={this.SelectAllHandler}>
		// 				<CheckBoxOutlinedIcon fontSize="large" />
		// 			</IconButton>
		// 		</Tooltip>
		// 	);
		// 	ActionButtons.push(
		// 		<Tooltip key="btn-unselect-all" title={t("common:tooltip.common.uncheck-all")} arrow>
		// 			<IconButton size="small" color="primary" onClick={this.UnselectAllHandler}>
		// 				<CheckBoxOutlineBlankOutlinedIcon fontSize="large" />
		// 			</IconButton>
		// 		</Tooltip>
		// 	);
		// }

		return ActionButtons;
	}

	DocumentDetailHeaderRight = () => {
		let { t } = this.props;
		let { docSearchOption, showHiddenMenu } = this.state;
		let { DocumentListNavigator, DocumentFunctionTypeFilter, DocumentStatusListFilter, DocumentListSorting } = this;

		if (!showHiddenMenu) {
			return null;
		}

		return (
			<div className="header-right">

				<DocumentFunctionTypeFilter />

				<DocumentStatusListFilter />

				<DocumentListSorting />

				<Box py={1} px={2}>
					<Select
						value={docSearchOption.page_size}
						onChange={this.onDocumentListLimitChangeHandler}
						renderValue={() => docSearchOption.page_size + " " + t("common:search.view.record-per-page")}
						variant={"standard"}
						style={{ color: "#007BFF" }}
					>
						{[100, 200, 500, 1000].map((count, index) =>
							<MenuItem value={count} key={index} >{count + t("common:search.view.record-per-page")}</MenuItem>
						)}
					</Select>
				</Box>

				{/* <Nav activeKey={docSearchOption.take} onSelect={this.onDocumentListLimitChangeHandler} className="mr-auto">
					<NavDropdown title={docSearchOption.take + " " + t("common:search.view.record-per-page")} id="basic-nav-dropdown">
						<NavDropdown.Item eventKey={10}>{"10 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={20}>{"20 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={50}>{"50 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={100}>{"100 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
					</NavDropdown>
				</Nav> */}

				<div className="pagination">

					<DocumentListNavigator />

					{/* <button className="btn-pagination" onClick={this.docListPrevPageData}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</button>

					<button className="btn-pagination" onClick={this.docListNextPageData}>
						<FontAwesomeIcon icon={faChevronRight} />
					</button> */}

					<IconButton
						onClick={this.docListPrevPageData}
						disabled={docSearchOption.current === 1 ? true : false} >
						<ChevronLeftIcon />
					</IconButton>

					<IconButton
						onClick={this.docListNextPageData}
						disabled={docSearchOption.current === docSearchOption.page ? true : false}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>);
	}

	handleScroll(event) {
		const scrollPosition = event.target.scrollTop;
        if (scrollPosition > 50) {
            shedOnScroll.set(true);
        } else {
            shedOnScroll.set(false);
        }
	}

	DocumentDetailBody = (props) => {
		let { t } = this.props;
		let { DocumentList, DocumentDetailHeaderRight, MoreActionButton, handleScroll } = this;

		return (
			<div className="document-detail-wrapper evaluation-wrapper"
				onScroll={handleScroll}
			>
				<div className="header-wrapper sticky">
					<div className="header-left">
						<IconButton className="btn-return btn-custom"
							onClick={(ev) => {
								this.setState({ showDetail: false, showHiddenMenu: false, documents: [], evaluateDocList: {} });
								documentMainList.documents.set(() => []);
							}}>
							<ArrowBackIcon title={t("common:document.return-to-list-page")} />
						</IconButton>

						<MoreActionButton />

					</div>

					<div className="header-center document-title"></div>

					<DocumentDetailHeaderRight />

				</div>

				<div className="body-wrapper" style={{ overflow: "unset" }}>
					<DocumentList />
				</div>

			</div>
		);
	}

	CSVDownload = (items) => {
		let { t } = this.props;
		let headers = {
			"doc_type_name": { // 書類種別
				"label": t("common:documents.evaluate.csv-header.document-type"),
			},
			"received_date": { // 取引年月日
				"label": t("common:documents.evaluate.csv-header.deal-date"), // received-date
				"value": (data) => {
					if (data.received_date !== null && data.received_date.includes('T')) return data.received_date.split('T')[0]
					return data.received_date;
				}
			},
			"counter_party_name": { // 相手先
				"label": t("common:documents.evaluate.csv-header.counter-party-name"),
			},
			"title": { // タイトル
				"label": t("common:documents.evaluate.csv-header.title"),
			},
			"amount": { // 金額
				"label": t("common:documents.evaluate.csv-header.amount"),
				"prefix": "currency",
			}, // with currency // "currency",
			"product_name": { // 商品名
				"label": t("common:documents.evaluate.csv-header.product-name"),
			},
			"creator_name": { // 書類登録者
				"label": t("common:documents.evaluate.csv-header.creator-name"),
			},
			"issued_date": { // 書類入力日
				"label": t("common:documents.evaluate.csv-header.issued-date"),
				"value": (data) => {
					if (data.issued_date !== null && data.issued_date.includes('T')) return data.issued_date.split('T')[0]
					return data.issued_date;
				}
			},
			"confirmed_user_name": { // 書類確認者
				"label": t("common:documents.evaluate.csv-header.confirmed-user"),
			},
			"confirmed_date": { // 書類確認日
				"label": t("common:documents.evaluate.csv-header.confirmed-date"),
				"value": (data) => {
					if (data.confirmed_date !== null && data.confirmed_date.includes('T')) return data.confirmed_date.split('T')[0]
					return data.confirmed_date;
				}
			},
			"reference_document": { // お客様書類ID
				"label": t("common:documents.evaluate.csv-header.customer-document-id"),
			},
			"eval_data": { // メタデータ
				"label": t("common:documents.evaluate.csv-header.eval-metadata"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_data)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						if (data.data_missing) {
							let data_missing = [];
							for (let missing of data.data_missing) {
								data_missing.push(t(`common:documents.evaluate.data-missing.${missing}`));
							}
							if (data_missing.length === 3) {
								return t("common:documents.evaluate.data-missing.all");
							} else if (data_missing.length > 0) {
								return t("common:documents.evaluate.data-missing-prefix", { missing_field: data_missing.join(", ") });
							} else {
								return t("common:documents.evaluate.ok");
							}
						} else {
							return null;
						}
					}
				},
			},
			"width": { // 横幅
				"label": t("common:documents.evaluate.csv-header.width"),
			},
			"height": { // 縦幅
				"label": t("common:documents.evaluate.csv-header.height"),
			},
			"dpi": { // DPI
				"label": t("common:documents.evaluate.csv-header.dpi"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_dpi)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.dpi;
					}
				},
			},
			"color_depth": { // 色深度
				"label": t("common:documents.evaluate.csv-header.color-depth"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_color_depth)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.color_depth;
					}
				},
			},
			"eval_dpi": { // 画像情報
				"label": t("common:documents.evaluate.csv-header.eval-dpi"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_dpi)) {
						return t("common:documents.evaluate-skip-check");
					} else if (data.eval_dpi === 1) {
						return t("common:documents.evaluate.ok");
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_color_depth": { // 色深度
				"label": t("common:documents.evaluate.csv-header.eval-color-depth"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_color_depth)) {
						return t("common:documents.evaluate-skip-check");
					} else if (data.eval_color_depth === 1) {
						return t("common:documents.evaluate.ok");
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_timestamp_intime": { // TS付与期限
				"label": t("common:documents.evaluate-timestamp-period"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_timestamp_intime)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.timestamp_period;
					}
				},
			},
			"eval_timestamp_result": { // タイムスタンプ
				"label": t("common:documents.evaluate-timestamp"),
				"value": (data) => {
					if (data.eval_timestamp_exist) {
						if (data.eval_timestamp_hash) {
							return data.timestamp_date?.split('T')[0];
						} else {
							return t("common:documents.evaluate.timestamp.hash-not-same");
						}
					} else {
						return t("common:documents.evaluate.timestamp.not-exists");
					}
				},
			},
			"eval_timestamp_validity": { // TS日時/有効期限
				"label": t("common:documents.evaluate-timestamp-date"),
				"value": (data) => {
					if (data.eval_timestamp_validity === 1) {
						return data.timestamp_expired?.split('T')[0];
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_status": { // 一括検証結果
				"label": t("common:documents.evaluate-status"),
				"value": {
					0: t("common:documents.evaluate.ng"),
					1: t("common:documents.evaluate.ok"),
				},
			},
		}

		let rows = [];
		let headerLabel = [];
		for (let col in headers) {
			let header = headers[col];
			headerLabel.push(header.label);
		}
		rows.push(`"${headerLabel.join('","')}"`);

		for (let item of items) {
			let row = [];
			for (let col in headers) {
				let header = headers[col];
				let colVal = item[col];
				if (header.hasOwnProperty("value")) {
					if (typeof header.value == "function") {
						row.push(header.value(item));
					} else if (header.value instanceof Object) {
						row.push(header.value[colVal] || colVal);
					} else {
						row.push(colVal);
					}
				} else if (header.hasOwnProperty("prefix")) {
					if (item.hasOwnProperty(header.prefix)) {
						row.push(`${colVal} ${item[header.prefix]}`);
					} else {
						row.push(`${colVal} ${header.prefix}`);
					}
				} else if (header.hasOwnProperty("join")) {
					let values = [colVal];
					for (let jCol of header.join.columns) {
						values.push(item[jCol]);
					}
					row.push(values.join(header.join.separator));
				} else {
					row.push(colVal);
				}
			}
			rows.push(`"${row.join('","')}"`);
		}
		// console.log(rows);

		let file_name = 'export.csv';
		let BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
		let csv = rows.join("\r\n");

		let blob = new Blob([BOM, csv], { type: 'text/csv;charset=utf-8;' });
		// if (navigator.msSaveBlob) { // IE 10+
		// 	navigator.msSaveBlob(blob, exportedFilenmae);
		// } else {
		let link = document.createElement("a");
		// if (link.download !== undefined) { // feature detection
		// Browsers that support HTML5 download attribute
		let url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", file_name);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		// }
		// }
	}

	ItemsList = () => {
		let { t } = this.props;
		let { evaluates } = this.state;
		const {documents} = documentMainList.get({noproxy: true});
		console.log('documents', documents);

		if (evaluates === null) {
			return <LoadingList />;
		}
		if (evaluates !== null && documents === null) {
			return <LoadingList />;
		}

		if (evaluates.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list error-tr">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let ItemsList = [];
		// console.log(evaluates);
		for (let eva of evaluates) {
			//let btnDetailClass = ["sign-status"];

			let statusIconProps = {
				className: `icon ${eva.status_code}`,
				icon: faQuestionCircle,
			};

			if (eva.evaluate_status === 1) {
				statusIconProps.icon = faCheckCircle;
			} else if (eva.evaluate_status === 0) {
				statusIconProps.icon = faExclamationCircle;
			}

			ItemsList.push(
				<tr key={eva.id} className="document-row-list" onClick={(ev) => {
					// let target = ev.target.closest(".document-row-list");
					// if(target.classList.contains("selected")){
					// 	target.classList.remove("selected");
					// }else{
					// 	target.classList.add("selected");
					// }
				}}>

					<td className="evaluated-date"
						onClick={(ev) => {
							documentMainList.documents.set(() => null);
							this.setState({ documents: null, showDetail: true })
							this.GetEvaluationDetail(eva.id)
						}} >
						<div className="inner-container clickable">

							<span>{eva.evaluate_date}</span>
						</div>
					</td>
					<td className="evaluator-info"
						onClick={(ev) => {
							documentMainList.documents.set(() => null);
							this.setState({ documents: null, showDetail: true })
							this.GetEvaluationDetail(eva.id)
						}}
					>
						<div className="inner-container clickable">

							<span>{eva.evaluator_name}</span>
						</div>
					</td>
					{/* <td className="evaluated-status">
						<div className="inner-container">
							<span>{t("common:documents.evaluated-status")}</span>
							<div className={"document-evaluated-status " + eva.status_code}>
								<FontAwesomeIcon {...statusIconProps} />
								<span className="status-label">{t(`common:documents.evaluated-${eva.status_code}`)}</span>
							</div>
						</div>
					</td> */}
					{/* <td className="evaluation-summary-info clickable"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container">
							<span>{`${eva.total_documents}${t("common:documents.evaluated-total-documents")}`}</span>
							<span>{`${eva.total_files}${t("common:documents.evaluated-total-files")}`}</span>
							<span>{`${eva.total_pages || "?! "}${t("common:documents.evaluated-total-pages")}`}</span>
						</div>
					</td> */}
					<td className="evaluation-summary-info clickable"
						onClick={(ev) => {
							documentMainList.documents.set(() => null);
							this.setState({ documents: null, showDetail: true })
							this.GetEvaluationDetail(eva.id)
						}}
					>
						<div className="inner-container">
							<span>{t("common:documents.evaluated.summary-total-files-count", { file_count: eva.total_files })}</span>
							<span>{t("common:documents.evaluated.summary-ok-count", { ok_count: eva.total_ok })}</span>
							<span>{t("common:documents.evaluated.summary-ng-count", { ng_count: eva.total_ng })}</span>
						</div>
					</td>
					<td className="doc-row-action clickable"
						onClick={(ev) => {
							documentMainList.documents.set(() => null);
							this.setState({ documents: null, showDetail: true })
							this.GetEvaluationDetail(eva.id)
						}}
					>
						<div className="inner-container">
							<div className="action-icon evaluated-status">
								<FontAwesomeIcon {...statusIconProps} />
							</div>
						</div>
					</td>
					<td className="stamp-box">
						<Tooltip title={t("common:tooltip.documents.evaluate.download-csv")} arrow>
							<span>
								<IconButton
									color="primary"
									onClick={(ev) => {
										this.GetEvaluationDetail(eva.id);
										setTimeout(() => {
											console.log('documents csv 1', documents);
											this.CSVDownload(documents);
										}, 2000)
									}}>
									<DownloadIcon />
								</IconButton>
							</span>
						</Tooltip>
					</td>
				</tr>
			);
		}
		return (
			<ResizableTableWithCache storageKey={`colWidths_Evaluation`}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s =>
							(
								<tr className={`${s.get({noproxy: true}) && "shed"}`}>
									<th className="evaluated-date" id="evaluated-date">
										<div>
											<span>{t("common:documents.evaluated-date")}</span>
										</div>
									</th>
									<th className="evaluated-verifier" id="evaluated-verifier">
										<div>
											<span>{t("common:documents.evaluator")}</span>
										</div>
									</th>
									<th id="evaluation-summary-info">
										<div>

										</div>
									</th>
									<th className="evaluated-results" id="evaluated-results">
										<div>
											<span>{t("common:documents.evaluate-hash")}</span>
										</div>
									</th>
									<th className="stamp-box" id="stamp-box">
										<div>
											<span>{"CSV"}</span>
										</div>
									</th>
								</tr>
							)
						}
					</StateFragment>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</ResizableTableWithCache>
		);
	}

	PDFFilesInDocument = (props) => {
		let { t } = this.props;
		let { files } = props;
		let fileKeys = Object.keys(files || {});
		if (fileKeys.length > 0) {
			let firstKey = fileKeys.shift();
			let firstFile = files[firstKey];
			let FileLabel = [];

			FileLabel.push(
				<span key="first-file" className="file-name-label">{`${firstFile.name || firstKey}`}</span>
			)

			if (fileKeys.length > 0) {
				let labelMore = t("common:documents.and-one-more-file");
				if (fileKeys.length > 1) {
					labelMore = t("common:documents.and-more-files", { count: fileKeys.length });
				}
				FileLabel.push(
					<div key="more-files" className="more-files">
						<Link to="#" className="more-files-link">
							<span>{labelMore}</span>
						</Link>
						<div className="more-files-block">
							{fileKeys.map(hash => {
								let file = files[hash];
								return (
									<li key={hash} className="file-label">{file.name || hash}</li>
								);
							})}
						</div>
					</div>
				)
			}

			return FileLabel;
		} else {
			return null;
		}
	}

	StatusIconElement = (props) => {
		let { status, mini, medium, className } = props;
		// doc.hasOwnProperty("eval_status")
		// if ([0, 1].includes(status)) {
		let classStatus = "processing";
		let IconStatus = faQuestionCircle;

		if (status === 1) {
			classStatus = "ok";
			IconStatus = faCheckCircle;
		} else if (status === 0 && !mini) {
			classStatus = "ng";
			IconStatus = faExclamationTriangle;
		}

		let additionalClassName = className ? ` ${className}` : "";

		if (mini) {
			return <div className={`evaluated-status-mark-mini ${classStatus}${additionalClassName}`}></div>;
		} else if (medium) {
			return <div className={`evaluated-status-mark-medium ${classStatus}${additionalClassName}`}></div>;
		} else {
			return <FontAwesomeIcon icon={IconStatus} className={`icon ${classStatus}${additionalClassName}`} />;
		}
		// } else {
		// 	return null;
		// }
	}

	DocumentListAsNewEvaluate = (props) => {
		let { t } = this.props;
		let { evaluateDocList, docSearchOption } = this.state;
		let { PDFFilesInDocument } = this;

		const {documents} = documentMainList.get({noproxy: true});

		let selectedDocs = Object.keys(evaluateDocList);
		let ItemsList = [];
		for (let doc of documents) {
			let Amount = parseFloat(doc.amount || "0").toString();
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			let convertAmount = Amount[0];
			if (parseInt(Amount[1]) > 0) {
				convertAmount = Amount.join(".")
			}
			Amount = convertAmount + " " + (doc.currency || "JPY");

			let selectedClass = "";
			if (selectedDocs.includes(doc.id.toString())) {
				selectedClass = " selected";
			}
			let clickableClass = "";
			if (docSearchOption.random === 0) {
				clickableClass = " clickable";
			}

			// console.log(doc);

			ItemsList.push(
				<tr key={doc.id} className={`document-row-list${clickableClass}${selectedClass}`} onClick={(ev) => {
					let { evaluateDocList, docSearchOption } = this.state;
					if (docSearchOption.random === 0) {
						if (Object.keys(evaluateDocList).includes(doc.id.toString())) {
							delete evaluateDocList[doc.id];
						} else {
							evaluateDocList[doc.id] = doc;
						}
						this.setState({ evaluateDocList });
					}
				}}>
					<td className="sign-status">
						<Tooltip title={t("common:tooltip.common.view-document")} arrow>
							<IconButton color="primary" href={`/documents/detail/${doc.id}`} target="_blank">
								<FileOpenIcon title={t("common:document.show-detail-page") + " " + doc.id} />
							</IconButton>
						</Tooltip>
					</td>
					<td className="creator-info">
						<div className="inner-container">
							<span>{doc.creator_name}</span>
						</div>
					</td>
					<td className="function-type-info">
						<div className="inner-container">
							<span>{t(`common:${doc.function_type}`)}</span>
						</div>
					</td>
					<td className="document-info">
						<div className="inner-container">
							<span>{t(`common:${doc.document_type}`)}</span>
							<div className={"document-progress " + doc.status_code}>
								<span className="status-label">{t(`common:${doc.status_label}`)}</span>
							</div>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							<div className={"document-files"}>
								<PDFFilesInDocument files={doc.files} />
							</div>
						</div>
					</td>
					{/* <td className="counter-party-info">
						<div className="inner-container">
						</div>
					</td> */}
					<td className="amount-info">
						<div className="inner-container">
							<span>{Amount}</span>
						</div>
					</td>
					<td className="document-created-date">
						<div className="inner-container">
							<span>{doc.received_date && moment(doc.received_date).format("YYYY-MM-DD")}</span>
						</div>
					</td>
					<td className="document-confirmed-date">
						<div className="inner-container">
							<span>{doc.confirmed_date && moment(doc.confirmed_date).format("YYYY-MM-DD")}</span>
						</div>
					</td>
				</tr>
			);
		}
		return (
			<ResizableTableWithCache storageKey={`colWidths_Evaluation_New`}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th className="evaluation-icon" id="evaluation-icon">
									<div>

									</div>
								</th>
								<th className="evaluation-creator" id="evaluation-creator">
									<div>
										<span>{t("common:documents.creator")}</span>
									</div>
								</th>
								<th className="evaluation-function" id="evaluation-function">
									<div>
										<span>{t("common:documents.document-info.function-type")}</span>
									</div>
								</th>
								<th className="evaluation-document" id="evaluation-document">
									<div>
										<span>{t("common:documents.document-info.imprints")}</span>
									</div>
								</th>
								<th id="doc-title">
									<div>
										<span>{t("common:documents.document-info.doc-title")} / {t("common:documents.document-info.file-name")}</span>
									</div>
								</th>
								<th className="evaluation-amount" id="evaluation-amount">
									<div>
										<span>{t("common:documents.document-info.amount")}</span>
									</div>
								</th>
								<th className="evaluation-date" id="evaluation-issued-date">
									<div>
										<span>{t("common:documents.issued-date")}</span>
									</div>
								</th>
								<th className="evaluation-date" id="evaluation-confirmed-date">
									<div>
										<span>{t("common:documents.confirmed-date")}</span>
									</div>
								</th>
							</tr>
						)}
					</StateFragment>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</ResizableTableWithCache>
		);
	}

	DocumentListAsReadOnly = (props) => {
		let { t } = this.props;

		let { StatusIconElement } = this;

		let { documents } = documentMainList.get({noproxy: true});

		let ItemsList = [];
		for (let doc of documents) {
			let data_missing = [];
			if (doc?.data_missing) {
				for (let missing of doc?.data_missing) {
					data_missing.push(t(`common:documents.evaluate.data-missing.${missing}`));
				}
				if (data_missing.length === 3) {
					data_missing = t("common:documents.evaluate.data-missing.all");
				} else if (data_missing.length > 0) {
					data_missing = t("common:documents.evaluate.data-missing-prefix", { missing_field: data_missing.join(", ") });
				}
			} else {
				data_missing = null;
			}

			let SingleValueBlock = (props) => {
				let { value } = props;

				if (!value) {
					value = "documents.evaluate-not-available";
				}
				return (
					<div className="evaluate-detail evaluated-status">
						<span>{value}</span>
					</div>
				);
			}

			let DatetimeNewLineBlock = (props) => {
				let { date } = props;

				if (!date) {
					return null;
				}

				let arrDatetime = date.split(" ");

				return (
					<div className="evaluate-detail evaluated-status" style={{
						flexDirection: "column",
						alignItems: "center",
						height: "32px",
						fontSize: "12px",
					}}>
						<span style={{ width: "auto" }}>{arrDatetime[0]}</span>
						<span style={{ width: "auto" }}>{arrDatetime[1]}</span>
					</div>
				);
			}

			let SkipCheckItemBlock = (props) => {
				let { value, label } = props;

				if (![null, undefined].includes(value)) {
					return [
						<StatusIconElement key="icon" status={value} mini={true} />,
						<SingleValueBlock key="label" value={label || t("common:documents.evaluate-skip-check")} />
					];
				} else {
					return <SingleValueBlock value={t("common:documents.evaluate-skip-check")} />;
				}
			}

			let TimeStampSignDate = (props) => {
				let { doc } = props;

				if (doc?.eval_timestamp_exist) {
					if (doc?.eval_timestamp_hash) {
						return <DatetimeNewLineBlock date={doc?.timestamp_date && moment.utc(doc?.timestamp_date).format("YYYY-MM-DD HH:mm:ss")} />;
					} else {
						return <SingleValueBlock value={t("common:documents.evaluate.timestamp.hash-not-same")} />;
					}
				} else {
					return <SingleValueBlock value={t("common:documents.evaluate.timestamp.not-exists")} />;
				}
			}

			ItemsList.push(
				<tr key={`${doc?.id}-${doc?.file_info?.hash}`} className={`document-row-list`}>
					<td className="sign-status">
						<Tooltip title={t("common:tooltip.common.view-document")} arrow>
							<IconButton href={`/documents/detail/${doc?.id}`} target="_blank">
								<FileOpenIcon color="primary" title={t("common:document.show-detail-page") + " " + doc?.id} />
							</IconButton>
						</Tooltip>
					</td>
					<td className="document-info">
						<div className="inner-container">
							<span>{t(`common:${doc?.document_type}`)}</span>
							<div className={"document-progress " + doc?.status_code}>
								<span className="status-label">{t(`common:${doc?.status_label}`)}</span>
							</div>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span className="file-document-title">{doc?.id + " - " + doc?.title}</span>
							<span className="file-name-label">{`${t("common:documents.file")}: ${doc?.file_info?.name}`}</span>
						</div>
					</td>
					<td className="document-created-date">
						<div className="inner-container">
							<span>{doc?.issued_date?.substring(0,10)}</span>
						</div>
					</td>
					<td className="preservation-require">
						<div className="inner-container">
							<span>{doc?.preservation_require !== null ? t(`common:${doc?.preservation_require_label}`) : t("common:documents.evaluate-not-available")}</span>
						</div>
					</td>
					<td className="confirm-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc?.eval_data} label={data_missing} />
						</div>
					</td>
					<td className="dpi-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc?.eval_dpi} label={doc?.dpi} />
						</div>
					</td>
					<td className="color-depth-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc?.eval_color_depth} label={doc?.color_depth} />
						</div>
					</td>
					<td className="timestamp-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc?.eval_timestamp_intime} label={doc?.timestamp_period} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<StatusIconElement status={doc?.eval_timestamp_result} medium={true} />
							<TimeStampSignDate doc={doc} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<StatusIconElement status={doc?.eval_timestamp_validity} medium={true} />
							<DatetimeNewLineBlock date={doc?.timestamp_expired && moment.utc(doc?.timestamp_expired).format("YYYY-MM-DD HH:mm:ss")} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<div className="action-icon evaluated-status">
								<StatusIconElement status={doc?.eval_status} />
							</div>
						</div>
					</td>
					{/* <td className="doc-row-action">
					</td> */}
				</tr>
			);
		}

		return (
			<ResizableTableWithCache storageKey={`colWidths_Evaluation_ReadOnly`}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th className="evaluation-icon" id="evaluation-icon">
									<div>

									</div>
								</th>
								<th className="evaluation-document" id="evaluation-document">
									<div>
										<span>{t("common:documents.document-info.imprints")}</span>
									</div>
								</th>
								<th className="evaluation-document-title" id="evaluation-document-title">
									<div>
										{/* <span>{t("common:documents.document-info.doc-title")} / {t("common:documents.document-info.file-name")}</span> */}
									</div>
								</th>
								<th className="evaluation-date" id="evaluation-date">
									<div>
										<span>{t("common:documents.issued-date")}</span>
									</div>
								</th>
								<th className="evaluation-require" id="evaluation-require">
									<div>
										<span>{t("common:documents.document-info.preservation-require")}</span>
									</div>
								</th>
								<th className="evaluation-data" id="evaluation-metadata">
									<div>
										<span>{t("common:documents.evaluate-metadata")}</span>
									</div>
								</th>
								<th className="evaluation-data" id="evaluation-dpi">
									<div>
										<span>{t("common:documents.evaluate-dpi")}</span>
									</div>
								</th>
								<th className="evaluation-data" id="evaluation-color-depth">
									<div>
										<span>{t("common:documents.evaluate-color-depth")}</span>
									</div>
								</th>
								<th className="evaluation-data" id="evaluation-timestamp-period">
									<div>
										<span>{t("common:documents.evaluate-timestamp-period")}</span>
									</div>
								</th>
								<th className="evaluate-header bg-lightblue" id="evaluate-timestamp">
									<div>
										<span>{t("common:documents.evaluate-timestamp")}</span>
									</div>
								</th>
								<th className="evaluate-header bg-lightblue" id="evaluate-tamp-date">
									<div>
										<span>{t("common:documents.evaluate-timestamp-date")}</span>
									</div>
								</th>
								<th className="evaluate-header bg-lightblue" id="evaluate-status">
									<div>
										<span>{t("common:documents.evaluate-status")}</span>
									</div>
								</th>
							</tr>
						)}
					</StateFragment>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</ResizableTableWithCache>
		);
	}

	DocumentList = () => {
		let { t } = this.props;
		let { showHiddenMenu } = this.state;

		let { documents } = documentMainList.get({noproxy: true});

		// console.log(documents);
		if (documents === null) {
			return <LoadingList />;
		}

		if (documents.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let { DocumentListAsNewEvaluate, DocumentListAsReadOnly } = this;

		if (showHiddenMenu) {
			return <DocumentListAsNewEvaluate />;
		} else {
			return <DocumentListAsReadOnly />;
		}
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { searchOption, evaluates } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Button
				sx={{ ml: 1, borderRadius: 19 }}
				key="btn-new-evaluate"
				variant="contained"
				onClick={this.SearchDialog}
				startIcon={<AddIcon />}
			>
				{t('common:main.general.new-evaluate')}
			</Button>
		)

		ButtonList.push(
			<IconButton
				sx={{ ml: 2, mr: 2, mt: 1 }}
				key="list-reload"
				color="primary"
				style={{
					margin: "0 8px", padding: "4px"
				}}
				disabled={evaluates ? false : true}
				onClick={(ev) => {
					this.GetDocumentListData(searchOption);
				}}
			>
				{evaluates ? <RefreshIcon sx={{ fontSize: 36 }} /> : <CircularProgress size="1.4em" />}
			</IconButton>
		);

		return ButtonList;
	}

	AdditionalButtons = () => {
		//let { t } = this.props;
		// let { searchOption, evaluates } = this.state;
		let ButtonList = [];

		return ButtonList;
	}

}

export default withTranslation()(Evaluation);
