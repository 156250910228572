// @ts-nocheck
import mappingTranslateDefault from "../Main/MappingTranslateCommon";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Col, Container, Form, InputGroup, Nav, NavDropdown,} from 'react-bootstrap';
import {faExclamationTriangle,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {blue} from "@mui/material/colors";
import logo from "../Assets/img/logo_figure.svg";
import AddIcon from "@mui/icons-material/Add";
import Service from "../../Service";
import React from "react";
import List from "@mui/material/List";
import {Autocomplete, CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import {userInfoState} from "../../drive-feature/data-access/state";
import Loading from "../Loading";
import { signerSearch } from ".";
import { hookstate, StateFragment, useHookstate } from "@hookstate/core";
import { throttle } from "../../drive-feature/utils/hooks";
export const valueSearchGuest = hookstate('');

export default class GuestSetting {

	static get key() {
		return "guest-setting";
	}
	static get name() {
		return "GuestSetting";
	}
	static get code() {
		return GuestSetting.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = GuestSetting.key;
		this.name = GuestSetting.name;
		this.code = GuestSetting.code;
		this.formData = {
			company_seal: "common:documents.host-setting.company-seal",
			use_digital_certificate: "common:documents.host-setting.digital-certificate",
			sign_level: "common:documents.document-info.sign-level",
			counter_party_name: "common:documents.document-info.counter-party-name",
			guests: "common:documents.guest-setting.guests",
			document_view_required: "common:general.document-viewing.checkbox-require",
			is_applying_guest_mfa: "common:general.mfa-auth.checkbox-guest-sign",
		};
		this.guestKeyFields = {
			"family_name": "family_name",
			"first_name": "first_name",
			"family_name_kana": "family_name_kana",
			"first_name_kana": "first_name_kana",
			"company_name": "company_name",
			"mobile_number": "mobile",
			"email": "email",
			"approver_id": "approver_id",
		}
		this.initialValues = {
			guests: {},
			document_view_required: !!objMain?.state?.docInfo?.document_view_required,
			is_applying_guest_mfa: !!objMain?.state?.docInfo?.is_applying_guest_mfa,
		}

		this.objMain.setPageObject(this);
		// console.log(this.objMain.state.formData.use_digital_certificate);
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { guestFields, guestFormData } = this.objMain.state;
		// this.state.fields = {};
		for (let key in this.guestKeyFields) {
			let label = this.guestKeyFields[key];
			guestFields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.guest.${label}`),
			};
			if (guestFormData[key]) {
				guestFields[key].value = guestFormData[key];
			}
		}

		this.objMain.updateState({ guestFields }, cb);
	}

	PleaseSelectHost = (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalTitle = "common:documents.determine.saving";
		modalProps = {
			"show": true,
			"centered": true,
			"backdrop": true,
		}
		ModalBody = () => {
			return (
				<div>{t("common:documents.please-select-host-or-guest")}</div>
			);
		};

		this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	formSubmitCallback = (ev) => {
		let { pageState, formData } = this.objMain.state;
		var counthost = Object.keys(formData.signers).length;
		var countguest = Object.keys(formData.guests).length;
		if (countguest === 0 && counthost === 0) {
			this.PleaseSelectHost();
		} else {
			pageState[this.name].completed = true;
			this.objMain.setFormData(this.formData);
			this.objMain.updateState({ pageState: pageState });
			this.objMain.nextPageStep(ev, this.constructor);
		}
	};

	setDigitalCertificateFlag = (ev, UserID) => {
		let guest = this.formData.guests[UserID];
		let digiCert = ![true, "true"].includes(guest.use_digital_certificate);
		this.formData.guests[UserID].use_digital_certificate = digiCert;
		this.objMain.setFormData(this.formData);
	}

	OptionalCertificateType = (props) => {
		let { t } = this.props;
		let { user } = props;
		let { CertificateType } = this.objMain.state;
		let { sign_level } = this.formData;
		let CertItems = [];

		// console.log(sign_level);
		if (sign_level.toString() === "1") {
			CertItems.push(<NavDropdown.Item key={0} eventKey={0}>{t(`common:documents.host-setting.options-not-use`)}</NavDropdown.Item>);
		}

		for (let i in CertificateType) {
			let cert = CertificateType[i];
			if (cert.id === 2) {
				continue;
			}

			// let certLabel = `${cert.id} : ${t(`common:documents.host-setting.options-${cert.label}`)}`;
			let certLabel = `${t(`common:documents.host-setting.options-${cert.label}`)}`;

			let CertWarning = () => { return null; };
			if (!user?.available_certs?.includes(cert.id)) {
				CertWarning = () => {
					return <FontAwesomeIcon icon={faExclamationTriangle} className="guest-no-certificate-warning" />;
				};
			}

			CertItems.push(
				<NavDropdown.Item key={cert.id} eventKey={cert.id}>
					<CertWarning />
					{certLabel}
				</NavDropdown.Item>
			);
		}

		return CertItems;
	}

	onCertChangeHandler = (key, user) => {
		let cert_type = parseInt(key);
		// console.log(cert_type)
		this.formData.guests[user.id].self_certificate = (cert_type !== 0);
		this.formData.guests[user.id].certificate_type = cert_type;
		this.objMain.setFormData(this.formData);
	}

	handleOpenApproverDialog = () => {
		let { modal } = this.objMain.state;
		let { t } = this.props;
		modal = {
			props: {
				show: true,
				dialogClassName: "profile-dialog",
				centered: true,
			},
			title: "common:settings.guest.approver-settings",
			body: this.ApproverForm,
			action: [
				(
					<Button
						sx={{ml: 1}}
						variant="contained"
						onClick={this.submitGuestForm}
					>
						<span>{t("common:general.ok")}</span>
					</Button>
				)
			],
		};

		this.objMain.updateState({modal});
	};

	handleGetInitialApproverList = () => {
		let {approver_list, approver_list_pagination, approver_list_loading, enable_load_more_approver_list} = this.objMain.state;

		if (!approver_list_loading && enable_load_more_approver_list) {
			this.objMain.updateState({
				approver_list_loading: true,
			});

			Service.getGuestApproverList(approver_list_pagination).then(resp => {
				const {payload, ok} = resp;

				let new_list = [];

				if (ok) {
					if (payload.length > 0) {
						if (approver_list_pagination.page_number === 1) {
							new_list = [...payload];
						} else {
							new_list = [...approver_list, ...payload];
						}
						this.objMain.updateState({
							approver_list: new_list,
							approver_list_pagination: approver_list_pagination
						});
					} else if (payload.length === 0) {
						this.objMain.updateState({enable_load_more_approver_list: false});
					}
				}
			}).catch((error) => {

			}).finally(() => {
				this.objMain.updateState({approver_list_loading: false});
			});
		}
	};

	ApproverForm =() => {
		let { t } = this.props;

		let {approver_list, guestFormData, approver_list_loading, approver_last_selection} = this.objMain.state;

		guestFormData["approver_id"] = approver_last_selection?.user_id;

		const handleSelectApprover = (event, newValue) => {
			if (newValue) {
				guestFormData["approver_id"] = newValue.user_id;
				localStorage.setItem("approver_last_selection", JSON.stringify(newValue));
				this.objMain.updateState({
					guestFormData,
					approver_last_selection: newValue,
				});
			}
		};

		const handleInputDebounce = (func, delay) => {
			let timeoutId;
			return function (...args) {
				clearTimeout(timeoutId);
				timeoutId = setTimeout(() => func.apply(this, args), delay);
			};
		}

		const handleFilterName = handleInputDebounce((name) => {
			let {approver_list_pagination} = this.objMain.state;
			this.objMain.updateState({
				approver_list_pagination: {
					...approver_list_pagination,
					page_number: 1,
					search: name,
				},
				enable_load_more_approver_list: true,
			}, () => {
				this.handleGetInitialApproverList();
			})
		}, 1000);

		const handleInputChange = (event, newInputValue, reason) => {
			if (reason === 'clear') {
				let { approver_list_pagination } = this.objMain.state;
				this.objMain.updateState({
					approver_list: [],
					approver_list_pagination: {
						...approver_list_pagination,
						page_number: 1,
						search: "",
					},
					approver_list_loading: false,
				}, () => {
					this.handleGetInitialApproverList();
				})
			}
		}
		const handleSearch = (event) => {
			handleFilterName(event.target.value);
		}

		const handleScroll = (ev) => {
			let { approver_list_pagination } = this.objMain.state;
			const target = ev.target;
			if (target) {
				if ((target.scrollHeight - target.scrollTop) === (target.clientHeight + 0.5)) {
					this.objMain.updateState({
						approver_list_pagination: {
							...approver_list_pagination,
							page_number: approver_list_pagination.page_number + 1,
						}
					}, () => {
						this.handleGetInitialApproverList();
					});
				}
			}
		}

		const handleClose = () => {
			let {approver_list_pagination} = this.objMain.state;
			this.objMain.updateState({
				approver_list_pagination: {
					...approver_list_pagination,
					page_number: 1,
					search: "",
				},
				approver_list_loading: false,
			})
		}

		const CustomList = React.forwardRef((props, ref) => {
			const { children, ...other } = props;
			return (
				<List {...other} ref={ref} onScroll={handleScroll}>
					{children}
					<div
						style={{
							height: 50
						}}
					/>
					{approver_list_loading && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CircularProgress size={20}/>
						</div>
					)}
				</List>
			);
		});

		return (
			<div className="add-approver-container">
				<span className={"add-approver-subtitle"}>
					{t("common:settings.guest.select-approver-desc")}
				</span>

				<div className={"select-approver-container"}>
					<span className={"select-approver-label"}>
						{t("common:settings.guest.approver")}
					</span>
					<Autocomplete
						classes={{popper: 'select-approver-popper'}}
						options={approver_list}
						classes={{popper: 'select-approver-popper'}}
						value={approver_last_selection}
						noOptionsText={
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CircularProgress size={20}/>
							</div>
						}
						autoHighlight
						getOptionLabel={(item) => `${item.family_name} ${item.first_name}`}
						renderOption={(props, item) => (
							<Box component="li" {...props} key={item.user_id}>
								{item.family_name} {item.first_name}
							</Box>
						)}
						isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
						onChange={handleSelectApprover}
						onClose={handleClose}
						onInputChange={handleInputChange}
						ListboxComponent={CustomList}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={"Search"}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password',
								}}
								onChange={handleSearch}
							/>
						)}
					/>
				</div>
			</div>
		);
	};

	openGuestFormDialog = () => {
		let { t } = this.props;
		let { modal } = this.objMain.state;

		let lastSelectionApprover = parseInt(JSON.parse(localStorage.getItem("approver_last_selection"))?.user_id, 10);

		modal = {
			props: {
				show: true,
				dialogClassName: "profile-dialog",
				centered: true,
			},
			title: "common:settings.guest.add_guest",
			body: this.GuestForm,
			action: [
				(
					<Button
						sx={{ml: 1}}
						type={"submit"}
						variant="contained"
					>
						<span>{t("common:general.ok")}</span>
					</Button>
				)
			],
			form: {
				id: "settings-user-form",
				onSubmit: (ev) => this.guestFormSubmitCallback(ev),
				noValidate: false,
			},
		};

		/* Default structure */
		let { guestKeyFields } = this;
		let formData = {};
		for (let key in guestKeyFields) {
			formData[key] = "";
		}
		formData["user_id"] = null;
		formData["approver_id"] = lastSelectionApprover;
		formData["tenant_id"] = null;
		formData["status"] = 0;
		formData["groups"] = [];

		this.objMain.updateState({ guestFormData: formData }, this.setStateFields(() => {
			this.objMain.updateState({ modal });
		}));
	}

	guestFormSubmitCallback = (ev) => {
		ev.preventDefault();
		const user_info = userInfoState.get({noproxy:true});

		if (user_info?.administrator_role == "0") {
			this.handleOpenApproverDialog();
		} else if (user_info?.administrator_role == "1") {
			this.submitGuestForm();
		}
	}

	GuestModalActionTryAgain = () => {
		let { t } = this.props;

		return [
			(
				<Button sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.objMain.state;
					modal.body = this.GuestForm;
					modal.action = [
						(
							<Button
							sx={{ml: 1}}
							variant="contained"
							key="ok"
							onClick={this.guestFormSubmitCallback}>
						  <span>{t("common:general.ok")}</span>
						</Button>
						)
					];
					this.objMain.updateState({ modal });
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)
		];
	}

	OrganizationSelector = () => {
		let { t } = this.props;
		let { guestFormData, organizations } = this.objMain.state;

		if (organizations === null) {
			return <Loading />;
		}

		organizations = organizations ?? [];

		let OrgItems = [];

		/* */
		for (let item of organizations) {

			if (item.user_type !== 2) {
				continue;
			}

			let checked = false;
			if (guestFormData.groups.includes(item['user_id'].toString())) {
				checked = true;
			}

			/* */
			OrgItems.push(
				<div className="org-selector-item" key={item['user_id']}>
					<label className="checkboxBtn">
						<input type="checkbox" name="organizations[]" value={item['user_id']} checked={checked} onChange={(ev) => {
							let { guestFormData } = this.objMain.state;
							if (ev.target.checked) {
								guestFormData.groups.push(ev.target.value);
							} else {
								var index = guestFormData.groups.indexOf(ev.target.value);
								if (index !== -1) {
									guestFormData.groups.splice(index, 1);
								}
							}
							this.objMain.updateState({ guestFormData });
						}} />
						<div>{item['name']}</div>
					</label>
				</div>
			);
		}

		/* */
		return (
			<div className="org-selector">
				<div className="org-selector-label">
					<div>{t('common:settings.user.organization')}</div>
				</div>
				<div className="org-selector-itemlist">
					{OrgItems}
				</div>
			</div>
		);

	}

	onChangeHandler = (ev, onChangeCB, field) => {
		let key = ev.target.name;
		let val = ev.target.value;
		if (ev.target.type === "checkbox") {
			val = ev.target.checked;
		}

		let { guestFormData, guestMsgValidate } = this.objMain.state;
		guestFormData[key] = val;

		if (ev.target.required) {
			ev.target.closest(".has-validation").classList.add("was-validated");
		}

		this.objMain.updateState({ guestFormData, guestMsgValidate }, onChangeCB);
	};
	ValidateMessage = (props) => {
		let { t } = this.props;
		let { field } = props;
		let { guestFieldValidate, guestMsgValidate } = this.objMain.state;
		let tmpMsgValidate = guestMsgValidate;
		if (!tmpMsgValidate || tmpMsgValidate === "") {
			let fieldTrans = t(`common:message.req.${field.label}`, field.label);
			tmpMsgValidate = t("common:message.input.required", { field_name: fieldTrans });
			if (guestFieldValidate.hasOwnProperty(field.name)) {
				tmpMsgValidate = t(`common:${guestFieldValidate[field.name]}`);
			}
		}

		return <span>{tmpMsgValidate}</span>;
	};

	FieldControl = (props) => {
		let { t } = this.props;
		let { name } = props;
		let { ValidateMessage } = this;
		let { guestFormData, guestFormValidate, guestFieldValidate, activePage, guestFields, guestMsgValidate } = this.objMain.state;
		let newProps = { ...props };

		let Prepend = () => { return null; }
		if (props.hasOwnProperty("prepend")) {
			Prepend = props.prepend;
			delete newProps.prepend;
		}

		let Append = () => { return null; }
		if (props.hasOwnProperty("append")) {
			Append = props.append;
			delete newProps.append;
		}

		let field = guestFields[name];
		if (!field) {
			return null;
		}

		if (!props.hasOwnProperty("onChange")) {
			newProps.onChange = (ev) => this.onChangeHandler(ev, null, field);
		}

		if (props.hasOwnProperty("change")) {
			newProps.onChange = (ev) => {
				this.onChangeHandler(ev, props.change, field);
			};
			delete newProps.change;
		}

		let groupProps = {};
		if (newProps.hasOwnProperty("key")) {
			groupProps.key = newProps.key;
			delete newProps.key;
		}
		if (newProps.hasOwnProperty("xs")) {
			groupProps.xs = newProps.xs;
			delete newProps.xs;
		}
		if (newProps.hasOwnProperty("md")) {
			groupProps.md = newProps.md;
			delete newProps.md;
		}
		if (newProps.hasOwnProperty("xl")) {
			groupProps.xl = newProps.xl;
			delete newProps.xl;
		}

		let validated = "";
		let validateField = guestFieldValidate;
		if (newProps.validate && [true, "true"].includes(newProps.validate)) {
			if (activePage) {
				let pageName = activePage.name;
				validateField = guestFieldValidate[pageName];
				if (guestFormValidate[pageName]) {
					validated = " was-validated";
				}
			} else if (guestFormValidate) {
				validated = " was-validated";
			}
		}

		if (guestFormValidate && validateField?.hasOwnProperty(field.name)) {
			validated = " was-validated";
		}

		let optionLabel = props.optionLabel || {};

		return (
			<Form.Group {...groupProps} as={Col} controlId={field.name} className={`custom-form-outline${validated}`}>
				<Form.Label>
					<span>{field.label}</span>
					<span className={optionLabel.className}>{optionLabel.value}</span>
				</Form.Label>
				<InputGroup className="mb-2" hasValidation >
					<Prepend />
					<Form.Control
						name={field.name}
						placeholder={field.label}
						isValid={this.objMain.state.guestIsInvalid.hasOwnProperty(field.name) && !this.objMain.state.guestIsInvalid[field.name]}
						isInvalid={this.objMain.state.guestIsInvalid.hasOwnProperty(field.name) && this.objMain.state.guestIsInvalid[field.name]}
						value={[null, undefined].includes(guestFormData[field.name]) ? "" : guestFormData[field.name]}
						{...newProps}
					/>
					<Append />
					<Form.Control.Feedback type="invalid">
						<ValidateMessage field={field} />
					</Form.Control.Feedback>
				</InputGroup>
			</Form.Group>
		);
	};

	
	GuestForm = () => {
		let { t } = this.props;
		let { guestFormData } = this.objMain.state;
		let { FieldControl, OrganizationSelector } = this;
		const isExistEmail = useHookstate(false);
		
		function debounceInput(func, wait) {
			let timeout;
	
			return function executedFunction(...args) {
				const context = this;
				const later = () => {
					clearTimeout(timeout);
					func.apply(context, args);
				};
	
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
			};
		}
		function handleInputChange(event) {
			const email = event.target.value;
			console.log(email);
			const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
			if (regexEmail.test(email)) {
				Service.GuestCollaborationEmailVerify({
					email: email,
				}).then((resp) => {
					if (resp.ok && resp.payload.email_verify) {
						console.log("hadfas")
						isExistEmail.set(true);
					} else {
						isExistEmail.set(false);
					}
				}).catch((err) => {
					isExistEmail.set(false);
				});
			} else {
				isExistEmail.set(false);
			}
		}
		let debouncedInputChange = debounceInput(handleInputChange, 1000);

		return (
			<div className="form-container">
				<Form.Control type="hidden" name="user_id" defaultValue={guestFormData['user_id']} />
				<Form.Control type="hidden" name="tenant_id" defaultValue={guestFormData['tenant_id']} />

				<Form.Row>
					<FieldControl name="family_name" xs={12} md={6} required validate="true" />
					<FieldControl name="first_name" xs={12} md={6} required validate="true" />
				</Form.Row>

				<Form.Row>
					<FieldControl name="family_name_kana" xs={12} md={6} />
					<FieldControl name="first_name_kana" xs={12} md={6} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="company_name" xs={12} md={6} />
					<FieldControl name="mobile_number" xs={12} md={6} />
				</Form.Row>

				<Form.Row className="relative">
					<FieldControl name="email" type="email" xs={12} md={12} required validate="true" readOnly={false} onInput={debouncedInputChange}/>
					<img className="logo-exist-email icon-absolute" src={logo} alt="icon" style={isExistEmail.get({noproxy: true}) ? {opacity: 1}: {opacity: 0}}></img>
				</Form.Row>

				<Form.Row>
					<div className="col-xl-12">
						<OrganizationSelector />
					</div>
				</Form.Row>

				<Form.Row>
					<Form.Group className="lz-mt-10 status" controlId="status">
						{(guestFormData.status === 0 || guestFormData.status === 1) && <Form.Check
							type="switch"
							name='status'
							value='status'
							onChange={this.objMain.onChangeHandler}
							label={t('common:settings.guest.active')}
							checked={[true, "true", 0, "0"].includes(guestFormData["status"])}
						/>}
					</Form.Group>
				</Form.Row>

			</div>
		);
	}

	/** [Event] */
	submitGuestForm = () => {
		let { t } = this.props;
		const user_info = userInfoState.get({noproxy:true});
		let { guestFormData } = this.objMain.state;
		let { modal } = this.objMain.state;

		/* */
		let body = {
			"user_id": guestFormData["user_id"] ?? undefined,
			"family_name": guestFormData["family_name"],
			"first_name": guestFormData["first_name"],
			"family_name_kana": guestFormData["family_name_kana"],
			"first_name_kana": guestFormData["first_name_kana"],
			"company_name": guestFormData["company_name"],
			"mobile_number": guestFormData["mobile_number"],
			"email": guestFormData["email"],
			"organizations": guestFormData["groups"],
			"status": '1',
		};

		if (user_info?.administrator_role == "0") {
			body = {
				...body,
				"approver_id": guestFormData["approver_id"] ?? undefined,
			}
		}

		/* */
		if ([true, "true", 0, "0"].includes(guestFormData["status"])) {
			body["status"] = '0';
		}

		modal.title = "common:settings.guest.add_guest";
		modal.body = Loading;
		modal.action = null;
		modal.close = false;

		let tempAllGuests = {...this.objMain.state.allGuests};

		/* */
		this.objMain.updateState( { modal }, () => {
				let {modal} = this.objMain.state;
				Service.setUserGuest(body).then(resp => {
					if (resp.ok === true) {
						let {
							user_id,
							family_name,
							first_name,
							email,
							company_name,
							status,
							user_type,
							groups,
							available_certs,
						} = resp.payload;

						modal.close = true;
						modal.action = [];
						modal.body = () => {
							return (
								<div>
									<p>{t(`common:documents.info.guest-registration-success`)}</p>
								</div>
							)
						}
						let createdGuest = {
							available_certs: available_certs,
							company_name: company_name,
							email: email,
							family_name: family_name,
							first_name: first_name,
							groups: groups,
							id: user_id,
							linkage_tenant_id: null,
							linkage_user_id: null,
							name: `${family_name} ${first_name}`,
							profile_image: null,
							status: status,
							user_id: user_id,
							user_type: user_type,
						}
						if(groups.length > 0){
							groups.forEach(a=>{
								if(signerSearch['guest'].detail?.[a.id]?.get({noproxy: true})){
									const el2 = document.querySelector(`[data-group="${a.id}"]`);
									if(signerSearch['guest'].detail?.[a.id]?.get({noproxy: true})?.show || el2){
										signerSearch['guest'].detail?.[a.id]?.detail?.data?.merge({
											[user_id]: createdGuest
										});
									}
									signerSearch['guest'].detail?.[a.id]?.total?.set(v=> v + 1);
									signerSearch['guest'].detail?.[a.id]?.group_members_count?.set(v=> v + 1);
									signerSearch['guest'].data?.[a.id]?.group_members_count?.set(v=> v + 1);
								}
								
							});
							
						} else {
							signerSearch['guest'].data?.merge({
								[user_id]: createdGuest
							});
						}
						// tempAllGuests = {
						// 	...tempAllGuests,
						// 	[user_id]: createdGuest
						// };

						this.objMain.updateState({
							modal,
							// allGuests: tempAllGuests,
							// availableGuests: tempAllGuests,
							// searchAvailableGuests: tempAllGuests,
						});
					}
				}).catch(err => {
					let errMessage = err.response.data.payload.message;
					if (err.response.data.payload.hasOwnProperty("error")) {
						errMessage = err.response.data.payload.error;
					}

					modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
					modal.action = this.GuestModalActionTryAgain;
					modal.close = true;
					if (err?.response?.data?.payload?.['duplicated_guest']?.user_id) {
						const duplicatedGuest = err?.response?.data?.payload?.['duplicated_guest'];
						let { modal } = this.objMain.state;
						let SendMessage = () => {
						  let messageArr = [];
						  messageArr.push(
							  <div>
								<p>{t('common:settings.guest.guest-exist')}</p>
							  </div>
						  );
						  messageArr.push(
							  <div
								  style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
								  }}
							  >
								<div>
								  <p>{duplicatedGuest.additional_info.company_name} {duplicatedGuest.family_name} {duplicatedGuest.first_name}</p>
								  <p>{duplicatedGuest.email}</p>
								</div>
							  </div>
						  );
						  messageArr.push(
							  <div>
								<p>{t('common:settings.guest.register-confirm')}</p>
							  </div>
						  );
						  return messageArr;
						}
			
						modal.props = {
						  show: true,
						  centered: true,
						  backdrop: true,
						};
			
						modal.body = () => {
						  return (
							  <div className="">
								<SendMessage />
							  </div>
						  );
						};
			
						modal.action = [(
							<Button
								sx={{ml: 1}}
								variant="contained"
								key="ok"
								onClick={(ev) => {
			
								//   loadingBtnResend.set(true);
								//   loadingBtn.set(true);
								  modal.props = {
									show: false,
									centered: true,
									backdrop: true,
								  };
								  modal.body = null;
								  modal.close = false;
								  modal.action = [];
								  this.objMain.setState({ modal });
			
								  Service.setUserGuest({
									...body,
									ignore_duplicate: true,
								  })
									  .then(async (resp) => {
										// createUserGuestFunction();
										if (resp.ok === true) {
											let {
												user_id,
												family_name,
												first_name,
												email,
												company_name,
												status,
												user_type,
												groups,
												available_certs,
											} = resp.payload;
					
											modal.close = true;
											modal.action = [];
											modal.body = () => {
												return (
													<div>
														<p>{t(`common:documents.info.guest-registration-success`)}</p>
													</div>
												)
											}
											let createdGuest = {
														available_certs: available_certs,
														company_name: company_name,
														email: email,
														family_name: family_name,
														first_name: first_name,
														groups: groups,
														id: user_id,
														linkage_tenant_id: null,
														linkage_user_id: null,
														name: `${family_name} ${first_name}`,
														profile_image: null,
														status: status,
														user_id: user_id,
														user_type: user_type,
											}
											if(groups.length > 0){
												groups.forEach(a=>{
													if(signerSearch['guest'].detail?.[a.id]?.get({noproxy: true})){
														const el2 = document.querySelector(`[data-group="${a.id}"]`);
														if(signerSearch['guest'].detail?.[a.id]?.get({noproxy: true})?.show || el2){
															signerSearch['guest'].detail?.[a.id]?.detail?.data?.merge({
																[user_id]: createdGuest
															});
														}
														signerSearch['guest'].detail?.[a.id]?.total?.set(v=> v + 1);
														signerSearch['guest'].detail?.[a.id]?.group_members_count?.set(v=> v + 1);
														signerSearch['guest'].data?.[a.id]?.group_members_count?.set(v=> v + 1);
													}
													
												});
												
											} else {
												signerSearch['guest'].data?.merge({
													[user_id]: createdGuest
												});
											}
					
											// tempAllGuests = {
											// 	...tempAllGuests,
											// 	[user_id]: {
											// 		available_certs: available_certs,
											// 		company_name: company_name,
											// 		email: email,
											// 		family_name: family_name,
											// 		first_name: first_name,
											// 		groups: groups,
											// 		id: user_id,
											// 		linkage_tenant_id: null,
											// 		linkage_user_id: null,
											// 		name: `${family_name} ${first_name}`,
											// 		profile_image: null,
											// 		status: status,
											// 		user_id: user_id,
											// 		user_type: user_type,
											// 	}
											// };
					
											this.objMain.updateState({
												modal,
												// allGuests: tempAllGuests,
												// availableGuests: tempAllGuests,
												// searchAvailableGuests: tempAllGuests,
											});
										}
									  })
									  .catch((error) => {
										// isOpenSelectApprover.set(false);
			
										let msgErr = error?.response?.data?.message;
			
										if (
											error?.code === "ECONNABORTED" ||
											(error?.response?.status === 504 &&
												error?.response?.data?.message === "Endpoint request timed out")
										) {
										  msgErr = t(
											  "message.error.request.timed_out",
											  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
										  );
										  // return;
										}
										/* Update DialogBody */
										let { modal } = this.objMain.state;
										modal.props = {
										  show: true,
										  dialogClassName: "loading-dialog",
										  centered: true,
										};
										modal.body = t(`common:${msgErr || 'message.error.server_unknown_error'}`);
										modal.close = true;
			
										this.objMain.setState({ modal });
										// loadingBtnResend.set(false);
										// loadingBtn.set(false);
									  })
									//   .finally(() => loadingBtn.set(false));
								}}>
							  <span>{t("common:general.ok")}</span>
							</Button>
						)];
			
						modal.close = true;
			
						this.objMain.setState({ modal });
			
						// loadingBtnResend.set(false);
						// loadingBtn.set(false);
						return;
					  }

					this.objMain.updateState({modal});
				});
			});

	}


	render() {
		let { t } = this.props;
		let { AvailableUser, SelectedUsers, SearchInputChange } = this.objMain;
		let { creationType, searchGuestsText } = this.objMain.state;
		let { guests, sign_level } = this.formData;
		let { OptionalCertificateType } = this;

		let SelectedUserProps = {
			users: guests,
			user_type: "guest",
			// is_revision: this.objMain.state?.formData?.is_revision
		}

		// console.log(creationType, sign_level);
		if (["signer", "circle"].includes(creationType) && sign_level.toString() !== "0") {
			SelectedUserProps.optional = (props) => {
				let { user } = props;
				let { formData } = this.objMain.state;

				// let cert_type = parseInt(formData.certificate_type);
				// console.log(cert_type, user.certificate_type);
				// return null;

				if (sign_level.toString() === "2" && !user.certificate_type) {
					user.certificate_type = formData.certificate_type;
					if(formData.certificate_type == 2){
						user.certificate_type = 1;
					}
				}
				// console.log(user, this.objMain.state?.CertificateType)
				let labelTrans = this.objMain.state?.CertificateType[user.certificate_type]
				let CertTitle =  labelTrans ? `${t(`common:documents.host-setting.options-${labelTrans?.label}`)}`: user.certificate_type || t(`common:documents.host-setting.options-not-use`);
				console.log(user);
				if (!user?.available_certs?.includes(user.certificate_type) && user.certificate_type) {
					CertTitle = <span><FontAwesomeIcon icon={faExclamationTriangle} className="guest-no-certificate-warning-title" />{CertTitle}</span>;
				}

				return (
					<Nav activeKey={user.certificate_type} onSelect={(key) => {
						this.onCertChangeHandler(key, user);
					}} className="guest-certificate-selection">
						<NavDropdown title={CertTitle} id="basic-nav-dropdown">
							<OptionalCertificateType user={user} />
						</NavDropdown>
					</Nav>
				);
			};
		}

		let AvailableUserProps = {
			users: guests,
			user_type: "guest",
		}
		if (["signer", "circle"].includes(creationType)) {
			AvailableUserProps.optional = (props) => {
				let { user } = props;
				// console.log(user);
				if (user.linkage_tenant_id && user.linkage_user_id) {
					return (
						<div className="user-linkage-container">
							<img src={logo} className="user-linkage-icon" alt="paperlogic" />
						</div>
					);
				} else {
					return null;
				}
			};
		}

		// let showDigiCertField = this.formData.company_seal ? "none" : "block";

		return (

			<Container fluid="md" className={this.key + "-container form-wrapper container-fluid"} >

				<Form
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="container-fluid full-form"
				>
					<div className="layout-right-title form-header guest_settings_layout" >
						<h2>{creationType == "sender" ? t('common:menu.documents.step.sender-guest-setting') : this.headerInfo}</h2>
						{/* {this.objMain.state?.formData?.is_revision ? <></> :  */}
						<div className="checkbox_guest">
							<FormControlLabel
								label={
									mappingTranslateDefault(
										t,
										"common:general.mfa-auth.checkbox-guest-sign",
										"ゲストの二要素認証を設定"
									)
								}
								control={
									<Checkbox
										sx={{
											color: blue[400],
											"&.Mui-checked": {
												color: blue[400],
											},
										}}
										checked={!!this.formData.is_applying_guest_mfa}
										onChange={(e) => {
											// this.formData['is_applying_guest_mfa'] = ;
											let data = { ...this.formData, is_applying_guest_mfa: e.target.checked };

											this.objMain.setFormData(data);
											// this.setState({
											// 	formData: {
											// 		...this.state.formData,
											// 		isApplyMFAGuest: e.target.checked
											// 	}
											// });
										}}
									/>
								}
							/>
						</div>
						{/* } */}
						{["signer", "circle"].includes(creationType) && (
							<div className="checkbox_guest">
								<FormControlLabel
									label={
										mappingTranslateDefault(
											t,
											"common:general.document-viewing.checkbox-require",
											"書類閲覧を必須にする"
										)
									}
									control={
										<Checkbox
											sx={{
												color: blue[400],
												"&.Mui-checked": {
													color: blue[400],
												},
											}}
											checked={!!this.formData.document_view_required}
											onChange={(e) => {
												let data = {...this.formData, document_view_required: e.target.checked};

												this.objMain.setFormData(data);
											}}
										/>
									}
								/>
							</div>
						)}
					</div>

					{/* {this.objMain.state?.formData?.is_revision ? <></> : */}
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Button
							size="medium"
							startIcon={<AddIcon/>}
							onClick={() => this.openGuestFormDialog()}
						>
							{t('common:settings.guest.add_guest')}
						</Button>
					</div>
					{/* } */}

					<div className="form-body flex-column">

						<Form.Row className="flex-full">
							<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
								<Form.Label>{creationType == "sender" ? t("common:documents.guest-setting.guests-recipient-list") : t("common:documents.guest-setting.guests-list")}</Form.Label>
								<SelectedUsers {...SelectedUserProps} />
							</Form.Group>

							{/* {this.objMain.state?.formData?.is_revision ? <></> :  */}
							<Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
								<Form.Label>{t("common:documents.guest-setting.available-user-list")}</Form.Label>
								<InputGroup className="form-control">
									<div className="search-input-container">
									<StateFragment state={valueSearchGuest}>
										{s=>{
											return <TextField
											className="search-input-field"
											placeholder={t('common:documents.general.guest-search-input-placeholder')}
											onChange={(e) => {
												s.set(e.target.value || '');
												return SearchInputChange(AvailableUserProps, e);
												}
											}
											onKeyDown={(e) => {
												if (e.key === 'Enter'){
													e.preventDefault();
													e.stopPropagation();
												}
											}}
											value={s.get()}
											defaultValue={searchGuestsText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
										}}
										</StateFragment>
									</div>
									<AvailableUser {...AvailableUserProps} />
								</InputGroup >
							</Form.Group>
							{/* } */}

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<ChevronLeftIcon />
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'>
									<span className="btn-label">{t('common:documents.general.next')}</span>
									<ChevronRightIcon />
								</Button>
							</div>
						</div>

					</div>

				</Form>

			</Container>

		);

	}

}
