// @ts-nocheck
import React, { createRef, Suspense } from 'react';
import {
	Form,
	Col,
	Container,
	InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';

import mappedDataToTreeView from "../../drive-feature/utils/mappedDataToTreeView";
import FileExplorer from '../Fragments/FileExplorer';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DriveFolders from '../../drive-feature/pages/drive-folders/drive-folders';
import DriveLayout from '../../drive-feature/components/drive-layout/drive-layout';
import { currentSelectedCompanyFolderState, driveNavCompanyTree, selectedFoldersCompany } from '../../drive-feature/data-access/company-folders/company-folders.state';
import { breabCrumbState, currentFolderTypeState, driveDetailLoading, driveFoldersDetail, driveFoldersNav, folderDetailSearch, userInfoState } from '../../drive-feature/data-access/state';
import { getFoldersTree } from '../../drive-feature/data-access/api';
import {Document, Page, pdfjs} from 'react-pdf';

import VerificationForm from './VerificationForm'

export default class DocumentInfo {
	
	refDataList = createRef();
	static get key() {
		return "document-info";
	}
	static get name() {
		return "DocumentInfo";
	}
	static get code() {
		return DocumentInfo.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = DocumentInfo.key;
		this.name = DocumentInfo.name;
		this.code = DocumentInfo.code;
		this.formData = {
			document_type: "common:documents.document-info.document-type",
			doc_title: "common:documents.document-info.doc-title",
			invoice_number: 'common:documents.document-info.invoice-number',
			amount: "common:documents.document-info.amount",
			currency: "common:documents.document-info.currency",
			sign_level: "common:documents.document-info.sign-level",
			display_imprint: "common:documents.document-info.display-imprint",
			contract_date: "common:documents.document-info.contract-date",
			expiry_date: "common:documents.document-info.expiry-date",
			effective_date: "common:documents.document-info.effective-date",
			document_create_date: "common:documents.document-info.document-create-date",
			// received_date: "common:documents.document-info.received-date",
			received_date: "common:documents.document-info.deal-date",
			counter_party_name: "common:documents.document-info.counter-party-name",
			supplier_counter_party_name: "common:documents.document-info.supplier-name",
			product: "common:documents.document-info.product",
			reference: "common:documents.document-info.reference",
			filebox_shortcut: "common:documents.document-info.shortcut-to-filebox",
			doc_attributes: "common:documents.document-info.attributes",
			content: "common:documents.document-info.content",
			preservation_require: "common:documents.document-info.preservation-require",
			dateofnoti: "common:documents.document-info.date-noti-",
			select_datenoti: "common:documents.document-info.select_datenoti",
			select_datenoti1: "",
			drive_folder_id: null
		};
		this.tempSelectedFilebox = null;
		this.initialValues = {
			// document_type: "contract",
			// currency: "JPY",
			use_digital_certificate: true,
			display_imprint: true,
			doc_attributes: [],
			doc_title: "",
			invoice_number: "",
			amount: "0",
			filebox_shortcut: "",
			dateofnoti: "",

		}
		// console.log('run here')
		// console.log(this.objMain.state.fields);
		// console.log(this.objMain.state.formData);
		this.objMain.setPageObject(this);
		
		// this.initDocTitle();
	}

	customValidity = (ev = null) => {
		let { formData } = this.objMain.state;
		let allValidity = true;

		if (this.ChkDateNoti() && parseInt(formData.dateofnoti) !== 1) {
			allValidity = false;
		}

		return allValidity;
	}

	formSubmitCallback = (ev) => {
		let { formData } = this.objMain.state;
		const isNotCacheHost = formData.function_type === 2 || (formData.function_type !== 2 && [1, 2, "1", "2"].includes(formData.sign_level))
		if(isNotCacheHost){
			localStorage.removeItem('prev_host_signer_list');
		}
		
		if(formData.function_type.toString() !== '2'){
			//witness type
			if (formData.sign_level.toString() === "0") {
				formData.use_digital_certificate = false;
				formData.certificate_type = 1
			}
			// hybrid type 
			else if (formData.sign_level.toString() === "1") {
				formData.use_digital_certificate = localStorage.getItem('prev_use_digital_certificate') === 'true';
				if(!formData.certificate_type){
					formData.certificate_type = Number(localStorage.getItem('prev_certificate_type')) || 1
				}else{
					localStorage.setItem('prev_certificate_type', formData.certificate_type)
				}
				if(formData.use_digital_certificate == false) formData.certificate_type = 1
			}
			// party type
			else if(formData.sign_level.toString() === "2"){
				formData.use_digital_certificate = true;
				if(!formData.certificate_type){
					formData.certificate_type = Number(localStorage.getItem('prev_certificate_type')) || 1
				}else{
					localStorage.setItem('prev_certificate_type', formData.certificate_type)
				}
			}
		}
		// sender
		else{
			formData.use_digital_certificate = localStorage.getItem('prev_use_digital_certificate') === 'true';
			if(!formData.certificate_type){
				formData.certificate_type = Number(localStorage.getItem('prev_certificate_type')) || 1
			}else{
				localStorage.setItem('prev_certificate_type', formData.certificate_type)
			}
			if(formData.use_digital_certificate == false) formData.certificate_type = 1
			
		}	

		const localHostSinger = localStorage.getItem('prev_host_signer_list');
		if(localHostSinger !== 'undefined' && !window.location.pathname.includes('documents/edit/') && Object.keys(formData?.signers || {})?.length === 0) {
			formData.signers = JSON.parse(localHostSinger || '{}');
		}
		if (this.customValidity(ev)) {
			let { pageState } = this.objMain.state;
			pageState[this.name].completed = true;
			this.objMain.setFormData(this.formData);
			this.objMain.updateState({ pageState: pageState });
			this.objMain.nextPageStep(ev, this.constructor);
		} else {
			ev.stopPropagation();
		}
	}

	/** */
	addAttribute = (ev) => {
		/* */
		// let key = "Note" + this.formData.doc_attributes.length;
		this.formData.doc_attributes.push({ key: "", value: "" });
		this.objMain.setFormData(this.formData);
	}

	removeAttribute = (ev) => {
		/* */
		// console.log(ev.target.closest(".remove").dataset);
		let row = parseInt(ev.target.closest(".remove").dataset.row);
		this.formData.doc_attributes.splice(row, 1);
		this.objMain.setFormData(this.formData);
	}

	setAttibute = (ev) => {
		// console.log(ev);
		// console.log(ev.target.value);
		let row = parseInt(ev.target.dataset.row);
		let type = ev.target.dataset.type;
		if (type === "key") {
			this.formData.doc_attributes[row].key = ev.target.value;
		} else {
			this.formData.doc_attributes[row].value = ev.target.value;
		}
		this.objMain.setFormData(this.formData);
	}

	renderAttributes = (ev) => {
		let { t } = this.props;
		let { fieldValidate } = this.objMain.state;
		let attrRows = [];
		// console.log(this.formData.doc_attributes);
		for (let i in this.formData.doc_attributes) {
			let attr = this.formData.doc_attributes[i];
			attrRows.push(
				<Form.Row key={i}>
					{/* {FieldControl({ name: "amount", type: "number", step: "0.01", xs: 3, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, required: true, children: this.CurrencyItems() })} */}

					<Form.Group xs={5} as={Col} controlId={"key[" + i + "]"} className={fieldValidate[this.name]}>
						<Form.Control
							name={"key[" + i + "]"}
							value={attr.key}
							data-row={i}
							data-type="key"
							onChange={this.setAttibute}
							placeholder={t("common:documents.document-info.key")}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group xs={7} as={Col} controlId={"value[" + i + "]"} className={fieldValidate[this.name]}>
						<InputGroup hasValidation>
							<Form.Control
								name={"value[" + i + "]"}
								value={attr.value}
								data-row={i}
								data-type="value"
								onChange={this.setAttibute}
								placeholder={t("common:documents.document-info.value")}
								required
							/>
							<IconButton data-row={i} className="btn-icon remove" onClick={this.removeAttribute}>
								<DoDisturbOnOutlinedIcon className="file-remove" />
							</IconButton>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>

				</Form.Row>

			);
		}

		return attrRows;
	}

	showFileboxPathSelection = async (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			"dialogClassName": "modal-full zero-padding",
			"centered": true,
		}
		selectedFoldersCompany.set(null);
		modalTitle = "common:documents.document-info.filebox-select-path";
		ModalAction = [];
		ModalBody = Loading;
		ModalAction.push(
			<Button
				key="ok"
				sx={{ ml: 1 }}
				variant="contained" onClick={(ev) => {
					// this.autoAddImprints(ev);
					this.formData.filebox_shortcut = this.tempSelectedFilebox;
					// console.log(selectedFoldersCompany.get({stealth: true}), breabCrumbState.get({noproxy: true}));
					
					if (!selectedFoldersCompany.get({stealth: true})){
						if(breabCrumbState.get({noproxy: true}).length > 1){
							this.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length)]
								.reduce((arr,item)=>{
									if(!arr.find(c=> c.id == item.id)) arr.push(item);
									return arr;
								},[]).map(a=> {
									if (a.id === 'company'){
										return '';
									}
									return a.name;
								}).join('/') + '/';
							this.formData.shared_folders_path = this.formData.filebox_shortcut;
							this.formData.drive_folder_id = breabCrumbState.get({noproxy: true})[breabCrumbState.get({noproxy: true}).length - 1]?.id || null;
						} else {
							this.formData.filebox_shortcut = '/';
							this.formData.drive_folder_id = breabCrumbState.get({noproxy: true})[0].id;
							this.formData.shared_folders_path = '/';
						}
					} else {
						this.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length), 
							{...selectedFoldersCompany.get({stealth: true})}].reduce((arr,item)=>{
								if(!arr.find(c=> c.id == item.id)) arr.push(item);
							return arr;
						},[]).map(a=> {
							if (a.id === 'company'){
								return '';
							}
							return a.name;
						}).join('/') + '/';
						this.formData.shared_folders_path = this.formData.filebox_shortcut;
						this.formData.drive_folder_id = selectedFoldersCompany.get({stealth: true})?.id || null;
					}
					// console.log(selectedFoldersCompany.get({stealth: true}), breabCrumbState.get().length, )
					if(
						breabCrumbState.get({noporoxy: true}) 
						&& breabCrumbState.get({noporoxy: true})?.length === 1 
						&& breabCrumbState.get({noporoxy: true})[0]?.id === 'company')
					{
						this.formData.drive_folder_id = breabCrumbState.get({noporoxy: true})[0]?.drive_folder_id
					}
					breabCrumbState.set([]);
					currentFolderTypeState.set(0);
					currentSelectedCompanyFolderState.set(0);
					folderDetailSearch.set({
						non_files: 0,
						order_by: "name asc, updated_date desc, size asc, extension asc, memo asc",
						page_size: 50,
						type: null,
						page_number: 1,
						search: "",
						offset_folder_number: 0,
						offset_file_number: 0,
						offset_document_number: 0,
					});
					this.objMain.setFormData(this.formData);
					driveFoldersNav.set([]);
					this.objMain.modalToggle(false);
					selectedFoldersCompany.set(null);
				}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);
		this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps });
		this.fileBoxSelectionBody({
			path: "/",
			type: "D",
		});

	}

	fileBoxSelectionCallback = (resp) => {
		// console.log(resp);
		this.tempSelectedFilebox = resp;
	}

	fileBoxSelectionBody = (options) => {
		let { ModalBody } = this.objMain.state;
		const breabCrumbDefault = driveFoldersNav?.get({ stealth: true,noproxy: true })?.find((a) => a.type === 6);
		driveDetailLoading.set(true);
		if (!breabCrumbDefault){
			getFoldersTree({
				page_number: 1,
				page_size: 100,
				parent_id: "null",
			  }).then(r=>{
				// console.log(r.data.payload)
				breabCrumbState.set([r.data.payload.find(a=> a.type === 6)].map(mappedDataToTreeView));
				ModalBody = () => {
					return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true}/>} /></Suspense>;
				};
				this.objMain.updateState({ ModalBody });
			  });
		} else {
			breabCrumbState.set([breabCrumbDefault]);
			driveDetailLoading.set(false);	
			ModalBody = () => {
				return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true}/>} /></Suspense>;
			};
			this.objMain.updateState({ ModalBody });
		}
		
		// if (breabCrumbDefault){
		// 	breabCrumbState.set([breabCrumbState]);

		// 	ModalBody = () => {
		// 		return <Suspense fallback={<Loading />}><DriveLayout isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true}/>} /></Suspense>;
		// 	};
		// 	this.objMain.updateState({ ModalBody });
		// } else {
		
		// }
		// this.setState({ FileboxStorage });
	}

	CurrencyItems = () => {
		let { PageData } = this.objMain.state;
		let { currencies } = PageData;
		let OptionItems = [];
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { doc_types } = PageData;

		let ItemElements = [];
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	PreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	SignLevelItems = () => {
		let { t } = this.props;
		let items = {
			0: "soft",
			1: "mixed",
			2: "strong",
		}

		let ItemElements = [];
		for (let i in items) {
			ItemElements.push(<option key={i} value={i}>{t(`common:documents.document-info.sign-level-${items[i]}`)}</option>);
		}

		return ItemElements;
	}

	DocdateOfNotice = () => {
		let { t, name } = this.props;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData, UserInfo } = this.objMain.state;

		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i == 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i == 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			
			ItemElements.push(<option key={i} value={i}>{t(label)}</option>);
			// ItemElements.push(
			// 	<FormControlLabel key={i} value={i} label={label} className="date-noti label" control={<Radio {...radioProps} />} />
			// );
		}

		console.log(UserInfo);
		if (!formData.dateofnoti && formData.dateofnoti != 0 && UserInfo.dateofnoti != null) {
			formData.dateofnoti = parseInt(UserInfo.dateofnoti);
			if (!formData.select_datenoti1 && parseInt(UserInfo.dateofnoti) == 0) {
				formData.select_datenoti1 = UserInfo.select_datenoti;
			}
		}

		let radioProps = {
			"onChange": (ev) => {
				let { isInvalid, msgValidate } = this.objMain.state;
				if (ev.target.value) {
					formData.select_datenoti1 = "";
				}
				formData.dateofnoti = parseInt(ev.target.value);
				if(parseInt(ev.target.value) != 0){
					isInvalid.select_datenoti1 = false;
				} else {
					msgValidate = t("common:message.input.required-select_datenoti1")
					isInvalid.select_datenoti1 = true;
				}
				this.objMain.setState({ isInvalid, msgValidate });
				this.objMain.setFormData(formData);

			},
		};
		let dateOfNotiValidate = this.ChkDateNoti();
		let chkMsgErr = "none";
		if (dateOfNotiValidate && parseInt(formData.dateofnoti) !== 1) {
			chkMsgErr = "block";
		}
		return (
			// <RadioGroup row name="dateofnoti" aria-labelledby="demo-row-radio-buttons-group-label" value={formData.dateofnoti} >
			// 	{ItemElements}
			// </RadioGroup>
			<Form.Group as={Col} xs={12} md={6} className={`custom-form-outline`}>
				<Form.Label>{t(`common:documents.document-info.select_datenoti`)}</Form.Label>
				<InputGroup className="mb-2" hasValidation >
					<Form.Control
						name='dateofnoti'
						value={formData.dateofnoti}
						as='select'
						children={ItemElements}
						{...radioProps}
					/>
				</InputGroup>
				<FormHelperText style={{ color: "#dc3545", display: chkMsgErr }}>{t(`common:documents.document-info.msgerrorselect_noti`)}</FormHelperText>
			</Form.Group>
		);
	}

	DateNotiRequired = () => {
		let { formData } = this.objMain.state;

		if (formData.dateofnoti) {
			if (formData.dateofnoti == 1) {
				return false;
			}
			return true;
		} else if (formData.dateofnoti === 0) {
			return true;
		}

		return false;
	}

	ChkDateNoti = () => {
		let { formData } = this.objMain.state;

		let repExpiry_date = formData.expiry_date.replaceAll("-", "/");
		let currentDate = new Date();
		let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
		let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');

		if (calculateDateChk < formData.dateofnoti) {
			return true;
		}
		return false;
	}

	// ChksSelectDateNoti = (ev) => {
	// 	let { formData } = this.objMain.state;
	// 	let repExpiry_date = formData.select_datenoti1.replaceAll("-", "/");
	// 	let currentDate = new Date();
	// 	let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
	// 	let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');
	// 	// console.log('run here', formData.select_datenoti1,new Date(formData.select_datenoti1).getFullYear(), calculateDateChk, formatcurrentDate)

	// 	if ((isNaN(calculateDateChk) || calculateDateChk < 0) && new Date(formData.select_datenoti1).getFullYear() > 1000) {
	// 		formData.select_datenoti1 = "";
	// 		this.objMain.setFormData(formData);
	// 	}
	// }

	
	SignLevelCallback = () => {
		let { formData } = this.objMain.state;

		if (formData.sign_level.toString() === "0") {
			formData.use_digital_certificate = false;
		} else if (formData.sign_level.toString() === "2") {
			formData.use_digital_certificate = true;
		}
		formData.certificate_type =  Number(localStorage.getItem("prev_certificate_type")) || 1;
		if(formData.use_digital_certificate === true){
			formData.certificate_type == 2 ? formData.company_seal = true : formData.company_seal = false;
		}else{
			formData.company_seal = false;
		}

		const signerIds = Object.keys(formData.signers || '{}')
		if(this.formData.sign_level == 0 && formData.sign_level == 2){
			if(signerIds?.length > 0){
				signerIds?.map((key) => {
					if(formData.signers[key].user_type == 0 ){
						formData.signers[key].certificate_type = formData.certificate_type
						formData.signers[key].self_certificate = true
					}
				})
			}
		}

		this.objMain.setFormData(formData);
	}


	SignerForm = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;
		let { DocdateOfNotice } = this;
		let { formData } = this.objMain.state;
		// let ChkDateNoti = this.ChkDateNoti();

		// console.log('props.attribute', formData)
		if (formData?.contract_date){
			formData.contract_date = formData.contract_date.substring(0, 10);
		}

		if (formData?.expiry_date){
			formData.expiry_date = formData.expiry_date.substring(0, 10);
		}
		if (formData?.select_datenoti1){
			formData.select_datenoti1 = formData.select_datenoti1.substring(0, 10);
		}
		
		let selectDateNotiValidate = false;
		let chkDatenotiSelected = "none";
		if (formData.dateofnoti == '0') {
			chkDatenotiSelected = "block";
			selectDateNotiValidate = true;
		}

		let dateOfNotiValidate = this.ChkDateNoti();
		let chkMsgErr = "none";
		if (dateOfNotiValidate && parseInt(formData.dateofnoti) !== 1) {
			chkMsgErr = "block";
		}

		// let { CounterPartyList } = this;
		// let { Form }
		// console.log(this.formData.sign_level);
		// console.log(this.objMain.formData.sign_level);
		let required = this.DateNotiRequired();
		let validate = this.DateNotiRequired();

		return (

			<div className="form-body">

				<Form.Row>
					{FieldControl({ name: "document_type", as: "select", xs: 12, md: 4, children: this.DocumentTypeItems() })}
					{FieldControl({ name: "sign_level", as: "select", xs: 12, md: 4, children: this.SignLevelItems(), change: this.SignLevelCallback, disabled:  formData.is_revision})}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "doc_title", xs: 12, md: 12, required: true, validate: "true" })}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "amount", type: "number", xs: 8, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, children: this.CurrencyItems() })}
				</Form.Row>

				{/* <Form.Row>
					{FieldControl({ name: "counter_party_name", list: "counter-party", autoComplete: "true", xs: 12, md: 6 })}
					<CounterPartyList/>
				</Form.Row> */}

				<Form.Row>
					{FieldControl({ name: "contract_date", type: "date", xs: 12, md: 6 })}
					{FieldControl({ name: "expiry_date", type: "date", xs: 12, md: 6, required, validate: validate ? "true" : "false" })}
				</Form.Row>

				{/* <Form.Row>
					{FieldControl({ name: "effective_date", type: "date", xs: 12, md: 6 })}
					{FieldControl({ name: "dateofnoti", as: "select", xs: 12, md: 6, children: DocdateOfNotice() })}
				</Form.Row> */}

				{/* <FormControl key="dateofnoti" className="date-noti label">
					<FormLabel >{t(`common:documents.document-info.select_datenoti`)}</FormLabel> */}
					{/* <DocdateOfNotice /> */}
					{/* <FormHelperText style={{ color: "#dc3545", display: chkMsgErr }}>{t(`common:documents.document-info.msgerrorselect_noti`)}</FormHelperText>
				</FormControl> */}
				<Form.Row>
					<DocdateOfNotice />
					{FieldControl({ name: "select_datenoti1", type: "date", xs: 12, md: 6, validate: selectDateNotiValidate ? "true" : "false", required: selectDateNotiValidate, style:{ display: chkDatenotiSelected } })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "product", xs: 12, md: 12 })}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "reference", xs: 12, md: 12 })}
				</Form.Row>

				<Form.Row>
					{FieldControl({
						name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
						className: "filebox-shortcut-path",
						prepend: () => {
							return (
								<InputGroup.Prepend className="mb-3">
									<Button variant="outlined"
										sx={{ height: 40, mr: -1 }}
										className="input-group-child" onClick={this.showFileboxPathSelection}>
										<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
									</Button>
								</InputGroup.Prepend>
							)
						}
					})}
				</Form.Row>

				<div key={0} className="attributes-wrapper">
					{this.renderAttributes()}
				</div>

				<Form.Row className="attributes-wrapper">

					<Form.Group as={Col} controlId="attributes">
						<Button variant="outlined"
							onClick={this.addAttribute} className="btn-custom attributes" >
							<FontAwesomeIcon icon={faPlus} />
							<span className="label">{t('common:documents.document-info.attributes')}</span>
						</Button>
					</Form.Group>

				</Form.Row>



			</div>
		);
	}

	SenderForm = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;
		// let { CounterPartyList } = this;
		// let { Form }

		return (

			<div className="form-body">

				<Form.Row>
					{FieldControl({ name: "document_type", as: "select", xs: 12, md: 4, children: this.DocumentTypeItems() })}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "doc_title", xs: 12, md: 12, required: true, validate: "true" })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "amount", type: "number", step: "0.01", xs: 8, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, children: this.CurrencyItems() })}
				</Form.Row>

				{/* <Form.Row>
					{FieldControl({ name: "supplier_counter_party_name", list: "counter-party", autoComplete: "true", xs: 12, md: 6 })}
					<CounterPartyList/>
				</Form.Row> */}

				<Form.Row>
					{FieldControl({ name: "product", xs: 12, md: 12 })}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "reference", xs: 12, md: 12 })}
				</Form.Row>

				<Form.Row>
					{FieldControl({
						name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
						className: "filebox-shortcut-path",
						prepend: () => {
							return (
								<InputGroup.Prepend className="mb-3">
									<Button variant="outlined"
										sx={{ height: 40, mr: -1 }}
										className="input-group-child" onClick={this.showFileboxPathSelection}>
										<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
									</Button>
								</InputGroup.Prepend>
							)
						}
					})}
				</Form.Row>

				<div className="attributes-wrapper">
					{this.renderAttributes()}
				</div>
				<Form.Row className="attributes-wrapper">

					<Form.Group as={Col} controlId="attributes">
						<Button variant="outlined" onClick={this.addAttribute} className="btn-custom attributes" >
							<FontAwesomeIcon icon={faPlus} />
							<span className="label">{t('common:documents.document-info.attributes')}</span>
						</Button>
					</Form.Group>
				</Form.Row>



			</div>
		);
	}

	CircleForm = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;
		// let { Form }
		// console.log(this.formData.sign_level);
		// console.log(this.objMain.formData.sign_level);
		return (

			<div className="form-body">

				<Form.Row>
					{FieldControl({ name: "document_type", as: "select", xs: 12, md: 4, children: this.DocumentTypeItems() })}
					{FieldControl({ name: "sign_level", as: "select", xs: 12, md: 4, children: this.SignLevelItems(), change: this.SignLevelCallback })}
				</Form.Row>
				<Form.Row>
					{FieldControl({ name: "doc_title", xs: 12, md: 12, required: true, validate: "true" })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "amount", type: "number", step: "0.01", xs: 8, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, children: this.CurrencyItems() })}
				</Form.Row>

				
				{/* for signer */}
				<Form.Row>
					{FieldControl({ name: "document_create_date", type: "date", xs: 12, md: 6, required: true, validate: "true" })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "counter_party_name", list: "counter-party", autoComplete: "off", xs: 12, md: 6 })}
					{/* <this.CounterPartyList/> */}
					<datalist ref={this.refDataList} id="counter-party">
						{this.objMain.state.counterPartyNameList?.map((counterPartyName, i) => <option key={`${counterPartyName}-${i}`} value={counterPartyName} />)}
					</datalist>
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "content", type: "textarea", xs: 12, md: 12 })}
				</Form.Row>

				<Form.Row>
					{FieldControl({
						name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
						className: "filebox-shortcut-path",
						prepend: () => {
							return (
								<InputGroup.Prepend className="mb-3">
									<Button variant="outlined"
										sx={{ height: 40, mr: -1 }}
										className="input-group-child" onClick={this.showFileboxPathSelection}>
										<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
									</Button>
								</InputGroup.Prepend>
							)
						}
					})}
				</Form.Row>

				<div className="attributes-wrapper">
					{this.renderAttributes()}
				</div>

				<Form.Row className="attributes-wrapper">

					<Form.Group as={Col} controlId="attributes">
						<Button
							variant="outlined"
							onClick={this.addAttribute} className="btn-custom attributes" >
							<FontAwesomeIcon icon={faPlus} />
							<span className="label">{t('common:documents.document-info.attributes')}</span>
						</Button>
					</Form.Group>

				</Form.Row>

			</div>
		);
	}

	/* PDFView verification form */
	onDocumentLoadSuccess = async (pdfObject) => {
		let {
            PDFFile,
        } = this.objMain.state;

		if (PDFFile.tryPassword) {
			PDFFile.password = PDFFile.tryPassword;
			delete PDFFile.tryPassword;
		}

        this.objMain.updateState({ PDFLoadSuccess: true, PDFFile });
    }

    onDocumentLoadError = (pdfObject) => {
        // console.log(pdfObject);
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.load-pdf-fail")}</span>
            </div>
        );
    }

	increasePreviewSize = (ev) => {
        let { scale = 1 } = this.objMain.state;
        scale += 0.3;
        if (scale > 10) {
            scale = 10;
        }
        scale = parseFloat(scale.toFixed(2));
        this.objMain.updateState({ scale, autoFit: false });
    }

    decreasePreviewSize = (ev) => {
        let { scale = 1 } = this.objMain.state;
        scale -= 0.3;
        if (scale < 0.1) {
            scale = 0.1;
        }
        scale = parseFloat(scale.toFixed(2));
        this.objMain.updateState({ scale, autoFit: false });
    }

	ActionController = () => {
        let { PDFFile } = this.objMain.state;

        // if (!controller) {
        //     return null;
        // }

        let PageInfo = "Loading..."
        if (PDFFile.initialDone) {
            PageInfo = `${1}`;
        }

        return (
            <div className="control-container">

                {/* <ButtonPDFFileInfo /> */}

                <div className="view-size-controller">
                    <div className="btn size-decrease" onClick={this.decreasePreviewSize}>
                        <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div className="btn size-increase" onClick={this.increasePreviewSize}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>


                <div className="page-index-info">
                    <span className="info-label">{PageInfo}</span>
                </div>

                

            </div>
        );
    }

	PDFPages = () => {

        let { PDFFile, showAll = true, scale = 1 } = this.objMain.state;
		if (!PDFFile.initialDone) {
            return null;
        }

        let Pages = [];
        for (let i = 0; i < PDFFile.numPages; i++) {
            let active = "";
            if (i + 1 === parseInt(PDFFile.currentPage)) {
                active = " active";
            }
            if (showAll) {
                active = " active";
            }

            Pages.push(
                <div key={i} className={`pdf-page-panel${active}`}>
                    <Page debug={true} key={i} renderMode="canvas" pageNumber={i + 1} renderTextLayer={false} scale={scale} className={`pdf-custom-page scale-${scale}${active}`} />
                </div>
            );
        }

        return Pages;
    }

	/* PDFView verification form */

	VerificationForm = () => {
		return (
			<VerificationForm 
				t={this.props.t}
				baseClass={this.constructor}
				objMain={this.objMain} 
				formData={this.formData}
			/>
		);
	}

	CounterPartyList = () => {
		let { counterPartyNameList } = this.objMain.state;

		let row = [];

		for (let i = 0; i < counterPartyNameList.length; i++) {
			let counterPartyName = counterPartyNameList[i];

			row.push(
				<option key={`${counterPartyName}-${i}`} value={counterPartyName} />
			);
		}
		return (
			<datalist id="counter-party">
				{row}
			</datalist>
		);
	}

	DocInfoForm = () => {
		let { creationType } = this.objMain.state;

		if (creationType === "signer") {
			return this.SignerForm();
		} else if (creationType === "sender") {
			return this.SenderForm();
		} else if (creationType === "circle") {
			return this.CircleForm();
		} else if (creationType === "verification") {
			return this.VerificationForm();
		} else {
			return null;
		}
	}

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;
		//dateofnoti
		// form['dateofnoti'].setCustomValidity("");

		this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback);
	}

	render() {
		let { t } = this.props;

		let { creationType } = this.objMain.state;

		return (

			<div>
				{creationType === "verification" ? (
					<Form
						id={"form_" + this.name}
						onSubmit={this.formPrepareBeforeSubmit}
						noValidate
						className="full-form"
					>
						{this.DocInfoForm()}
					</Form>
				) : (

					<Container Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >

						<Form
							// validated={formValidate[this.name]}
							id={"form_" + this.name}
							onSubmit={this.formPrepareBeforeSubmit}
							noValidate
							className="full-form"
						>

							<div className="layout-right-title form-header" >
								<h2>{this.headerInfo}</h2>
							</div>

							{this.DocInfoForm()}

							<div className="form-foot">

								<div className="page-action-bottom-relative">
									<div className="wrapper-button-cancel">
										<Button
											variant="contained"
											color="info"
											size='large'
											startIcon={<ChevronLeftIcon />}
											onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
											<span className="btn-label">{t('common:documents.general.back')}</span>
										</Button>
									</div>
									<div className="wrapper-button-submit">
										<Button
											variant="contained"
											type="submit"
											size='large'
											endIcon={<ChevronRightIcon />}>
											<span className="btn-label">{t('common:documents.general.next')}</span>
										</Button>
									</div>
								</div>

							</div>

						</Form>

					</Container >
				)}
			</div>

			
			

		);

	}

}